import React,{useState, useEffect} from 'react'
import Group1018 from "../../../assets/Image/Group1018.svg";
import PREVIEWICONA from "../../../assets/Image/PREVIEWICONA.svg"
import OTPInput from "otp-input-react";
import { Link } from 'react-router-dom';
import LazyLoader from "../../common/lazyLoader";
import PostRequest from '../../apiConnection/postRequest';
import { setLocalStoarge, getLocalStoarge, removeLocalStoargeKey} from "../../common/allfunction";
import logo from "../../../assets/Image/logo.svg";
import { useTranslation } from "react-i18next";



const SuperOTPlogin = (props) =>{
  const [OTP, setOTP] = useState((props.match.params?.otp && props.match.params?.otp!=="" ) ? props.match.params?.otp : "");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [t, i18n] = useTranslation('common');
  const [alerts, setAlerts] = useState({
    alert: "",
    alerttype: "",
    alertclass: "danger",
    showalert: false,
  });
  useEffect(() => {
    handleTranslationLang();
  }, []);
  const handleTranslationLang = () => {
    let lang = getLocalStoarge("langauage");
    if (lang) {
      i18n.changeLanguage(lang)
    }
  }
  const [counter, setCounter] = useState(90);
  const { alert, alerttype, alertclass, showalert } = alerts;
  const showalertMessage= async()=>{
    setTimeout(() => {
   setAlerts({
     alert: "Otp Invia nuovamente con successo.",
     alerttype: "Info",
     alertclass: "info",
     showalert: false,
   })
 }, 3000);  
}
  const authTwoFaVerify = async (e) => {
    e.preventDefault();
    setAlerts({
      alert: "Per favore attendere un attimo.",
      alerttype: "Info",
      alertclass: "info",
      showalert: true,
    });
    setIsLoading(true);
    const text_otp = OTP
    try {
      const payload = {
        otp: text_otp,
        email: email
      };
      
      const OTPResult = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_2FA_OTP}`,
        payload
      );
      if (OTPResult && OTPResult.status === 200) {
        if (OTPResult.data.code === 200) {
          setIsLoading(false);
          const { access_token, expires_in, refresh_token } = OTPResult.data.data.login;
          const logged_user = {
            access_token,
            expires_in,
            refresh_token,
          };
          setLocalStoarge("R3", JSON.stringify(logged_user));
          removeLocalStoargeKey('admin_email');
          const { profile_pic, user_name, language} = OTPResult.data.data.profile;
          const profie_user = {
            profile_pic,
            user_name,
          };
          setLocalStoarge("admin_profile", JSON.stringify(profie_user));
          setLocalStoarge('langauage', (language==0)?"it":'en');
          setAlerts({
            alert: "Accesso effettuato con successo.",
            alerttype: "Success",
            alertclass: "success",
            showalert: true,
          });
            setTimeout(() => {
              window.location.href = "protocol";
            }, 500);
        }
      } else if (OTPResult && OTPResult.status === 203) {
        setIsLoading(false);
        setAlerts({
          alert: OTPResult.data.message,
          alerttype: "Error",
          alertclass: "danger",
          showalert: true,
        });
      }
    } catch (error) {
      setIsLoading(false);
      setAlerts({
        alert: "Internal server error.",
        alerttype: "Error",
        alertclass: "danger",
        showalert: true,
      });
    }
  };

  const resendOtp = async () => {
    setAlerts({
      alert: "Per favore attendere un attimo.",
      alerttype: "Info",
      alertclass: "info",
      showalert: true,
    });
    setIsLoading(true);
    try {
      const payload = {
        email: email,
        role_id:"3"
      };
      const OTPResult = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_AUTH_RESEND_OTP}`,
        payload
      );
      if (OTPResult && OTPResult.status === 200) {
        if (OTPResult.data.code === 200) {
            setIsLoading(false);
            setTimeout(setAlerts({
              alert: "Otp Invia nuovamente con successo.",
              alerttype: "Success",
              alertclass: "success",
              showalert: true,
            }),1000)
            setCounter(90);
            showalertMessage()
            
          }else{
            setIsLoading(false);
            window.localStorage.clear();
            setAlerts({
              alert: "Otp is not send.",
              alerttype: "Error",
              alertclass: "danger",
              showalert: true,
            });
            showalertMessage()

            return false;
          }
      } else if (OTPResult && OTPResult.status === 203) {
        setIsLoading(false);
        setAlerts({
          alert: OTPResult.data.message,
          alerttype: "Error",
          alertclass: "danger",
          showalert: true,
        });
      }
    } catch (error) {
      setIsLoading(false);
      setAlerts({
        alert: "Internal server error.",
        alerttype: "Error",
        alertclass: "danger",
        showalert: true,
      });
      showalertMessage()

    }
  };

  useEffect(() => {
    const authTwoFa = getLocalStoarge("admin_email");
    const urlemail = (props.match.params?.email && props.match.params?.email!=="" ) ? props.match.params?.email : "";
    if ((authTwoFa === null || (authTwoFa===undefined)) && ((urlemail === null) || (urlemail === ""))) {
      window.location.href = "/";
    } else {
      setEmail((urlemail!=="") ? urlemail : authTwoFa);
    }
  }, []);

  // Timer 
  useEffect(() => {
    const timer =
    counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const resetTimer = () =>{
    if(counter==0){
      resendOtp();
    }
  }

  return (
    <>
  <section className="bg_otp autenticazione1 otpipad d-flex align-items-center bgloginsuper-admin">
        <div className="container">
          <div className="row mx-mobile">
          <div className="logo-w">
              {/* <Link to="signin">
                <img src={logo} className="logo" alt="logo" />
              </Link> */}
            </div>
            <div className="auth">
              <div className="icon-aut">
                <img src={Group1018} className="key" alt="icon" />
                <h2 className="otp-heading w-100">{t('user.Otp.Authentication')}</h2>
                <p dangerouslySetInnerHTML={{ __html: t('user.Otp.enter_otp1')}} ></p>
                <form method="POST" action="/action_page.php" className='cdn-center'>
                  <div className="inautho form-group">
                    {showalert ? (
                      <div className={`alert alert-${alertclass}`}>{alert}</div>
                    ) : (
                      <></>
                    )}
                    <OTPInput
                      className="otp-box"
                      value={OTP}
                      onChange={setOTP}
                      autoFocus OTPLength={6}
                      otpType="number"
                      disabled={false} />

                    <small id="emailHelp" className={"form-text2 otpline mb-3" +(counter==0 ? " torna cursor-p" : " ")} onClick={(e) => resetTimer()}>
                    {t('user.Otp.resend')} {counter>0?<span>{t('user.Otp.validation')}{counter}</span>:null} 
                    </small>
                  </div>
                  <br />
                </form>
                <button type="button" onClick={(e) => authTwoFaVerify(e)} className="buttons">{t('user.Otp.confirm')}</button>
                <Link to='/bi-steps'>
                  <div className="torna">
                    <img src={PREVIEWICONA} alt="icon" /> {t('user.Otp.back')}
                  </div>
                </Link>
              </div>
            </div>
          </div>
          {(isLoading) ? <LazyLoader /> : null}

        </div>
      </section>
    </>
  )
}

export default SuperOTPlogin