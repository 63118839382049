import React, { useEffect, useState, useParams } from "react";
import { Link } from "react-router-dom";
import logo from "../../../../assets/Image/logo.svg";
import Group941 from "../../../../assets/Image/Group941.svg";
import Group14351 from "../../../../assets/Image/Group14351.svg";
import Ellipse174 from "../../../../assets/Image/Ellipse174.svg";
import { useTranslation } from "react-i18next";
import { getLocalStoarge } from '../../../common/allfunction';
import { t } from "i18next";
import { NBackResult } from "./NBackResult";
import PostRequest from '../../../apiConnection/postRequest';


export const NBackResume = ({ props }) => {

    const { round, totalTime, resetRound, togglePause, timeRemaining, avgAccuracy, accuracyCurrentRound, params, totalAvgResponseTime, n,free } = props;
    console.log("totalTime", totalTime * 60);
    console.log("timeRemaining-2 props", timeRemaining);
    const [t, i18n] = useTranslation("common");
    const [companyId, setCompanyId] = useState(getLocalStoarge('company_id'));
    const [status, setStatus] = useState("in-complete")
    const [isLoading, setIsLoading] = useState(true);
    // const [payload, setPayload] = useState({});
    const [activityPlayTime, setActivityPlayTime] = useState((totalTime * 60) - timeRemaining)
    // console.log("props", props)
    const component_id = parseInt(params.component_id);
    const repeat_time = parseInt(params.repeat_time);
    const day_sequence = parseInt(params.day_sequence);
    const activity_id = parseInt(params.activity_id);

    useEffect(() => {
        console.log("useeffect-1");
        togglePause();
        // checkStatus();
    }, [])

    useEffect(() => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, [])

    const [payload, setPayload] = useState({
        company_id: companyId,
        component_id: component_id,
        day_sequence: day_sequence,
        repeat_time: repeat_time,
        activity_id: activity_id,
        status: status,
        // activity_score: avgAccuracy,
        activity_score: 0.123,

        activity_play_time: ((totalTime * 60) - timeRemaining),
        activity_round_no: round + 1,
        total_avg_response_time: 123
    })

    useEffect(() => {
        if(timeRemaining > 0){
            submitUserActivityData(payload)
        }
    }, [payload])


    const submitUserActivityData = async (payload_1) => {
        // alert("resume page api called");

        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );

            console.log("refresh_token", refreshToken);
            if (refreshToken) {
                const result = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SUBMIT_USER_ACTIVITY_DATA}`,
                    payload_1,
                    "",
                    ""
                );
                console.log("api_submit_result", result);
                if (result && result.status === 200) {
                    console.log("api_submit_result_1", result);
                    setIsLoading(false);

                } else if (result && result.status === 203) {
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log("error", error)
        }
    }


    const reset = () => {
        console.log("fun_1", resetRound)
        resetRound(accuracyCurrentRound, n);
    }

    const startCountdown = () => {
        console.log("count down started")
        startTimer();
    }

    // const handleClick = () => {
    //     // Ensure that you are invoking the prop function correctly
    //     updateMessage('Hello from Child');
    // };
    // var timeRemaining_1 = 0
    return (
        <>
            {
                // (timeLeft > totalTime) ?
                (timeRemaining > 0) ?
                    <section className="reaction-speed-section">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="stepsin stpelog">
                                        <Link to="dashboard">
                                            <img src={logo} className="logo-steps" alt="logo" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="btnNext-top">
                                        <Link to="/reaction-speed-intro">
                                            <button
                                                className="btn btn-primary reaction-speed-btnEsci"
                                            >
                                                {t("user.activity.reaction_speed.back")}
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="reaction-speed-resume">
                                        <div className="col-md-3 col-sm-12 reaction-speed-resume-1">
                                            <img src={Group941} className="logo-steps" alt="logo" />
                                        </div>
                                        <div className="col-md-6 col-sm-12 reaction-speed-resume-2">
                                            <h2>{t("user.activity.reaction_speed.resume_heading")}</h2>
                                            <p>{t("user.activity.reaction_speed.resume_message")}</p>
                                            <button className="btn btn-primary btnNext" onClick={event => { reset() }}>{t("user.activity.executive_function.continue")}</button>
                                            <div className="col-md-3">
                                                <img src={Ellipse174} className="logo-steps" alt="logo" />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12 reaction-speed-resume-3">
                                            <img src={Group14351} alt="logo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    <NBackResult props={{ "params": params, "avgAccuracy": avgAccuracy, "totalTime": totalTime, "totalAvgResponseTime": totalAvgResponseTime ,"free":free}} />
            }


        </>
    )
}