import { Route } from "react-router-dom";

const SA_UnProtectedRoute = ({ component: Component, ...restOfProps }) => {
  const isAuthenticated = JSON.parse( localStorage.getItem("R3"));
  const isAuthenticatedRoleId = JSON.parse( localStorage.getItem("roleId"));

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        (isAuthenticated?.access_token && isAuthenticatedRoleId && isAuthenticatedRoleId == 3) ? window.location.href='/sa/protocol' : <Component {...props} /> 
      }
    />
  );
}

export default SA_UnProtectedRoute;