import axios from 'axios';
import React from 'react'

export const fileUploadApi = (formData, onUploadProgress) => {

    let role = parseInt(window.localStorage.getItem('roleId'));
    let saved = (role === 2) ? window.localStorage.getItem('R2') : (role === 3) ? window.localStorage.getItem('R3') : window.localStorage.getItem('R1');
    let initialValue = JSON.parse(saved);
    let Token = (initialValue)?.access_token;

    return axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_FILE_UPLOAD}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${Token}`
        },
        onUploadProgress,
    });
}
