import { Route } from "react-router-dom";

const UnProtectedRoute = ({ component: Component, ...restOfProps }) => {
  const isAuthenticated = JSON.parse( localStorage.getItem("R1"));
  const isAuthenticatedRoleId = JSON.parse( localStorage.getItem("roleId"));
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        (isAuthenticated?.access_token && isAuthenticatedRoleId && isAuthenticatedRoleId == 1) ? window.location.href='/app/dashboard' : <Component {...props} /> 
      }
    />
  );
}

export default UnProtectedRoute;