import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../../assets/Image/logo.svg";
import { useTranslation } from "react-i18next";
import { getLocalStoarge } from '../../../common/allfunction';
import BRAIN_NEXT_ICONA from "../../../../assets/Image/BRAIN_NEXT_ICONA.svg";
import { GoNoGo } from "./GoNoGo";
import Circle from "./Circle.svg";
import Hexagone from "./hexagon.svg";
import Square from "./Square.svg";
import Triangle from "./Triangle.svg";
import Rectangle from "./Rectangle.svg"

export const GoNoGoMasterShape = (props) => {
    const [t, i18n] = useTranslation("common");
    const [masterShape, setMasterShape] = useState();
    const [imageUrls, setImageUrls] = useState();
    const [call, setCall] = useState(0);

    // console.log("props", props)
    const component_id = props?.match?.params?.component_id;
    const repeat_time = props?.match?.params?.repeat_time;
    const day_sequence = props?.match?.params?.day_sequence;
    const activity_id = props?.match?.params?.activity_id;
    const [theme, setTheme] = useState(JSON.parse(localStorage.getItem("theme")));
    const [free , setFree] = useState(props?.match?.params?.free);
    const shapes = ["Circle", "Square", "Hexagone", "Triangle", "Rectangle"];

    useEffect(() => {
        document.body.className = (theme) ? theme : 'light';
    }, [theme])

    useEffect(() => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, [])

    useEffect(() => {
        const imageName = shapes[(Math.floor(Math.random() * shapes.length))]
        if (imageName === "Circle") {
            setImageUrls(Circle)
            setMasterShape("Circle")
        }
        if (imageName === "Hexagone") {
            setImageUrls(Hexagone)
            setMasterShape("Hexagone")
        }
        if (imageName === "Square") {
            setImageUrls(Square)
            setMasterShape("Square")
        }
        if (imageName === "Triangle") {
            setImageUrls(Triangle)
            setMasterShape("Triangle")
        }
        if (imageName === "Rectangle") {
            setImageUrls(Rectangle)
            setMasterShape("Rectangle")
        }
    }, [])


    const resetRound = () => {
        setCall(0);
    }

    return (
        <>
            {call <= 0 ?
                <section className="steps-process steps-buttonchange reaction-speed-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="stepsin stpelog">
                                <Link to="/dashboard">
                                    <img src={logo} className="logo-steps" alt="logo" />
                                </Link>
                            </div>
                            <div className="col-md-12 p-l-0">
                                <div className="tabControl">
                                    <div className="tab-content" id="pills-tabContent">
                                        <div
                                            id="pills-1"
                                            role="tabpanel"
                                            aria-labelledby="pills-home-tab">
                                            <div className="reaction-mob">
                                                <div className="fl-xw  set-middlepart">
                                                    <div className="row Topspace on-responsive reverse-d rew-full ipad-tmanage">
                                                        <div className="col-md-6">
                                                            <div className="mt-0 align-top reaction-speed-p">
                                                                <p className="reaction-speed-p-1">{t('user.activity.go_no_go.intro_heading')}</p>
                                                                <p className="reaction-speed-p-2 go-no-go-msg" dangerouslySetInnerHTML={{ __html: t('user.activity.go_no_go_visivo_acustico_simple.master_page_message') }}></p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="go-no-go-game">
                                                                {
                                                                    <>
                                                                        <img src={imageUrls} width="200" height="200" />
                                                                        <p>
                                                                            <strong> {t('user.activity.go_no_go.master_figure_message')}</strong>
                                                                        </p>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="btnNext-top">
                                                            <Link to={`/go-no-go-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}`}>
                                                                <button
                                                                    className="btn btn-primary btnNext"
                                                                >
                                                                    {t("user.activity.reaction_speed.exit")}
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="btnNext">
                                                    <div className="fit-bdots">
                                                        <ul
                                                            className="nav nav-pills nav-fill"
                                                            id="pills-tab"
                                                            role="tablist">
                                                            <li className="nav-item">
                                                                <a
                                                                    onClick={() => changeDot(1)}
                                                                    id="pills-home-tab"
                                                                    data-toggle="pill"
                                                                    href="#pills-1"
                                                                    role="tab"
                                                                    aria-controls="first"
                                                                    aria-selected="true"
                                                                ></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div>
                                                        <div>
                                                        </div>
                                                        <div>
                                                            <button onClick={() => setCall(1)}
                                                                className="btn btn-primary btnNext"
                                                            >
                                                                {t("user.activity.executive_function.start")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                :
                <GoNoGo data={{ "masterShape": masterShape, "params": props.match.params, "resetRound": resetRound , "free":free }} />
            }
        </>
    );
}

