import { useState, useEffect } from "react";
import userIcon from "../../../assets/Image/userIcon.svg";
import password from "../../../assets/Image/password.svg";
import logo from "../../../assets/Image/logo.svg";
import { Link } from "react-router-dom";
import PostRequest from "../../apiConnection/postRequest";
import LazyLoader from "../../common/lazyLoader";
import { getCompany, getDynamicCompany, setLocalStoarge, getLocalStoarge, removeLocalStoarge } from "../../common/allfunction";
import { useTranslation } from "react-i18next";
import hidePassword from "../../../assets/Image/hidePassword.svg";
import showPassword from "../../../assets/Image/showPassword.svg";
import CHEVRON_ICONA_DOWN from '../../../assets/Image/CHEVRON_ICONA_DOWN.svg';
import CHEVRON_ICONA_UP from '../../../assets/Image/CHEVRON_ICONA_UP.svg';



const AdminLogin = (props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [inputValues, setInputValue] = useState({
    email: "",
    password: "",
  });
  const [alerts, setAlerts] = useState({
    alert: "",
    alerttype: "",
    alertclass: "danger",
    showalert: false,
  });
  const { alert, alerttype, alertclass, showalert } = alerts;
  const [t, i18n] = useTranslation('common');
  const [validation, setValidation] = useState({
    email: "",
    password: "",
  });

  // password eye icon
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  useEffect(() => {
    handleTranslationLang();
  }, [selectedLanguage])

  //handle submit updates
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValue({ ...inputValues, [name]: value });
    setAlerts("", "", "", false);
    setValidation({
      email: "",
      password: "",
    });
  };
  const handleTranslationLang = () => {
    let lang = getLocalStoarge("language");
    if (lang) {
      i18n.changeLanguage(lang)
    }
  }
  const [companyId, setCompanyId] = useState()
  const checkValidation = async () => {
    let errors = validation;
    // email validation
    let Emailregex = new RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    const email = inputValues.email;
    if (!email) {
      errors.email = "";
    } else if (!email.match(Emailregex)) {
      errors.email = t('user.login.valid_email');
    } else {
      errors.email = "";
    }
    //password validation
    const Passregex = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/
    );
    const password = inputValues.password;
    if (!password) {
      errors.password = t('user.login.password_req');
    } else if (password.length < 8) {
      errors.password = t('admin.login.wrong_pass');
    } else if (password.length > 32) {
      errors.password =t('admin.login.wrong_pass');
    }
    else if (!password.match(Passregex)) {
      errors.password = t('admin.login.wrong_pass');
    }
    else {
      errors.password = "";
    }

    setValidation(errors);
    let return_val = true;
    await Object.entries(errors).forEach(([key, value]) => {
      if (value !== "") return_val = false;
    });
    return return_val;
  };
  const do_Login = async (e) => {
    e.preventDefault();
    setAlerts({
      alert: t('user.login.wait'),
      alerttype: "Info",
      alertclass: "info",
      showalert: true,
    });
    const { email, password } = inputValues;
    if (
      email == "" ||
      password == "" ||
      email == undefined ||
      password == undefined
    ) {
      setAlerts({
        alert: t('user.login.valid_both'),
        alerttype: "Error",
        alertclass: "danger",
        showalert: true,
      });
      return false;
    }
    const company = companyId;
    setIsLoading(true);
    const validate = await checkValidation();
    if (validate) {
      try {
        const payload = {
          username: email,
          password: password,
          company: company,
          role_id: "2"
        };
        const loginResult = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADMIN_LOGIN}`,
          payload
        );
        if (loginResult && loginResult.status === 200) {
          if (loginResult.data.code === 200) {
            setLocalStoarge('roleId', 2)
            if (loginResult.data.data.login.two_fa == 0) {
              setIsLoading(false);
              const { access_token, expires_in, refresh_token, two_fa } = loginResult.data.data.login;
              const logged_user = {
                access_token,
                expires_in,
                refresh_token,
                two_fa,
              };
              setLocalStoarge("R2", JSON.stringify(logged_user))
              const { profile_pic, user_name, language, company_id } = loginResult.data.data.profile;
              let admin_data = {
                profile_pic,
                user_name
              };
              setLocalStoarge('admin_profile', JSON.stringify(admin_data))
              setLocalStoarge("langauage", language == 0 ? "it" : "en");
              setLocalStoarge("company_id", company_id);
              setLocalStoarge("langauage", language == 0 ? "it" : "en");

              setAlerts({
                alert: t('user.login.success'),
                alerttype: "Success",
                alertclass: "success",
                showalert: true
              });
              setTimeout(() => {
                // window.location.href = "/admin/bi-steps";
                window.location.href = "/admin/pages/dashboard";

              }, 500);
            }
            else {
              setIsLoading(false);
              // Add email for 2fa
              setLocalStoarge("admin_email", email);
              window.location.href = "/admin/bi-otp";
            }
          }
        }
        else if (loginResult && loginResult.status === 203) {
          setIsLoading(false);
          setAlerts({
            alert: t('user.login.invalid_both'),
            alerttype: "Error",
            alertclass: "danger",
            showalert: true,
          });
          // setTimeout(() => {
          //   setAlerts("", "", "", false);
          // },5000)
        }
      } catch (error) {
        setIsLoading(false);
        setAlerts({
          alert: t('user.login.valid_both'),
          alerttype: "Error",
          alertclass: "danger",
          showalert: true,
        });
        // setTimeout(() => {
        //   setAlerts("", "", "", false);
        // },5000)
      }
    } else {
      setIsLoading(false);
      setAlerts("", "", "", false);
    }
  }

  useEffect(() => {
    async function fetchCompanyAPI() {
      const company = await getDynamicCompany(props.match.params.company_id);
      setSelectedLanguage((company.content.language === 0) ? 'it' : 'en');
      setLocalStoarge("language",(company.content.language === 0) ? 'it' : 'en');

      if (company !== false) {
        localStorage.setItem('dynamic_id', props.match.params.company_id)
        setCompanyId(company.document_id);
      }
      else {
        window.location.href = "/admin/invalid"
      }
      
    }
    fetchCompanyAPI()
    handleTranslationLang();
  }, [])

  const handleLanguageChange = (language) => {
    setLocalStoarge("language", language)
    setSelectedLanguage(language);
  };

  const toggleButton = (e) => {
    setIsDropdownOpen(!isDropdownOpen)
  }


  return (
    <>
      <div className="bgadminmob">
        <section className="bgyellowadmin m-top25m commonipadbg loginipad-bg">
          {/* <section className="bg_otp bgyellowadmin m-top25m commonipadbg loginipad-bg"> */}
          <div className="language-block p-4">
            <div className="dropdown">
              <button
                type="button"
                className="dropdown-toggle bg-none"
                onClick={(e) => toggleButton(e)}
              >
                {t("user.login.language")}
                {(!isDropdownOpen) ? <img src={CHEVRON_ICONA_DOWN} className="ml-2" alt="img" /> : <img src={CHEVRON_ICONA_UP} className="ml-2" alt="img" />}
              </button>
              <div className="drop-down-menu-items">
                <div className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
                  <p className="ml-2 p-0 mb-0 switch-para">
                    <input type="radio" name="language" className="cursor-pointer" id="italianRadio"
                      checked={selectedLanguage === 'it'}
                      onChange={() => handleLanguageChange('it')} />
                    <label className="ml-1 cursor-pointer" htmlFor="italianRadio">Italiano</label>
                  </p>
                  <p className="ml-2 p-0 mb-0 switch-para">
                    <input type="radio" name="language" className="cursor-pointer" id="englishRadio"
                      checked={selectedLanguage === 'en'}
                      onChange={() => handleLanguageChange('en')} />
                    <label className="ml-1 cursor-pointer" htmlFor="englishRadio">English</label>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="logo-w">
              <Link to={`${localStorage.getItem("dynamic_id")}`}>
                <img src={logo} className="logo" alt="logo" />
              </Link>

            </div>
            <div className="row top63">
              <div className="forgot">
                <div className="icon-aut">
                  <h2 className="otp-heading">{t('user.login.Welcome')}</h2>
                  <p className="Riemposta">
                    {t('user.login.evolvityservice1')}<br />
                    {t('user.login.evolvityservice2')}
                  </p>
                  <form onSubmit={(e) => do_Login(e)} method="POST">
                    <div className="registrati-form riempostainput">
                      {showalert ? (
                        <div className={`alert alert-${alertclass}`}>{alert}</div>
                      ) : (
                        <></>
                      )}
                      <div className="form-group relative">
                        <img src={userIcon} alt="icon" className="icon_left" />
                        <input
                          type="email"
                          placeholder="User ID"
                          className="form-control"
                          onChange={handleChange}
                          name="email"
                          defaultValue={inputValues.email}
                        />
                        {validation.email && (
                          <p className="danger">{validation.email}</p>
                        )}
                      </div>
                      <div className="form-group relative">
                        <img src={password} alt="icon" className="icon_left" />
                        <input
                          // type="password"
                          type={isRevealPwd ? "text" : "password"}
                          placeholder="Password"
                          className="form-control"
                          autoComplete="off"
                          onChange={handleChange}
                          name="password"
                          defaultValue={inputValues.password}
                        />
                        <img src={isRevealPwd ? hidePassword : showPassword}
                          onClick={() => setIsRevealPwd(prevState => !prevState)} alt="icon"
                          className="eye_icon_right" />
                        {validation.password && (
                          <p className="danger password-validation">{validation.password}</p>
                        )}
                      </div>

                      <Link to="/passforgot">
                      <div className="forgot-password-style">{t('user.login.forgotpass')}</div>
                      </Link>
                      <button type="submit" className="buttons m91"> {t('user.login.login')}</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {(isLoading) ? <LazyLoader /> : null}
          </div>
          <p className="Powered-by">{t("admin.login.powered")}</p>
        </section>

      </div>
    </>
  );
};
export default AdminLogin;
