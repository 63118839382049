import { Route } from "react-router-dom";

const AdminProtectedRoute = ({ component: Component, ...restOfProps }) => {
  const isAuthenticated = JSON.parse( localStorage.getItem("R2"));
  const isAuthenticatedRoleId = JSON.parse( localStorage.getItem("roleId"));
  if((isAuthenticated && isAuthenticatedRoleId == 2)){
    // remove r2
    localStorage.removeItem("R1");
    localStorage.removeItem("R3");
  }
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated?.access_token && isAuthenticatedRoleId == 2? <Component {...props} /> : window.location.href='/admin/signin'
      }
    />
  );
}


export default AdminProtectedRoute;