import React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { getLocalStoarge } from '../common/allfunction';
import PostRequest from '../apiConnection/postRequest';
import GetRequest from "../apiConnection/getRequest";
import { Link } from "react-router-dom";
import logo from "../../assets/Image/logo.svg";
import Group1432 from "../../assets/Image/Group_1432.svg";
import { exists } from 'i18next';
import LazyLoader from '../common/lazyLoader';

export const PreAssessment = (props) => {
    const [t, i18n] = useTranslation("common");
    const [companyId, setCompanyId] = useState(getLocalStoarge('company_id'));
    const [componentId, setComponentId] = useState(props.match.params.component_id);
    const [daySequence, setDaySequence] = useState(props.match.params.day_sequence)
    const [repeatTime, setRepeatTime] = useState(props.match.params.repeat_time)
    const [activityId, setActivityId] = useState(props.match.params.activity_id)
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [counttab, setCountTab] = useState(1);
    const [questionNo, setQuestionNo] = useState(0);
    const [lastPage, setLastPage] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [next, setNext] = useState(false)


    useEffect(() => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
        preAsssessmentData()
    }, [])


    const params = props?.match?.params;

    // console.log("props preassessment", props);
    const preAsssessmentData = async () => {
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );

            // console.log("refresh_token", refreshToken);
            if (refreshToken) {
                const getPreAssessmentData = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_PREASSESSMENT_DATA}?activity_id=${activityId}`,
                    "",
                    "",
                    ""
                );
                if (getPreAssessmentData && getPreAssessmentData.status === 200) {
                    // console.log("getPreAssessmentData_4_1", getPreAssessmentData);
                    setIsLoading(false);
                    if (getPreAssessmentData?.data?.data?.activity_quations?.questions?.length > 0) {
                        // console.log("get-api-data", getPreAssessmentData?.data?.data?.activity_quations?.questions);
                        let arr = []
                        getPreAssessmentData.data.data.activity_quations.questions.forEach((data, index) => {
                            arr = [...arr, {
                                que: data
                            }]
                            setData(arr)
                        })
                    }
                } else if (getPreAssessmentData && getPreAssessmentData.status === 203) {
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log("error", error)
        }
    }
    // console.log("only data", data);
    // console.log("data_1", data[0]?.que?.que);
    // console.log("question no", questionNo, "==", data.length);

    const preAsssessmentDataSubmit = async () => {

        const payload =
        {
            company_id: companyId,
            day_sequence: parseInt(daySequence),
            repeat_time: parseInt(repeatTime),
            activity_id: parseInt(activityId),
            questions: answers
        }
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );

            // console.log("refresh_token", refreshToken);
            if (refreshToken) {
                const result = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SUBMIT_PREASSESSMENT_DATA}`,
                    payload,
                    "",
                    ""
                );
                // console.log("api_submit_result", result);
                if (result && result.status === 200) {
                    setIsLoading(false);
                }
                setIsLoading(false)
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log("error", error)
        }
    }


    const changeQuestion = (q, lastQuesIndex) => {
        // console.log(q + 1, "mm", data.length);
        if ((q + 1) === data.length) {
            //Call function from where we are submiting Q&A data of user
            preAsssessmentDataSubmit()
            setLastPage(true);
        } else {
            setQuestionNo(q + 1);
            let res;
            // console.log("currQuesIndex", lastQuesIndex + 1);
            var currQuesIndex = lastQuesIndex + 1;
            var currQues;
            currQues = data[currQuesIndex].que.que;

            res = doesQuestionExist(answers, currQues);
            setNext(false)
            // console.log("ress", res);
            if (res !== -1) {
                setNext(true)
            } else {
                setNext(false)
            }
        }
    }

    const answerSubmit = (questionType, ques, questionOptions, ans, options_marks, index) => {

        // console.log("questionType", questionType, "questionOptions", questionOptions, "questionOptions", questionOptions, "ans", ans, "options_marks", options_marks, "index", index);

        let score = options_marks[index];

        setNext(true);

        let existingQuestionIndex = doesQuestionExist(answers, ques);
        // console.log("existingQuestionIndex", existingQuestionIndex);
        if (existingQuestionIndex !== -1) {
            let answer_1 = answers?.slice(0);
            answers[existingQuestionIndex].user_answered = ans;
            answers[existingQuestionIndex].score = score;

            setAnswers([...answer_1]);
        } else {
            setAnswers(answers => [...answers, { type: questionType, que: ques, options: questionOptions, user_answered: ans, score: score }]);
        }
    }

    // console.log("answer_1", answers);
    // console.log("next", next);

    const doesQuestionExist = (arr, newQuestion) => {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].que === newQuestion) {

                return i; // Question already exists in the array
            }
        }
        return -1; // Question does not exist in the array
    }


    return (
        <>
            {lastPage === false ?
                <section className="pre-assessment-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="stepsin stpelog">
                                <Link to="/dashboard">
                                    <img src={logo} className="logo-steps" alt="logo" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-6">
                            <div className="tabControl bw-tab-control">
                                {/* <div className="tab-content" id="pills-tabContent">
                                    <div
                                        className={`tab-pane fade qbg2 commonbg-question ${counttab === 1 ? "show active" : ""
                                            }`}
                                        id="pills-2"
                                        role="tabpanel"
                                        aria-labelledby="pills-profile-tab"
                                    > */}
                                <div className="row question-w on-responsive mx-0 justify-content-center">
                                    {/* <div className="col-md-3"></div> */}
                                    <div className="col-md-12 col-sm-6 tab-fully">
                                        {isLoading ? <LazyLoader /> : ''}
                                        <div className="auth question_steps bw-auth">
                                            {data && data.length > 0 ? data?.map((data_1, i) => (
                                                <>
                                                    {questionNo === i ?
                                                        <>
                                                            <div className="pre-assessment-question pw-assessment-question-mobile">
                                                                <div className="pre-assessment-question-1">
                                                                    <strong><p className='pre-assessment-question-text'>{data_1?.que?.que}</p></strong>
                                                                </div>
                                                                <div className="row topfields">
                                                                    <div className="col-md-12 p-0-mobile">
                                                                        <div className="">
                                                                            {answers.some((item) => item.que === data_1?.que?.que) ?
                                                                                <div>
                                                                                    {
                                                                                        data_1?.que?.options?.map((data_2, i) => {
                                                                                            // return <div className="col-md-12 col-sm-6 m-widhdevice">
                                                                                            return <div className="col-md-12 col-sm-6">

                                                                                                <div>
                                                                                                    <div className="form-group">
                                                                                                        <input
                                                                                                            type="radio"
                                                                                                            className="form-control"
                                                                                                            name={data_1.que.que}
                                                                                                            value={data_2}
                                                                                                            key={i}
                                                                                                        />
                                                                                                        {(answers.find((item_1) => item_1.que === data_1.que.que).user_answered) === data_2 ?
                                                                                                            <div className={"pre-assessment-answered"} onClick={() => answerSubmit(data_1.que.type, data_1.que.que, data_1.que.options, data_2, data_1?.que?.options_marks, i)}>
                                                                                                                <label for={data_2} >{data_2}</label>
                                                                                                            </div>
                                                                                                            :
                                                                                                            <div className={"pre-assessment"} onClick={() => answerSubmit(data_1.que.type, data_1.que.que, data_1.que.options, data_2, data_1?.que?.options_marks, i)}>
                                                                                                                <label for={data_2} >{data_2}</label>
                                                                                                            </div>
                                                                                                        }

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        })
                                                                                    }

                                                                                </div>

                                                                                :

                                                                                <div>
                                                                                    {
                                                                                        data_1?.que?.options?.map((data_2, i) => {
                                                                                            // return <div className="col-md-12 m-widhdevice">
                                                                                            return <div className="col-md-12">

                                                                                                <div>
                                                                                                    <div className="form-group relative">
                                                                                                        <input
                                                                                                            type="radio"
                                                                                                            className="form-control"
                                                                                                            name={data_1.que.que}
                                                                                                            value={data_2}
                                                                                                            key={i}
                                                                                                        />

                                                                                                        <div className="pre-assessment" onClick={() => answerSubmit(data_1.que.type, data_1.que.que, data_1.que.options, data_2, data_1?.que?.options_marks, i)}>
                                                                                                            <label for={data_2} >{data_2}</label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div >
                                                            </div>
                                                            {/* buttons */}
                                                            {questionNo > 0 ?
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary btnNext pre-back pw-preback-btn"
                                                                    onClick={() => (setQuestionNo(questionNo - 1), setNext(true))}
                                                                >
                                                                    {t("user.mindfulness_training.pre_assessment.pre_assessment_btn_back")}
                                                                </button>
                                                                :

                                                                <Link to={`/mindfulness/${props.match.params.repeat_time}`}>
                                                                    <button type='button' className="btn btn-primary btnNext pre-back pw-preback-btn">{t("user.mindfulness_training.pre_assessment.pre_assessment_btn_back")}</button>
                                                                </Link>
                                                            }

                                                            {questionNo < data.length ?
                                                                <div style={{float:'right'}}>
                                                                <button
                                                                    type="button"
                                                                    className={next ? "bw-pre-assesment-question-next pw-preback-btn" : "bw-pre-assesment-question-disable pw-preback-btn"}
                                                                    onClick={() => changeQuestion(questionNo, i)}
                                                                    disabled={next ? false : true}
                                                                >
                                                                    {t("user.mindfulness_training.pre_assessment.pre_assessment_btn_next")}
                                                                </button>
                                                                </div>
                                                                : null
                                                            }
                                                        </>
                                                        : null}
                                                </>
                                            )) : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div id="btnNext">
                                    <div className="fit-bdots bw-dots-none">
                                        <ul
                                            className="nav nav-pills nav-fill"
                                            id="pills-tab"
                                            role="tablist"
                                        >
                                            {data && data.length > 0 ? data?.map((data_1, i) => (
                                                <li className="nav-item">
                                                    <a
                                                        className={`nav-link  ${questionNo === i ? "active" : ""
                                                            }`}
                                                        onClick={() => setQuestionNo(i)}
                                                        id="pills-tab-pre-assesment"
                                                        data-toggle="pill"
                                                        href="#pills-1"
                                                        onKeyDown={(e) => {
                                                            if (e.keyCode === 9) e.preventDefault();
                                                        }}

                                                    ></a>
                                                </li>
                                            )) : null}
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
                :
                <section id="pre-assessment-1">
                    <div className="stepsin stpelog">
                        <Link to="/dashboard">
                            <img src={logo} className="logo-steps" alt="logo" />
                        </Link>
                    </div>
                    <div className="container-fluid">
                        <div className="col-sm-12">
                            <div className="row pre-assessment-last">
                                <div className="col-sm-6">
                                    <div className="pre-assessment-div-1">
                                        <h2 className="pre-assessment-heading">{t("user.activity.mindfulness_pre_Assessment.heading")}</h2>
                                        <div className="pre-assessment-div-1-1">
                                            <p><strong> {t("user.activity.mindfulness_pre_Assessment.heading_1")}</strong></p>
                                            <p>{t("user.activity.mindfulness_pre_Assessment.para")}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="pre-assessment-div-2">
                                        <img src={Group1432} alt="icon" className="pre-assessment-thumb" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pre-assessment-active bw-active">
                            {/* <Link to={`/congnitive-training/${parseInt(params.repeat_time)}/${parseInt(params.day_sequence)}/${parseInt(params.component_id)}/true`}> */}
                            <Link to={`/mindfulness/${parseInt(repeatTime)}/${parseInt(daySequence)}/${parseInt(componentId)}`}>
                                <button
                                    type="button"
                                    className="btn"
                                    onClick={() => setQuestionNo(questionNo - 1)}
                                >
                                    {t("user.mindfulness_training.pre_assessment.pre_assessment_btn_activity")}
                                </button>
                            </Link>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}