import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../../assets/Image/logo.svg";
import { useTranslation } from "react-i18next";
import Group1432 from "../../../../assets/Image/Group_1432.svg";
import { getLocalStoarge, setLocalStoarge } from '../../../common/allfunction';
import PostRequest from '../../../apiConnection/postRequest';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import bw_group_1432 from "../../../../assets/Image/bw_group_1432.svg";

export const WorkingMemoryResult = ({ props }) => {
  const { params, avgAccuracy, totalTime, totalAvgResponseTime, round, timeRemaining ,free } = props;
  // console.log("props", props);

  const [t, i18n] = useTranslation("common");
  const [companyId, setCompanyId] = useState(getLocalStoarge('company_id'));
  const [isLoading, setIsLoading] = useState(true);

  const [payload, setPayload] = useState({
    company_id: companyId,
    component_id: parseInt(params.component_id),
    day_sequence: parseInt(params.day_sequence),
    repeat_time: parseInt(params.repeat_time),
    activity_id: parseInt(params.activity_id),
    status: "complete",
    activity_score: avgAccuracy,
    activity_play_time: ((totalTime * 60) - timeRemaining),
    total_avg_response_time: 0,
    activity_round_no: round + 1
  })

  useEffect(() => {
    let lang = getLocalStoarge("langauage");
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [])

  useEffect(() => {
    // console.log("payload", payload);
    if (free) {
      var resultObj = [];
      if (localStorage.getItem('cognativeResult_'+payload.repeat_time).length > 0) {
        resultObj = JSON.parse(localStorage.getItem('cognativeResult_'+payload.repeat_time));
      }
      resultObj[3] = {
        'activityName': 3,
        'avgAccuracy': avgAccuracy ? avgAccuracy: 0,
        'totalAvgResponseTime': totalAvgResponseTime ?totalAvgResponseTime :0
      }
      setLocalStoarge("cognativeResult_"+payload.repeat_time, JSON.stringify(resultObj));
      setIsLoading(false)
    } else {
      submitUserActivityData(payload);
    }
  }, [payload])


  const submitUserActivityData = async (payload_1) => {
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        "",
        "refresh_token"
      );

      // console.log("refresh_token", refreshToken);
      if (refreshToken) {
        const result = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SUBMIT_USER_ACTIVITY_DATA}`,
          payload_1,
          "",
          ""
        );
        // console.log("api_submit_result", result);
        if (result) {
          if (result.status === 200) {
            // Handle status 200
            // console.log("api_submit_result_1", result);
          } else if (result.status === 203) {
            // Handle status 203
          }
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error)
    }
  }


  function roundUpAvgAccuracy(number, decimals) {
    return Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
  }


  return (
    <section className="reaction-speed-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="stepsin stpelog">
              <Link to="dashboard">
                <img src={logo} className="logo-steps" alt="logo" />
              </Link>
            </div>
          </div>
          <div className="col-md-12">
            <div className="reaction-speed-result">
              <div className="col-md-6">
                <div className="reaction-speed-result-1">
                  <h2>{t("user.activity.reaction_speed.result_heading")}</h2>
                  <p>
                    {t("user.activity.working_memory.result_message_1")} {roundUpAvgAccuracy(avgAccuracy, 1)}  {t("user.activity.working_memory.result_message_2")}
                  </p>
                  <div className="row">
                    <div className="reaction-speed-result-1-1">
                      <div className="col-sm-10 col-md-10 col-lg-6 col-xl-6 col-xxl-6">
                        <p className="reaction-speed-result-reponse-bar">
                          <CircularProgressbar value={avgAccuracy} maxValue={9} strokeWidth={12} styles={buildStyles({
                            pathColor: roundUpAvgAccuracy(avgAccuracy, 1) > 3 ? '#9ECB61' : '#B02418',
                          })}
                          /></p>
                      </div>
                      <div className="col-md-6 reaction-speed-result-accuracy">
                        <h6>{roundUpAvgAccuracy(avgAccuracy, 1)}</h6>
                        <p>{t("user.activity.working_memory.result_accuracy")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className={`reaction-speed-result-2 ${roundUpAvgAccuracy(avgAccuracy, 1) > 3 ? 'tumbs-up' : 'tumbs-down'}`}>
                  {roundUpAvgAccuracy(avgAccuracy, 1) > 3 ?
                    < img src={Group1432} alt="" />
                    :
                    <img src={bw_group_1432} alt="" />
                  }
                </div>
              </div>
            </div>
            <Link to={`/congnitive-training/${parseInt(params.repeat_time)}`}>
              <button className="btn btn-primary btnNext-act">
                {t("user.activity.reaction_speed.exit")}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )

}

