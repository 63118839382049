import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { getLocalStoarge } from '../../../common/allfunction';
import ReactApexChart from 'react-apexcharts';
import PostRequest from '../../../apiConnection/postRequest';
import GetRequest from '../../../apiConnection/getRequest';
import LazyLoader from '../../../common/lazyLoader';


const CognitiveRadarChart = (props) => {
    const [t, i18n] = useTranslation("common");
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(
        {
            series: [

                {
                    name: 'pre-assessment',
                    data: [0,0,0,0,0],
                },
                {
                    name: 'post-assessment',
                    data: [0,0,0,0,0],
                }
            ],
            options: {
                chart: {
                    width: "100%",
                    height: "100%",
                    type: 'radar',
                },
                colors: ['#6D71F9', '#54C1FB'],
                markers: {
                    size: 4,
                    colors: ['#6D71F9', '#54C1FB'],
                    strokeColor: ['#6D71F9', '#54C1FB'],
                    strokeWidth: 2,
                },
                fill: {
                    opacity: 0.1
                },
                legend: {
                    show: true
                },
                xaxis: {
                    categories: [t('user.activityReport.Cognitive-activity1'), t('user.activityReport.Cognitive-activity2'), t('user.activityReport.Cognitive-activity3'), t('user.activityReport.Cognitive-activity4'), t('user.activityReport.Cognitive-activity5')],
                    labels: {
                        style: {
                            fontSize: '14px',
                            fontWeight: 500,
                            color: "#272848",
                            textTransform: 'capitalize'

                        },
                    }
                },
                yaxis: {
                    show: false,
                },
            },
            responsive: [{
                breakpoint: 576,
                options: {},
            }]

        }
    );

    useEffect(() => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
        getRadarGraphData();
    }, [props.repeatTime]);

    const getRadarGraphData = async () => {
        setIsLoading(true)
        try {
            // const refreshToken = await PostRequest(
            //     `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
            //     {},
            //     "",
            //     "refresh_token"
            // );
            // if (refreshToken) {
                const getRadarGraphData = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_USER_COGNITIVE_SPIDER_GRAPH}?repeat_time=${props.repeatTime}`,
                );
                setIsLoading(false)
                if (getRadarGraphData && getRadarGraphData.status === 200) {
                    let result = getRadarGraphData.data.data.ActivitiesData
                    let preArr = []
                    let postArr = [];
                    Object.values(result).forEach(value => {
                        const [index0, index1] = value;
                        preArr.push(index0);
                        postArr.push(index1);
                    });
                    setData(prevData => ({
                        series: [
                            {
                                name: 'Pre-assessment',
                                data: preArr,
                            },
                            {
                                name: 'Post-assessment',
                                data: postArr,
                            },
                        ],
                        options: prevData.options,
                    }));
                }
            // } else {
            //     setIsLoading(false);
            // }
        } catch (error) {
            console.log("error", error)
            setIsLoading(false);
        }
    }

    return (
        <div>
            {isLoading ? <LazyLoader /> : null}
            <div className='second-top-heading mt-3'>
                <div className="col-12">
                    <h2 className='second-page-heading'>{t('user.activityReport.second_heading')}</h2>
                    <p dangerouslySetInnerHTML={{ __html: t('user.activityReport.second_description') }}></p>
                </div>
            </div>

            <div className='row ml-0 mr-0 mt-3 mb-2'>
                <div className="col-lg-12 radar-chart">
                    <ReactApexChart options={data?.options} series={data?.series} type="radar" width='100%' height={600} />
                </div>
            </div>
            <div className='cognitive-activity-name-section second-heading-activity-name'>
                <div>
                    <h5 className='second-page-activity-name'>{t('user.activityReport.Cognitive-activity1')}</h5>
                    <p className='second-page-activity-para'>{t('user.activityReport.Cognitive-activity_para_1')}</p>
                </div>
                <div>
                    <h5 className='second-page-activity-name'>{t('user.activityReport.Cognitive-activity2')}</h5>
                    <p className='second-page-activity-para'>{t('user.activityReport.Cognitive-activity_para_2')}</p>
                </div>
                <div>
                    <h5 className='second-page-activity-name'>{t('user.activityReport.Cognitive-activity3')}</h5>
                    <p className='second-page-activity-para'>{t('user.activityReport.Cognitive-activity_para_3')}</p>
                </div>
                <div>
                    <h5 className='second-page-activity-name'>{t('user.activityReport.Cognitive-activity4')}</h5>
                    <p className='second-page-activity-para'>{t('user.activityReport.Cognitive-activity_para_4')}</p>
                </div>
                <div>
                    <h5 className='second-page-activity-name'>{t('user.activityReport.Cognitive-activity5')}</h5>
                    <p className='second-page-activity-para'>{t('user.activityReport.Cognitive-activity_para_5')}</p>
                </div>
            </div>

        </div>
    )
}

export default CognitiveRadarChart
