import { useState, useEffect } from "react";
import userIcon from "../../../assets/Image/userIcon.svg";
import password from "../../../assets/Image/password.svg";
import hidePassword from "../../../assets/Image/hidePassword.svg";
import showPassword from "../../../assets/Image/showPassword.svg";
import CHEVRON_ICONA_DOWN from '../../../assets/Image/CHEVRON_ICONA_DOWN.svg';
import CHEVRON_ICONA_UP from '../../../assets/Image/CHEVRON_ICONA_UP.svg';

import {
  getDynamicCompany,
  getQuestionairs,
  getLocalStoarge,
  setLocalStoarge,
  removeLocalStoarge,
  encryptFunction
} from "../../common/allfunction";
import logo from "../../../assets/Image/logo.svg";
import { Link ,useHistory} from "react-router-dom";
import PostRequest from "../../apiConnection/postRequest";
import GetRequest from "../../apiConnection/getRequest";
import LazyLoader from "../../common/lazyLoader";
import { useTranslation } from "react-i18next";

const Login = (props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValues, setInputValue] = useState({
    email: "",
    password: "",
  });
  const [alerts, setAlerts] = useState({
    alert: "",
    alerttype: "",
    alertclass: "danger",
    showalert: false,
  });
  const { alert, alerttype, alertclass, showalert } = alerts;
  const [validation, setValidation] = useState({
    email: "",
    password: "",
  });
  const [companyId, setCompanyId] = useState();
  const [t, i18n] = useTranslation("common");
  const history = useHistory();

  useEffect(() => {
    async function fetchCompanyAPI() {
      const company = await getDynamicCompany(props.match.params.company_id);
      if (company !== false) {
        localStorage.setItem('dynamic_id', props.match.params.company_id)
        localStorage.setItem('company_id', company.document_id)
        setCompanyId(company.document_id);
        setLocalStoarge("language", company.content.language === 0 ? 'it' : 'en')
        setSelectedLanguage(company.content.language === 0 ? 'it' : 'en');
        // setLocalStoarge("englishConsentId",company.content.consent_id_en);
        // setLocalStoarge("italianConsentId",company.content.consent_id_it);
        localStorage.setItem('consentIdEnglish', encryptFunction(company.content.consent_id_en));
        localStorage.setItem('consentIdItalian', encryptFunction(company.content.consent_id_it));

      }
      else {
        window.location.href = "/app/invalid"
      }
    }
    fetchCompanyAPI();
    handleTranslationLang();
  }, []);


  useEffect(() => {
    handleTranslationLang();
  }, [selectedLanguage])

  //handle submit updates
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValue({ ...inputValues, [name]: value });
    setAlerts("", "", "", false);
    setValidation({
      email: "",
      password: "",
    });
  };

  const checkValidation = async () => {
    let errors = validation;
    // email validation
    let Emailregex = new RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    const email = inputValues.email;
    if (!email) {
      errors.email = "";
    } else if (!email.match(Emailregex)) {
      errors.email = t("user.login.valid_email");
    } else {
      errors.email = "";
    }
    //password validation
    const Passregex = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/
    );
    const password = inputValues.password;
    if (!password) {
      errors.password = t("user.login.password_req");
    } else if (password.length < 8) {
      errors.password =t("user.login.wrong_pass");
    } else if (password.length > 32) {
      errors.password = t("user.login.wrong_pass");
    } else if (!password.match(Passregex)) {
      errors.password = t("user.login.wrong_pass");
    } else {
      errors.password = "";
    }

    setValidation(errors);
    let return_val = true;
    Object.entries(errors).forEach(([key, value]) => {
      if (value !== "") return_val = false;
    });
    return return_val;
  };

  // password eye icon
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  const do_Login = async (e) => {
    e.preventDefault();
    setAlerts({
      alert: t("user.login.wait"),
      alerttype: "Info",
      alertclass: "info",
      showalert: true,
    });
    const { email, password } = inputValues;
    if (
      email == "" ||
      password == "" ||
      email == undefined ||
      password == undefined
    ) {
      setAlerts({
        alert: t("user.login.valid_both"),
        alerttype: "Error",
        alertclass: "danger",
        showalert: true,
      });
      return false;
    }
    const company = companyId;
    setIsLoading(true);
    const validate = await checkValidation();
    if (validate) {
      try {
        const payload = {
          username: email,
          password: password,
          company: company,
          role_id: "1",
        };

        const loginResult = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_LOGIN}`,
          payload
        );
        if (loginResult && loginResult.status === 200) {
          if (loginResult.data.code === 200) {
             console.log("loginResult", loginResult.data.data.profile.onboarding);
             const { access_token, expires_in, refresh_token, two_fa } = loginResult.data.data.login;
             const logged_user = {
               access_token,
               expires_in,
               refresh_token,
               two_fa,
             };
             setLocalStoarge("R1", JSON.stringify(logged_user));
             setLocalStoarge("roleId", 1);
             const { profile_pic, user_name, language, user_id, scan } = loginResult.data.data.profile;
             const { already_exist } = loginResult.data.data.measurement;
             const { done } = loginResult.data.data.questionnaire;
             const { scanLimitOver } = loginResult.data.data.scanLimit;

             const profie_user = {
               profile_pic,
               user_name,
             };
             setLocalStoarge("noOfScan", scan);
             setLocalStoarge("user_id", user_id);
             setLocalStoarge("profile", JSON.stringify(profie_user));
             setLocalStoarge("measurment_data", already_exist);
             setLocalStoarge("questionnare", done);
             setLocalStoarge("langauage", language == 0 ? "it" : "en");
             setLocalStoarge("scanLimitOver", scanLimitOver);
             // add redirection url for onboarding users
             if(loginResult.data.data.profile.onboarding == 1){
                 setIsLoading(false);
                 window.location.href = "/app/updatepassword";
             }
             else{              
                if (loginResult.data.data.login.two_fa == 0) {             
                 
                  /**
                   * User Guide Popup enable & disable conditition
                   */
                  if(scan > 0){
                    setLocalStoarge("pageGuide", 1);
                    setLocalStoarge("summaryPageGuide", 1);
                  }
                  if (scan == 0)
                  {
                    console.log("assign program to new user");
                    await GetRequest(`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ASSIGN_PROGRAM_NEW_USER}?company_id=${company}`,
                      payload,
                      "",
                      ""
                    );
                  }
                  setAlerts({
                    alert: t("user.login.success"),
                    alerttype: "Success",
                    alertclass: "success",
                    showalert: true,
                  });
                  if(scanLimitOver == 0)
                  {                
                    if (done == 1) {
                      if (already_exist == 1) {
                        setIsLoading(false);
                        setTimeout(() => {
                          window.location.href = "/app/dashboard";
                        }, 500);
                      } else {
                        const scanData = getQuestionairs();
                        if (!scanData) {
                          setIsLoading(false);
                          removeLocalStoarge();
                          setAlerts({
                            alert: t("user.login.server"),
                            alerttype: "Error",
                            alertclass: "danger",
                            showalert: true,
                          });                       
                        }
                      }
                    } else {
                      setIsLoading(false);
                      setTimeout(() => {
                        window.location.href = "/app/steps";
                      }, 500);
                    }             
                }
                else{
                  setIsLoading(false);
                  setTimeout(() => {
                    window.location.href = "/app/dashboard";
                  }, 500);
                }
                } else {
                  // Add email for 2fa
                  setIsLoading(false);
                  setLocalStoarge("user_email", email);
                  window.location.href = "/app/otp";
                }
             }
          }
        } else if (loginResult && loginResult.status === 203) {
        console.log("failed- res", loginResult.data.data.res);
          setIsLoading(false);
          setAlerts({
            alert: (loginResult.data.data.res == "license") ? t("user.login.invalid_license") :t("user.login.valid_both"),
            alerttype: "Error",
            alertclass: "danger",
            showalert: true,
          });
         }
      } catch (error) {
        setIsLoading(false);
        setAlerts({
          alert: t("user.login.valid_both"),
          alerttype: "Error",
          alertclass: "danger",
          showalert: true,
        });      
        console.log("error...",error);
      }
    } else {
      setIsLoading(false);
      setAlerts("", "", "", false);
    }
  };

  const handleTranslationLang = () => {
    let lang = getLocalStoarge("language");
    if (lang) {
      i18n.changeLanguage(lang);
    }
  };

  const toggleButton = (e) => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleLanguageChange = (language) => {
    setLocalStoarge("language", language)
    setSelectedLanguage(language);
    setIsDropdownOpen(false);
    
  };

  return (    
      <div className="bg-imgmobile">
        <section className="mid-align">
          <div className="container-fluid">
            <div className="row ipad-reverse">
              <div className="col-md-5 bg-image relative widfuipad">
                <div className="heading-login text-center mb-3 none-m">
                  <div className="logo-w">
                    <Link to={`${localStorage.getItem("dynamic_id")}`}>
                      <img src={logo} className="logo" alt="logo" />
                    </Link>
                  </div>
                  <h2>{t("user.login.account")}</h2>
                  <p className="mb80">
                    {t("user.login.new")}
                    <br />
                    {t("admin.login.register")}
                  </p>
                  <Link to={`/register/${props.match.params.company_id}`}>
                    <button className="buttons register-btn">{t("admin.login.register_text")}</button>
                  </Link>
                  <p className="bottom">{t("admin.login.powered")}</p>
                </div>
              </div>
              <div className="col-md-7 widfuipad">
                <div className="language-block p-4">
                  <div className="dropdown">
                    <button
                      type="button"
                      className="dropdown-toggle bg-none"
                      onClick={(e) => toggleButton(e)}
                    >
                      {t("user.login.language")}
                      {(!isDropdownOpen) ? <img src={CHEVRON_ICONA_DOWN} className="ml-2" alt="img" /> : <img src={CHEVRON_ICONA_UP} className="ml-2" alt="img" />}
                    </button>
                    <div className="drop-down-menu-items">
                      <div className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
                        <p className="ml-2 p-0 switch-para">
                          <input type="radio" name="language" className="cursor-pointer" id="italianRadio"
                            checked={selectedLanguage === 'it'}
                            onChange={() => handleLanguageChange('it')} />
                          <label className="ml-1 mb-0 cursor-pointer" htmlFor="italianRadio">Italiano</label>
                        </p>
                        <p className="ml-2 p-0 switch-para">
                          <input type="radio" name="language" className="cursor-pointer" id="englishRadio"
                            checked={selectedLanguage === 'en'}
                            onChange={() => handleLanguageChange('en')} />
                          <label className="ml-1 mb-0 cursor-pointer" htmlFor="englishRadio">English</label>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="heading-login formtop227 text-center">
                  <div className="logo-w">
                    <Link to="login">
                      <img src={logo} className="logo normal-hide" alt="logo" />
                    </Link>
                  </div>
                  <h2>{t('user.login.Welcome')}</h2>
                  <p className="mb-50">
                    {t('user.login.evolvityservice1')}<br />
                    {t('user.login.evolvityservice2')}
                  </p>
                </div>
                <form onSubmit={(e) => do_Login(e)} method="POST">
                  <div className="registrati-form">
                    {showalert ? (
                      <div className={`alert alert-${alertclass}`}>{alert}</div>
                    ) : (
                      <></>
                    )}
                    <div className="form-group relative">
                      <img src={userIcon} alt="icon" className="icon_left" />
                      <input
                        type="email"
                        placeholder="User ID"
                        className="form-control"
                        onChange={handleChange}
                        name="email"
                        defaultValue={inputValues.email}
                      />
                      {validation.email && (
                        <p className="danger">{validation.email}</p>
                      )}
                    </div>

                    <div className="form-group relative">
                      <img src={password} alt="icon" className="icon_left" />
                      <input
                        // type="password"
                        type={isRevealPwd ? "text" : "password"}
                        placeholder="Password"
                        className="form-control"
                        autoComplete="off"
                        onChange={handleChange}
                        name="password"
                        defaultValue={inputValues.password}
                      />
                      <img src={isRevealPwd ? hidePassword : showPassword}
                        onClick={() => setIsRevealPwd(prevState => !prevState)} alt="icon"
                        className="eye_icon_right" />
                      {validation.password && (
                        <p className="danger password-validation">{validation.password}</p>
                      )}
                    </div>

                    <small>
                      <Link to="/forget-password">
                        <div className="forgot-password-style">{t('user.login.forgotpass')}</div>
                      </Link>
                    </small>
                    <div className="text-center">
                      <button type="submit" className="buttons accedi">
                        {t('user.login.login')}
                      </button>
                    </div>
                    <p className="regi-mob">
                      {t('user.login.haveaccount')}
                      <Link to={`/register/${props.match.params.company_id}`}>
                        <strong>{t('user.login.Registerurself')}</strong>{" "}
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
            {isLoading ? <LazyLoader /> : null}
          </div>
        </section>
        <p className="Powered">{t("admin.login.powered")}</p>
      </div>  
  );
};
export default Login;
