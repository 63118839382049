import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import cal from "../../../assets/Image/calendar.svg";
const Filter = ({callBack,prefix}) => {
    const [t, i18n] = useTranslation("common");
    const [enFilter,setEnFilter] = useState(0);
    const [wFilter,setWFilter] = useState([]);
    const [dFilter,setDFilter] = useState([]);
    const [activeMonth,setActiveMonth] = useState(0);
    const [activeWeek,setActiveWeek] = useState(0);
    const [activeDate,setActiveDate] = useState(0);
    useEffect(()=>{
        const date = new Date();
        const elements = [];
        for (let index = 0; index < weekOfMonth(date); index++) {
            elements.push(<li key={index}>{index + 1}</li>);
        }
        setWFilter(elements);
        const elementsD = [];
        
        let count = getAllDatesInMonth(date.getFullYear(),date.getMonth());
        count.map((data)=>{
            elementsD.push(<li key={data}>{data}</li>);
        })
       
        setDFilter(elementsD);
    },[]);
    useEffect(()=>{
     
        if(activeWeek > 0 ){
            setActiveDate(0)
            callBack({'month':activeMonth,'week':activeWeek,'date':activeDate,'prefix':prefix});
            getWeek(activeMonth);
            showFilter();
        }
        else if(activeDate > 0 && activeWeek==0){
            setActiveWeek(0)
            callBack({'month':activeMonth,'week':activeWeek,'date':activeDate,'prefix':prefix});
            getWeek(activeMonth);
            showFilter();
        }
    },[activeWeek]);
    useEffect(()=>{
        
        if(activeWeek > 0 && activeDate==0){
            setActiveDate(0)
            callBack({'month':activeMonth,'week':activeWeek,'date':activeDate,'prefix':prefix});
            getWeek(activeMonth);
            showFilter();
        }
        else if(activeDate > 0 ){
            setActiveWeek(0)
            callBack({'month':activeMonth,'week':activeWeek,'date':activeDate,'prefix':prefix});
            getWeek(activeMonth);
            showFilter();
        }
        
    },[activeDate]);
    const showFilter = () =>{
        setEnFilter(!enFilter);
    } 
    const monthsName = [
        `${t("admin.training.Months.January")}`,
        `${t("admin.training.Months.February")}`,
        `${t("admin.training.Months.March")}`,
        `${t("admin.training.Months.April")}`,
        `${t("admin.training.Months.May")}`,
        `${t("admin.training.Months.June")}`,
        `${t("admin.training.Months.July")}`,
        `${t("admin.training.Months.August")}`,
        `${t("admin.training.Months.September")}`,
        `${t("admin.training.Months.October")}`,
        `${t("admin.training.Months.November")}`,
        `${t("admin.training.Months.December")}`,
    ];
    const weekDetail = (month) =>{
        
        setActiveDate(0)
        setActiveWeek(0);
        getWeek(month);
        
        
    }
    const getWeek = (index) =>{
        setActiveMonth(index);
        const date = new Date();
        const elementsD = [];
        let count = getAllDatesInMonth(date.getFullYear(),index-1);
        let monthDate = 0;
        count.map((data)=>{
            elementsD.push(<li key={data} onClick={()=>setActiveDate(data)} className={activeDate==data?`active`:``} >{data}</li>);
            monthDate = data;
        })
        setDFilter(elementsD);
        const weekdate = new Date(date.getFullYear(),index-1,count[count.length-1]);
        const getYear= weekOfMonth(weekdate);
        const elements = [];
        for (let index = 0; index < weekOfMonth(weekdate); index++) {
            elements.push(<li key={index} onClick={()=>setActiveWeek(index+1)} className={activeWeek==index+1?`active`:``} >{index + 1}</li>);
        }
        setWFilter(elements);
    }
    const weekOfMonth = (date) => {
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        const dayOfMonth = date.getDate();
        const adjustedDayOfMonth = dayOfMonth + firstDay.getDay();
        return Math.ceil(adjustedDayOfMonth / 7);
    }
    const getAllDatesInMonth = (year, month) => {
        const date = new Date(year, month, 1);
        const dates = [];
        
        while (date.getMonth() === month) {
            let dt = new Date(date);
            dates.push(dt.getDate());
            date.setDate(date.getDate() + 1);
        }
        return dates;
    }
   
    return (
        <>
            <div className="pull-right position-relative">
                <button className="btn btn-sm trends-flt-btn" onClick={()=>showFilter()}><img src={cal} /> Filtri</button>
                {enFilter?
                    <div className="trends-filter" >
                        <h4>Seleziona mese</h4>
                        <ul>
                            {
                                
                                monthsName.map((data,index)=>{
                                    return(
                                        <li onClick={()=>weekDetail(index+1)} className={activeMonth==index+1?`active`:``}>{data}</li>
                                    )
                                })
                            }
                        </ul>
                        <h4>Seleziona settimana</h4>
                        <ul>
                            {wFilter}
                        </ul>
                        <h4>Seleziona Giorno</h4>
                        <ul>
                            {dFilter}
                           
                        </ul>
                    </div>
                :null}
            </div>
        </>
    );
}

export default Filter;
