import React from 'react'
const { useState } = React;
import { PowerBIEmbed } from 'powerbi-client-react';
import { models, Report, Embed, service, Page } from 'powerbi-client';

function PowerBiDemo() {
    const [report, setReport] = useState();
  return (
    // embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=bb083eae-174c-4985-87f3-bb4ba6a3f43f&groupId=11f2e4c3-1a13-4285-bc40-09d59836052b',

    <div className="">
        <PowerBIEmbed
            embedConfig = {{
                type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                id: 'bb083eae-174c-4985-87f3-bb4ba6a3f43f',
                embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=3467a775-4e63-4457-87de-2467f1851061&groupId=3b7f8651-84bb-45f6-9656-804b7745e8a8&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
                // embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=bb083eae-174c-4985-87f3-bb4ba6a3f43f&groupId=11f2e4c3-1a13-4285-bc40-09d59836052b&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
                accessToken: 'H4sIAAAAAAAEAB2Tt66EVgAF_-W1WCIvYMnFJee4S-qAJeccLP-7n91PNWfO3z92cndj8v3586fNEUgyAIVTaSgf0nq0hitysLkvGM6J6AxFnj_nSUJ8kvfN2Zup-rXDvMPr4JLyrX3Lo6C9go0q34-__YNdykVSxkfx4KVfTaUQVnpW6IzHFsknVjszplIEu9EUmprM7KuSsaE9chaiVu7WaeGrFHtt3j5JZjtad1vchU_c-x9DRYvdrtqCuWfB8hvMkE5VBwttfywK9hAJL9WCLMyHnl5ibvQ0ihxF-aA9awB2t46k5OZj2XKltybPNHHbeK69V80dKZzwASM6i_XFKaBcEiGGwTM6iSnTpdRhGGXU2MnUjuWVgGVqb0dF4rMGpVJeBhkHj_maCkJblCv30a8UQj6QAy0_mxbiA9FyaYZFg9aViMU6I61gYBWyWc_dWfIxjdYB8jigXueHJALpSmKDesIwB0kqEAmPGTMOGsfIGmI5jUdaQ-F4KlV4lDgVpw8q3szu8-FxgpPTd5YpaDotskC-8dmmqQQ_T1HxDYPTvXVKSHtsvyFi9J_3jd1y_sELZuAmmpJZ7fj6drjreDxr7rF4j9n4bHCh3cS7iAS9dFQqD_8rEdvDeuQhlRMdIayEjr08MegJLbKvK1L7OdxWbFUP2ep4-WaivziDjVpwljnhbrIVbkqpx69xtIlDeFbHOshl0hlgF9SpjrjKBfzGW1FzitZh0COgrW_QLCTdHTWDTHAinswkIVQCmkDltwHVMSqPM_1WsW8ssIpnZAVxqM-7r7jWQUeCJ4EW5gGr7r3Y1qHIFXQxUQhkmvpCqiZn42FxFkvXJSjL2LrHY_dLPplhD2N89ifsMyglqyJEvjjQnTqvOYT7XyCtoyyDuQWhKZT5_vzxwy33tI1afv_eybc7OtLz80W8y5IR1xiZ-P0lzkt3mSDZYfYgkFwfVr3ehGc-WdXEqCMQk91zAxIkhQk-TlT1gXYY-I2EHs7cMpHnzuqc97JhdMi2-aBGLbPd8cEZXeNThbNqSxkp73BD5nTml4EJ12BlubCl9a1xVvvY3VWmWCR7cejMIwJ4zxhAIi8fx0sF5ObOx3ibCUKrCdcKV6fVMrt4IMXKYqn4YacsKvJFGgZ5l2Z1SuTeEGw-TznAMno2Vf0M9pJ9So1daOFXw-Ic9dRBXalWsFGI_1nbZHLS3p1IS7-dz0L2F3Xk0CEMNKZUXb3xEGJjWtkkUusixzcpbkFW8Dx6Edyw1jT466__NN9TlS-K_2u5kvvksqz11HiTMC3ONngocP6nvLockm1f8l9MXtypTXVijbwvNwhauk6CLKAHBqE7pHJwrtI-Lq4Y2uBe6QoiIoReZGMX9FuPRyizbGzkPeRIGpd9orodC1BDC23rZR1Z3Fjt08DsFR-sV1hfLqIcUyBGra9qFL77k7MbwwbwYl0NxA8WUc2DWNbZG6rO-zdyeBacTbl9BkU0VxxVb7wYlgVd4X1Xs6p78yP9bhX5QcLK2CIMOHYBsHcZJMeqlk6b0Zqf3Qq9BXpf4Ky1bgWSyu44Oo3eIvFDYhaqpaKflAZ5sEYRGSmsnbwSzIFc6hkBpvwKuXgV1yql38wrkpgmjIYS0oTdQx0OUAPCXBuhhLGvLIMLpaZspe9LLX81__MvuRWr4kIGAAA=.eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOmZhbHNlfX0=',
                tokenType: models.TokenType.Embed,
                settings: {
                    panes: {
                        filters: {
                            expanded: false,
                            visible: false
                        }
                    },
                    background: models.BackgroundType.Transparent,
                }
            }}

            eventHandlers = { 
                new Map([
                    ['loaded', function () {console.log('Report loaded');}],
                    ['rendered', function () {console.log('Report rendered');}],
                    ['error', function (event) {console.log(event.detail);}]
                ])
            }
                
            cssClassName = { "report-style-class" }

           
            getEmbeddedComponent = { (embedObject) => {
                setReport(embedObject);
            } }
        />
    </div>
    
  );
}

export default PowerBiDemo