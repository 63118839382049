import React from "react";
import logo from "../../assets/Image/logo.svg";
import ICONA_LIGHT1 from "../../assets/Image/ICONA_LIGHT1.svg";
import DARK_ICONA2 from "../../assets/Image/DARK_ICONA2.svg";
import ONBOARDINGICONA from "../../assets/Image/ONBOARDINGICONA.svg";
import RIFERIMENTIICONA from "../../assets/Image/RIFERIMENTIICONA.svg";
import { Link } from "react-router-dom";
import Group756 from "../../assets/Image/Group756.svg";
import Group824 from "../../assets/Image/Group824.svg";
import Group758 from "../../assets/Image/Group758.svg";
import FORMAZI from '../../assets/Image/FORMAZIONE_ICONA.svg';
import { useState, useEffect } from "react";
import logoipad from "../../assets/Image/logo-ipad.svg";
import { AiOutlineCaretLeft } from "react-icons/ai";
import terms from "../../assets/Image/T&C_ICONA.svg";
import logout from "../../assets/Image/logout.svg";
import {
  Logout,
  removeLocalStoarge1,
  getLocalStoarge,
  setLocalStoarge
} from "../common/allfunction";
import { useTranslation } from "react-i18next";

function SidebarProfile() {
  const [activebg, setActivebg] = useState("active");
  const [theme, setTheme] = useState("light");
  const [toggleStatus, setToggleStatus] = useState(true);
  const [t, i18n] = useTranslation("common");
  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
    setToggleStatus(theme === "light" ? true : false);
    setLocalStoarge(
      "theme",
      JSON.stringify(theme === "light" ? "dark" : "light")
    );
  };

  useEffect(() => {
    document.body.className = theme;
    handleTranslationLang();
  }, [theme]);
  const handleTranslationLang = () => {
    let lang = getLocalStoarge("langauage");
    if (lang) {
      i18n.changeLanguage(lang);
    }
  };
  useEffect(() => {
    let theme1 = getLocalStoarge("theme");
    theme1 = theme1 ? JSON.parse(theme1) : "";
    setTheme(theme1);
    // console.log(theme1);
    if (theme1) {
      setToggleStatus(theme1 === "light" ? false : true);
    } else {
      setTheme("light");
      setToggleStatus(false);
    }
    setActivebg(pageName);
  }, []);
  const removeToken = () => {
    const logout = Logout();
    if (logout) {
      window.location.href = `/admin/signin/${localStorage.getItem('dynamic_id')}`;
      removeLocalStoarge1();
    } else {
      console.log("something was wrong");
    }
  };

  const path = window.location.pathname;
  const pageName = path.substring(path.lastIndexOf("/") + 1);
  return (
    <>
      <div className="left-dashboard"  style={{ position: "unset" }}>
        <div className="w-100">
          <Link to="/pages/dashboard">
            <img
              src={logo}
              className="img-fluid logo_common ipd-logo-n"
              alt="logo"
            />
            <img
              src={logoipad}
              className="img-fluid logo_common vison-tab"
              alt="logo"
            />
          </Link>
          <Link to="/pages/dashboard">
            <div className="activ-tab">
              <span className="name_left">
                <AiOutlineCaretLeft />
                <span className="ipad-textn">
                  {t("user.sidebarProfile.dashboard")}
                </span>
              </span>
            </div>
          </Link>
          <div className="navigate manageside-bar">
            <ul>
              <li className={activebg == "bi-steps" ? "active" : ""}>
                <Link to="/bi-steps">
                  <img src={ONBOARDINGICONA} alt="icon" />
                  <span className="ipad-textn">
                    {t("user.sidebarProfile.steps")}
                  </span>
                </Link>
              </li>
              <li className={activebg == "bi-privacy" ? "active" : ""}>
                <Link to="/bi-privacy">
                  <img src={Group758} alt="icon" />
                  <span className="ipad-textn">
                    {t("user.sidebarProfile.privacy")}
                  </span>
                </Link>
              </li>
              <li className={activebg == "bi-terms" ? "active" : ""}>
                <Link to="/bi-terms">
                  <img src={terms} alt="icon" />
                  <span className="ipad-textn">
                    {t("user.sidebarProfile.t&c")}
                  </span>
                </Link>
              </li>
              <li
                className={activebg == "scientific-references" ? "active" : ""}
              >
                <Link to="/scientific-references">
                  {" "}
                  <img src={RIFERIMENTIICONA} alt="icon" />
                  <span className="ipad-textn">
                    {t("user.sidebarProfile.referments")}
                  </span>
                </Link>
              </li>

              <li
                className={
                  activebg == "frequently-asked-questions" ? "active" : ""
                }
              >
                <Link to="/frequently-asked-questions">
                  <img src={Group824} alt="icon" />
                  <span className="ipad-textn">
                    {t("user.sidebarProfile.FAQ")}
                  </span>
                </Link>
              </li>

              <li className={activebg == "training" ? "active" : ""}>
                <Link to="/training">
                  <img src={FORMAZI} alt="icon" />
                  <span className="ipad-textn">
                    {t("user.sidebar.training")}
                  </span>
                </Link>
              </li>
              
              <li className={activebg == "my-profile" ? "active" : ""}>
                <Link to="/my-profile">
                  <img src={Group756} alt="icon" />
                  <span className="ipad-textn">
                    {t("user.sidebarProfile.profile")}
                  </span>
                </Link>
              </li>
             

              <li onClick={removeToken} className="cursor">
                <img src={logout} alt="icon" />
                <span className="ipad-textn logout-text">
                  {t("user.sidebarProfile.logout")}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="ali-bottom">
          <div className="bot-set">
            {toggleStatus == true && (
              <input
                type="checkbox"
                className="checkbox"
                id="checkbox"
                defaultChecked={true}
                onClick={toggleTheme}
              />
            )}
            {toggleStatus == false && (
              <input
                type="checkbox"
                className="checkbox"
                id="checkbox"
                onClick={toggleTheme}
              />
            )}
            {/* <label htmlFor="checkbox" className="label">
            <div className="icco">
              <img src={ICONA_LIGHT1} className="img-fluid" alt="logo" />
            </div>
            <div className="moon">
              <img src={DARK_ICONA2} className="img-fluid" alt="logo" />
            </div>
            <div className="anim-dari">
              <div className="ball"></div>
              <div className="light"></div>
              <div className="dark"></div>
            </div>
          </label>
        </div>
        <div className="show_only-mob">
          <span
            onClick={() => {
              openNav();
            }}
          >
            <i className="fas fa-bars closebtn"></i>
          </span>
          <div id="mySidenav" className="sidenav">
            <a
              href=""
              className="closebtn"
              onClick={() => {
                closeNav();
              }}
            >
              <i className="fas fa-times"></i>
            </a>
            <div className="navigate manageside-bar">
              <img src={logo} className="img-fluid mobil_logo" alt="logo" />
              <Link to="/pages/dashboard">
                <div className="activ-tab">
                  <span className="name_left">
                    <i className="fas fa-caret-left"></i>{t('user.sidebarProfile.logout')}
                  </span>
                </div>
              </Link>
              <ul>
                <li className={activebg == "bi-steps" ? "active" : ""}>
                  <Link to="/bi-steps">
                    <img src={ONBOARDINGICONA} alt="icon" />
                    {t('user.sidebarProfile.steps')}
                  </Link>
                </li>
                <li className={activebg == "bi-privacy" ? "active" : ""}>
                  <Link to="/bi-privacy">
                    <img src={Group758} alt="icon" />
                    {t('user.sidebarProfile.privacy')}
                  </Link>
                </li>
                <li className={activebg == "bi-terms" ? "active" : ""}>
                  <Link to="/bi-terms">
                    <img src={Group824} alt="icon" />
                    {t('user.sidebarProfile.t&c')}
                  </Link>
                </li>
                <li
                  className={
                    activebg == "scientific-references" ? "active" : ""
                  }
                >
                  <Link to="/scientific-references">
                    {" "}
                    <img src={RIFERIMENTIICONA} alt="icon" />
                    {t('user.sidebarProfile.referments')}
                  </Link>
                </li>
                <li
                  className={
                    activebg == "frequently-asked-questions" ? "active" : ""
                  }
                >
                  <Link to="/frequently-asked-questions">
                    <img src={Group824} alt="icon" />
                   {t('user.sidebarProfile.FAQ')}
                  </Link>
                </li>
       
                <li className={activebg == "my-profile" ? "active" : ""}>
                  <Link to="/my-profile">
                    <img src={Group756} alt="icon" />
                    {t('user.sidebarProfile.profile')}
                  </Link>
                </li>
                <li>
                  <img src={logout} alt="icon" />
                  <span className="ipad-textn" onClick={removeToken}>
                    {t('user.sidebarProfile.logout')}
                  </span>
                </li>
              </ul>
              <div className="bot-set">
                {toggleStatus == true && (
                  <input
                    type="checkbox"
                    className="checkbox"
                    id="checkbox"
                    defaultChecked={true}
                    onClick={toggleTheme}
                  />
                )}
                {toggleStatus == false && (
                  <input
                    type="checkbox"
                    className="checkbox"
                    id="checkbox"
                    onClick={toggleTheme}
                  />
                )}
                {/* <label htmlFor="checkbox" className="label">
                  <div className="icco">
                    <img src={ICONA_LIGHT1} className="img-fluid" alt="logo" />
                  </div>
                  <div className="moon">
                    <img src={DARK_ICONA2} className="img-fluid" alt="logo" />
                  </div>
                  <div className="anim-dari">
                    <div className="ball"></div>
                    <div className="light"></div>
                    <div className="dark"></div>
                  </div>
                </label> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default SidebarProfile;
