import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../../assets/Image/logo.svg";
import { useTranslation } from "react-i18next";
import { getLocalStoarge } from '../../../common/allfunction';
import GoNoGoIntroImg from "../../../../assets/Image/GoNoGoIntroImg.svg";
import BRAIN_NEXT_ICONA from "../../../../assets/Image/BRAIN_NEXT_ICONA.svg";
{/**Tutorial Modal Image */ }
import TutorialModal from "../../../common/tutorialModal";
import HeaderIcon from "../../../../assets/Image/tutorial/go-no-go.svg";
import step1img from "../../../../assets/Image/tutorial/gng-visivo-acustico-step1.svg";
import step2img from "../../../../assets/Image/tutorial/gng-visivo-acustico-step2.svg";
import step3img from "../../../../assets/Image/tutorial/gng-visivo-acustico-step3.svg";

export const GoNoGoIntro = (props) => {

    const [t, i18n] = useTranslation('common');
    const [btnText, setBtnText] = useState(`${t('user.steps.next')}`);
    const component_id = props?.match?.params?.component_id;
    const repeat_time = props?.match?.params?.repeat_time;
    const day_sequence = props?.match?.params?.day_sequence;
    const activity_id = props?.match?.params?.activity_id;
    {/**Tutorial modal const */ }
    const [popupData, setPopupData] = useState({});
    const [modalIns1, setModalIns1] = useState(false);
    const [theme, setTheme] = useState(JSON.parse(localStorage.getItem("theme")));
    const [free, setFree] = useState(props?.match?.params?.free);
    useEffect(() => {
        document.body.className = (theme) ? theme : 'light';
    }, [theme])

    useEffect(() => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
        {/**Tutorial modal record */ }
        let currentLink = !free ? `/go-no-go/${component_id}/${repeat_time}/${day_sequence}/${activity_id}` : `/go-no-go/${component_id}/${repeat_time}/${day_sequence}/${activity_id}/${free}`;
        let modalRecord = {
            modalHeading: t('user.activity.go_no_go.intro_heading'),
            icon: HeaderIcon,
            step1text: t('user.activity.go_no_go.step1text'),
            step1img: step1img,
            step2text: t('user.activity.go_no_go.step2text'),
            step2img: step2img,
            step3text: t('user.activity.go_no_go.step3text'),
            step3img: step3img,
            startLink: currentLink
        };
        setPopupData({ ...modalRecord });
    }, [])
    const handleModal = () => {
        setModalIns1(false);
    }
    return (
        <>
            {(modalIns1) ? <TutorialModal popUpEnable={modalIns1} handleModal={handleModal} header={popupData} /> : null}
            <section className="steps-process steps-buttonchange reaction-speed-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="stepsin stpelog">
                            <Link to="/dashboard">
                                <img src={logo} className="logo-steps" alt="logo" />
                            </Link>
                        </div>
                        <div className="col-md-12 p-l-0">
                            <div className="tabControl" >
                                <div className="tab-content" id="pills-tabContent">
                                    <div
                                        id="pills-1"
                                        role="tabpanel"
                                        aria-labelledby="pills-home-tab">
                                        <div className="reaction-mob">
                                            <div className="fl-xw  set-middlepart">
                                                <div className="row Topspace on-responsive reverse-d rew-full ipad-tmanage">
                                                    <div className="col-md-7 leftsteps">
                                                        <div className="pal-70">
                                                            <h2 className="steps-heading top-Align">
                                                                {t('user.activity.go_no_go.intro_heading')}
                                                            </h2>
                                                            <p className="deskt-n" dangerouslySetInnerHTML={{ __html: t('user.activity.go_no_go.intro_message') }}></p>
                                                            <p className="visi-none" dangerouslySetInnerHTML={{ __html: t('user.activity.go_no_go.intro_message') }}></p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 text-center on-spacmob gogogointro-img-dark">
                                                        <img
                                                            src={GoNoGoIntroImg}
                                                            className="img-fluid mang-imgi"
                                                            alt="icon"
                                                        />
                                                    </div>
                                                    <div className="btnNext-top">
                                                        <Link to={`/congnitive-training/${repeat_time}`}>
                                                            <button className="btn btn-primary btnNext"
                                                            >{t("user.activity.reaction_speed.back")}
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="btnNext">
                                                <div className="fit-bdots">
                                                    <ul
                                                        className="nav nav-pills nav-fill"
                                                        id="pills-tab"
                                                        role="tablist">
                                                        <li className="nav-item">
                                                            <a
                                                                onClick={() => changeDot(1)}
                                                                id="pills-home-tab"
                                                                data-toggle="pill"
                                                                href="#pills-1"
                                                                role="tab"
                                                                aria-controls="first"
                                                                aria-selected="true"
                                                            ></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <div className="activity-tutorial-btn-view-dark">
                                                        <button className="remove-colorbtn cursor-pointer" onClick={() => setModalIns1(true)} >
                                                            {t("user.activity.executive_function.see_tutorial")}
                                                        </button>

                                                        <img src={BRAIN_NEXT_ICONA} className="" alt="" />
                                                    </div>
                                                    {free ?
                                                        <Link to={`/go-no-go/${component_id}/${repeat_time}/${day_sequence}/${activity_id}/${free}`}>
                                                            <button
                                                                className="btn btn-primary btnNext"
                                                            >
                                                                {t("user.activity.executive_function.start")}
                                                            </button>
                                                        </Link>
                                                        :
                                                        <Link to={`/go-no-go/${component_id}/${repeat_time}/${day_sequence}/${activity_id}`}>
                                                            <button
                                                                className="btn btn-primary btnNext"
                                                            >
                                                                {t("user.activity.executive_function.start")}
                                                            </button>
                                                        </Link>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

