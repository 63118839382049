import { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import logo from "../../../../assets/Image/logo.svg";
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import NEXTICONA from "../../../../assets/Image/NEXTICONA.svg";
import PRIVIEWICONA from '../../../../assets/Image/PREVIEWICONA.svg';
import MindfulnessRadarChart from './MindfulnessRadarChart';
import MindfulnessOverAllMindfulness from './MindfulnessOverAllMindfulness';
import MindfulnessActvityWisePrePost1 from './MindfulnessActvityWisePrePost1';
import MindfulnessActivityWisePrePost2 from './MindfulnessActivityWisePrePost2';
import MindfulnessBarChart from './MindfulnessBarChart';
import { getLocalStoarge, getUserScore } from '../../../common/allfunction';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRepetition } from '../../../../Store/TrainingRepetition/RepeatSlice';
import LazyLoader from '../../../common/lazyLoader';


const MindfulnessReport = (props) => {
  const [t, i18n] = useTranslation("common");
  const [repeatTime, setRepeatTime] = useState(props.match.params.repeatTime);
  const [componentId, setComponentId] = useState(props.match.params.component_id);
  const [reportPageCount, setReportPageCount] = useState(5);
  const [currentPage, setCurrentPage] = useState(null);
  const [reportEnable, setReportEnable] = useState(getLocalStoarge("post"));
  const [theme, setTheme] = useState(JSON.parse(localStorage.getItem("theme")));
  const [mindfulnessDate, setMindFulnessDate] = useState([]);
  const [mindfulnessRepeatation, setMindfulnessRepeatation] = useState(1);
  const [companyId, setCompanyId] = useState(getLocalStoarge('company_id'));
  const [dynamicId, setDynamicId] = useState(getLocalStoarge('dynamic_id'));
  const [data, setData] = useState({});
  const [assessmentComplete, setAssessmentComplete] = useState();
  const [isLoadingCheck, setIsLoadingCheck] = useState(false);
  const [lastReaptTime, setLastReaptTime] = useState(1);

  useEffect(() => {
    setIsLoadingCheck(true);
    getUserScoreData();
  }, [repeatTime])

  const getUserScoreData = async () => {
    const data = await getUserScore(companyId, dynamicId, componentId, repeatTime);
    setData(data);
  }


  const handlePageClick = (event) => {
    let page = event.selected + 1
    setCurrentPage(page)
  };

  useEffect(() => {
    document.body.className = (theme) ? theme : 'light';
  }, [theme]);


  useEffect(() => {
    if (data) {
      setMindfulnessRepeatation(data?.module_training_date?.repeat_allowed_mindfulness);
      setMindFulnessDate(data?.module_training_date?.repeat_start_dates_mindfulness);
      setAssessmentComplete(data?.trainingData?.postAssessment_date);
      setCurrentPage(1);
      setIsLoadingCheck(false);
    }
  }, [data]);

  const handleRepeat = (index) => {
    setLastReaptTime(repeatTime);
    setRepeatTime(index)
  }

  // useEffect(() => {
  //   if (!assessmentComplete) {
  //     setRepeatTime(lastReaptTime);
  //   }
  // }, [assessmentComplete])

  const renderMindfulnessComponent = () => {
    switch (currentPage) {
      case 1:
        return <MindfulnessOverAllMindfulness data={data} />;
      case 2:
        return <MindfulnessRadarChart repeatTime={repeatTime} />;
      case 3:
        return <MindfulnessActvityWisePrePost1 repeatTime={repeatTime} />;
      case 4:
        return <MindfulnessActivityWisePrePost2 repeatTime={repeatTime} />;
      case 5:
        return <MindfulnessBarChart repeatTime={repeatTime} />;
      default:
        return null;
    }
  }

  return (
    <>
      {isLoadingCheck ? <LazyLoader /> : null}
      <div>
        <div className='row m-0 mb-3'>
          <div className="col-md-3 col-lg-4">
            <Link to="/dashboard">
              <img src={logo} className="logo-steps" alt="logo" />
            </Link>
          </div>
          <div className="col-md-7 col-lg-4 mt-3">
            <div className='text-center'>
              <p className='report-prev'>{t('user.activityReport.view_previous')}</p>
              <div className='d-flex repeat-time-show'>
                {mindfulnessDate && mindfulnessDate.length > 0 && mindfulnessDate.map((date, index) => {
                  const activeClass = (repeatTime == index + 1) ? 'bw-active-active mr-4 cursor-pointer' : 'bw-inactive cursor-pointer ml-3 mr-3';
                  const formattedDate = moment(date * 1000).format("DD.MM.YYYY");
                  return (
                    <div key={index} className={activeClass} onClick={() => handleRepeat(index + 1)}>
                      <span className='repeat-time-font'>Report {index + 1} </span>
                      <p>{formattedDate}</p>
                    </div>
                  );
                })}
              </div>
              <hr className='report-hr m-0' />
            </div>
          </div>
          <div className="col-md-2 col-lg-4 text-right">
            <Link to={`/mindfulness/${repeatTime}`}>
              <button
                className="btn btn-primary report-btnNext px-0 py-2 mr-4 mt-4 "
              >
                {t("user.activity.reaction_speed.back")}
              </button>
            </Link>
          </div>
        </div>
        <div className='load-component'>
          {data && renderMindfulnessComponent()}
        </div>

      </div>

      {data ? <div className="d-flex sa-program-pagination mt-3 mb-5">
        <ReactPaginate
          breakLabel="..."
          nextLabel={<img src={NEXTICONA} alt="icon" />}
          previousLabel={<img src={PRIVIEWICONA} alt="icon" />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={reportPageCount}
          renderOnZeroPageCount={null}
          containerClassName="selectuser-pagination"
          pageClassName="page-class"
          previousClassName={currentPage == 1 ? "d-none" : ""}
          nextClassName={currentPage == reportPageCount ? "d-none" : ""}
          forcePage={currentPage - 1}
        />
      </div> : null}
    </>


  )
}

export default MindfulnessReport
