import { useEffect, useState, useRef, forwardRef } from "react";
import logo from "../../assets/Image/logo.svg";
// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import questionsAnxietyAndDepression from "./questionsAnxietyAndDepression";
import PostRequest from "../apiConnection/postRequest";
import GetRequest from "../apiConnection/getRequest";
import PutRequest from "../apiConnection/putRequest";
import LazyLoader from "../common/lazyLoader";
import { setLocalStoarge , getLocalStoarge} from "../common/allfunction";

// import { useLocation } from "react-router-dom";


function AnxietyAndDepression(props) {
  const [counttab, setCountTab] = useState(1);
  const [t, i18n] = useTranslation("common");
  const [btntext, setBtnText] = useState(`${t("user.Questionnare.next")}`);
  const [questions, setQuestion] = useState( (getLocalStoarge("langauage") == "it") ? questionsAnxietyAndDepression.questionsADforIT() : questionsAnxietyAndDepression.questionsAD());
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [demographic, setDemographic] = useState({});
  const [measurementId, setMeasurementId] = useState();
  const [resultToken, setResultToken] = useState();
  const [set, setSet] = useState(props.match.params.set);
  const [type, setType] = useState(props.match.params.type);
  const [setNumber, setSetNumber]=useState((props.match.params.type == 'depression') ?questionsAnxietyAndDepression.depressionSet("set"+props.match.params.set) : questionsAnxietyAndDepression.anxietySet("set"+props.match.params.set));
  const [surveyId, setSurveyId]=useState();
  const [surveyResult, setSurveyResult] = useState();  
  const [alerts, setAlerts] = useState({
    alert: "",
    alerttype: "",
    alertclass: "danger",
    showalert: false,
  });
  const { alert, alerttype, alertclass, showalert } = alerts;
  
useEffect( () => {
  getAllData()
}, []);

let arr = [];
let arrQue = [];

Object.entries(questions).forEach((k,v)=>{
  if(setNumber.includes(k[0]))
  {
    arr = [...arr,k[1]]
    arrQue = [...arrQue, k[0]]
  } 
})


const redirect =()=>{
  window.location.href = "/app/dashboard/anxietyAndDepression";
}

const [q1, setQ1] = useState(arrQue[0]);
const [q2, setQ2] = useState(arrQue[1]);
const [q3, setQ3] = useState(arrQue[2]);
const [q4, setQ4] = useState(arrQue[3]);
const [q5, setQ5] = useState(arrQue[4]);

const [que1, setQue1] = useState(arr[0].label);
const [que2, setQue2] = useState(arr[1].label);
const [que3, setQue3] = useState(arr[2].label);
const [que4, setQue4] = useState(arr[3].label);
const [que5, setQue5] = useState(arr[4].label);

const [answerOne, setAnswerOne] = useState();
const [answerTwo, setAnswerTwo] = useState();
const [answerThree, setAnswerThree] = useState();
const [answerFour, setAnswerFour] = useState();
const [answerFive, setAnswerFive] = useState();

const [questionSubmit, setQuestionSubmit] = useState({});
const [token, setToken] = useState(() => {
  // getting stored value
  const saved = localStorage.getItem("R1");
  const initialValue = JSON.parse(saved);
  return initialValue.access_token || "";
});

const submitAll= ()=>{  
if( answerOne == undefined ||  answerTwo == undefined || answerThree == undefined || answerFour == undefined || answerFive == undefined )
{
  setAlerts({
    alert: t("user.Questionnare.question_error_msg"),
    alerttype: "Error",
    alertclass: "danger",
    showalert: true,
  });
  setTimeout(() => {
    setAlerts({
      alert: "",
      alerttype: "",
      alertclass: "",
      showalert: false,
    });
  }, 4000)
}
else{
  setAlerts({
    alert: t("user.Questionnare.question_success_msg"),
    alerttype: "Success",
    alertclass: "success",
    showalert: true,
  });
  // setTimeout(() => {
    setQuestionSubmit({[q1]: answerOne, [q2]: answerTwo, [q3]: answerThree, [q4] : answerFour, [q5]: answerFive});
  // }, 4000)
}
 
}  
useEffect(() =>{
  if( answerOne !== undefined && answerTwo !== undefined && answerThree !== undefined && answerFour !== undefined && answerFive !== undefined )
  {
    submitSurveyAnswer();

  } else {
setAlerts({
  alert:"",
  alerttype: "",
  alertclass: "",
  showalert: false,
});
  }


}, [questionSubmit])

  const nextpage = () => {
    var next = counttab <= 5 ? counttab + 1 : counttab;
    var textchange =
      counttab < 4
        ? `${t("user.Questionnare.next")}`
        // : questionairs == 0
        // ? `${t("user.Questionnare.save")}`
        : `${t("user.Questionnare.fine")}`;



    if (
      // (
        // btntext === `${t("user.Questionnare.save")}` ||
        // btntext === `${t("user.Questionnare.fine")}`) &&
        next == 5
    ) {
      // handleSubmitData();
      // window.location.href = "./dashboard";
    }
    setBtnText(textchange);
    setCountTab(next);
  };

  const changeDot = (newval) => {
    var newtext =
      newval < 5
        ? `${t("user.Questionnare.next")}`
        // : questionairs == 0
        // ? `${t("user.Questionnare.next")}`
        : `${t("user.Questionnare.fine")}`;
    setBtnText(newtext);
    setCountTab(newval);
  };

  const getAllData = async () => {
    setIsLoading(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        token,
        "refresh_token"
      );

      if (refreshToken) {
        const getAllDataResult = await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_HEALTH_DATA_AND_TOKEN}`
        );

        if (getAllDataResult && getAllDataResult.status === 200) {
          setIsLoading(false);
          setDemographic({"Dem_Gender" : getAllDataResult.data.data.GENDER, "Dem_Age" : getAllDataResult.data.data.REAL_AGE, "Dem_Region":"1" })
          setMeasurementId(getAllDataResult.data.data.MEASUREMENT_ID);
          setResultToken(getAllDataResult.data.data.dfxToken);
        
        } else {
          setIsLoading(false);
          setLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const submitSurveyAnswer = async () => {  
    const content = {
      surveyId : (type == 'depression') ? "SURVEY_DEPRESSION_MODERATE" : "SURVEY_ANXIETY_MODERATE",
      resultToken : resultToken,
      measurementID : measurementId,
      qset : set,
      questions : questionSubmit,
      demographics : demographic   
    };
    setIsLoading(true);
    try {

       const getResult = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SUBMIT_SURVEY_ANSWER}`,
          content,
          token
        );
        if (getResult && getResult.status === 200) {
          setSurveyResult(getResult.data.data.SurveyResults);
      
          const payloadUpdate = {
            groupSignalID : getResult.data.data.SurveyResults.GroupSignalID,
            surveySignalResults : getResult.data.data.SurveyResults.SurveySignalResults.prob
          }
            const getHealthResult = await PutRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_UPDATE_USER_LAST_HEALTH_DATA}`,
                payloadUpdate,
                "",
                ""
            );
              
            if(getHealthResult && getHealthResult.status === 200)
            {
              window.location.href = "/app/dashboard/anxietyAndDepression";
              setIsLoading(false);
              setLoading(false);   
            }
            else {
              setIsLoading(false);
              setLoading(false);
            }                       
        
        } else {
          window.location.href = "/app/dashboard/anxietyAndDepression";
          setIsLoading(false);
          setLoading(false);
        }
   
    } catch (error) {
      setIsLoading(false);
    }
  };

    return (
        <>
          <section className="steps-process cover-q question-ipad bgsteps4">
              <div className="container-fluid">
                <div className="row">
                    <div className="stepsin stpelog">
                      {/* <Link to="dashboard"> */}
                        <img src={logo} className="logo-steps" alt="logo" />
                      {/* </Link> */}
                     </div>
                      <div className="col-md-12 p-l-0 mobabsul">                    
                          <div className="tabControl">
                            <div className="tab-content" id="pills-tabContent">
                               <div
                                className={`tab-pane fade qbg2 commonbg-question
                                ${counttab === 1 ? "show active" : ""}`}
                                id="pills-1"
                                role="tabpanel"
                                aria-labelledby="pills-contact-tab"
                                >
                                    <div className="row question-w on-responsive mx-0">
                                      <div className="col-md-3"></div>
                                      <div className="col-md-6 tab-fully">
                                        <div className="auth question_steps">
                                          <div className="icon-aut relative">
                                            <h3 className="otp-heading top-Align mobw100 heading-que">
                                            { (props.match.params.type == 'depression') ? t("user.dashboard.heading_depression_assessment") : t("user.dashboard.heading_anxiety_assessment")}
                                            </h3>
                                            {/* <p className="Prima-di">/ */}

                                            {
                                            // arr.map((element) => {     
                                          <>

                                            {/* return( */}
                                              <>
                                              <p  className="que-font"
                                              dangerouslySetInnerHTML={{
                                                // __html: element.label,

                                                __html:que1,
                                                


                                              }}
                                            ></p>
                                     
                                            {/* {showalert ? (
                                              <div
                                                className={`newalertred alert alert-${alertclass}`}
                                              >
                                              </div>
                                            ) : (
                                              <></>
                                            )} */}
                                            {
                                            delete arr[0].label
                                            
                                            }
                                             {
                                            // Object.entries(element).map((ele, val)=>{   
                                              Object.entries(arr[0]).map((e, v)=>{      

                                                return(
                                                <div className="row ">
                                                    <div className="col-md-12 input-width-que m-widhdevice pr4">
                                                        <div className="relative m-2">
                                                          <p                                                          
                                                            className="form-control input-width-ans"
                                                            style={{ backgroundColor: (answerOne == `${v+1}`) ? "#6d71f9" : "white", color : (answerOne == `${v+1}`) ? "white" : "black", fontSize : "16px"}}
                                                            placeholder={e[1]}
                                                            id={v+1}                                                           
                                                            // value={e[1]}
                                                            name="placeOfWork"
                                                            readOnly
                                                            onClick={(e) =>
                                                              setAnswerOne(v+1)
                                                            }
                                                          >{e[1]}</p>
                                                        </div>
                                                    </div>
                                                </div>          
                                                )
                                              })
                                            }           
                                          </>
                                        {/* ) */}
                                        </>

                                            //  })
                                            }
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-3 visiblity-hide"></div>
                                    </div>
                                
                                    <div id="btnNext">
                                      <div className="fit-bdots">
                                        <ul
                                          className="nav nav-pills nav-fill"
                                          id="pills-tab"
                                          role="tablist"
                                        >
                                          <li className="nav-item">
                                            <a
                                              className={`nav-link  ${
                                                counttab === 1 ? "active" : ""
                                              }`}
                                              onClick={() => changeDot(1)}
                                              id="pills-home-tab"
                                              data-toggle="pill"
                                              href="#pills-1"
                                              role="tab"
                                            ></a>
                                          </li>
                                          <li className="nav-item">
                                            <a
                                              className={`nav-link  ${
                                                counttab === 2 ? "active" : ""
                                              }`}
                                              onClick={() => changeDot(2)}
                                              id="pills-profile-tab"
                                              data-toggle="pill"
                                              href="#pills-2"
                                              role="tab"
                                            ></a>
                                          </li>
                                          <li className="nav-item">
                                            <a
                                              className={`nav-link  ${
                                                counttab === 3 ? "active" : ""
                                              }`}
                                              onClick={() => changeDot(3)}
                                              id="pills-contact-tab"
                                              data-toggle="pill"
                                              href="#pills-3"
                                              role="tab"
                                            ></a>
                                          </li>
                                          <li className="nav-item">
                                            <a
                                              className={`nav-link  ${
                                                counttab === 4 ? "active" : ""
                                              }`}
                                              onClick={() => changeDot(4)}
                                              id="pills-contact-tab"
                                              data-toggle="pill"
                                              href="#pills-4"
                                              role="tab"
                                            ></a>
                                          </li>
                                          <li className="nav-item">
                                            <a
                                              className={`nav-link  ${
                                                counttab === 5 ? "active" : ""
                                              }`}
                                              onClick={() => changeDot(5)}
                                              id="pills-contact-tab"
                                              data-toggle="pill"
                                              href="#pills-5"
                                              role="tab"
                                            ></a>
                                          </li>                         
                                        </ul>
                                        <button
                                          className="btn btn-primary btnNext mnn"
                                          onClick={() => redirect()}
                                          id="reverse"
                                        >                          
                                          {t("user.Questionnare.backwards")}
                                        </button>
                                    
                                        <button
                                          className="btn btn-primary btnNext"
                                          onClick={nextpage}
                                          onSubmit={nextpage}
                                        >
                                          {btntext}
                                        </button>
                                      </div>
                                    </div>
                              </div>

                              <div
                                className={`tab-pane fade qbg2 commonbg-question
                                ${counttab === 2 ? "show active" : ""}`}
                                id="pills-2"
                                role="tabpanel"
                                aria-labelledby="pills-contact-tab"
                                >
                                    <div className="row question-w on-responsive mx-0">
                                      <div className="col-md-3"></div>
                                      <div className="col-md-6 tab-fully">
                                        <div className="auth question_steps">
                                          <div className="icon-aut relative">
                                            <h3 className="otp-heading top-Align mobw100 heading-que">
                                            { (props.match.params.type == 'depression') ? t("user.dashboard.heading_depression_assessment") : t("user.dashboard.heading_anxiety_assessment")}

                                            </h3>
                                            {
                                            // arr.map((element) => {     
                                          <>                                          
                                              <>
                                              <p className="que-font"
                                              dangerouslySetInnerHTML={{
                                                __html:que2
                                              }}
                                            ></p>
                                     
                                            {/* {showalert ? (
                                              <div
                                                className={`newalertred alert alert-${alertclass}`}
                                              >
                                              </div>
                                            ) : (
                                              <></>
                                            )} */}
                                            {
                                            delete arr[1].label
                                            
                                            }
                                          
                                            {
                                            // Object.entries(element).map((ele, val)=>{   
                                              Object.entries(arr[1]).map((e1, v1)=>{      

                                                return(
                                                <div className="row ">
                                                     <div className="col-md-12 input-width-que m-widhdevice pr4">
                                                        <div className="relative m-2">
                                                          <p
                                                            className="form-control input-width-ans"
                                                            style={{ backgroundColor: (answerTwo == `${v1+1}`) ? "#6d71f9" : "white", color : (answerTwo == `${v1+1}`) ? "white" : "black", fontSize : "16px"}}
                                                            placeholder={e1[1]}
                                                            id={v1+1}                                                          
                                                            name="placeOfWork"
                                                            readOnly
                                                            onClick={(e) =>
                                                              setAnswerTwo(v1+1)
                                                            }
                                                          >{e1[1]}</p>
                                                        </div>
                                                    </div>
                                                </div>          
                                                )
                                              })
                                            }           
                                          </>
                                        {/* ) */}
                                        </>

                                            //  })
                                            }
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-3 visiblity-hide"></div>
                                    </div>
                                
                                    <div id="btnNext">
                                      <div className="fit-bdots">
                                        <ul
                                          className="nav nav-pills nav-fill"
                                          id="pills-tab"
                                          role="tablist"
                                        >
                                          <li className="nav-item">
                                            <a
                                              className={`nav-link  ${
                                                counttab === 1 ? "active" : ""
                                              }`}
                                              onClick={() => changeDot(1)}
                                              id="pills-home-tab"
                                              data-toggle="pill"
                                              href="#pills-1"
                                              role="tab"
                                            ></a>
                                          </li>
                                          <li className="nav-item">
                                            <a
                                              className={`nav-link  ${
                                                counttab === 2 ? "active" : ""
                                              }`}
                                              onClick={() => changeDot(2)}
                                              id="pills-profile-tab"
                                              data-toggle="pill"
                                              href="#pills-2"
                                              role="tab"
                                            ></a>
                                          </li>
                                          <li className="nav-item">
                                            <a
                                              className={`nav-link  ${
                                                counttab === 3 ? "active" : ""
                                              }`}
                                              onClick={() => changeDot(3)}
                                              id="pills-contact-tab"
                                              data-toggle="pill"
                                              href="#pills-3"
                                              role="tab"
                                            ></a>
                                          </li>
                                          <li className="nav-item">
                                            <a
                                              className={`nav-link  ${
                                                counttab === 4 ? "active" : ""
                                              }`}
                                              onClick={() => changeDot(4)}
                                              id="pills-contact-tab"
                                              data-toggle="pill"
                                              href="#pills-4"
                                              role="tab"
                                            ></a>
                                          </li>
                                          <li className="nav-item">
                                            <a
                                              className={`nav-link  ${
                                                counttab === 5 ? "active" : ""
                                              }`}
                                              onClick={() => changeDot(5)}
                                              id="pills-contact-tab"
                                              data-toggle="pill"
                                              href="#pills-5"
                                              role="tab"
                                            ></a>
                                          </li>                         
                                        </ul>
                                        <button
                                          className="btn btn-primary btnNext mnn"
                                          onClick={() => changeDot(1)}
                                          id="reverse"
                                        >                          
                                          {t("user.Questionnare.backwards")}
                                        </button>
                                    
                                        <button
                                          className="btn btn-primary btnNext"
                                          onClick={nextpage}
                                          onSubmit={nextpage}
                                        >
                                          {btntext}
                                        </button>
                                      </div>
                                    </div>
                              </div>

                              <div
                                className={`tab-pane fade qbg2 commonbg-question
                                ${counttab === 3 ? "show active" : ""}`}
                                id="pills-3"
                                role="tabpanel"
                                aria-labelledby="pills-contact-tab"
                                >
                                    <div className="row question-w on-responsive mx-0">
                                      <div className="col-md-3"></div>
                                      <div className="col-md-6 tab-fully">
                                        <div className="auth question_steps">
                                          <div className="icon-aut relative">
                                            <h3 className="otp-heading top-Align mobw100 heading-que">
                                            { (props.match.params.type == 'depression') ? t("user.dashboard.heading_depression_assessment") : t("user.dashboard.heading_anxiety_assessment")}
                                            </h3>
                                            {
                                            // arr.map((element) => {     
                                          <>                                          
                                              <>
                                              <p className="que-font"
                                              dangerouslySetInnerHTML={{
                                                __html:que3
                                              }}
                                            ></p>
                                     
                                            {/* {showalert ? (
                                              <div
                                                className={`newalertred alert alert-${alertclass}`}
                                              >
                                              </div>
                                            ) : (
                                              <></>
                                            )} */}
                                            {
                                            delete arr[2].label
                                            
                                            }
                                          
                                            {
                                            // Object.entries(element).map((ele, val)=>{   
                                              Object.entries(arr[1]).map((e1, v1)=>{      

                                                return(
                                                <div className="row ">
                                                     <div className="col-md-12 input-width-que m-widhdevice pr4">
                                                        <div className="relative m-2">
                                                          <p
                                                            className="form-control input-width-ans"
                                                            style={{ backgroundColor: (answerThree == `${v1+1}`) ? "#6d71f9" : "white", color : (answerThree == `${v1+1}`) ? "white" : "black", fontSize : "16px"}}
                                                            placeholder={e1[1]}
                                                            id={v1+1}                                                          
                                                            name="placeOfWork"
                                                            readOnly
                                                            onClick={(e) =>
                                                              setAnswerThree(v1+1)
                                                            }
                                                          >{e1[1]}</p>
                                                        </div>
                                                    </div>
                                                </div>          
                                                )
                                              })
                                            }           
                                          </>
                                        {/* ) */}
                                        </>

                                            //  })
                                            }
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-3 visiblity-hide"></div>
                                    </div>
                                
                                    <div id="btnNext">
                                      <div className="fit-bdots">
                                        <ul
                                          className="nav nav-pills nav-fill"
                                          id="pills-tab"
                                          role="tablist"
                                        >
                                          <li className="nav-item">
                                            <a
                                              className={`nav-link  ${
                                                counttab === 1 ? "active" : ""
                                              }`}
                                              onClick={() => changeDot(1)}
                                              id="pills-home-tab"
                                              data-toggle="pill"
                                              href="#pills-1"
                                              role="tab"
                                            ></a>
                                          </li>
                                          <li className="nav-item">
                                            <a
                                              className={`nav-link  ${
                                                counttab === 2 ? "active" : ""
                                              }`}
                                              onClick={() => changeDot(2)}
                                              id="pills-profile-tab"
                                              data-toggle="pill"
                                              href="#pills-2"
                                              role="tab"
                                            ></a>
                                          </li>
                                          <li className="nav-item">
                                            <a
                                              className={`nav-link  ${
                                                counttab === 3 ? "active" : ""
                                              }`}
                                              onClick={() => changeDot(3)}
                                              id="pills-contact-tab"
                                              data-toggle="pill"
                                              href="#pills-3"
                                              role="tab"
                                            ></a>
                                          </li>
                                          <li className="nav-item">
                                            <a
                                              className={`nav-link  ${
                                                counttab === 4 ? "active" : ""
                                              }`}
                                              onClick={() => changeDot(4)}
                                              id="pills-contact-tab"
                                              data-toggle="pill"
                                              href="#pills-4"
                                              role="tab"
                                            ></a>
                                          </li>
                                          <li className="nav-item">
                                            <a
                                              className={`nav-link  ${
                                                counttab === 5 ? "active" : ""
                                              }`}
                                              onClick={() => changeDot(5)}
                                              id="pills-contact-tab"
                                              data-toggle="pill"
                                              href="#pills-5"
                                              role="tab"
                                            ></a>
                                          </li>                         
                                        </ul>
                                        <button
                                          className="btn btn-primary btnNext mnn"
                                          onClick={() => changeDot(2)}
                                          id="reverse"
                                        >                          
                                          {t("user.Questionnare.backwards")}
                                        </button>
                                    
                                        <button
                                          className="btn btn-primary btnNext"
                                          onClick={nextpage}
                                          onSubmit={nextpage}
                                        >
                                          {btntext}
                                        </button>
                                      </div>
                                    </div>
                              </div>


                              <div
                                className={`tab-pane fade qbg2 commonbg-question
                                ${counttab === 4 ? "show active" : ""}`}
                                id="pills-4"
                                role="tabpanel"
                                aria-labelledby="pills-contact-tab"
                                >
                                    <div className="row question-w on-responsive mx-0">
                                      <div className="col-md-3"></div>
                                      <div className="col-md-6 tab-fully">
                                        <div className="auth question_steps">
                                          <div className="icon-aut relative">
                                            <h3 className="otp-heading top-Align mobw100 heading-que">
                                            { (props.match.params.type == 'depression') ? t("user.dashboard.heading_depression_assessment") : t("user.dashboard.heading_anxiety_assessment")}
                                            </h3>
                                            {
                                            // arr.map((element) => {     
                                          <>                                          
                                              <>
                                              <p className="que-font"
                                              dangerouslySetInnerHTML={{
                                                __html:que4
                                              }}
                                            ></p>
                                     
                                            {/* {showalert ? (
                                              <div
                                                className={`newalertred alert alert-${alertclass}`}
                                              >
                                              </div>
                                            ) : (
                                              <></>
                                            )} */}
                                            {
                                            delete arr[2].label                                            
                                            }                                          
                                            {
                                            // Object.entries(element).map((ele, val)=>{   
                                              Object.entries(arr[1]).map((e1, v1)=>{      

                                                return(
                                                <div className="row ">
                                                     <div className="col-md-12 input-width-que m-widhdevice pr4">
                                                        <div className="relative m-2">
                                                          <p
                                                            className="form-control input-width-ans"
                                                            style={{ backgroundColor: (answerFour == `${v1+1}`) ? "#6d71f9" : "white", color : (answerFour == `${v1+1}`) ? "white" : "black", fontSize : "16px"}}
                                                            placeholder={e1[1]}
                                                            id={v1+1}   
                                                            name="placeOfWork"
                                                            readOnly
                                                            onClick={(e) =>
                                                              setAnswerFour(v1+1)
                                                            }
                                                          >{e1[1]}</p>
                                                        </div>
                                                    </div>
                                                </div>          
                                                )
                                              })
                                            }           
                                          </>
                                        {/* ) */}
                                        </>

                                            //  })
                                            }
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-3 visiblity-hide"></div>
                                    </div>
                                
                                    <div id="btnNext">
                                      <div className="fit-bdots">
                                        <ul
                                          className="nav nav-pills nav-fill"
                                          id="pills-tab"
                                          role="tablist"
                                        >
                                          <li className="nav-item">
                                            <a
                                              className={`nav-link  ${
                                                counttab === 1 ? "active" : ""
                                              }`}
                                              onClick={() => changeDot(1)}
                                              id="pills-home-tab"
                                              data-toggle="pill"
                                              href="#pills-1"
                                              role="tab"
                                            ></a>
                                          </li>
                                          <li className="nav-item">
                                            <a
                                              className={`nav-link  ${
                                                counttab === 2 ? "active" : ""
                                              }`}
                                              onClick={() => changeDot(2)}
                                              id="pills-profile-tab"
                                              data-toggle="pill"
                                              href="#pills-2"
                                              role="tab"
                                            ></a>
                                          </li>
                                          <li className="nav-item">
                                            <a
                                              className={`nav-link  ${
                                                counttab === 3 ? "active" : ""
                                              }`}
                                              onClick={() => changeDot(3)}
                                              id="pills-contact-tab"
                                              data-toggle="pill"
                                              href="#pills-3"
                                              role="tab"
                                            ></a>
                                          </li>
                                          <li className="nav-item">
                                            <a
                                              className={`nav-link  ${
                                                counttab === 4 ? "active" : ""
                                              }`}
                                              onClick={() => changeDot(4)}
                                              id="pills-contact-tab"
                                              data-toggle="pill"
                                              href="#pills-4"
                                              role="tab"
                                            ></a>
                                          </li>
                                          <li className="nav-item">
                                            <a
                                              className={`nav-link  ${
                                                counttab === 5 ? "active" : ""
                                              }`}
                                              onClick={() => changeDot(5)}
                                              id="pills-contact-tab"
                                              data-toggle="pill"
                                              href="#pills-5"
                                              role="tab"
                                            ></a>
                                          </li>                         
                                        </ul>
                                        <button
                                          className="btn btn-primary btnNext mnn"
                                          onClick={() => changeDot(3)}
                                          id="reverse"
                                        >                          
                                          {t("user.Questionnare.backwards")}
                                        </button>
                                    
                                        <button
                                          className="btn btn-primary btnNext"
                                          onClick={nextpage}
                                          onSubmit={nextpage}
                                        >
                                          {btntext}
                                        </button>
                                      </div>
                                    </div>
                              </div>

                              <div
                                className={`tab-pane fade qbg2 commonbg-question
                                ${counttab === 5 ? "show active" : ""}`}
                                id="pills-5"
                                role="tabpanel"
                                aria-labelledby="pills-contact-tab"
                                >
                                    <div className="row question-w on-responsive mx-0">
                                      <div className="col-md-3"></div>
                                      <div className="col-md-6 tab-fully">
                                        <div className="auth question_steps">
                                          <div className="icon-aut relative">
                                            <h3 className="otp-heading top-Align mobw100 heading-que ">
                                            { (props.match.params.type == 'depression') ? t("user.dashboard.heading_depression_assessment") : t("user.dashboard.heading_anxiety_assessment")}
                                            </h3>
                                            {
                                            // arr.map((element) => {     
                                          <>                                          
                                              <>
                                              <p className="que-font"
                                              dangerouslySetInnerHTML={{
                                                __html:que5
                                              }}
                                            ></p>
                                     
                                     {showalert ? (
                                            <div
                                              className={`newalertred alert alert-${alertclass}`}
                                            >
                                              {alert}
                                            </div>
                                          ) : (
                                            <></>
                                          )}                                        
                                          
                                            {
                                            // Object.entries(element).map((ele, val)=>{   
                                              Object.entries(arr[1]).map((e1, v1)=>{      

                                                return(
                                                <div className="row ">
                                                <div className="col-md-12 input-width-que m-widhdevice pr4">
                                                        <div className="relative m-2">
                                                          <p
                                                            className="form-control input-width-ans"
                                                            style={{ backgroundColor: (answerFive == `${v1+1}`) ? "#6d71f9" : "white", color : (answerFive == `${v1+1}`) ? "white" : "black", fontSize : "16px"}}
                                                            placeholder={e1[1]}
                                                            id={v1+1}  
                                                            name="placeOfWork"
                                                            readOnly
                                                            onClick={(e) =>
                                                              setAnswerFive(v1+1)
                                                            }
                                                          >{e1[1]}</p>
                                                        </div>
                                                    </div>
                                                </div>          
                                                )
                                              })
                                            }           
                                          </>
                                        {/* ) */}
                                        </>

                                            //  })
                                            }
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-3 visiblity-hide"></div>
                                    </div>
                                
                                    <div id="btnNext">
                                      <div className="fit-bdots">
                                        <ul
                                          className="nav nav-pills nav-fill"
                                          id="pills-tab"
                                          role="tablist"
                                        >
                                          <li className="nav-item">
                                            <a
                                              className={`nav-link  ${
                                                counttab === 1 ? "active" : ""
                                              }`}
                                              onClick={() => changeDot(1)}
                                              id="pills-home-tab"
                                              data-toggle="pill"
                                              href="#pills-1"
                                              role="tab"
                                            ></a>
                                          </li>
                                          <li className="nav-item">
                                            <a
                                              className={`nav-link  ${
                                                counttab === 2 ? "active" : ""
                                              }`}
                                              onClick={() => changeDot(2)}
                                              id="pills-profile-tab"
                                              data-toggle="pill"
                                              href="#pills-2"
                                              role="tab"
                                            ></a>
                                          </li>
                                          <li className="nav-item">
                                            <a
                                              className={`nav-link  ${
                                                counttab === 3 ? "active" : ""
                                              }`}
                                              onClick={() => changeDot(3)}
                                              id="pills-contact-tab"
                                              data-toggle="pill"
                                              href="#pills-3"
                                              role="tab"
                                            ></a>
                                          </li>
                                          <li className="nav-item">
                                            <a
                                              className={`nav-link  ${
                                                counttab === 4 ? "active" : ""
                                              }`}
                                              onClick={() => changeDot(4)}
                                              id="pills-contact-tab"
                                              data-toggle="pill"
                                              href="#pills-4"
                                              role="tab"
                                            ></a>
                                          </li>
                                          <li className="nav-item">
                                            <a
                                              className={`nav-link  ${
                                                counttab === 5 ? "active" : ""
                                              }`}
                                              onClick={() => changeDot(5)}
                                              id="pills-contact-tab"
                                              data-toggle="pill"
                                              href="#pills-5"
                                              role="tab"
                                            ></a>
                                          </li>                         
                                        </ul>
                                        <button
                                          className="btn btn-primary btnNext mnn"
                                          onClick={() => changeDot(4)}
                                          id="reverse"
                                        >                          
                                          {t("user.Questionnare.backwards")}
                                        </button>
                                    
                                        <button
                                          className="btn btn-primary btnNext"
                                          onClick={submitAll}
                                          onSubmit={nextpage}
                                        >
                                          {btntext}
                                        </button>
                                      </div>
                                    </div>
                              </div>

                            </div>
                          </div>
                      </div>
                  </div>
                </div>
                {isLoading ? <LazyLoader /> : null}

            </section>
        </>
    )
}
export default AnxietyAndDepression;