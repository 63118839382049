import React, { useState, useEffect, useRef } from "react";
import AudioControls from "./AudioControls";
import Backdrop from "./Backdrop";
import Lottie from 'react-lottie';
import animationData from './animation.json';
import animationData1 from './animation1.json';
import animationData2 from './animation2.json';
import animationData3 from './animation3.json';
import AUDIO_ICONA from '../../../assets/Image/AUDIO_ICONA.svg'
import { getLocalStoarge } from "../../common/allfunction";
import { useTranslation } from 'react-i18next';


/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-an-audio-player-with-react-hooks
 */
const AudioPlayer = ({ tracks, startStop, setModelClose, audioDuration }) => {

  const animations = [
    animationData,
    animationData1,
    animationData2,
    animationData3,
  ];
  // State
  const [trackIndex, setTrackIndex] = useState(0);
  const [trackProgress, setTrackProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [t, i18n] = useTranslation("common");


  const [words, setWords] = useState(['first', 'second', 'third']);
  // const [feel, setFeel] = useState(null)

  // Destructure for conciseness
  const { title, artist, color, image, audioSrc } = tracks[trackIndex];

  // Refs
  const audioRef = useRef(new Audio(audioSrc));
  const intervalRef = useRef();
  const isReady = useRef(false);


  // Destructure for conciseness
  const { duration } = audioRef.current;

  const currentPercentage = duration
    ? `${(trackProgress / duration) * 100}%`
    : "0%";
  const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #fff), color-stop(${currentPercentage}, #777))
  `;

  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        // toNextTrack();
        // onStop(audioRef.current.currentTime)
        setIsPlaying(false);
        setModelClose('complete');
        audioDuration(audioRef.current.duration)
      } else {
        // onStop(audioRef.current.currentTime)
        setTrackProgress(audioRef.current.currentTime);
      }
    }, [1000]);

  };

  useEffect(() => {
    let lang = getLocalStoarge("langauage");
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [])

  const onScrub = (value) => {
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };

  const onScrubEnd = () => {
    startTimer();
  };

  const toPrevTrack = () => {
    if (trackIndex - 1 < 0) {
      setTrackIndex(tracks.length - 1);
    } else {
      setTrackIndex(trackIndex - 1);
    }
  };

  const toNextTrack = () => {
    if (trackIndex < tracks.length - 1) {
      setTrackIndex(trackIndex + 1);
    } else {
      setTrackIndex(0);
    }
  };

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
      startTimer();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying, trackIndex]);

  useEffect(() => {
    // Play audio when the component mounts
    audioRef.current.play();
    setIsPlaying(true);
    startTimer();

    // Clean up on unmount
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);

  // Handles cleanup and setup when changing tracks
  useEffect(() => {
    audioRef.current.pause();
    audioRef.current = new Audio(audioSrc);
    setTrackProgress(audioRef.current.currentTime);

    if (isReady.current) {
      audioRef.current.play();
      setIsPlaying(true);
      startTimer();
    } else {
      // Set the isReady ref as true for the next pass
      isReady.current = true;
    }
  }, [trackIndex]);

  useEffect(() => {
    // Pause and clean up on unmount
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);


  useEffect(() => {
    // Play audio when the component mounts
    audioRef.current.play();
    setIsPlaying(true);
    startTimer();
    startStop(true); // Call startStop with true on page load
  
    // Clean up on unmount
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);



  const [currentAnimationIndex, setCurrentAnimationIndex] = useState(0);


  useEffect(() => {
    let animationInterval;
    const startAnimation = () => {
      setCurrentAnimationIndex(() => Math.floor(Math.random() * animations.length));
    };

    startAnimation();

    animationInterval = setInterval(startAnimation, 10000); // Adjust the interval time for slower animation

    return () => {
      clearInterval(animationInterval);
    };
  }, []);

  // useEffect(() => {
  //   let wordInterval;
  //   const startWordAnimation = () => {
  //     setFeel(() => words[(Math.floor(Math.random() * words.length))]);
  //   };

  //   startWordAnimation();

  //   wordInterval = setInterval(startWordAnimation, 10000);

  //   return () => {
  //     clearInterval(wordInterval);
  //   };
  // }, []);

  const currentAnimationData = animations[currentAnimationIndex];

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: currentAnimationData,
  };

  const formatTime = () => {
    const minutes = Math.floor(trackProgress / 60);
    const seconds = Math.floor(trackProgress % 60);

    return `${padNumber(minutes)}:${padNumber(seconds)}`;
  };

  const padNumber = number => {
    return number.toString().padStart(2, '0');
  };

  return (
    <div className="audio-player">
      <Lottie options={defaultOptions} isClickToPause={isPlaying} />
      <div className="track-info">
        {/* <AudioControls
          isPlaying={isPlaying}
          onPrevClick={toPrevTrack}
          onNextClick={toNextTrack}
          onPlayPauseClick={setIsPlaying}
        /> */}
        {/* {Boolean(feel) ? <h4 className="audio-random-word">{t(`user.activity.audioFile.${feel}`)}</h4> : null} */}
        <input
          type="hidden"
          value={trackProgress}
          step="1"
          min="0"
          max={duration ? duration : `${duration}`}
          className="progress"
          onChange={(e) => onScrub(e.target.value)}
          onMouseUp={onScrubEnd}
          onKeyUp={onScrubEnd}
          style={{ background: trackStyling }}
        />
      </div>
      {/* <Backdrop
        trackIndex={trackIndex}
        activeColor={color}
        isPlaying={isPlaying}
      /> */}

      <div className="audio-count">
        <img src={AUDIO_ICONA} alt="" className="mr-1" /> <span>{formatTime()}</span>
      </div>
    </div>
  );
};

export default AudioPlayer;
