import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../../assets/Image/logo.svg";
import Group941 from "../../../../assets/Image/Group941.svg";
import Group14351 from "../../../../assets/Image/Group14351.svg";
import Ellipse174 from "../../../../assets/Image/Ellipse174.svg";
import { useTranslation } from "react-i18next";
import { getLocalStoarge } from '../../../common/allfunction';
import { ReactionSpeedResult } from "./ReactionSpeedResult";
import { submitUserActivityData } from '../common/utilty';
import LazyLoader from "../../../common/lazyLoader";


export const ReactionSpeedResume = ({ props }) => {

    const { round, totalTime, resetRound, togglePause, startTimer, timeRemaining, avgAccuracy, params, totalAvgResponseTime, free } = props;
    const [t, i18n] = useTranslation("common");
    const companyId = getLocalStoarge('company_id');
    const [isLoading, setIsLoading] = useState(true);
    const component_id = parseInt(params.component_id);
    const repeat_time = parseInt(params.repeat_time);
    const day_sequence = parseInt(params.day_sequence);
    const activity_id = parseInt(params.activity_id);

    useEffect(() => {
        togglePause();
    }, [])



    useEffect(() => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, [])

    const [payload, setPayload] = useState({
        company_id: companyId,
        component_id: component_id,
        day_sequence: day_sequence,
        repeat_time: repeat_time,
        activity_id: activity_id,
        status: "in-complete",
        activity_score: avgAccuracy,
        activity_play_time: ((totalTime * 60) - timeRemaining),
        activity_round_no: round + 1,
        total_avg_response_time: totalAvgResponseTime
    })

    useEffect(() => {
        if (timeRemaining > 0 && !free) {
            submitUserActivityData(payload, setIsLoading)
        }
    }, [payload])


    const reset = (fun) => {
        resetRound();
    }

    const startCountdown = () => {
        startTimer();
    }

    return (
        <>
            {
                ((timeRemaining > 0) && (!free)) ?
                    <>
                        {(isLoading) ? <LazyLoader /> : null}
                        <section className="reaction-speed-section">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="stepsin stpelog">
                                            <Link to="dashboard">
                                                <img src={logo} className="logo-steps" alt="logo" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="btnNext-top">
                                            <Link to={`/reaction-speed-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}`}>
                                                <button
                                                    className="btn btn-primary reaction-speed-btnEsci"
                                                >
                                                    {t("user.activity.reaction_speed.back")}
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="reaction-speed-resume">
                                            <div className="col-md-3 col-sm-12 reaction-speed-resume-1">
                                                <img src={Group941} className="logo-steps" alt="logo" />
                                            </div>
                                            <div className="col-md-6 col-sm-12 reaction-speed-resume-2">
                                                <h2>{t("user.activity.reaction_speed.resume_heading")}</h2>
                                                <p>{t("user.activity.reaction_speed.resume_message")}</p>
                                                <button className="btn btn-primary btnNext" onClick={event => { reset(); startCountdown() }}>{t("user.activity.executive_function.continue")}</button>
                                                <div className="col-md-3">
                                                    <img src={Ellipse174} className="logo-steps" alt="logo" />
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-sm-12 reaction-speed-resume-3">
                                                <img src={Group14351} alt="logo" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                    :
                    <ReactionSpeedResult props={{ "params": params, "avgAccuracy": avgAccuracy, "totalTime": totalTime, "totalAvgResponseTime": totalAvgResponseTime, "round": round, "free": free }} />
            }
        </>
    )
}