import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../../assets/Image/logo.svg";
import GroupPlus1 from "../../../../assets/Image/GroupPlus1.svg";

import Circle from "../../../../assets/Image/Circle170.svg";
import Square from "../../../../assets/Image/Square170.svg";
import Hexagon from "../../../../assets/Image/Hexagon170.svg";
import Rhombus from "../../../../assets/Image/Rhombus170.svg";
import Triangle from "../../../../assets/Image/Triangle170.svg"

import { useTranslation } from "react-i18next";
import { getLocalStoarge } from '../../../common/allfunction';
import { NBackInfo } from "./NBackInfo";
import { NBackResume } from "./NBackResume";
import PostRequest from '../../../apiConnection/postRequest';
import GetRequest from "../../../apiConnection/getRequest";



export const NBackIndex = ({ props }) => {

    const { round, totalTime, resetRound, startTimer, timeRemaining, avgAccuracy, params, step, n, free } = props;

    // console.log("params_xyz, n", params, n);

    const [t, i18n] = useTranslation("common");
    const [shapeArr, setShapeArr] = useState([]);
    const [score, setScore] = useState(1);
    const [steps, setSteps] = useState(step);

    // const [n, setN] = useState(2);
    const totalSteps = 25;
    const [shape, setShape] = useState();
    const [value, setValue] = useState();
    const allShapes = ['circle', 'triangle', 'hexagon', 'square', 'rhombus'];
    // const allShapes = ['circle', 'triangle', 'hexagon'];
    const [arrLength, setArrLength] = useState(0);
    const [click, setClick] = useState(false);
    const [nBackInfo, setNBackInfo] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [timeRemain, setTimeRemain] = useState(timeRemaining);
    const [isPaused, setIsPaused] = useState(false);
    const [accuracyCurrentRound, setAccuracyCurrentRound] = useState(0);
    const [totalAccuracy, setTotalAccuracy] = useState(avgAccuracy);
    const component_id = parseInt(params.component_id);
    const repeat_time = parseInt(params.repeat_time);
    const day_sequence = parseInt(params.day_sequence);
    const activity_id = parseInt(params.activity_id);


    const circle = Circle;
    const square = Square;
    const hexagon = Hexagon;
    const rhombus = Rhombus
    const triangle = Triangle;


    // console.log("props of n back index", props);


    useEffect(() => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, [])

    //Below function used to change step value after regular interval of time
    useEffect(() => {
        console.log("Start");
        setClick(false);

        if (steps <= totalSteps) {
            const interval = setInterval(() => {
                setShape(allShapes[(Math.floor(Math.random() * allShapes.length))]);
                setArrLength(arrLength + 1);
            }, 2000);
            return () => clearInterval(interval);
        }
    }, [steps]);


    useEffect(() => {
        if (shape) {
            console.log("shape array is set");
            setShapeArr(shapeArr => [...shapeArr, shape]);
        }
    }, [arrLength])


    useEffect(() => {
        console.log("shapeArr, Step No, Score, value", shapeArr, steps, score, value);
        checkResult();
        setSteps(steps + 1);
        console.log("step is incresed by one");
        console.log("end...");
    }, [shapeArr])
    useEffect(() => {
        console.log('totalAccuracy ', totalAccuracy);
    }, [totalAccuracy]);
    function clickByUser() {
        //Condition first N=1
        setClick(true);
        if (n === 1) {
            if (steps > 1) {

                console.log("Click function called by user");
                console.log("Shape clicked by user is ", shape);
                console.log("Arr length", shapeArr.length);
                console.log(shapeArr.length - 2, shapeArr[shapeArr.length - 2]);
                console.log(shapeArr.length - 1, shapeArr[shapeArr.length - 1]);

                // console.log("check", shapeArr[shapeArr.length - 2], "==", value)

                let index = shapeArr.length - 2;
                console.log("Index value", shapeArr[index], value);
                if (shapeArr[shapeArr.length - 1] === shapeArr[index]) {
                    // alert("true click", value, shapeArr[index])
                    setScore(score + 1);
                    console.log("Correct Ans");
                } else {
                    // alert("false Click ", value, shapeArr[index])
                    console.log("Wrong Ans")
                }
            }
        }

        //Condition first N=2
        if (n === 2) {
            if (steps > 2) {

                console.log("Click function called by user");
                console.log("Value clicked by user is", value);
                console.log("Arr length", shapeArr.length)
                console.log(shapeArr.length - 3, shapeArr[shapeArr.length - 3]);
                console.log(shapeArr.length - 1, shapeArr[shapeArr.length - 1]);
                console.log("value", value)

                let index = shapeArr.length - 3;
                console.log("Index value", shapeArr[index], value);
                if (shapeArr[shapeArr.length - 1] === shapeArr[index]) {
                    // alert("true click", value, shapeArr[index])
                    setScore(score + 1);
                    console.log("Correct Ans");
                } else {
                    // alert("false Click ", value, shapeArr[index])
                    console.log("Wrong Ans")
                }
            }
        }

        //Condition first N=3
        if (n === 3) {
            if (steps > 3) {

                console.log("Click function called by user");
                console.log("Value clicked by user is", value);
                console.log("Arr length", shapeArr.length)
                console.log(shapeArr.length - 4, shapeArr[shapeArr.length - 4]);
                console.log(shapeArr.length - 1, shapeArr[shapeArr.length - 1]);
                console.log("value", value);

                let index = shapeArr.length - 4;
                if (shapeArr[shapeArr.length - 1] === shapeArr[index]) {
                    // alert("true click", value, shapeArr[index])
                    setScore(score + 1);
                    console.log("Correct Ans");
                } else {
                    // alert("false Click ", value, shapeArr[index])
                    console.log("Wrong Ans")
                }
            }
        }

        //Condition first N=4
        if (n === 4) {
            if (steps > 4) {

                console.log("Click function called by user");
                console.log("Value clicked by user is", value);
                console.log("Arr length", shapeArr.length)
                console.log(shapeArr.length - 5, shapeArr[shapeArr.length - 5]);
                console.log(shapeArr.length - 1, shapeArr[shapeArr.length - 1]);
                console.log("value", value);

                let index = shapeArr.length - 5;
                if (shapeArr[shapeArr.length - 1] === shapeArr[index]) {
                    // alert("true click", value, shapeArr[index])
                    setScore(score + 1);
                    console.log("Correct Ans");
                } else {
                    // alert("false Click ", value, shapeArr[index])
                    console.log("Wrong Ans")
                }
            }
        }
        setValue();
    }


    function unClickByUser() {
        //Condition first N=1
        if (n === 1) {
            if (steps > 1) {
                console.log("UnClick function called");
                console.log("Arr length", shapeArr.length)
                console.log(shapeArr.length - 2, shapeArr[shapeArr.length - 2]);
                console.log(shapeArr.length - 1, shapeArr[shapeArr.length - 1]);

                if ((shapeArr[shapeArr.length - 2]) !== (shapeArr[shapeArr.length - 1])) {
                    console.log("Correct...");
                    setScore(score + 1);
                } else {
                    console.log("false unclick, You have to click this time");
                }
            }
        }

        //Condition first N=2
        if (n === 2) {
            if (steps > 2) {

                console.log("UnClick function called");
                console.log("Step no", steps);
                console.log("Arr length", shapeArr.length)
                console.log(shapeArr.length - 3, shapeArr[shapeArr.length - 3]);
                console.log(shapeArr.length - 1, shapeArr[shapeArr.length - 1]);

                if ((shapeArr[shapeArr.length - 3]) !== (shapeArr[shapeArr.length - 1])) {
                    console.log("Correct...");
                    setScore(score + 1);
                } else {
                    console.log("false unclick, You have to click this time");
                }
            }
        }

        //Condition first N=3
        if (n === 3) {
            if (steps > 3) {

                console.log("UnClick function called");
                console.log("Step no", steps);
                console.log("Arr length", shapeArr.length)
                console.log(shapeArr.length - 4, shapeArr[shapeArr.length - 4]);
                console.log(shapeArr.length - 1, shapeArr[shapeArr.length - 1]);

                if ((shapeArr[shapeArr.length - 4]) !== (shapeArr[shapeArr.length - 1])) {
                    console.log("Correct...");
                    setScore(score + 1);
                } else {
                    console.log("false unclick, You have to click this time");
                }
            }
        }

        //Condition first N=4
        if (n === 4) {
            if (steps > 4) {
                console.log("UnClick function called");
                console.log("Step no", steps);
                console.log("Arr length", shapeArr.length);
                console.log(shapeArr.length - 5, shapeArr[shapeArr.length - 5]);
                console.log(shapeArr.length - 1, shapeArr[shapeArr.length - 1]);

                if ((shapeArr[shapeArr.length - 5]) !== (shapeArr[shapeArr.length - 1])) {
                    console.log("Correct...");
                    setScore(score + 1);
                } else {
                    console.log("false unclick, You have to click this time");
                }
            }
        }
        setValue();
    }

    const checkResult = () => {
        console.log("checkResult aa gya");
        if (steps) {
            //Condition 1 User will click a value
            // if (value) {
            //     clickByUser(value);
            // }
            //Condition 2 User leave 
            if (!click) {
                unClickByUser();
            }
        }
    }

    useEffect(() => {
        setAccuracyCurrentRound((score / totalSteps));
    }, [score])

    useEffect(() => {
        avgScore();
    }, [accuracyCurrentRound])


    const avgScore = () => {
        let accuracy;
        accuracy = (((avgAccuracy * round) + accuracyCurrentRound) / (round + 1));
        console.log('accuracy ', accuracy);
        setTotalAccuracy(accuracy);
    }

    useEffect(() => {
        console.log("you are inside time interval");
        let timerId;
        if (!isPaused && timeRemain > 0) {
            timerId = setInterval(() => {
                setTimeRemain((prevTime) => prevTime - 1);
            }, 1000);
        }

        return () => {
            if (timerId) {
                clearInterval(timerId);
            }
        };
    }, [timeRemain, isPaused]);

    const togglePause = () => {
        setIsPaused(!isPaused);
    };


    const minutes = Math.floor(timeRemain / 60);
    const seconds = timeRemain % 60;


    return (
        <>
            {steps < totalSteps ?
                <section className="reaction-speed-section n-back-section-mob">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="stepsin stpelog">
                                    <Link to="dashboard">
                                        <img src={logo} className="logo-steps" alt="logo" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="btnNext-top">
                                    {free ?
                                        <Link to={`/n-back-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}/${free}`}>
                                            <button
                                                className="btn btn-primary  btnNext"
                                            >
                                                {t("user.activity.reaction_speed.back")}
                                            </button>
                                        </Link>
                                        :
                                        <Link to={`/n-back-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}`}>
                                            <button
                                                className="btn btn-primary  btnNext"
                                            >
                                                {t("user.activity.reaction_speed.back")}
                                            </button>
                                        </Link>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="reaction-speed-p">
                                    <p className="reaction-speed-p-1">{t("user.activity.n_back.intro_heading")}</p>
                                    <p className="reaction-speed-p-2">{t("user.activity.n_back.info_message_1")} {n} {t("user.activity.n_back.info_message_2")}</p>
                                    <div className="n-count">
                                        <span className="n-curr">{n}</span>
                                        {[...Array(n)].map((e, i) =>
                                            <span className={steps - 2 >= i ? 'active_dot' : 'dot'} key={i}></span>
                                        )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="reaction-speed-step">
                                    <p>{steps}/{totalSteps}</p>
                                </div>
                            </div>
                            {/* <div style={{ justifyContent: 'center' }}>
                                Time Remaining: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                                AccuracyCurrentRound:{accuracyCurrentRound}
                                AvgAccuracy: {avgAccuracy}
                            </div> */}

                            <div className="col-md-12">
                                {/* <h3>Steps:{steps}</h3>
                                <h5>Score: {score}</h5>
                                <h5>Shape: {shape}</h5> */}
                                <div className="n-back-main">
                                    <div className="n-back-plus" style={{ display: steps - 1 > n ? 'flex' : 'none', justifyContent: "center", cursor: 'pointer' }}>
                                        <img src={GroupPlus1} alt="" />
                                    </div>
                                    <div onClick={(e) => clickByUser()} style={{ display: "flex", justifyContent: "center", margin: 35, cursor: 'pointer' }}>
                                        {/* <h1 onClick={() => checkShape(localShape)}>{localShape}</h1> */}
                                        {/* <h1>{shape}</h1> */}
                                        {
                                            shape === 'square' ?
                                                <img src={square} alt="" />
                                                :
                                                shape === 'circle' ?
                                                    <img src={circle} alt="" />
                                                    :
                                                    shape === 'triangle' ?
                                                        <img src={triangle} alt="" />
                                                        :
                                                        shape === 'hexagon' ?
                                                            <img src={hexagon} alt="" />
                                                            :
                                                            shape === 'rhombus' ?
                                                                <img src={rhombus} alt="" />
                                                                : null
                                        }
                                    </div>
                                    <div className="n-back-plus" style={{ display: steps - 1 > n ? 'flex' : 'none', justifyContent: "center", cursor: 'pointer' }} >
                                        <img src={GroupPlus1} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="n-back-index-msg">
                                    {
                                        steps - 1 > n ?
                                            <p>{t("user.activity.n_back.info_message_3")} {n} {t("user.activity.n_back.info_message_4")}</p>
                                            :
                                            <p>{t("user.activity.n_back.info_message_5")}</p>

                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </section >
                :
                <NBackResume props={{ "round": round, "totalTime": totalTime, "resetRound": resetRound, "togglePause": togglePause, "timeRemaining": timeRemain, "avgAccuracy": totalAccuracy, "accuracyCurrentRound": accuracyCurrentRound, "params": params, "n": n, "free": free }} />
            }
        </>
    )
}
