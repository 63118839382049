import React from "react";
import ELIMINA_ICONA from "../../assets/Image/ELIMINA_ICONA.svg";
import Group878 from "../../assets/Image/Group878.svg";
import leftarrow from "../../assets/Image/leftarrow.svg";
// import SidebarProfile from '../admin/sidebarProfile';
import SidebarProfile from '../admin/sidebar';
import SidebarMob from "../admin/sidebarmob";
import { useState, useEffect, useRef } from "react";
import PostRequest from "../apiConnection/postRequest";
import LazyLoader from "../common/lazyLoader";
import { getProfile, setLocalStoarge, getLocalStoarge } from "../common/allfunction";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AdminProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [scrollTop, setScrollTop] = useState(false);
  const [token, setToken] = useState(() => {
    const saved = localStorage.getItem("R2");
    const initialValue = JSON.parse(saved);
    return initialValue.access_token || "";
  });

  const [profileImage, setProfileImage] = useState("default.png");
  const [profileUrlImage, setProfileUrlImage] = useState(null);
  const [IsDisabled, setIsDisabled] = useState(true);
  const [twoFa, setTwoFa] = useState(0);
  const [user_name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(null);
  const inputElement = useRef();
  const [t, i18n] = useTranslation('common');
  const [langText, setLangText] = useState(0);
  const [validation, setValidation] = useState({
    email: "",
    password: "",
    user_name: ""
  });

  const [alerts, setAlerts] = useState({
    alert: "",
    alerttype: "",
    alertclass: "danger",
    showalert: false,
  });
  const { alert, alerttype, alertclass, showalert } = alerts;
  const [userlang, setuserlang] = useState(getLocalStoarge("langauage"));

  useEffect(() => {
    getAdminProfile();
    handleTranslationLang();
    scrollMoveTop();
  }, []);
  const handleTranslationLang = () => {
    let lang = getLocalStoarge("langauage");
    if (lang) {
      i18n.changeLanguage(lang)
    }
  }
  const scrollMoveTop = () => {
    if (!scrollTop) {
      window.scrollTo(0, 0);
      setScrollTop(true);
    }
  }
  const defaultPassword = "*******";
  const [inputValuesnew, setInputValuesnew] = useState({
    password: defaultPassword
  });
  const [defaultImage, setDefaultImage] = useState(process.env.REACT_APP_BACKEND_BASE_URL +
    process.env.REACT_APP_STATIC_PATH + "/default.png")
const handleImageError = (event) => {
    event.target.onerror = null; 
    event.target.src = defaultImage; // Replace with fallback image URL
  };

  // validation 
  const checkValidation = async () => {
    let errors = validation;
    // email validation
    let Emailregex = new RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    const email1 = email;
    if (!email1) {
      errors.email = t("admin.profile.valid_admin_email");
      setAlerts({
        // alert: addProfileResult.data.message,
        alert: t("admin.profile.valid_admin_email"),
        alerttype: "Error",
        alertclass: "danger",
        showalert: true,
      });
    } else if (!email.match(Emailregex)) {
      errors.email = t("admin.profile.valid_admin_email");
      setAlerts({
        // alert: addProfileResult.data.message,
        alert: t("admin.profile.valid_admin_email"),
        alerttype: "Error",
        alertclass: "danger",
        showalert: true,
      });
    } else {
      errors.email = "";
    }
    let userExp = new RegExp(/^[a-zA-Z ]*$/);
    if ((user_name === undefined || user_name === null || user_name === '') ||
      (isNaN(user_name) !== true || parseInt(user_name.length) < 3) || !user_name.match(userExp)) {
      errors.user_name = t("admin.profile.valid_admin_name");
      setAlerts({
        alert: t("admin.profile.valid_admin_name"),
        alerttype: "Error",
        alertclass: "danger",
        showalert: true,
      });
      setTimeout(() => {
        setAlerts({
          alert: "",
          alerttype: "",
          alertclass: "",
          showalert: false,
        });
      },
        process.env.REACT_APP_ALERT_TIMEOUT * 1000);
    }
    else {
      errors.user_name = "";
    }

    //password validation
    const Passregex = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/
    );
    const password1 = password;
    if (password1 || password1 === '') {
      if (password.length < 8) {
        errors.password = t("admin.profile.valid_admin_password1");
        setAlerts({
          alert: t("admin.profile.valid_admin_password1"),
          alerttype: "Error",
          alertclass: "danger",
          showalert: true,
        });
        setTimeout(() => {
          setAlerts({
            alert: "",
            alerttype: "",
            alertclass: "",
            showalert: false,
          });
        },
          process.env.REACT_APP_ALERT_TIMEOUT * 1000);
      } else if (password.length > 32) {
        errors.password = t("admin.profile.valid_admin_password2");
        setAlerts({
          alert: t("admin.profile.valid_admin_password2"),
          alerttype: "Error",
          alertclass: "danger",
          showalert: true,
        });
        setTimeout(() => {
          setAlerts({
            alert: "",
            alerttype: "",
            alertclass: "",
            showalert: false,
          });
        },
          process.env.REACT_APP_ALERT_TIMEOUT * 1000);
      } else if (!password.match(Passregex)) {
        errors.password = t("admin.profile.valid_admin_password3");
        setAlerts({
          alert: t("admin.profile.valid_admin_password3"),
          alerttype: "Error",
          alertclass: "danger",
          showalert: true,
        });
        setTimeout(() => {
          setAlerts({
            alert: "",
            alerttype: "",
            alertclass: "",
            showalert: false,
          });
        },
          process.env.REACT_APP_ALERT_TIMEOUT * 1000);
      }
    }
    else {
      errors.password = "";
    }
    setValidation(errors);
    let return_val = true;
    await Object.entries(errors).forEach(([key, value]) => {
      if (value !== "") return_val = false;
    });
    return return_val;
  };

  const handleImageChange = async (e) => {
    e.preventDefault();
    let files = e.target.files[0];
    if (files) {
      let dataImage = new FormData();
      dataImage.append("image", e.target.files[0]);
      const checkImage = e.target.files[0];
      const fileExtension = checkImage.name.replace(/^.*\./, '');
      const fileSize = Math.round((checkImage.size / 1024));
      // to check image size and type
      if (fileExtension === 'png' || fileExtension === 'PNG' || fileExtension === 'jpg' || fileExtension === 'JPG' || fileExtension === 'JPEG' || fileExtension === 'jpeg') {
        if (fileSize <= 1040) {
          setIsLoading(true);
          try {
            const refreshToken = await PostRequest(
              `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
              {},
              token,
              "refresh_token"
            );
            if (refreshToken) {
              const addProfileResult = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADMIN_ADD_PROFILE_PICTURE}`,
                dataImage,
                token,
                "ImageUpload"
              );
              if (addProfileResult && addProfileResult.status === 200) {
                setIsLoading(false);
                if (addProfileResult.data.code === 200) {
                  setIsLoading(false);
                  setProfileImage(addProfileResult.data.data.filename);
                  setProfileUrlImage(addProfileResult.data.data.filename);

                } else {
                  setIsLoading(false);
                }
              }
            } else {
              setIsLoading(false);
            }
          } catch (error) {
            setIsLoading(false);
          }
        }
        else {
          setAlerts({
            alert: t("user.profile.valid_image_size"),
            alerttype: "Error",
            alertclass: "danger",
            showalert: true,
          });
          setTimeout(() => {
            setAlerts({
              alert: "",
              alerttype: "",
              alertclass: "",
              showalert: false,
            });
          },
            process.env.REACT_APP_ALERT_TIMEOUT * 1000);
        }
      }
      else {
        setAlerts({
          alert: t("user.profile.valid_image_type"),
          alerttype: "Error",
          alertclass: "danger",
          showalert: true,
        });
        setTimeout(() => {
          setAlerts({
            alert: "",
            alerttype: "",
            alertclass: "",
            showalert: false,
          });
        },
          process.env.REACT_APP_ALERT_TIMEOUT * 1000);
      }
    }
  };

  const removeImage = async (e) => {
    e.preventDefault();
    const Image_data = "default.png";
    const profile = { profile_pic: Image_data };
    setLocalStoarge("profile", JSON.stringify(profile));
    setProfileImage(Image_data);
    setProfileUrlImage(Image_data);
  };

  const checkupdate = (e) => {
    const { name, checked, value } = e.target;
    let valueset = 0;
    if (checked === true) {
      valueset = 1;
    } else {
      valueset = 0;
    }
    if (name == 'twoFa') {
      setTwoFa(valueset);
    } else if (name == 'name') {
      setName(value);
    } else if (name == 'email') {
      setEmail(value);
    } else if (name == 'password') {
      setPassword(value);
    } else if (name == 'language') {
      setLangText(value);
    }
    else {
      setInputValuesnew({ ...inputValuesnew, [name]: valueset });
    }
    setAlerts("", "", "", false);

  };
  const submitProfile = async (e) => {
    e.preventDefault();
    const validate = await checkValidation();
    if (!validate) {
      // console.log(validation);
    }
    else {
      const Payload = {
        "profile_pic": profileUrlImage,
        "twofa": twoFa,
        "user_name": user_name,
        "password": password,
        "email": email,
        "lang": parseInt(langText)
      };

      try {
        setIsLoading(true);
        const refreshToken = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
          {},
          token,
          "refresh_token"
        );
        if (refreshToken) {
          const addProfileResult = await PostRequest(
            `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADMIN_SUBMIT_PROFILE}`,
            Payload,
            token,
            ""
          );
          if (addProfileResult && addProfileResult.status === 200) {
            if (addProfileResult.data.code === 200) {
              const addedprofileresult = addProfileResult.data.data;
              setProfileImage(
                addedprofileresult.profile_pic !== null
                  ? addedprofileresult.profile_pic
                  : "default.png"
              );
              setProfileUrlImage(
                addedprofileresult.profile_pic !== null
                  ? addedprofileresult.profile_pic
                  : "default.png"
              );
              let Admin_data = {
                profile_pic: profileImage,
                user_name: user_name
              };
              setLocalStoarge("langauage", parseInt(langText) == 0 ? "it" : "en");
              setLocalStoarge('admin_profile', JSON.stringify(Admin_data))
              setLangText(parseInt(langText));
              setIsLoading(false);
              setAlerts({
                alert: t("user.profile.profilo_aggiornato"),
                alerttype: "Success",
                alertclass: "success",
                showalert: true,
              });
              setTimeout(() => {
                window.location.reload();
                setAlerts({
                  alert: "",
                  alerttype: "",
                  alertclass: "",
                  showalert: false,
                });
              },
                process.env.REACT_APP_ALERT_TIMEOUT * 1000);
            }
            else {
              setIsLoading(false);
              setAlerts({
                alert: t("user.profile.profileNotUpdated"),
                alerttype: "Error",
                alertclass: "danger",
                showalert: true,
              });
              setTimeout(() => {
                setAlerts({
                  alert: "",
                  alerttype: "",
                  alertclass: "",
                  showalert: false,
                });
              },
                process.env.REACT_APP_ALERT_TIMEOUT * 1000);
            }
          } else {
            setIsLoading(false);
            setAlerts({
              alert: t("user.profile.profileNotUpdated"),
              alerttype: "Error",
              alertclass: "danger",
              showalert: true,
            });
            setTimeout(() => {
              setAlerts({
                alert: "",
                alerttype: "",
                alertclass: "",
                showalert: false,
              });
            },
              process.env.REACT_APP_ALERT_TIMEOUT * 1000);
          }
        }
        else {
          setIsLoading(false);
          setAlerts({
            alert: t("user.profile.profileNotUpdated"),
            alerttype: "Error",
            alertclass: "danger",
            showalert: true,
          });
          setTimeout(() => {
            setAlerts({
              alert: "",
              alerttype: "",
              alertclass: "",
              showalert: false,
            });
          },
            process.env.REACT_APP_ALERT_TIMEOUT * 1000);
        }
      } catch (error) {
        setIsLoading(false);
        setAlerts({
          alert: t("user.profile.profileNotUpdated"),
          alerttype: "Error",
          alertclass: "danger",
          showalert: true,
        });
        setTimeout(() => {
          setAlerts({
            alert: "",
            alerttype: "",
            alertclass: "",
            showalert: false,
          });
        },
          process.env.REACT_APP_ALERT_TIMEOUT * 1000);
      }
    }

  };

  const getAdminProfile = async () => {
    setIsLoading(true);

    try {
      const profileData = await getProfile();
      if (profileData) {
        const getProfile = profileData;
        // setLocalStoarge('langauage', (getProfile.language == 0) ?"it":"en");
        setLocalStoarge("langauage", getProfile.language == 0 ? "it" : "en");
        setLangText(getProfile.language);
        setInputValuesnew(getProfile);
        setProfileImage(
          getProfile.profile_pic !== null
            ? getProfile.profile_pic
            : "default.png"
        );
        setProfileUrlImage(
          getProfile.profile_pic !== null
            ? getProfile.profile_pic
            : null
        );
        setName(getProfile.user_name);
        setEmail(getProfile.email);
        setPassword(password)
        setTwoFa(getProfile.two_fa);
        setIsLoading(false);
        handleTranslationLang();
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const profileData = async (e) => {
    e.preventDefault();
    const {
      profile_pic,
      vitali,
      physiological,
      mental,
      physicists,
      general_risks,
      metabolic_risks,
      measurement_of_psychological_values,
    } = inputValues;

    const validatedata = async (data) => {
      data.preventDefault();
      const {
        profile_pic,
        vitali,
        physiological,
        mental,
        physicists,
        general_risks,
        metabolic_risks,
        measurement_of_psychological_values,
      } = data;
      let result = true;
      if (
        profile_pic === undefined ||
        profile_pic === null ||
        isNaN(profile_pic) === true ||
        parseInt(profile_pic) < 10
      ) {
        // result = "Please enter a valid profile pic.";
        setAlerts({
          alert: t("user.profile.profilo_aggiornato"),
          alerttype: "Error",
          alertclass: "danger",
          showalert: true,
        });
      }
      return result;
    };
    const validate = await validatedata(inputValuesnew);
  }

  return (
    <>
      <section className="full_bg dashboard-mobile">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2 p-0 darkimenu  none-viewport ipad-widhset">
              <SidebarProfile />
            </div>
            <div className="col-md-10 p0 pr-0 ipd-righ">
              <div className="right-dashboard topspace profile-right">
                <h2 className="heading-dash imposta">
                  <Link to="/profile-menu">
                    <img src={leftarrow} className="deskt-n" alt="icon" /></Link>
                  {t("user.profile.heading")} <span className="visi-none">{t("user.profile.profile")}</span>
                </h2>
                {showalert ? (
                  <div className={`alert alert-${alertclass} mt-2`}>{alert}</div>
                ) : (
                  <></>
                )}
                <p className="visi-none">{t("user.profile.manage")}</p>
                <div className="row">
                  <div className="col-md-6 fulxs">
                    <div className="leftprofiles">
                      <div className="l-rflex">
                        <div className="profil_img">
                          <span className="circl-profile viewprofile">
                            <img
                              src={process.env.REACT_APP_BACKEND_BASE_URL + process.env.REACT_APP_STATIC_PATH + profileImage}
                              className="img-fluid"
                              alt="img"
                              onError={handleImageError}

                            />
                          </span>
                          {/* {(!IsDisabled)?
                          <> */}
                          <label htmlFor="file-upload" className="deskt-n">
                            <img
                              src={Group878}
                              className="profile-edit-icon deskt-n"
                              alt="icon"
                            />
                          </label>
                          <input
                            id="file-upload"
                            type="file"
                            className="deskt-n"
                            onChange={(e) => handleImageChange(e)}
                          />
                          {/* </>:null} */}
                        </div>
                        <div className="ri-dete visi-none">
                          {/* {(!IsDisabled)?
                          <> */}
                          <label
                            htmlFor="file-upload"
                            className="buttons height-bnt"
                          >
                            {t("user.profile.modify")}
                          </label>
                          <input
                            id="file-upload"
                            type="file"
                            onChange={(e) => handleImageChange(e)}
                          />

                          <button
                            className="delete_btn height-bnt"
                            onClick={(e) => removeImage(e)
                            }
                          >

                            <img src={ELIMINA_ICONA} alt="icon" />
                          </button>
                          {/* </>:null} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 fulxs max-w70"></div>
                  <div className="col-md-6 max-w70">
                    <form ref={inputElement}>
                      <div className="form-group relative">
                        <label>{t("user.profile.name")}</label>
                        {/* <img
                          src={Group878}
                          className="edit-icon visi-none"
                          alt="icon"
                        /> */}

                        <input
                          type="text"
                          onChange={(e) => checkupdate(e)}
                          value={user_name}
                          name="name"
                          autoComplete="off"
                          className="form-control"
                          aria-describedby="emailHelp"
                        // placeholder=""
                        // disabled={(IsDisabled)?true:false}
                        />
                      </div>
                      <div className="form-group relative">
                        <label>{t("user.profile.email")}</label>
                        <img
                          src={Group878}
                          className="edit-icon visi-none"
                          alt="icon"
                        />
                        <input
                          type="email"
                          // placeholder={email}
                          className="form-control"
                          onChange={(e) => checkupdate(e)}
                          name="email"
                          value={email}
                        />
                      </div>
                      <div className="form-group relative">
                        <label>{t("user.profile.Password")}</label>
                        <img
                          src={Group878}
                          className="edit-icon visi-none"
                          alt="icon"
                        />
                        <input
                          type="password"
                          className="form-control"
                          id="exampleInputEmail3"
                          aria-describedby="emailHelp"
                          // placeholder="********"
                          name="password"
                          onChange={(e) => checkupdate(e)}
                          value={inputValuesnew.password}
                        // disabled={(IsDisabled)?true:false}                          
                        />
                      </div>

                      {/* <div className="visi-none">
                        <div className="informazioni">
                          <h4>Informazioni personali </h4>
                          <p>
                            I dati personali inseriti la prima volta che hai
                            fatto l’accesso a Evolvity sono cambiati?
                          </p>
                         {(IsDisabled)?
                          <a
                            onClick={() => setIsDisabled(false)}
                            className="buttons modifica modifi-m"
                          >
                            Modifica
                          </a>:
                          <a
                            onClick={() => cancelEdit()}
                            className="buttons modifica modifi-m"
                          >
                           annulla
                          </a>}
                          <img
                            src={Group878}
                            className="deskt-n modifiiconi"
                            alt="icon"
                          />
                        </div>
                      </div> */}
                    </form>
                  </div>
                  <div className="col-md-6 max-w70">
                    <form>
                      <div className="checki-r">
                        <div className="d-flexswitch">
                          <div className="vb">
                            <h4>{t('user.profile.2FA')}</h4>
                          </div>
                          <div className="nb">
                            <label className="switch">
                              <input
                                type="checkbox"
                                key={Math.random()}
                                onChange={(e) => checkupdate(e)}
                                defaultChecked={(twoFa == 0) ? false : true}
                                name="twoFa"
                              // disabled={(IsDisabled)?true:false}
                              />
                              <span className="slider"></span>
                            </label>
                          </div>
                        </div>
                        <div className="d-flexswitch mt-3">
                          <div className="vb">
                            <h4>{t('user.profile.language')}</h4>
                          </div>
                          <div className="arrow_down1">
                            <select name='language' className="border_n" onChange={(e) => checkupdate(e)} value={langText}>
                              <option value="0">Italiano</option>
                              <option value="1">English</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {/* <div className="deskt-n">
                        <div className="informazioni">
                          <h4>Informazioni personali </h4>
                          <p>
                            I dati personali inseriti la prima volta che hai
                            fatto l’accesso a Evolvity sono cambiati?
                          </p>
                         {(IsDisabled)?
                          <a
                            onClick={() => setIsDisabled(false)}
                            className="" 
                          >
                            <img
                              src={Group878}
                              className="deskt-n modifiiconi"
                              alt="icon"
                            />
                          </a>:
                          <a
                            onClick={() => cancelEdit()}
                            className=""
                          >
                            <img
                              src={Group878}
                              className="deskt-n modifiiconi"
                              alt="icon"
                            />
                          </a>}
                         
                        </div>
                      </div> */}
                    </form>
                  </div>
                  {/* {(!IsDisabled)?
                    <> */}
                  <button
                    className="buttons Salva visi-none"
                    onClick={(e) => submitProfile(e)}
                  >
                    {t('user.profile.Salva_modifiche')}
                  </button>
                  <button
                    className="buttons Salva deskt-n"
                    onClick={(e) => submitProfile(e)}
                  >
                    {t('admin.profile.button')}
                  </button>
                  {/* </>:null} */}
                </div>
              </div>

              {/* <a className="buttons Salva">Salva modifiche</a> */}
            </div>
          </div>
          {isLoading ? <LazyLoader /> : null}
        </div>
      </section>
      <SidebarMob />
    </>
  );
};
export default AdminProfile;
