import React from "react";
import Group842 from "../../assets/Image/Group842.svg";
import Group1165 from "../../assets/Image/Group1165.svg";
import Riepilogo1 from "../../assets/Image/Riepilogo1.svg";
import REPORT_ICONA from "../../assets/Image/REPORT_ICONA.svg";
import FORMAZIONE_ICONA from "../../assets/Image/FORMAZIONE_ICONA.svg";
import userManualImg from '../../assets/Image/user-manual-sidebar.svg';
import Istruzioni from '../../assets/Image/INFO_icon.svg'
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function sidebarmob() {
  const [activebg, setActivebg] = useState("active");
  const path = window.location.pathname;
  const pageName = path.substring(path.lastIndexOf("/") + 1);
  useEffect(() => {
    setActivebg(pageName);
  }, []);
  return (
    <section>
      <div className="mobl-menu">
        <div className={activebg == "dashboard" ? "active" : ""}>
          <Link to="/pages/dashboard">
            <img src={Group842} alt="icon" />
          </Link>
        </div>
        <div className={activebg == "report" ? "active" : ""}>
          <Link to="/pages/report">
            <img src={REPORT_ICONA} alt="icon" />
          </Link>
        </div>
        <div className={activebg == "summary" ? "active" : ""}>
          <Link to="/pages/summary">
            <img src={Riepilogo1} alt="icon" />
          </Link>
        </div>

        <div className={activebg == "training" ? 'active' : 'com-act'}>
          <Link to='/training'><img src={FORMAZIONE_ICONA} alt="icon" /></Link>
        </div>
        <div className={activebg == "profile-menu" ? "active" : "com-act"}>
          <Link to="/profile-menu">
            <img src={Group1165} alt="icon" />
          </Link>
        </div>
        <div className={activebg == "admin-manual" ? 'active' : 'com-act'}>
          <Link to='/admin-manual'><img src={Istruzioni} alt="icon" /></Link>
        </div>

      </div>
    </section>
  );
}

export default sidebarmob;
