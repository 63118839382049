import React from 'react'
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { useTranslation } from 'react-i18next';
import Group769 from '../../assets/Image/Group769.svg'
import { useEffect, useState } from 'react'
import GetRequest from '../apiConnection/getRequest';
import PostRequest from '../apiConnection/postRequest';
import { getLocalStoarge } from "../common/allfunction";
import LazyLoader from './lazyLoader';

export const ViewDocument = (props) => {

    const [docs, setDocs] = useState([])
    const [t, i18n] = useTranslation("common");
    const [fileName, setFileName] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [url, setUrl] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [hasLoadError, setHasLoadError] = useState(false);
    const [description, setDescription] = useState("")
    const [text, setText] = useState('')


    const updateContentCount = async () => {
        let todayTime = new Date();
        let utcDate = new Date(todayTime.getUTCFullYear(), todayTime.getUTCMonth(), todayTime.getUTCDate()).getTime() / 1000;
        let payload = {
            companyId: props.companyId,
            contentType: 'document',
            date: utcDate,
            programs: props.program,
            selectedCourse : props.selectedCourse
        }
        try {
            const getAllDataResult = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_CONTENT_COUNT}`,
                payload,
                "",
                ""
            );
            if (getAllDataResult && getAllDataResult.status === 200) {
                console.log("passed")
            } else {
                console.log("failed")
            }
        } catch (error) {
            console.log(error)

        }

    }
    const getContentDetails = async () => {
        setIsLoading(true)
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const contentData = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_CONTENT_DETAILS}?contentId=${props.contentId}`
                );
                if (contentData && contentData.status === 200) {
                    setIsLoading(false)
                    if(localStorage.getItem("roleId") == '1'){
                        updateContentCount()
                        props.updateUserCourseContentStatus(props.contentId)           
                    } 
                    const result = contentData?.data?.data;
                    if (result.text) {
                        setText(result.text)
                    }
                    setUrl(result.url)
                    setFileName(result.title)
                    setDescription(result?.description)
                    let filePath = `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_DOCUMENT_PATH}/${result.filePath}`
                    setDocs([{ uri: filePath, fileType: 'pdf' }])
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getContentDetails()
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, [])

    const closeModal = () => {
        props.close()
        setDocs([{}])
    }

    const handlePreview = () => {
        window.open(docs[0].uri)
    }

    const handleOpenUrl = () => {
        window.open(url)
    }

    const handleIframeLoad = () => {
        setIsLoaded(true);
    };


    const handleIframeError = () => {
        setIsLoading(false);
        setHasLoadError(true);
    };

    return (
        <>
            <div className="model-pop-new ">
                < div className="model-pop " >
                {!props.userProgramDetails ?  <div className="modal-header">
                    <button
                            type="button"
                            className="close r0-side"
                            onClick={(e) => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div> : null} 
                    {isLoading ? <LazyLoader /> : ''}
                    {text ? <div className='model-body bg-white'>
                        <h3>{fileName}</h3>
                        <h6>{description}</h6>

                        <div dangerouslySetInnerHTML={{ __html: text }} />

                    </div> :
                        (docs?.length > 0) && url === '' ?
                            <div className='model-body bg-white'>
                            {!props.userProgramDetails ? <h3>{fileName}</h3>: null}
                                <DocViewer documents={docs} pluginRenderers={DocViewerRenderers}

                                    initialActiveDocument={docs[0]}
                                    style={{ height: '400px', width: '100%' }}
                                    className='react-pdf'
                                    config={{
                                        header: {
                                            disableHeader: true,
                                            disableFileName: false,
                                            retainURLParams: false,
                                        },
                                        csvDelimiter: ",",
                                        pdfZoom: {
                                            defaultZoom: 1.1,
                                            zoomJump: 0.2,
                                        },
                                        pdfVerticalScrollByDefault: true,
                                    }}
                                />
                                <div><button className='enable-btn m-2' onClick={handlePreview}>Preview</button></div>
                            </div>
                            : (url != '') ?
                                <>
                                    <div>
                                        <iframe
                                            title="Link Frame"
                                            src={url}
                                            width="100%" height="400px"
                                            onLoad={handleIframeLoad}
                                            onError={handleIframeError}
                                        ></iframe>

                                        {isLoading ? (
                                            <p>{t("superadmin.training.popup.loading-url")}</p>
                                        ) : hasLoadError ? (
                                            <p>{t("superadmin.training.popup.error-url-load")}</p>
                                        ) : (
                                            <p className='url-error-msg'>{t("superadmin.training.popup.url-error")}
                                            </p>
                                        )}
                                    </div>
                                    <button type='button' className='enable-btn' onClick={handleOpenUrl}>{t("superadmin.training.popup.load-url")}</button>
                                </>
                                : ''
                    }
                </div >
            </div >
        </>
    )
}
