import React from "react";
import password from "../../../assets/Image/password.svg";
import logo from "../../../assets/Image/logo.svg";
import PREVIEWICONA from "../../../assets/Image/PREVIEWICONA.svg";
import { Link } from "react-router-dom";
import Image2022 from "../../../assets/Image/Image2022.png";
import Group1015 from "../../../assets/Image/Group1015.svg";
import PostRequest from "../../apiConnection/postRequest";
import { useState, useEffect } from 'react';
import LazyLoader from "../../common/lazyLoader";

const Resetpassword = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [alerts, setAlerts] = useState({
    alert: '',
    alerttype: '',
    alertclass: 'danger',
    showalert: false,
  });
  const [token, setToken] = useState(props.match.params.token);
  const { alert, alerttype, alertclass, showalert } = alerts;
  const [inputValues, setInputValue] = useState({
    confirmpassword: '',
    password: '',
  });
  const [validation, setValidation] = useState({
    confirmpassword: '',
    password: '',
  });
  const checkValidation = async (obj) => {
    const {password, confirm_password, token} = obj;
    let errors = validation;
    // email validation
    const passRegex  = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/);
    if (!password) {
      errors.password = t('admin.login.pass_req');
    } else if (password.length < 8) {
      errors.password = t('user.login.valid_password1');
    } else if (password.length > 32) {
      errors.password = t('user.login.valid_password2');
    } else if (!password.match(passRegex)) {
      errors.password = t('user.login.valid_password3');
    } else if (password!==confirm_password) {
      errors.password = t('user.login.confirm_pass');
    } else if ((!token) || (token.length!==36)) {
      errors.password = t('user.login.invalid_pass');
    } else {
      errors.password = "";
    }

    setValidation(errors);
    let return_val = true;
    await Object.entries(errors).forEach(([key, value]) => {
      if (value !== "")
        return_val = false;
    });
    return return_val;
  };

  const do_Reset = async (e) => {
    e.preventDefault();
    setAlerts({
      alert: t("admin.profile.please_wait"),
      alerttype: 'Info',
      alertclass: 'info',
      showalert: true,
    })
    if (token == "" || token == undefined) {
      setAlerts({
        alert: t("admin.profile.invalid_link"),
        alerttype: 'Error',
        alertclass: 'danger',
        showalert: true,
      })
      return false;
    }
    const {password, confirmpassword} = inputValues;
      const payload = {
        "password":password,
        "confirm_password":confirmpassword, 
        "token": token
      }
      setIsLoading(true);
      const validate = await checkValidation(payload);
      if (validate!==true){
        setIsLoading(false);
        setAlerts({
          alert: validation.password,
          alerttype: 'Error',
          alertclass: 'danger',
          showalert: true,
        })
      } else {
        try {
          const resetResult = await PostRequest(
            `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_RESET_PASSWORD}`,
            payload
          );

          if (resetResult && resetResult.status === 200) {
            if (resetResult.data.code === 200) {
              setIsLoading(false);
              setAlerts({
                alert: t("admin.profile.reset_password_done"),
                alerttype: 'Success',
                alertclass: 'success',
                showalert: true,
              })
              setTimeout(() => { window.location.href = `/admin/signin/${resetResult.data.data?.dynamic_company_id}` }, 5000);
            }
          } else if (resetResult && resetResult.status === 203) {
            setIsLoading(false);
            setAlerts({
              alert: resetResult.data.message,
              alerttype: 'Error',
              alertclass: 'danger',
              showalert: true,
            })
          } else {
            setIsLoading(false);
            setAlerts({
              alert: t("admin.profile.invalid_link"),
              alerttype: 'Error',
              alertclass: 'danger',
              showalert: true,
            })
          }
        } catch (error) {
          setIsLoading(false);
          setAlerts({
            alert: t("admin.profile.internal_error"),
            alerttype: "Error",
            alertclass: "danger",
            showalert: true,
          });
        }  
      }
  }
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValue({ ...inputValues, [name]: value });
    setAlerts('', '', '', false);
    setValidation({
      password: '',
      confirmpassword: ''
    })
  }
  return (
    <>
      <div className="bgadminmob">
      <section className="bg_otp m-top25m reset-bg bgyellowadmin">
        <div className="container">
          <div className="logo-w">
            <Link to="/signin">
              <img src={logo} className="logo" alt="logo" />
            </Link>
          </div>
          <div className="row top63">
            <div className="forgot">
              <div className="icon-aut">
                <img src={Group1015} className="key" alt="icon" />
                <h2 className="otp-heading">{t("admin.profile.reset_password_heading")}</h2>
                <p className="Riemposta"
                  dangerouslySetInnerHTML={{
                    __html: t("admin.profile.reset_password_text"),
                  }}>                    
                  </p>
                <form onSubmit={(e) => do_Reset(e)} method="POST" className="registrati-form riempostainput">
                {(showalert) ? (
                  <div className={`alert alert-${alertclass}`}>
                    {alert}
                  </div>
                ) : (<></>)}

                  <div className="form-group relative">
                    <img src={password} alt="icon" className="icon_left" />
                    <input
                      type="password"
                      placeholder={t("admin.profile.new_password")}
                      className="form-control"
                      id="exampleInputEmail1"
                      onChange={handleChange}
                      name='password'
                      defaultValue={inputValues.password}
                      />
                      </div>
                    <div className="form-group relative">
                      <img src={password} alt="icon" className="icon_left" />
                      <input
                        type="password"
                        placeholder={t("admin.profile.repeat_password")}
                        className="form-control"
                        id="exampleInputEmail1"
                        onChange={handleChange}
                        name='confirmpassword'
                        defaultValue={inputValues.confirmpassword}    
                      />
                     </div>
                 <button type="submit" className="buttons m91">{t("admin.profile.confirm")}</button>
                    <Link to='/signin'>
                       <div className="torna">
                        <img src={PREVIEWICONA} alt="icon"/> {t("admin.profile.back_btn")}
                       </div>
                   </Link> 
                </form>
                </div>
              </div>
            </div>
          </div>
          {(isLoading)?<LazyLoader />:null}
         </section>
        <p className="Powered">© Powered By Neocogita</p>
      </div>
    </>
  );
}

export default Resetpassword;
