// import 

import { useEffect } from "react";
import { getLocalStoarge } from "../common/allfunction";
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import Chart from 'chart.js/auto'; // Import Chart.js library
import 'chartjs-adapter-date-fns'; // Import chart.js date-fns adapter if you're using dates in your chart
import { isMobile } from "react-device-detect";
const customStyles = {
	baseBgColor: "#9ECB61" // Replace with your desired background color for progress lin bar
};
const ProgressBarStyle = {
	bgColor: '#9ECB61'
}

ChartJS.register(
	ArcElement,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);
const GraphRecord = (props) => {
    let lang = getLocalStoarge("langauage");
    let bgColor = ["#367B21", "#9ECB61", "#F9EF96", "#F6BF42", "#B02418"];
    const { healthData,btnChange } = props;
    const labels = lang == 'it' ? ['ECCELLENTE', 'BUONO', 'SUFFICIENTE', 'SCARSO', 'MOLTO SCARSO'] : ['EXCELLENT', 'GOOD', 'SUFFICIENT', 'POOR', 'VERY POOR'];
    const paddingOptions = {
		desktop: {
			left: 0,
			right: 0,
			top: 10,
			bottom: 0
		},
		mobile: {
			left: 0,
			right: 0,
			top: 50,
			bottom: 0
		}
	};
    
    useEffect(() => {
        const data = [];
        const labelArr = [];
        let labelsType = [];
        let bgColorArr = [];
        let colorArr = [];
        if (btnChange == 's') {
			Object.keys(healthData.weeklyWellBeingIndex).map((element) => {
				data.push(healthData.weeklyWellBeingIndex[element]);
			});
            labelsType = lang == 'it' ? ['LUN', 'MAR', 'MER', 'GIO', 'VEN', 'SAB', 'DOM'] : ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']
		    bgColorArr = ["#367B21", "#9ECB61", "#F9EF96", "#F6BF42", "#B02418"];
        }
		else if (btnChange == 'm') {
			Object.keys(healthData.monthlyWellBeingIndex).map((element) => {
                let date = new Date(element);
				labelArr.push(date.getDate());
			});
            let index = 0;
            Object.values(healthData.monthlyWellBeingIndex).map((element) => {
				data.push(element);
                if (element <= 40) {
                    colorArr[index] = "#B02418";
                } else if (element <= 60) {
                    colorArr[index] = "#F6BF42";
                } else if (element <= 70) {
                    colorArr[index] = "#F9EF96";
                } else if (element <= 80) {
                    colorArr[index] = "#9ECB61";
                }
                else {
                    colorArr[index] = "#367B21";
                }
                index++;
			});
            bgColorArr =colorArr;
            labelsType = labelArr;
		}
        const barChartData = {
            labels: labelsType ,
            datasets: [{
                label: "",
                data: data,
                backgroundColor: bgColorArr
            }]
        };

        const ctx = document.getElementById("mycanvas").getContext("2d");
        const myObjBar = new Chart(ctx, {
            type: 'bar',
            data: barChartData,
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                        align: 'end',
                        fontColor: "white",
                        labels: {
                            generateLabels: function (chart) {
                                return labels.map(function (label, i) {
                                    const color = bgColor[i];
                                    return {
                                        text: label,
                                        fillStyle: color
                                    };
                                });
                            },
                            usePointStyle: true,
                            pointStyle: 'circle',
                            font: {
                                size: 10
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        grid: {
                            display: true
                        }
                    },
                    y: {
                        max: 100,
                        min: 0,
                        ticks: {
                            stepSize: 20,
                        },
                        beginAtZero: true
                    },
                    yAxes: [{
                        ticks: {
                            fontColor: "white",
                            fontSize: 18,
                            stepSize: 1,
                            beginAtZero: true
                        }
                    }],
                },
                layout: {
                    padding: isMobile ? paddingOptions.mobile : paddingOptions.desktop
                },

            }
        });

        const bars = myObjBar.data.datasets[0].backgroundColor;
        bars.forEach((color, i) => {
            const value = barChartData.datasets[0].data[i];
            if (value <= 40) {
                bars[i] = "#B02418";
            } else if (value <= 60) {
                bars[i] = "#F6BF42";
            } else if (value <= 70) {
                bars[i] = "#F9EF96";
            } else if (value <= 80) {
                bars[i] = "#9ECB61";
            }
            else {
                bars[i] = "#367B21";
            }
        });

        myObjBar.update(); // Update the chart

        // Clean up function
        return () => {
            myObjBar.destroy(); // Destroy the chart instance when component unmounts
        };
    }, ['',healthData,btnChange])
    return (
        <canvas id="mycanvas" width="100%"></canvas>
    );
}

export default GraphRecord;