import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./assets/scss/responsive.scss";
import "./assets/scss/style.scss";
import "./components/admin/admin_assets/scss/Adminstyle.scss"
import "./components/superAdmin/super_admin_assets/scss/Super-admin-style.scss"
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import common_it from "./translations/it/common.json";
import common_en from "./translations/en/common.json";
import store from './Store/TrainingRepetition/Store';
import { Provider } from 'react-redux';

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'it',                              // language to use
    resources: {
        en: {
            common: common_en               // 'common' is our custom namespace
        },
        it: {
            common: common_it
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <I18nextProvider i18n={i18next}>
                <App />
            </I18nextProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();

