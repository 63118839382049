import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Chart, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import GetRequest from "../apiConnection/getRequest";
import { getLocalStoarge } from "../common/allfunction";
import LazyLoader from "../common/lazyLoader";
Chart.register(CategoryScale, LinearScale, BarElement);

const AdminStatics = (props) => {
    const [t, i18n] = useTranslation("common");
    const [passPercentage, setPassPercentage] = useState((props.data.completePercent > 0) ? (props.data.completePercent * 100) : 0);
    // const [arr, setArr] = useState();
    const [videoContentCount, setVideoContentCount] = useState([])
    const [documentContentCount, setDocumentContentCount] = useState([])
    const [monthValidate, setMonthValidate] = useState(false);
    const [mostViewed, setMostViewed] = useState([])
    const [testScore, setTestScore] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        getVideoContentDetails();
        getTestScore();
    }, [props.data, props.data.programId])
    const getVideoContentDetails = async () => {
        try {
            let company_id = getLocalStoarge('company_id');
            let program_id = props.data.programId;
            setIsLoading(true);
            const videoResult = await GetRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MOST_VIEWED_COURSE}?companyId=` + company_id + `&programId=` + program_id
            );
            if (videoResult && videoResult.status === 200) {
                setIsLoading(false);
                const valuesArray = [];
                let courseDict = videoResult.data.data;
                for (const key in courseDict) {
                    if (courseDict.hasOwnProperty(key)) {
                        valuesArray.push({ key: key, data: courseDict[key] });
                    }
                }

                setMostViewed(valuesArray)
            } else {
                setIsLoading(false);
            }

        } catch (error) {
            setIsLoading(false);
        }
    }
    const getTestScore = async () => {
        try {
            let company_id = getLocalStoarge('company_id');
            let program_id = props.data.programId;
            setIsLoading(true);
            const videoResult = await GetRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_TEST_SCORE}?companyId=` + company_id + `&programId=` + program_id
            );
            if (videoResult && videoResult.status === 200) {
                setIsLoading(false);
                setTestScore(videoResult.data.data)
            } else {
                setIsLoading(false);
            }

        } catch (error) {
            setIsLoading(false);
        }
    }
    const getMonthName = (monthNumber) => {
        const monthsName = [
            `${t("admin.training.Months.January")}`,
            `${t("admin.training.Months.February")}`,
            `${t("admin.training.Months.March")}`,
            `${t("admin.training.Months.April")}`,
            `${t("admin.training.Months.May")}`,
            `${t("admin.training.Months.June")}`,
            `${t("admin.training.Months.July")}`,
            `${t("admin.training.Months.August")}`,
            `${t("admin.training.Months.September")}`,
            `${t("admin.training.Months.October")}`,
            `${t("admin.training.Months.November")}`,
            `${t("admin.training.Months.December")}`,
        ];

        return monthsName[monthNumber - 1]; // Subtract 1 to convert 1-indexed month number to 0-indexed array index
    }
    const getMonthsBetweenDates = (startDate, endDate) => {
        const months = [];
        let startDate1 = new Date(startDate);
        while (startDate1 <= endDate) {
            const monthNumber = startDate1.getMonth() + 1; // Adding 1 to convert 0-indexed month to 1-indexed
            const monthName = startDate1.toLocaleString('default', { month: 'long' });
            const year = startDate1.getFullYear();
            months.push(getMonthName(monthNumber));
            console.log('monthNumber..', monthNumber);
            // Move to the next month
            startDate1.setMonth(startDate1.getMonth() + 1);
        }
        console.log('months....', months);
        return months;
    };
    const getMonthsBetweenDatesNo = (startDate, endDate) => {
        const months = [];
        let currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            const monthNumber = currentDate.getMonth() + 1; // Adding 1 to convert 0-indexed month to 1-indexed
            const monthName = currentDate.toLocaleString('default', { month: 'long' });
            const year = currentDate.getFullYear();
            months.push(monthNumber);

            // Move to the next month
            currentDate.setMonth(currentDate.getMonth() + 1);
        }

        return months;
    };
    const getDaysBetweenDates = (startDate, endDate) => {
        const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
        const start = new Date(startDate);
        const end = new Date(endDate);
        const diffDays = Math.round(Math.abs((start - end) / oneDay));
        return diffDays;
    };
    const startDate = new Date(props.dateRange[0]);
    const endDate = new Date(props.dateRange[1]);
    const [labelArr, setLabelArr] = useState(getMonthsBetweenDates(startDate, endDate));
    const numberOfDays = getDaysBetweenDates(startDate, endDate);
    console.table(numberOfDays);
    // console.log(getMonthsBetweenDatesNo(startDate, endDate));
    // console.log(getMonthsBetweenDates(startDate, endDate));
    // const monthsName = [
    //     `${t("admin.training.Months.January")}`,
    //     `${t("admin.training.Months.February")}`,
    //     `${t("admin.training.Months.March")}`,
    //     `${t("admin.training.Months.April")}`,
    //     `${t("admin.training.Months.May")}`,
    //     `${t("admin.training.Months.June")}`,
    //     `${t("admin.training.Months.July")}`,
    //     `${t("admin.training.Months.August")}`,
    //     `${t("admin.training.Months.September")}`,
    //     `${t("admin.training.Months.October")}`,
    //     `${t("admin.training.Months.November")}`,
    //     `${t("admin.training.Months.December")}`,
    // ];
    const daysLimit = 25;
    useEffect(() => {
        let arr = [];
        Object.entries(props.data).forEach((cn, val) => {
            if (cn[0] == "document&videoCounts") {
                arr = cn[1]
            }

        })
        let countByMonth = {};

        // Iterate through the data and calculate the total counts for each month
        arr?.forEach(item => {
            const { month, video, docCount, date } = item;
            if (month != undefined && numberOfDays > daysLimit) {
                if (!countByMonth[month]) {
                    countByMonth[month] = {
                        totalVideos: 0,
                        totalDocs: 0
                    };
                }
                if (video != undefined) {
                    countByMonth[month].totalVideos += video;
                }
                if (docCount != undefined) {

                    countByMonth[month].totalDocs += docCount;
                }
            }
            else if (numberOfDays <= daysLimit) {
                if (!countByMonth[date]) {
                    countByMonth[date] = {
                        totalVideos: 0,
                        totalDocs: 0
                    };
                }
                if (video != undefined) {
                    countByMonth[date].totalVideos += video;
                }
                if (docCount != undefined) {

                    countByMonth[date].totalDocs += docCount;
                }
            }
        });

        // Display the total video and document counts for each month
        const foo = [];
        const doc = [];
        for (var i = 1; i <= 12; i++) {
            foo.push(0);
            doc.push(0);
        }


        for (const month in countByMonth) {
            const { totalVideos, totalDocs } = countByMonth[month];
            let monthIndex = getSixMonthsFromCurrent().indexOf(getMonthNameFromNumber(month));

            foo[month - 1] = totalVideos;
            doc[month - 1] = totalDocs;

        }
        let newArrVideo = [];
        let newArrDoc = [];
        if (numberOfDays > daysLimit) {
            for (const [key, value] of Object.entries(getMonthsBetweenDatesNo(startDate, endDate))) {
                newArrVideo.push(foo[value - 1])
                newArrDoc.push(doc[value - 1])
            }
            setLabelArr(getMonthsBetweenDates(startDate, endDate));
        }
        else if (numberOfDays <= daysLimit) {
            for (const month in countByMonth) {
                const { totalVideos, totalDocs } = countByMonth[month];
                newArrVideo.push(totalVideos)
                newArrDoc.push(totalDocs)

            }
            let keyArr = [];
            Object.keys(countByMonth).map((element) => {
                let date = new Date(element);
                keyArr.push(date.getDate());
            })
            console.log("keyArr", keyArr)
            setLabelArr(keyArr);
        }
        setMonthValidate(getMonthsBetweenDatesNo(startDate, endDate).length <= 12 ? false : true)
        setVideoContentCount(newArrVideo);
        setDocumentContentCount(newArrDoc);

    }, [props.data])
    const getMonthNameFromNumber = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1); // Adjust month number to be zero-based
        return date.toLocaleString('default', { month: 'long' });
    };
    const [currentDate, setCurrentDate] = useState(new Date());


    // const getCurrentMonthName = () => {
    //     return currentDate.toLocaleString('default', { month: 'long' });
    // };



    const getSixMonthsFromCurrent = (dateInput) => {
        const months = [];
        const currentMonth = new Date(currentDate);

        for (let i = 0; i < 12; i++) {
            const nextMonth = new Date(currentMonth);
            nextMonth.setMonth(i);
            months.push(nextMonth.toLocaleString('default', { month: 'long' }));
        }

        return months;
    };
    useEffect(() => {
console.log("labelArr",labelArr)
        const graphData = {
            labels: labelArr,
            datasets: [
                {
                    label: 'Video',
                    data: videoContentCount,
                    backgroundColor: '#6D71F9',
                    categoryPercentage: 0.4,
                    barPercentage: 0.8,
                    borderRadius: 50,
                    borderWidth: 1,
                    borderSkipped: false
                },
                {
                    label: 'Documents',
                    data: documentContentCount,
                    backgroundColor: '#FFD749',
                    categoryPercentage: 0.4,
                    barPercentage: 0.8,
                    borderRadius: 50,
                    borderWidth: 1,
                    borderSkipped: false
                },
            ],
        }
        setBarChartData(graphData);
    }, [videoContentCount, documentContentCount])



    const [barChartData, setBarChartData] = useState({
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
            {
                label: 'Video',
                data: videoContentCount,
                backgroundColor: '#6D71F9',
                categoryPercentage: 0.4,
                barPercentage: 0.8,
                borderRadius: 50,
                borderWidth: 1,
                borderSkipped: false
            },
            {
                label: 'Documents',
                data: documentContentCount,
                backgroundColor: '#FFD749',
                categoryPercentage: 0.4,
                barPercentage: 0.8,
                borderRadius: 50,
                borderWidth: 1,
                borderSkipped: false
            },
        ],
    });

    let barChartOptions = {
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false
                }
            },
        }
    };
    const divRef = useRef(null);
    const [divHeight, setDivHeight] = useState(null);

    useEffect(() => {
        // console.log('if condition disable');
        if (divRef.current) {

            const height = divRef.current.offsetHeight;
            // console.log('if condition enable', height);
            setDivHeight(height);
        }
    }, [props.data]);
    return (
        <div className="row mt-10 w-100 mx-0">
            {isLoading ? <LazyLoader /> : null}
            <div className="col-md-4 pl-0" >
                <div className="durate-box ">
                    {/* {(monthValidate) ? <span className="alert alert-danger">Please select only 12 months</span> : null} */}
                    <h2>{t(`superadmin.training.test_program.complete`)}</h2>
                    <div className="durate-mediabox graph-box" style={{ height: divHeight }}>
                        <p className="total-count-person">{props.data.noOfUsers} {t(`superadmin.program.persone`)}</p>
                        <p className="centeralign-Progressbar" style={{ "max-width": 201, height: 201, }}>
                            <CircularProgressbar value={`${props.data.completePercent / 100}`} maxValue={1} strokeWidth={12} text={`${(props.data.completePercent) ? props.data.completePercent : 0}%`}
                                styles={buildStyles({
                                    pathColor: '#6d71f9'
                                })} />

                        </p>
                        <div className="program-status-radio mt-2">
                            <label className="radio-white active-purpule-radio concluso-radio">
                                <input type="radio" className="mr-2" name="status" value="2" />
                                <span>{t(`superadmin.training.test_program.done`)}</span>
                            </label>
                        </div>
                        <div className="program-status-radio">
                            <label className="radio-white inactive-purpule-radio concluso-radio ">
                                <input type="radio" className="mr-2 " name="progress" value="3" />
                                <span>{t(`superadmin.training.test_program.inProgress`)}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-8 pr-0">
                <div className="durate-box">
                    <h2>{t(`superadmin.training.test_program.views`)}</h2>
                    <div className="durate-mediabox" ref={divRef}>
                        <Bar options={barChartOptions} data={barChartData} />
                    </div>
                </div>
            </div>
            <div className="row w-100 mx-0" style={{display: 'none'}}>

                <div className="col-md-6 pl-0">
                    {mostViewed.length > 0 ?
                        <div className="durate-box">
                            <h2>{t(`superadmin.training.test_program.mostViewed`)}</h2>
                            <div className="durate-mediabox">

                                <ol className="row order-list">
                                    {


                                        mostViewed.map((elm, index) => {
                                            return (
                                                <li className="col-sm-6 course-style">
                                                    <h6>{elm.key}</h6>
                                                    <span className="view-count-style">
                                                        {t(`superadmin.training.test_program.completedBy`).toUpperCase()} {elm.data} {t(`superadmin.protocol.user`).toUpperCase()}
                                                    </span>
                                                </li>
                                            )
                                        })

                                    }
                                </ol>
                            </div>
                        </div>
                        : null
                    }
                </div>
                { testScore.userPassedTest || testScore.userCompletedTest ?
                    <>
                        <div className="col-md-3">
                            <div className="durate-box">
                                <h2>{t(`superadmin.training.test_program.test`)}</h2>
                                <div className="durate-mediabox text-center">
                                    {/* <h3>50</h3> */}
                                  
                                    {/* <span className="total-count-person text-aligment-circle">{t(`superadmin.training.test_program.testCompalte`).toUpperCase()}</span> */}
                                    <p className="centeralign-Progressbar" style={{ width: 111, height: 111, }}>
                                        <CircularProgressbar value={testScore.userCompletedTest} maxValue={100} strokeWidth={12} text={`${testScore.userCompletedTest}%`} />
                                    </p>
                                    <span className="text-center-display">%{t(`superadmin.training.test_program.complete`)}</span>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 pr-0 pt-2">
                            <div className="durate-box">
                                <h2 className="mt-4"> </h2>
                                <div className="durate-mediabox text-center">
                                    {/* <h3>50</h3> */}
                                    {/* <span className="total-count-person text-aligment-circle">{t(`superadmin.training.test_program.testPassed`).toUpperCase()}</span> */}
                                    <p className="centeralign-Progressbar" style={{ width: 111, height: 111, }}>
                                        <CircularProgressbar value={testScore.userPassedTest} maxValue={100} strokeWidth={12} text={`${testScore.userPassedTest}%`} />
                                    </p>
                                    <span className="text-center-display">%{t(`superadmin.training.test_program.passing`)}</span>
                                </div>
                            </div>
                        </div>
                    </>
                    : null}
            </div>

        </div>


    )
}

export default AdminStatics;