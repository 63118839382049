import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import Super_Sidebar from "./super_sidebar";
import Super_Sidebarmob from "./super_sidebarmob";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import PostRequest from "../../apiConnection/postRequest";
import LazyLoader from "../../common/lazyLoader";
import { useParams } from "react-router-dom";
import Modal from "../../common/modal";
import Group698 from "../../../assets/Image/Group698.svg";
import Group878 from "../../../assets/Image/Group878.svg";
import ExpandIcon from '../../../assets/Image/expand-icon.svg'
import REPORT_TRAINING from '../../../assets/Image/REPORT_TRAINING.svg'
import DeleteBin from '../../../assets/Image/delete-bin.svg'
import crosseditprofile from '../../../assets/Image/cross-editprofile.svg'
import VectorDown from "../../../assets/Image/VectorDown.svg";
import '../super_admin_assets/scss/protocol.scss';
import GetRequest from "../../apiConnection/getRequest";
import NEXTICONA from "../../../assets/Image/NEXTICONA.svg"
import PRIVIEWICONA from '../../../assets/Image/PREVIEWICONA.svg'
import filterFactory from "react-bootstrap-table2-filter";
import { useTranslation } from "react-i18next";
import { getLocalStoarge, setLocalStoarge, getCompanyDetails } from "../../common/allfunction";
import Group1171 from "../../../assets/Image/Group1171.svg";
import CrossAdmin from "../../../assets/Image/cross-admin-icon.svg";
import Group769 from "../../../assets/Image/Group769.svg";
import Popup_Cross from '../../../assets/Image/Popup_Cross.svg'
import Popup_right from '../../../assets/Image/Popup_right.svg'
import EDIT_PENCIL from '../../../assets/Image/EDIT_PENCIL.svg'
import Group759 from "../../../assets/Image/Group759.svg"
import leftarrow from "../../../assets/Image/leftarrow.svg"
import PREV_PAGE_ICON from '../../../assets/Image/PREVPAGE_ICON.svg';
import { useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import moment from 'moment';
import PutRequest from "../../apiConnection/putRequest";

const SA_Troubleshooting = (props) => {
    const { SearchBar } = Search;
    let history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [t, i18n] = useTranslation("common");
    const [companiesData, setCompaniesData] = useState([])
    const [generalColumn, setGeneralColumn] = useState([{ text: t("superadmin.troubleshooting.name_and_surname"), dataField: "name", sort: true }]);
    const [changeUserStatusId, setChangeUserStatusId] = useState({})
    const [enableCompanyModal, setEnableCompanyModal] = useState(false)
    const [disableCompanyModal, setDisableCompanyModal] = useState(false)
    const [popupLoader1, setPopupLoader1] = useState(false)
    const [popupLoader2, setPopupLoader2] = useState(false)
    const [modifyProgramUser, setModifyProgramUser] = useState(false)
    const [alertMessage, setAlertMessage] = useState({ show: false, spinner: false, message: "", class: "" })
    const [alertMessageRepeat, setAlertMessageRepeat] = useState({ show: false, spinner: false, message: "", class: "" })
    const [userDetails, setUserDetails] = useState({})
    const [getUserId, setUserId] = useState({})
    const [userProgramDetails, setUserProgramDetails] = useState([])
    const [isDisabled, setIsDisabled] = useState(false)
    const [userCourse, setUserCourse] = useState({})
    const [selectProgramUser, setSelectProgramUser] = useState(false)
    const [selectProgram, setSelectProgram] = useState([])
    const [userProgramStatusID, setUserProgramStatusId] = useState("")
    const [repeatProgramModal, setRepeatProgramModal] = useState(false)
    const [repeatProgramData, setRepeatProgramData] = useState({ companyId: props.match.params.company, userProgramStatusID: "", programId: "", userId: "", contentId :"" })
    const [allProgramList, setAllProgramList] = useState([])
    const [arrObj, setArrObj] = useState([]);
    const [disableRepeatProgramButton, setDisableRepeatProgramButton] = useState(false)
    const [companyDetails, setCompanyDetails] = useState({})
    const [showProgramList, setShowProgramList] = useState([])
    const [userSelectCourse, setUserSelectCourse] = useState({})
    const [userCurrentProgram, setUserCurrentProgram] = useState('')
    const [openAccordionId, setOpenAccordionId] = useState(null);
    const [pageCount, setPageCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [courseContentId, setCourseContentId] = useState([]);


    const closeModal = () => {
        setChangeUserStatusId({})
        setDisableCompanyModal(false)
        setEnableCompanyModal(false)
        setPopupLoader1(false)
        setPopupLoader2(false)
        setModifyProgramUser(false)
        setUserDetails({})
        setUserId()
        setUserProgramDetails([])
        setUserCourse({})
        setSelectProgramUser(false)
        setSelectProgram([])
        setAllProgramList([])
        setArrObj([])
        setAlertMessage({ show: false, spinner: false, message: "", class: "" })
        setShowProgramList([])
        setUserSelectCourse({})
        setUserCurrentProgram('')
        setOpenAccordionId(null)
        setCourseContentId([])
    }

    const handleAccordionToggle = (accordionId) => {
        if (accordionId === openAccordionId) {
            setOpenAccordionId(null);
        } else {
            setOpenAccordionId(accordionId);
        }
    };

    const handleSearch = (e) => {
        let value = e.target.value;
        let arr = allProgramList;
        if (value) {
            arr = arr.filter((item) => item.program_name?.toLowerCase().includes(value.toLowerCase()))
            setShowProgramList(arr)
        } else {
            // setItemOffset(0)
            setShowProgramList(allProgramList)
            // getCompanyAllProgram()
        }
    }
    const company_id = props.match.params.company;
    const actionButtons = (cell, row, rowIndex, formateExtraData) => {
        return (
            <div className="d-flex justify-content-center">
                <button className="table-action" onClick={() => { setModifyProgramUser(true); setUserDetails({ user_id: row.user_id, name: row.name, email: row.email }); setUserId({ user_id: row.user_id }); userAllProgramsDetails({ user_id: row.user_id }); }}><img src={Group878} alt="icon" /></button>
                {!(row.status == "1") ? (<button className="table-action bg-green-delete" onClick={() => { setEnableCompanyModal(true); setChangeUserStatusId({ user_id: row.user_id, status: "1", name: row.name }) }} title=" Click for enable">
                    <img src={CrossAdmin} alt="icon" />
                </button>) :
                    (<button className="table-action bg-green-delete" onClick={() => { setDisableCompanyModal(true); setChangeUserStatusId({ user_id: row.user_id, status: "0", name: row.name }); }} title="Click for delete">
                        {/* <img src={Group1171} alt="icon" /> */}
                        <img src={DeleteBin} alt="icon" />
                    </button>)
                }
            </div>

        )
    }
    const getCompaniesData = async (page) => {
        setIsLoading(true)
        try {            
            let skip = (page - 1) * 10
            const getAllDataResult = await GetRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_USER_TROUBLESHOOTING}?company=${company_id}&limit=10&offset=${skip}`
            );
            if (getAllDataResult && getAllDataResult.status === 200) {
                if (getAllDataResult.data.data?.data?.length > 0) {
                    let arr = []
                    getAllDataResult.data.data.data.forEach((data) => {
                        arr = [...arr, {
                            name: data.name || "",
                            email: data.email || "",
                            program: data.program || 0,
                            last_Access: data.last_Access ? moment.unix(data.last_Access).format("DD.MM.YY") : "",
                            status: data.status || 0,
                            user_id: data.user_id || 0
                        }]
                    })
                    setCompaniesData(arr)
                    setPageCount(Math.ceil(getAllDataResult.data.data.totalCount / 10))
                    setCurrentPage(page)
                }
                setIsLoading(false)
            } else if (getAllDataResult && getAllDataResult.status === 203) {
                setIsLoading(false)
            } else {
                setIsLoading(false)
            }

        } catch (error) {
            console.log("error", error)
            setIsLoading(false)

        }
    }
    const handleTranslationLang = () => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
        setGeneralColumn([
            {
                text: t("superadmin.troubleshooting.name_and_surname"),
                dataField: "name",
                sort: true,
            },
            {
                text: t("superadmin.troubleshooting.email"),
                dataField: "email",
                align: "left",
                sort: true,
                style: {
                    textAlign: "left"
                }
            },
            {
                text: t("superadmin.troubleshooting.programs"),
                dataField: "program",
                sort: true
            },
            {
                text: t("superadmin.troubleshooting.last_access"),
                dataField: "last_Access",
                sort: true,
            },
            {
                text: t("superadmin.protocol.action"),
                dataField: "button",
                formatter: actionButtons,
                sort: true,
                headerAlign: "center"
            },
        ])
    };

    const callAPi = async () => {
        setIsLoading(true)
        let company = await getCompanyDetails(company_id)
        if (company) { setCompanyDetails(company) } else { setIsLoading(false) }
        getCompaniesData(1)
        handleTranslationLang()
    }

    useEffect(() => {
        callAPi()

    }, []);

    useEffect(() => {
    // console.log("test", courseContentId)

    }, [courseContentId]);


    const userCourseUpdate = (e, id) => {
        // setUserCourse(JSON.parse(e.target.value));
        setUserSelectCourse(JSON.parse(e.target.value));
        setUserCurrentProgram(id)
    };

    const saveCourseContentId =(e)=>{
        let arr2 = [...courseContentId];
        let exists = arr2.find(filter => filter === e.target.value);
        if(exists) {
            const updatedFilters = arr2.filter(filter => filter !== e.target.value);
            setCourseContentId(updatedFilters);
            // arr2 = [...arr2, updatedFilters]

        }else {
            // arr2 = [...arr2, e.target.value]

            setCourseContentId([...arr2, e.target.value])
        }
    }

    const handleSelectProgram = (id) => {
        // setAlertMessage({ show: false, spinner: false, message: "", class: "" })
        if (arrObj.includes(id)) {
            let arr = arrObj
            arr = arr.filter((item) => item !== id)          
            setArrObj(arr)
        } else {
            setArrObj(arrObj => [...arrObj, id])
        }
    }

    const pageListRenderer = ({
        page,
        pages,
        active,
        disable,
        title,
        onPageChange
    }) => {
        const handleClick = (e) => {
            e.preventDefault();
            onPageChange(page);
        };
        const activeStyle = {};
        if (active) {
            activeStyle.backgroundColor = '#6d71f9';
            activeStyle.margin = "10px";
            activeStyle.padding = "5px 15px";
            activeStyle.color = 'white';
        } else {
            // activeStyle.backgroundColor = 'gray';
            activeStyle.margin = "10px";
            activeStyle.padding = "5px 15px";
            activeStyle.color = 'black';
        }
        if (typeof page === 'string') {
            activeStyle.backgroundColor = '';
            activeStyle.color = '#6d71f9';
        }
        return (
            <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12 d-flex justify-content-center align-items-center pagination-list">
                {pages.map(p => {
                    if (p.page === "<") return <div key={p.page} style={{ margin: "10px", cursor: "pointer" }} onClick={(e) => onPageChange(p.page)}><img src={PRIVIEWICONA} alt="icon" /></div>
                    if (p.page === ">") return <div key={p.page} style={{ margin: "10px", cursor: "pointer" }} onClick={(e) => onPageChange(p.page)}><img src={NEXTICONA} alt="icon" /></div>
                    if (p.active) return <div key={p.page} className="active" onClick={(e) => onPageChange(p.page)}>{p.page}</div>
                    return (
                        <div key={p.page} style={{ margin: "10px", cursor: "pointer" }} onClick={(e) => onPageChange(p.page)}>{p.page}</div>
                    )
                })}
            </div>
        );
    };
    let paginationOption = {
        paginationSize: 3,
        sizePerPage: 10,
        hideSizePerPage: true,
        withFirstAndLast: false,
        pageListRenderer
    }
    const changeCompanyUserStatus = async () => {
        setIsLoading(true);
        const payload =
        {
            user_id: changeUserStatusId.user_id,
            status: Number(changeUserStatusId.status)
        }
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const getDashboard_Data = await PutRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ACTIVE_DEACTIVE_COMPANY_USER}`,
                    payload,
                    "",
                    ""
                );
                if (getDashboard_Data && getDashboard_Data.status === 200) {
                    setIsLoading(false);
                    closeModal();
                    getCompaniesData(currentPage);
                    setIsDisabled(false)
                } else {
                    setIsLoading(false);
                    setIsDisabled(false)
                }
            } else {
                setIsLoading(false);
                setIsDisabled(false)
            }
        } catch (error) {
            setIsLoading(false);
            setIsDisabled(false)
        }
    }
    const disableCompanyModalContent = () => {
        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={Popup_Cross} alt="icon" />{" "}
                            </div>
                            {/* <strong>{t("user.dashboard.heading_1")}</strong> */}
                            <strong>{t("superadmin.troubleshooting.popup.disable_training_heading")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="container">
                            {popupLoader1 && <div className="d-flex alert alert-info">
                                <div className="spinner-container-new">
                                    <div className="loading-spinner-new">
                                    </div>
                                </div>
                                <div className="ml-1"> {t("superadmin.training.popup.wait")} </div>
                            </div>}
                            <h6><strong>{t("superadmin.troubleshooting.popup.disable_training_content")} "{changeUserStatusId?.name}"?</strong></h6>
                        </div>
                        <div className="d-flex justify-content-end buttons-superadmin">
                            <button className="buttons modal-button mr-3" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button className={isDisabled ? 'disabled-btn' : 'enable-btn'} disabled={isDisabled} onClick={() => { setPopupLoader1(true); changeCompanyUserStatus(); setIsDisabled(true) }}>{t("superadmin.protocol.popup.confirm")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const enableCompanyModalContent = () => {
        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={Popup_right} alt="icon" />{" "}
                            </div>
                            {/* <strong>{t("user.dashboard.heading_1")}</strong> */}
                            <strong>{t("superadmin.troubleshooting.popup.enable_training_heading")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="container">
                            {popupLoader2 && <div className="d-flex alert alert-info">
                                <div className="spinner-container-new">
                                    <div className="loading-spinner-new">
                                    </div>
                                </div>
                                <div className="ml-1"> {t("superadmin.training.popup.wait")} </div>
                            </div>}
                            <h6><strong>{t("superadmin.troubleshooting.popup.enable_training_content")} "{changeUserStatusId?.name}"?</strong></h6>
                        </div>
                        <div className="d-flex justify-content-end buttons-superadmin">
                            <button className="buttons modal-button mr-3" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button className="buttons modal-button" onClick={() => { setPopupLoader2(true); changeCompanyUserStatus() }}>{t("superadmin.protocol.popup.confirm")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    const userAllProgramsDetails = async (userData) => {
        setIsLoading(true);
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {

                const programDetails = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_USER_ALL_PROGRAMS_DETAILS}?user_id=${userData.user_id}`
                );
                if (programDetails && programDetails.status === 200) {
                    setIsLoading(false)
                    if (programDetails?.data?.data?.programs?.length > 0) {
                        let arr = [];
                        setUserProgramDetails(programDetails.data.data?.programs);
                        setUserProgramStatusId(programDetails.data.data?.userProgramStatusID)
                        Object.values(programDetails.data.data.programs).forEach((obj, objVal) => {
                            arr = [...arr, obj.id]
                        })
                        setArrObj(arr)
                    }
                    else {                      
                        setUserProgramDetails([]);
                        setUserProgramStatusId();
                        setIsLoading(false)
                    }
                } else {
                    setIsLoading(true);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }
    const getCompanyAllProgram = async () => {
        const payload = {
            company_id: props.match.params.company
        };
        setIsLoading(true);
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const getAllDataResult = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_COMPANY_PROGRAM}`,
                    payload,
                    "",
                    ""
                );
                if (getAllDataResult && getAllDataResult.status === 200) {

                    if (getAllDataResult?.data?.data?.length > 0) {
                        setAllProgramList(getAllDataResult?.data?.data)
                        setShowProgramList(getAllDataResult?.data?.data)
                        if (selectProgramUser === true) {
                            setIsLoading(true);
                        }
                        else {
                            setIsLoading(false)
                        }
                    }
                    else {
                        setAllProgramList();
                        if (selectProgramUser === true) {
                            setIsLoading(true);
                        }
                        else {
                            setIsLoading(false)
                        }
                    }
                } else {
                    if (selectProgramUser === true) {
                        setIsLoading(true);
                    }
                    else {
                        setIsLoading(false)
                    }
                }
            } else {
                if (selectProgramUser === true) {
                    setIsLoading(true);
                }
                else {
                    setIsLoading(false)
                }
            }
        } catch (error) {
            if (selectProgramUser === true) {
                setIsLoading(true);
            }
            else {
                setIsLoading(false)
            }
        }
    };

    const assignProgramToUser = async () => {
        setIsLoading(true);
        const payload = {
            user_id: getUserId?.user_id ?? null,
            programs: arrObj
        };
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const getAllDataResult = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ASSIGNEE_PROGRAM_TO_USER}`,
                    payload,
                    "",
                    ""
                );
                if (getAllDataResult && getAllDataResult.status === 200) {
                    setIsLoading(false);
                    setAlertMessage({})
                    setUserId({ user_id: userDetails.user_id });
                    setUserDetails({ user_id: userDetails.user_id, name: userDetails.name, email: userDetails.email })
                    if (selectProgramUser === true) {
                        setSelectProgramUser(false)
                        setModifyProgramUser(true)
                        await userAllProgramsDetails({ user_id: userDetails.user_id })
                        getCompaniesData(currentPage)
                    }
                    else if (modifyProgramUser === true) {
                        closeModal();
                        getCompaniesData(currentPage)
                    }
                } else {
                    setAlertMessage({ show: true, spinner: false, message: getAllDataResult.data?.message, class: "alert alert-danger" })
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };


    const modifyProgramUserModal = () => {
        return (
            <div>
                {(isLoading) ? <LazyLoader /> : null}
                <div className="model-pop" style={{ width: "100%" }}>
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={EDIT_PENCIL} alt="icon" />{" "}
                            </div>
                            <strong>{t("superadmin.training.test_program.modify_user_program")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => { closeModal() }}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="">
                            <div className="d-flex mt-3">
                                <div className="mr-3 col-md-6">
                                    <label className="form-label "><b>{t("superadmin.troubleshooting.name_and_surname")}</b></label>
                                    <span className="d-flex" >{userDetails.name}</span>
                                </div>

                                <div className="mr-3 col-md-6">
                                    <label className="form-label"><b>{t("superadmin.troubleshooting.email")}</b></label>
                                    <span className="d-flex">{userDetails.email}</span>
                                </div>
                            </div>
                            <div className="d-flex mt-3">
                                <div className="mr-3 col-md-6">
                                    <label className="form-label "><b>{t("superadmin.training.test_program.assigne_program")}</b></label>
                                </div>

                            </div>
                        </div>

                        {
                            (userProgramDetails.length > 0)
                                ?
                                userProgramDetails.map((data, i) => {
                                    return (
                                        <div key={i} className="col-md-12 mb-3">
                                            {/* <div id="accordion" className="accordion"> */}
                                            <div id={`accordion${data.id}`} className="accordion">

                                                <div className="row">
                                                    <div className="col-md-12 iapdwful">
                                                        <div className="card mb-0">
                                                            <div className="full-cover-bordertable">
                                                                {/* <div
                                                                    className="bg-light-purple card-header collapsed"
                                                                    data-toggle="collapse"
                                                                    href={`#${data.id}`}
                                                                    aria-expanded="true"
                                                                > */}

                                                                <div
                                                                    className={`bg-light-purple card-header ${openAccordionId === data.id
                                                                        ? ''
                                                                        : 'collapsed'
                                                                        }`}
                                                                    data-toggle="collapse"
                                                                    onClick={() =>
                                                                        handleAccordionToggle(data.id)
                                                                    }
                                                                    aria-expanded={
                                                                        openAccordionId === data.id
                                                                    }
                                                                >
                                                                    <a className="card-title">
                                                                        <div className="d-faqflex">
                                                                            {data.programName}
                                                                        </div>
                                                                    </a>
                                                                </div>

                                                                {/* <div
                                                                    id={data.id}
                                                                    className="card-body collapse"                                                                    
                                                                    data-parent={`accordion${data.id}`}
                                                                    > */}
                                                                <div
                                                                    id={data.id}
                                                                    className={`card-body collapse ${openAccordionId === data.id
                                                                        ? 'show'
                                                                        : ''
                                                                        }`}
                                                                    data-parent={`accordion${data.id}`}
                                                                >



                                                                    <div className="flex-inizia">
                                                                        <div className="arrow_down1 select-iniza mr-3">
                                                                            <select name="course" className="border_n right-super-select-arrow" onChange={(e) => {userCourseUpdate(e, data.id); setCourseContentId([])}}>
                                                                                <option value="0">{t("superadmin.training.test_program.option")}</option>
                                                                                {
                                                                                    Object.entries(data.course).map((key, val) => {
                                                                                        return (
                                                                                            <option key={val} value={JSON.stringify(key[1])}>{key[0]}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                        <button className="buttons modal-button height ripeti mr-0" onClick={() => { setRepeatProgramData({ ...repeatProgramData, programId: data.id }); setRepeatProgramModal(true) }}>{t("superadmin.training.test_program.repeat")}</button>
                                                                    </div>

                                                                    <table className="table-Percorsi">
                                                                        <tr>
                                                                            <th></th>
                                                                            <th>{t("superadmin.training.paths")}</th>
                                                                            <th>{t("superadmin.troubleshooting.last_access")}</th>
                                                                            <th>{t("superadmin.program.status")}</th>
                                                                        </tr>

                                                                        {
                                                                            (userCurrentProgram == data.id) ?
                                                                                Object.values(userSelectCourse).map((c, v) => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td className="vertical-align-check">
                                                                                                <div className="form-check">
                                                                                                    <input className="form-check-input" type="checkbox" checked={(courseContentId.includes(c.contentId))} value={c.contentId} id={c.contentId} onChange={(e)=> saveCourseContentId(e)}/>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>{c.contentName}</td>
                                                                                            <td>{moment.unix(c.lastAccess).format("DD.MM.YY")}</td>
                                                                                            <td><span style={{ backgroundColor: "orange", padding: "5px 5px", borderRadius: "5px" }}>{c.status}</span></td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                                :
                                                                                ""
                                                                            // Object.values(userCourse).map((c, v) => {
                                                                            //     return (
                                                                            //             <tr>
                                                                            //                 <td className="vertical-align-check">
                                                                            //                     <div className="form-check">
                                                                            //                         <input className="form-check-input" type="checkbox" value={c.contentId} id={c.contentId} />
                                                                            //                     </div>
                                                                            //                 </td>
                                                                            //                 <td>{c.contentName}</td>
                                                                            //                 <td>{moment.unix(c.lastAccess).format("DD.MM.YY")}</td>
                                                                            //                 <td><span style={{ backgroundColor: "orange", padding: "5px 5px", borderRadius: "5px" }}>{c.status}</span></td>
                                                                            //             </tr>
                                                                            //     )
                                                                            // })

                                                                        }
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <>
                                    {isLoading ? <LazyLoader /> : ''}
                                    <div className="card mb-0">
                                        {/* <div className="full-cover-bordertable"> */}
                                        <div
                                            className="bg-light-purple card-header collapsed"
                                            data-toggle="collapse"
                                            aria-expanded="true"
                                        >
                                            <a className="card-title">
                                                {t("superadmin.training.test_program.selectProgram")}
                                            </a>
                                        </div>
                                        {/* </div> */}
                                    </div>
                                </>
                        }

                        {alertMessage.show ? <div className={`d-flex mt-3 ${alertMessage.class}`} >
                            {alertMessage.spinner ? <div className="spinner-container-new">
                                <div className="loading-spinner-new">
                                </div>
                            </div>
                                : null}
                            <div className="ml-1">{alertMessage.message}</div>
                        </div> : null}
                        <div className="d-flex justify-content-end buttons-superadmin mt-3" >
                            <button className="buttons modal-button mr-3" onClick={() => { setSelectProgramUser(true); getCompanyAllProgram(); setModifyProgramUser(false); }}>{t("superadmin.training.test_program.add_schedule")}</button>
                            <button className="buttons modal-button" onClick={() => assignProgramToUser()}>{t("superadmin.training.popup.save")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    const selectProgramUserModal = () => {
        return (
            <div>
                <div className="model-pop">
                    {isLoading ? <LazyLoader /> : ''}
                    <div className="modal-header">
                        <div className="model-header-adjust">
                            <h5 className="modal-title pop_heading" id="exampleModalLabel">
                                <strong>{t("superadmin.training.programs")}</strong>
                            </h5>
                            <div className="search-cancel">
                                <div className="searchbar">
                                    <div className="position-relative">
                                        <img src={Group698} className="search_icon-new" alt="icon" />
                                        <label htmlFor="search-bar-0" className="search-label">
                                            <span id="search-bar-0-label" className="sr-only"></span>
                                            <input id="search-bar-0" aria-labelledby="search-bar-0-label" className="search-bar-new form-control" placeholder={t("superadmin.protocol.search")} type="text" onChange={handleSearch} />
                                        </label>
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    className="close close-btn-pr"
                                    onClick={() => setSelectProgramUser(false)}
                                >
                                    <img src={Group769} alt="icon" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="">
                            {
                                showProgramList.map((data, i) => {
                                    return (
                                        <div key={i} className="d-flex mt-3 obbligatorieta-check-new">
                                            <div className="mr-3 col-md-12">
                                                <label className={`mr-4 check-radiotype ${arrObj.includes(data.program_id) ? "checked-icon" : ""} `}>
                                                    <input type="checkbox" onChange={() => handleSelectProgram(data.program_id)} />
                                                </label>
                                                <label className="form-label "><b>{data.program_name}</b></label>
                                                <hr></hr>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {alertMessage.show ? <div className={`d-flex mt-3 ${alertMessage.class}`} >
                            {alertMessage.spinner ? <div className="spinner-container-new">
                                <div className="loading-spinner-new">
                                </div>
                            </div>
                                : null}
                            <div className="ml-1">{alertMessage.message}</div>
                        </div> : null}
                        <div className="d-flex justify-content-between">
                            <div className="m-2">{`${arrObj?.length}`} <span>{t(("superadmin.training.button.programLength"))}</span></div>

                            <div className="d-flex justify-content-end buttons-superadmin mt-3" >
                                <button className="buttons modal-button mr-3" onClick={() => { assignProgramToUser(); setAlertMessage({ show: true, spinner: true, message: t("superadmin.training.popup.wait"), class: "alert alert-info" }); }}>{t("superadmin.training.popup.add")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const repeatProgramAPI = async () => {
        let obj = {
            userId: userDetails.user_id,
            userProgramStatusID: userProgramStatusID,
            programId: repeatProgramData.programId,
            companyId: company_id,
            contentId: courseContentId
        }
        setIsLoading(true);
        setDisableRepeatProgramButton(true)
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {

                const programDetails = await PutRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_REPEAT_PROGRAM_TO_USER}`,
                    obj,
                    "",
                    ""
                );
                if (programDetails && programDetails.status === 200) {
                    setDisableRepeatProgramButton(false)
                    setRepeatProgramModal(false)
                    setRepeatProgramData({ programId: "" })
                    setAlertMessageRepeat({ show: false, spinner: false, message: "", class: "" })
                    setIsLoading(false)
                    userAllProgramsDetails({ user_id: userDetails.user_id })
                } else {
                    if (programDetails && programDetails.status == 203) {
                        setAlertMessageRepeat({ show: true, spinner: false, message: programDetails.data?.message, class: "alert alert-danger" })
                    }
                    setIsLoading(false);
                    setDisableRepeatProgramButton(false)
                }
            } else {
                setDisableRepeatProgramButton(false)
                setIsLoading(false);
            }
        } catch (error) {
            setDisableRepeatProgramButton(false)
            setIsLoading(false);
        }
    }
    const repeatProgramModalContent = () => {
        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={Popup_Cross} alt="icon" />{" "}
                            </div>
                            {/* <strong>{t("user.dashboard.heading_1")}</strong> */}
                            <strong>{t("superadmin.troubleshooting.popup.repeat_program_heading")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => { setRepeatProgramModal(false); setRepeatProgramData({ programId: "" }); setPopupLoader1(false) }}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="container">
                            {alertMessageRepeat.show ? <div className={`d-flex ${alertMessageRepeat.class}`}>
                                {alertMessageRepeat.spinner ? <div className="spinner-container-new">
                                    <div className="loading-spinner-new">
                                    </div>
                                </div> : null
                                }
                                <div className="ml-1"> {alertMessageRepeat.message} </div>
                            </div> : null}
                            <h6><strong>{t("superadmin.troubleshooting.popup.repeat_program_content")} ?</strong></h6>
                        </div>
                        <div className="d-flex justify-content-end buttons-superadmin">
                            <button className="buttons modal-button mr-3" onClick={() => { setRepeatProgramModal(false); setRepeatProgramData({ programId: "" }); setPopupLoader1(false) }}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button className="buttons modal-button" disabled={disableRepeatProgramButton} onClick={() => { setAlertMessageRepeat({ show: true, spinner: true, message: `${t("superadmin.training.popup.wait")}`, class: "alert alert-info" }); repeatProgramAPI() }}>{t("superadmin.protocol.popup.confirm")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const handlePageClickList = (event) => {
        let page = event.selected + 1;
        let start = event.selected * 10;
        setCurrentPage(page)
        getCompaniesData(page)
    };
    return (
        <>
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setDisableCompanyModal(false)} show={disableCompanyModal}>
                {disableCompanyModalContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setEnableCompanyModal(false)} show={enableCompanyModal}>
                {enableCompanyModalContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="modifica-programi-popup" onClose={() => setModifyProgramUser(false)} show={modifyProgramUser}>
                {modifyProgramUserModal()}
            </Modal>
            <Modal title="" size="sm" contentClasses="modifica-programi-popup" onClose={() => setSelectProgramUser(false)} show={selectProgramUser}>
                {selectProgramUserModal()}
            </Modal>
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setRepeatProgramModal(false)} show={repeatProgramModal}>
                {repeatProgramModalContent()}
            </Modal>

            <section className="full_bg dashboard-mobile">
                <div className="container-fluid">
                    <div className="row">
                        <Super_Sidebar path="training" />
                        <div className="col-md-10 pr-0 p0 ipd-righ">
                            <div className="right-dashboard topspace">
                                <div className="protocollo-space">
                                    <h2 className="heading-dash">
                                        <button className="back-button" onClick={() => window.location.href = `/sa/training/${company_id}`}><img src={PREV_PAGE_ICON} alt="icon" /></button>
                                        {companyDetails?.company_name} - {t("superadmin.training.button.troubleshooting")}                                    </h2>
                                </div>
                                {(companiesData.length > 0 && companiesData) ? <>
                                    <ToolkitProvider
                                        keyField="user_id"
                                        key={'datatable'}
                                        // data={(!controlValue) ? ((filterValue.length === 0) ? ((selection === 0) ? redsource : (selection === 1) ? redsource.slice(0, 10) : (selection === 2) ? redsource.slice(10, 20) : (selection === 3) ? redsource.slice(20, 30) : redsource) : filteredData) : reportDatatutti}
                                        data={companiesData}
                                        columns={generalColumn}
                                        search
                                    >{(props) => (

                                        <div id="test" className="table  ricsb ricsb-new protocolli-data">
                                            <div>
                                                <div>
                                                    <div className="searchbar d-flex justify-content-start position-relative m-2">
                                                        {/* <img
                                                            src={Group698}
                                                            className="search_icon"
                                                            alt="icon"
                                                        /> */}
                                                        {/* <SearchBar
                                                            placeholder={t("superadmin.protocol.search")}
                                                            {...props.searchProps}
                                                        /> */}
                                                    </div>
                                                    <BootstrapTable
                                                        src={VectorDown} alt="icon"
                                                        // data={(!controlValue) ? ((filterValue.length === 0) ? ((selection === 0) ? redsource : (selection === 1) ? redsource.slice(0, 10) : (selection === 2) ? redsource.slice(10, 20) : redsource) : filteredData) : reportDatatutti}
                                                        data={companiesData}
                                                        {...props.baseProps}
                                                        wrapperClasses={'table-responsive'}

                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    )}
                                    </ToolkitProvider>
                                    <div className="d-flex sa-program-pagination mt-2">
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel={<img src={NEXTICONA} alt="icon" />}
                                            previousLabel={<img src={PRIVIEWICONA} alt="icon" />}
                                            onPageChange={handlePageClickList}
                                            pageRangeDisplayed={3}
                                            pageCount={pageCount}
                                            renderOnZeroPageCount={null}
                                            containerClassName="selectuser-pagination"
                                            pageClassName="page-class"
                                            previousClassName={currentPage == 1 ? "d-none" : ""}
                                            nextClassName={currentPage == pageCount ? "d-none" : ""}
                                            forcePage={currentPage - 1}
                                        />
                                    </div>
                                </> : null}
                            </div>
                        </div>
                    </div>
                    {(isLoading) ? <LazyLoader /> : null}
                </div>
            </section>
            <Super_Sidebarmob />
        </>
    );
}

export default SA_Troubleshooting;


