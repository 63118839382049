import { useEffect, useState, useRef } from "react";
import { useHistory } from 'react-router-dom';
import Super_Sidebar from "./super_sidebar";
import Super_Sidebarmob from "./super_sidebarmob";
import Modal from "../../common/modal";
import PostRequest from "../../apiConnection/postRequest";
import LazyLoader from "../../common/lazyLoader";
import { useTranslation } from "react-i18next";
import { getLocalStoarge, setLocalStoarge, getCompanyDetails } from "../../common/allfunction";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import GetRequest from "../../apiConnection/getRequest";
import NEXTICONA from "../../../assets/Image/NEXTICONA.svg"
import PRIVIEWICONA from '../../../assets/Image/PREVIEWICONA.svg'
import DOCUMENT_ICON from "../../../assets/Image/DOCUMENT_ICON.svg";
import VectorDown from "../../../assets/Image/VectorDown.svg";
import filterFactory from "react-bootstrap-table2-filter";
import Group698 from "../../../assets/Image/Group698.svg";
import Group878 from "../../../assets/Image/Group878.svg";
import Group769 from "../../../assets/Image/Group769.svg";
import Group1171 from "../../../assets/Image/Group1171.svg";
import CrossAdmin from "../../../assets/Image/cross-admin-icon.svg";
import DeleteBin from '../../../assets/Image/delete-bin.svg'
import Delete_Icon_popup from '../../../assets/Image/Delete_Icon_popup.svg'
import Popup_Cross from '../../../assets/Image/Popup_Cross.svg'
import Popup_right from '../../../assets/Image/Popup_right.svg'
import PREV_PAGE_ICON from '../../../assets/Image/PREVPAGE_ICON.svg';
import '../super_admin_assets/scss/protocol.scss?v=0';
import PutRequest from "../../apiConnection/putRequest";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { SketchPicker } from 'react-color';

const Add_Protocol = (props) => {
    const [errorfetch, setErrorfetch] = useState(
        props.match.params?.errorfetch && props.match.params?.errorfetch !== ""
            ? props.match.params?.errorfetch
            : ""
    );
    let history = useHistory();
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const inputRef = useRef(null);
    const { SearchBar } = Search;
    const [company_id, setCompany_id] = useState(props.match.params.company_id)
    const [companyUserData, setCompanyUserData] = useState([])
    const [companyAdminData, setCompanyAdminData] = useState([])
    const path = window.location.pathname;
    const [t, i18n] = useTranslation("common");
    const [isLoading, setIsLoading] = useState(false);
    const [detail, setDetail] = useState("");
    const [loading, setLoading] = useState(true);
    const [tableData, settableData] = useState("");
    const [disableUserModal, setDisableUserModal] = useState(false)
    const [enableUserModal, setEnableUserModal] = useState(false)
    const [disableAdminModal, setDisableAdminModal] = useState(false)
    const [enableAdminModal, setEnableAdminModal] = useState(false)
    const [changeUserStatusId, setChangeUserStatusId] = useState({})
    const [popupLoader1, setPopupLoader1] = useState(false)
    const [popupLoader2, setPopupLoader2] = useState(false)
    const [popupLoader3, setPopupLoader3] = useState(false)
    const [companyDetails, setCompanyDetails] = useState({})
    const [isDisabled, setIsDisabled] = useState(true)
    const [personalText, setPersonalText] = useState("general");
    const [alerts, setAlerts] = useState({ alert: "", alerttype: "", alertclass: "danger", showalert: false, });
    const { alert, alerttype, alertclass, showalert } = alerts;
    const [profile_pic, setProfile] = useState();
    const [showpic, setSHowPic] = useState("default.png");
    const [inputValue, setInputValue] = useState({
        name: "", serviceStartDate: null, weeksProtocol: 0, referent: '', toiMeasurement: 0,
        // parameterMeasurements: 18,
        color1: "#FEE01A", color2: "#0057B7", address: '', user: 0,
        admin: 0, email: "", contractExpiry: "", reminderDay: "", measurementsReminder: 0, icon: "building.jpg", configId: "", urlParam: "", finalUrl: "",consentIdEn:"",consentIdIt:""
    })

    const [inputDateValue, setInputDateValue] = useState({
        serviceStartDate: null, contractExpiry: null
    })


    const [weekOption, setWeekOption] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15])
    const [textCopy, setTextCopy] = useState(false)
    const [showColorPicker1, setShowColorPicker1] = useState(false)
    const [showColorPicker2, setShowColorPicker2] = useState(false)
    const [allReminderDay, setAllReminderDay] = useState([t("superadmin.protocol.mon"), t("superadmin.protocol.tue"), t("superadmin.protocol.wed"), t("superadmin.protocol.thu"), t("superadmin.protocol.fri")])
    const [alertMessage, setAlertMessage] = useState({ show: false, spinner: false, message: "", class: "" })
    const [alertMessage2, setAlertMessage2] = useState();
    const [newUrlParam, setNewUrlParam] = useState();
    const actionButtons = (cell, row, rowIndex, formateExtraData) => {
        return (
            <div>
                <button className="table-action" onClick={() => { }}><img src={Group878} alt="icon" /></button>
                <button className="table-action" onClick={() => { setDisableUserModal(true); setChangeUserStatusId({ user_id: row.user_id, status: 0, name: row.name }) }}><img src={DeleteBin} alt="icon" /></button>
            </div>
        )
    }
    const actionButtonAdmin = (cell, row, rowIndex, formateExtraData) => {
        return (
            <div>
                <button className="table-action" onClick={() => { }}><img src={Group878} alt="icon" /></button>
                {!row.status ? <button className="table-action bg-green-delete" onClick={() => { setEnableAdminModal(true); setChangeUserStatusId({ user_id: row.user_id, status: 1, name: row.name }) }} title="Enable">
                    <img src={CrossAdmin} alt="icon" />
                </button> :
                    <button className="table-action bg-green-delete" onClick={() => { setDisableAdminModal(true); setChangeUserStatusId({ user_id: row.user_id, status: 0, name: row.name }) }} title="Disable">
                        <img src={Group1171} alt="icon" />
                    </button>
                }
            </div>
        )
    }
    const [generalColumn, setGeneralColumn] = useState([{ text: "Nome e Cognome", dataField: "name", sort: true }]);
    const [adminColumn, setAdminColumn] = useState([{ text: "Nome e Cognome", dataField: "name", sort: true }]);
    const getCompanyAllUser = async () => {
        const payload = {
            "company": company_id,
            "role_id": "1"
        };
        setIsLoading(true);
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const getAllDataResult = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_COMPANY_USER_LIST}`,
                    payload,
                    "",
                    ""
                );
                getCompanyAllAdmin()
                if (getAllDataResult && getAllDataResult.status === 200) {
                    setIsLoading(false);
                    if (getAllDataResult.data?.data?.length > 0) {
                        let arr = [];
                        getAllDataResult.data.data.forEach((item) => {
                            if (item.status == 1) {
                                arr = [...arr, {
                                    name: item.name || "",
                                    role: (item.designation == "null") ? "Administration" : item.designation,
                                    email: item.email || '',
                                    last_access: item.last_Access ? `${moment(new Date(item.last_Access * 1000)).format("DD.MM.YY").replace(/YYYY/g, "YY")}` : '-',
                                    measurements: item.scan > -1 ? item.scan : '',
                                    user_id: item.user_id || '',
                                    role_id: item.role_id || '',
                                    status: item.status || 0
                                }]
                            }
                        })
                        setCompanyUserData(arr)
                    }
                } else {
                    setIsLoading(false);
                    setLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error)
            setIsLoading(false);
        }
    };
    const validateConsentId = (value) => {
        const pattern = /^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$/;
        const isValidFormat = pattern.test(value);
        const isValidLength = value.length === 36; // 8 + 4 + 4 + 4 + 12 + 4 hyphens = 30 characters
        return isValidFormat && isValidLength;
    };
    const checkValidation = (e) => {
        e.preventDefault();
        let emailRegex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
        let userExp = new RegExp(/^[a-zA-Z ]*$/);
        let colorExp = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);
        let addressExp = new RegExp(/^[a-zA-Z0-9\s!@#$%^&*()_+-={};:'",.<>?/\\[\]]+$/);
        let configId_match = new RegExp(/^[a-zA-Z0-9]+$/);
        inputValue.toiMeasurement = parseInt(inputValue.toiMeasurement);
        inputValue.name = inputValue.name.trim();
        inputValue.referent = inputValue.referent.trim();
        inputValue.address = inputValue.address.trim();
        inputValue.configId = inputValue.configId.trim();
        if (inputValue.color1 == "" || inputValue.color1 == null || !inputValue.color1 || (!inputValue.color1.match(colorExp))) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.err_color1")}`, class: "alert alert-danger" })
        }
        else if (inputValue.color2 == "" || inputValue.color2 == null || !inputValue.color2 || (!inputValue.color2.match(colorExp))) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.err_color2")}`, class: "alert alert-danger" })
        }
        else if (inputValue.name?.length < 3 || inputValue.name?.length > 30 || !(inputValue.name.match(userExp))) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.invalidCompanyName")}`, class: "alert alert-danger" })
        }
        else if (inputValue.serviceStartDate == "" || inputValue.contractExpiry == "" || inputValue.serviceStartDate == null || inputValue.contractExpiry == null) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.invalidCEDate")}`, class: "alert alert-danger" })
        }
        else if (inputValue.weeksProtocol < 4 || inputValue.weeksProtocol > 52) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.weekError")}`, class: "alert alert-danger" })
        }
        else if (inputValue.referent?.length < 3 || inputValue.referent?.length > 30) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.invalidReferent")}`, class: "alert alert-danger" })
        }
        else if (inputValue.toiMeasurement < 0 || inputValue.toiMeasurement > 1000 || inputValue.toiMeasurement == undefined) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.invalidTOImeasurement")}`, class: "alert alert-danger" })
        }
        else if (inputValue.address?.length < 10 || inputValue.address?.length > 150 || (!inputValue.address.match(addressExp))) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.invalidAddress")}`, class: "alert alert-danger" })
        }
        else if (!inputValue.email.match(emailRegex)) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.invalidEmail")}`, class: "alert alert-danger" })
        }
        else if (inputValue.serviceStartDate > inputValue.contractExpiry) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.invalidContractExpireDate")}`, class: "alert alert-danger" })
        }
        else if (!inputValue.configId || inputValue.configId == "" || inputValue.configId == null || (!inputValue.configId.match(configId_match)) || inputValue.configId?.length <= 10 || inputValue.configId?.length >= 12) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.configIdError")}`, class: "alert alert-danger" })
        }
        else if (!inputValue.urlParam || inputValue.urlParam == "" || inputValue.urlParam == null || (!inputValue.urlParam.match(configId_match)) || inputValue.configId?.length <= 10 || inputValue.configId?.length >= 12) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.urlString_error")}`, class: "alert alert-danger" })
        }
        else if (!inputValue.consentIdEn || inputValue.consentIdEn == "" || inputValue.consentIdEn == null || (!validateConsentId(inputValue.consentIdEn))) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.consentIdEn")}`, class: "alert alert-danger" })
        }
        else if (!inputValue.consentIdIt || inputValue.consentIdIt == "" || inputValue.consentIdIt == null || (!validateConsentId(inputValue.consentIdIt))) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.consentIdIt")}`, class: "alert alert-danger" })
        }
        else {
            setAlertMessage({ show: true, spinner: true, message: `${t("superadmin.training.popup.wait")}`, class: "alert alert-info" })
            addProtocol()
        }
    }

    const addProtocol = async () => {
        setIsLoading(true);
        try {
            let payload = {
                icon: inputValue.icon,
                company_name: inputValue.name,
                start_date: inputValue.serviceStartDate,
                weeks: inputValue.weeksProtocol,
                referent: inputValue.referent,
                maxScan: inputValue.toiMeasurement,
                // measurementParameter: inputValue.parameterMeasurements,
                primary_color: inputValue.color1,
                secondary_color: inputValue.color2,
                address: inputValue.address,
                referentEmail: inputValue.email,
                contract_expirations_date: inputValue.contractExpiry,
                reminderDay: inputValue.reminderDay,
                measurementReminder: inputValue.measurementsReminder,
                configId: inputValue.configId,
                url: inputValue.finalUrl,
                consentIdEn: inputValue.consentIdEn,
                consentIdIt: inputValue.consentIdIt
            }
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const getCompanyData = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADD_COMPANY_DETAILS}`,
                    payload,
                    "",
                    ""
                );
                if (getCompanyData && getCompanyData.status === 200) {
                    setIsLoading(false);
                    setAlertMessage({ show: true, spinner: true, message: `${getCompanyData.data.message}`, class: "alert alert-success" })
                    setTimeout(() => {
                        setAlertMessage({})
                        window.location.href = "/sa/protocol";
                    }, 3000)

                } else {
                    if (getCompanyData.status === 203) {
                        setAlertMessage({ show: true, spinner: true, message: `${getCompanyData.data.message}`, class: "alert alert-danger" })
                        setAlertMessage2(getCompanyData.data.message);
                    }
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }

    const getCompanyAllAdmin = async () => {
        const payload = {
            "company": company_id,
            "role_id": "2"
        };
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const getDashboard_Data = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_COMPANY_USER_LIST}`,
                    payload,
                    "",
                    ""
                );
                if (getDashboard_Data && getDashboard_Data.status === 200) {
                    setIsLoading(false);
                    const alldata = getDashboard_Data.data.data;
                    let arr = [];
                    alldata.forEach((item) => {
                        arr = [...arr, {
                            name: item.name || "",
                            email: item.email || '',
                            last_access: item.last_Access ? `${moment(new Date(item.last_Access * 1000)).format("DD.MM.YY").replace(/YYYY/g, "YY")}` : '',
                            powerbi_role: item.power_bi_role.substring(0, 3).concat('...') || '',
                            powerbi_id: item.power_bi_id.substring(0, 20).concat('...') || '',
                            user_id: item.user_id || '',
                            role_id: item.role_id || '',
                            status: item.status || 0
                        }]
                    })
                    setCompanyAdminData(arr)
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };

    const handleTranslationLang = () => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
        setGeneralColumn([
            {
                text: t("superadmin.protocol.name_and_surname"),
                dataField: "name",
                sort: true,
            },
            {
                text: t("superadmin.protocol.role"),
                dataField: "role",
                sort: true,
            },
            {
                text: "E-mail",
                dataField: "email",
                sort: true,
            },
            {
                text: t("superadmin.protocol.last_access"),
                dataField: "last_access",
                sort: true,
            },
            {
                text: t("superadmin.protocol.measurements"),
                dataField: "measurements",
                sort: true,
            },
            {
                text: t("superadmin.protocol.action"),
                dataField: "button",
                formatter: actionButtons,
                sort: true,
                headerAlign: "left"
            },
        ])
        setAdminColumn([
            {
                text: t("superadmin.protocol.name_and_surname"),
                dataField: "name",
                sort: true,
            },
            {
                text: "E-mail",
                dataField: "email",
                sort: true,
            },
            {
                text: "PowerBI Role",
                dataField: "powerbi_role",
                sort: true,
            },
            {
                text: "PowerBI ID",
                dataField: "powerbi_id",
                sort: true,
            },
            {
                text: t("superadmin.protocol.last_access"),
                dataField: "last_access",
                sort: true,
            },
            {
                text: t("superadmin.protocol.action"),
                dataField: "button",
                formatter: actionButtonAdmin,
                sort: true,
                headerAlign: "left"
            },
        ])
    };
    const callApis = async () => {
        setIsLoading(true)
        let company = await getCompanyDetails(company_id)

        if (company) setCompanyDetails(company)
        else setIsLoading(false)
        await getCompanyAllDetails()
        getCompanyAllUser();
    }
    useEffect(() => {
        handleTranslationLang();
    }, []);
    useEffect(() => {
        callApis()
    }, [])
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (showColorPicker1 && ref1.current && !ref1.current.contains(e.target)) {
                setShowColorPicker1(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [showColorPicker1]);
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (showColorPicker2 && ref2.current && !ref2.current.contains(e.target)) {
                setShowColorPicker2(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [showColorPicker2]);
    useEffect(() => {
        calculateWeek(inputDateValue);
    }, [inputDateValue])
    const pageListRenderer = ({
        page,
        pages,
        active,
        disable,
        title,
        onPageChange
    }) => {
        return (
            <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12 d-flex justify-content-center pagination-list">
                {pages.map((p, index) => {
                    if (p.page === "<") return <div key={index} onClick={(e) => onPageChange(p.page)}><img src={PRIVIEWICONA} alt="icon" /></div>
                    if (p.page === ">") return <div key={index} onClick={(e) => onPageChange(p.page)}><img src={NEXTICONA} alt="icon" /></div>
                    if (p.active) return <div key={index} className="active" onClick={(e) => onPageChange(p.page)}>{p.page}</div>
                    return (
                        <div style={{ margin: "10px", cursor: "pointer" }} key={index} onClick={(e) => onPageChange(p.page)}>{p.page}</div>
                    )
                })}

            </div>
        );
    };
    let paginationOption = {
        paginationSize: 3,
        sizePerPage: 10,
        hideSizePerPage: true,
        withFirstAndLast: false,
        pageListRenderer
    }
    const closeModal = () => {
        setChangeUserStatusId({})
        setDisableAdminModal(false)
        setEnableAdminModal(false)
        setDisableUserModal(false)
        setPopupLoader1(false)
        setPopupLoader2(false)
        setPopupLoader3(false)
    }
    const changeCompanyUserStatus = async () => {
        const payload = changeUserStatusId
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const getDashboard_Data = await PutRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ACTIVE_DEACTIVE_COMPANY_USER}`,
                    payload,
                    "",
                    ""
                );
                if (getDashboard_Data && getDashboard_Data.status === 200) {
                    setIsLoading(false);
                    setIsDisabled(false)
                    closeModal()
                    getCompanyAllUser()
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }
    const disableUserModalContent = () => {
        return (
            <div className="model-pop-new">
                <div className="model-pop ">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={Delete_Icon_popup} alt="icon" />{" "}
                            </div>
                            <strong>{t("superadmin.protocol.popup.delete_user_heading")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div></div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="container">
                            {popupLoader1 && <div className="d-flex alert alert-info">

                                <div className="ml-1"> {t("user.login.wait")} </div>
                            </div>}
                            <h6>{t("superadmin.protocol.popup.delete_user_content")} "{changeUserStatusId?.name}"?</h6>
                        </div>
                        <div className="d-flex justify-content-end buttons-superadmin">
                            <button className="buttons modal-button mr-3" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button className={isDisabled ? 'disable-btn' : 'enable-btn'} disabled={isDisabled} onClick={() => { changeCompanyUserStatus(); setPopupLoader1(true); setIsDisabled(true) }}>{t("superadmin.protocol.popup.delete")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const disableAdminModalContent = () => {
        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={Popup_Cross} alt="icon" />{" "}
                            </div>
                            <strong>{t("superadmin.protocol.popup.disable_admin_heading")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="container">

                            {popupLoader2 && <div className="d-flex alert alert-info">
                                <div className="spinner-container-new">
                                    <div className="loading-spinner-new">
                                    </div>
                                </div>
                                <div className="ml-1"> {t("user.login.wait")} </div>
                            </div>}
                            <h6><strong>{t("superadmin.protocol.popup.disable_admin_content")} "{changeUserStatusId?.name}"?</strong></h6>
                        </div>
                        <div className="d-flex justify-content-end buttons-superadmin">
                            <button className="buttons modal-button mr-3" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button className="buttons modal-button" onClick={() => { changeCompanyUserStatus(); setPopupLoader2(true) }}>{t("superadmin.protocol.popup.confirm")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const enableAdminModalContent = () => {
        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={Popup_right} alt="icon" />{" "}
                            </div>
                            <strong>{t("superadmin.protocol.popup.enable_admin_heading")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="container">
                            {popupLoader3 && <div className="d-flex alert alert-info">
                                <div className="spinner-container-new">
                                    <div className="loading-spinner-new">
                                    </div>
                                </div>
                                <div className="ml-1"> {t("user.login.wait")} </div>
                            </div>}
                            <h6><strong>{t("superadmin.protocol.popup.enable_admin_content")} "{changeUserStatusId?.name}"?</strong></h6>
                        </div>
                        <div className="d-flex justify-content-end buttons-superadmin">
                            <button className="buttons modal-button mr-3" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button className="buttons modal-button" onClick={() => { changeCompanyUserStatus(); setPopupLoader3(true) }}>{t("superadmin.protocol.popup.confirm")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const handleImageChange = async (e) => {
        e.preventDefault();
        let files = e.target.files[0];
        if (files) {
            let dataImage = new FormData();
            dataImage.append("image", e.target.files[0]);
            const checkImage = e.target.files[0];
            const fileExtension = checkImage.name.replace(/^.*\./, '');
            const fileSize = Math.round((checkImage.size / 1024));
            // to check image size and type
            if (fileExtension === 'png' || fileExtension === 'PNG' || fileExtension === 'jpg' || fileExtension === 'JPG' || fileExtension === 'JPEG' || fileExtension === 'jpeg') {
                if (fileSize <= 1040) {
                    setIsLoading(true);
                    try {
                        const refreshToken = await PostRequest(
                            `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                            {},
                            "",
                            "refresh_token"
                        );
                        if (refreshToken) {
                            const addProfileResult = await PostRequest(
                                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOAD_COMPANY_LOGO}`,
                                dataImage,
                                "",
                                "ImageUpload"
                            );
                            if (addProfileResult && addProfileResult.status === 200) {
                                setIsLoading(false);
                                if (addProfileResult.data.code === 200) {
                                    setInputValue({ ...inputValue, icon: addProfileResult.data.data.filename })
                                    setSHowPic(addProfileResult.data.data.filename);
                                    setProfile(addProfileResult.data.data.filename);
                                } else {
                                    setIsLoading(false);
                                }
                            }
                        } else {
                            setIsLoading(false);
                        }
                    }
                    catch (error) {
                        console.log("catch", error)
                        setIsLoading(false);
                    }
                }
                else {
                    setAlerts({ alert: t("user.profile.valid_image_size"), alerttype: "Error", alertclass: "danger", showalert: true, });
                    setTimeout(() => {
                        setAlerts({ alert: "", alerttype: "", alertclass: "", showalert: false, });
                    }, process.env.REACT_APP_ALERT_TIMEOUT * 1000);
                }
            }
            else {
                setAlerts({ alert: t("user.profile.valid_image_type"), alerttype: "Error", alertclass: "danger", showalert: true, });
                setTimeout(() => {
                    setAlerts({ alert: "", alerttype: "", alertclass: "", showalert: false, });
                }, process.env.REACT_APP_ALERT_TIMEOUT * 1000);
            }
        }
    };

    const getCompanyAllDetails = async () => {
        setIsLoading(true);

        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const getCompanyData = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_COMPANY_DETAILS}?companyId=${company_id}`,
                );
                if (getCompanyData && getCompanyData.status === 200) {
                    setIsLoading(false);
                    if (getCompanyData.data?.data?.length > 0) {
                        setInputValue();
                    }
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }

    const checkupdate = async (e) => {
        setInputValue({ ...inputValue, [e.target.name]: e.target.value })
        if (e.target.name == "name") {
            setInputValue({ ...inputValue, name: e.target.value })

        }

    }

    const checkupdate1 = async (e) => {
        let space = e.target.value.includes(' ');
        if (space) {
            let words = e.target.value.split(' ');
            words = words.map(word => word[0]).join('');
            setNewUrlParam(words.toLowerCase())
            setInputValue({ ...inputValue, finalUrl: process.env.REACT_APP_FRONTEND_BASE_URL + '/app/register/' + words.toLowerCase(), urlParam: words.toLowerCase() })
            setIsDisabled(false);
            if (words.toLowerCase()) {
                setIsDisabled(false);
            }
            else {
                setIsDisabled(true);
            }
        } else {
            setNewUrlParam(e.target.value.toLowerCase())
            setInputValue({ ...inputValue, finalUrl: process.env.REACT_APP_FRONTEND_BASE_URL + '/app/register/' + e.target.value.toLowerCase(), urlParam: e.target.value.toLowerCase() })
            if (e.target.value.toLowerCase()) {
                setIsDisabled(false);
            }
            else {
                setIsDisabled(true);
            }
            console.log('else');
        }


    }
    const handleCopyClick = () => {
        navigator.clipboard.writeText(inputRef.current.value);
        setTimeout(() => {
            setTextCopy(false)
        }, 2000)
    };
    const getCompanyUrl = async (e) => {
        setInputValue({ ...inputValue, finalUrl: process.env.REACT_APP_FRONTEND_BASE_URL + '/app/register/' + e.target.value.toLowerCase(), urlParam: e.target.value.toLowerCase() })
        if (e.target.value) {
            const payload = {
                company_dynamic_id: e.target.value
            }
            const companyDetail = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_COMPANY_BY_DYNAMIC_ID}`,
                payload
            );

            if (companyDetail && companyDetail.status === 200) {
                setIsDisabled(true);
                setIsLoading(true);
                setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.company_registration_already_done")}`, class: "alert alert-danger" })

            } else if (companyDetail && companyDetail.status === 203) {
                setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.company_url__present")}`, class: "alert alert-success" })
                setIsDisabled(false);
                setIsLoading(true);

            }
        }
        else {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.urlString_error")}`, class: "alert alert-danger" })
            setIsDisabled(true);

        }
        setTimeout(() => {
            setIsLoading(false);
        }, 1000)
    }


    const calculateWeeksBetween = (date1, date2) => {
        // The number of milliseconds in one week
        var ONE_WEEK = 1000 * 60 * 60 * 24 * 7;
        // Convert both dates to milliseconds
        var date1_ms = new Date(date1 * 1000).getTime();
        var date2_ms = new Date(date2 * 1000).getTime();
        // Calculate the difference in milliseconds
        var difference_ms = Math.abs(date2_ms - date1_ms);
        // Convert back to weeks and return hole weeks
        return Math.floor(difference_ms / ONE_WEEK);

    }
    // const calculateWeek = (inputDateValue) =>{
    //         const currentDate1 = Math.floor(new Date().getTime()/1000);
    //         inputDateValue.serviceStartDate = (inputDateValue.serviceStartDate <= currentDate1+31536000) ? inputDateValue.serviceStartDate : currentDate1;
    //         (inputDateValue.serviceStartDate) ?
    //         inputDateValue.contractExpiry = (inputDateValue.contractExpiry <= inputDateValue.serviceStartDate+31622400) ? inputDateValue.contractExpiry : inputDateValue.serviceStartDate+31622400 : null;

    //     setInputValue({ ...inputValue, serviceStartDate: inputDateValue.serviceStartDate, contractExpiry: inputDateValue.contractExpiry })

    //     if (inputDateValue.contractExpiry != null && inputDateValue.serviceStartDate != null) {
    //         let value = calculateWeeksBetween(inputDateValue.serviceStartDate, inputDateValue.contractExpiry);
    //         setInputValue({ ...inputValue, weeksProtocol: value, serviceStartDate: inputDateValue.serviceStartDate, contractExpiry: inputDateValue.contractExpiry })
    //     }
    // }
    const calculateWeek = (inputDateValue) => {
        // Convert epoch time to a Date object
        const currentDate1 = Math.floor(new Date().getTime() / 1000);
        inputDateValue.serviceStartDate = (inputDateValue.serviceStartDate <= currentDate1 + 31536000) ? inputDateValue.serviceStartDate : currentDate1;

        if (inputDateValue.serviceStartDate) {
            inputDateValue.contractExpiry = (inputDateValue.contractExpiry <= inputDateValue.serviceStartDate + 31622400)
                ? inputDateValue.contractExpiry
                : inputDateValue.serviceStartDate + 31622400;
        } else {
            inputDateValue.contractExpiry = null;
        }

        setInputValue({ ...inputValue, serviceStartDate: inputDateValue.serviceStartDate, contractExpiry: inputDateValue.contractExpiry });

        if (inputDateValue.contractExpiry !== null && inputDateValue.serviceStartDate !== null) {
            let value = calculateWeeksBetween(inputDateValue.serviceStartDate, inputDateValue.contractExpiry);
            setInputValue({ ...inputValue, weeksProtocol: value, serviceStartDate: inputDateValue.serviceStartDate, contractExpiry: inputDateValue.contractExpiry });
        }
    };

    return (
        <>
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setDisableUserModal(false)} show={disableUserModal}>
                {disableUserModalContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setDisableAdminModal(false)} show={disableAdminModal}>
                {disableAdminModalContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setEnableAdminModal(false)} show={enableAdminModal}>
                {enableAdminModalContent()}
            </Modal>
            <section className="full_bg dashboard-mobile">
                <div className="container-fluid">
                    <div className="row">
                        <Super_Sidebar path="protocol" />
                        <div className=" col-md-10 p0 pr-0 ipd-righ">
                            <div className="right-dashboard topspace leftr-dari">
                                <div className="d-flex justify-content-between right-header">
                                    <h2 className="heading-dash">
                                        <button className="back-button" onClick={history.goBack}><img src={PREV_PAGE_ICON} alt="icon" /></button>
                                        {t("superadmin.protocol.new_company")}
                                    </h2>

                                    <div className="align-j-new buttons-superadmin">
                                        {personalText == "user" ? <button className="buttons">{t("superadmin.protocol.import_user")}</button> : personalText == "admin" ? <button className="buttons">{t("superadmin.protocol.new_admin")}</button> : null}
                                    </div>
                                </div>
                                <div className="graph-tabs ">
                                    <div className="card-body">
                                        <div className="tab-content">

                                            <div
                                                className="protocal"
                                                id="first"
                                                role="tabpanel"
                                                aria-labelledby="first-tab"
                                            >
                                                <div className="">
                                                    {/* {alertMessage.show ? <div className={`d-flex mt-3 ${alertMessage.class}`} >
                                                            {alertMessage.spinner ? <div className="spinner-container-new">
                                                                <div className="loading-spinner-new">
                                                                </div>
                                                            </div> : null}
                                                            <div className="ml-1">{alertMessage.message}</div>
                                                        </div> : null} */}
                                                    <div className="">
                                                        <div className="right-dashboard topspace profile-right pl-0">
                                                            {showalert ? <div className={`alert alert-${alertclass}`}>{alert}</div> : null}
                                                            <form className="company-details-form">
                                                                <div className="row">
                                                                    <div className="col-sm-6">
                                                                        <div className="">
                                                                            <div className="leftprofiles">
                                                                                <div className="l-rflex pr-0">
                                                                                    <div className="profil_img">
                                                                                        <span className="circl-profile circle-profile-new viewprofile">
                                                                                            <img
                                                                                                src={
                                                                                                    process.env.REACT_APP_BACKEND_BASE_URL +
                                                                                                    process.env.REACT_APP_STATIC_PATH_COMPANY_ICON +
                                                                                                    `/${inputValue.icon}`
                                                                                                }
                                                                                                className="img-fluid"
                                                                                                alt="img"
                                                                                            />
                                                                                        </span>
                                                                                        <label htmlFor="file-upload" className="deskt-n">
                                                                                            <img
                                                                                                src={Group878}
                                                                                                className="profile-edit-icon deskt-n"
                                                                                                alt="icon"
                                                                                            />
                                                                                        </label>
                                                                                        <input
                                                                                            id="file-upload"
                                                                                            type="file"
                                                                                            className="deskt-n"
                                                                                            onChange={(e) => handleImageChange(e)}
                                                                                        />

                                                                                        <><input
                                                                                            id="file-upload"
                                                                                            type="file"
                                                                                            className="deskt-n"
                                                                                            onChange={(e) => handleImageChange(e)}
                                                                                        />
                                                                                        </>

                                                                                    </div>
                                                                                    <div className="ri-dete visi-none">
                                                                                        <label
                                                                                            htmlFor="file-upload"
                                                                                            className="buttons height-bnt max-paddings d-flex justify-content-center align-items-center"
                                                                                        >
                                                                                            {t("superadmin.training.popup.add")}
                                                                                        </label>
                                                                                        <input
                                                                                            id="file-upload"
                                                                                            type="file"
                                                                                            onChange={(e) => handleImageChange(e)}
                                                                                        />

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                        <div className="d-flex justify-content-between color-box-margin">
                                                                            <div className="mr-2 d-flex position-relative" ref={ref1}>
                                                                                <div className="select-color" style={{ backgroundColor: inputValue.color1 }} onClick={() => setShowColorPicker1(true)}>
                                                                                    {showColorPicker1 ? <SketchPicker
                                                                                        color={inputValue.color1}
                                                                                        onChangeComplete={(color) => setInputValue({ ...inputValue, color1: color.hex })}
                                                                                    />
                                                                                        : null}
                                                                                </div>
                                                                                <div className="form-group relative">

                                                                                    <input
                                                                                        type="text"
                                                                                        onChange={(e) => checkupdate(e)}
                                                                                        value={inputValue.color1.replace(/^\s+/g, '')}
                                                                                        name="color1"
                                                                                        autoComplete="off"
                                                                                        className="form-control"
                                                                                        aria-describedby="emailHelp"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="ml-2 d-flex position-relative" ref={ref2} >
                                                                                <div className="select-color" style={{ backgroundColor: inputValue.color2 }} onClick={() => setShowColorPicker2(true)}>
                                                                                    {showColorPicker2 ? <SketchPicker
                                                                                        color={inputValue.color2}
                                                                                        onChangeComplete={(color) => setInputValue({ ...inputValue, color2: color.hex })}
                                                                                    />
                                                                                        : null}
                                                                                </div>
                                                                                <div className="form-group relative ">
                                                                                    <input
                                                                                        type="text"
                                                                                        onChange={(e) => checkupdate(e)}
                                                                                        value={inputValue.color2.replace(/^\s+/g, '')}
                                                                                        name="color2"
                                                                                        autoComplete="off"
                                                                                        className="form-control"
                                                                                        aria-describedby="emailHelp"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 max-w70">


                                                                        <div className="form-group relative">
                                                                            <label>{t("superadmin.add_protocal.name")}</label>
                                                                            {/* <img
                                                                                    src={Group878}
                                                                                    className="edit-icon visi-none"
                                                                                    alt="icon"
                                                                                /> */}
                                                                            <input
                                                                                type="text"
                                                                                onChange={(e) => checkupdate(e)}
                                                                                value={inputValue.name.replace(/^\s+/g, '')}
                                                                                name="name"
                                                                                autoComplete="off"
                                                                                className="form-control"
                                                                                aria-describedby="emailHelp"
                                                                                onBlur={(e) => checkupdate1(e)}
                                                                                placeholder={t("superadmin.add_protocal.name")}
                                                                            />
                                                                        </div>

                                                                        <div className="row">
                                                                            <div className="col-xl-6 form-group relative date-input-new">
                                                                                <label>{t("superadmin.add_protocal.startDate")}</label>
                                                                                <div className="edit-input relative">
                                                                                    <DatePicker dateFormat="dd.MM.yyyy"
                                                                                        selected={inputDateValue.serviceStartDate * 1000}
                                                                                        onChange={(date) => { setInputDateValue({ ...inputDateValue, serviceStartDate: date / 1000 }); setAlertMessage({}) }}
                                                                                        minDate={new Date().getTime()}
                                                                                        placeholderText="dd.mm.yyyy"
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                    /><span className="edit-date">
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-6 form-group relative week-input-new">
                                                                                <label>{t("superadmin.protocol.contractExpiry")}</label>
                                                                                <div className="edit-input w-100 relative">
                                                                                    <DatePicker dateFormat="dd.MM.yyyy"
                                                                                        selected={inputDateValue.contractExpiry * 1000}
                                                                                        onChange={(date) => { setInputDateValue({ ...inputDateValue, contractExpiry: date / 1000 }); setAlertMessage({}) }}
                                                                                        minDate={new Date().getTime()}
                                                                                        placeholderText="dd.mm.yyyy"
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group relative date-input-new">
                                                                            <label>{t("superadmin.protocol.weeksProtocol")}</label>
                                                                            <div className="arrow_down1 arrow_down1-new">
                                                                                <label htmlFor="weekProtocol" className="edit-date">
                                                                                </label>
                                                                                <input
                                                                                    id="weekProtocol"
                                                                                    name="weeksProtocol"
                                                                                    className="border_n"
                                                                                    value={inputValue.weeksProtocol}
                                                                                    onChange={(e) => checkupdate(e)}
                                                                                    style={{ width: '100%' }}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group relative">
                                                                            <label>{t("superadmin.add_protocal.referent")}</label>
                                                                            <input
                                                                                type="text"
                                                                                onChange={(e) => checkupdate(e)}
                                                                                value={inputValue.referent.replace(/^\s+/g, '')}
                                                                                name="referent"
                                                                                autoComplete="off"
                                                                                className="form-control"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder={t("superadmin.add_protocal.referent")}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group relative">
                                                                            <label>{t("superadmin.protocol.toiMeasurement")}</label>
                                                                            <input
                                                                                type="number"
                                                                                onChange={(e) => checkupdate(e)}
                                                                                value={inputValue.toiMeasurement}
                                                                                name="toiMeasurement"
                                                                                autoComplete="off"
                                                                                className="form-control"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder={t("superadmin.protocol.toiMeasurement")}
                                                                            />
                                                                        </div>
                                                                     


                                                                    </div>
                                                                    <div className="col-md-6 max-w70">


                                                                        <div className="form-group relative">
                                                                            <label>{t("superadmin.protocol.address")}</label>
                                                                            <input
                                                                                type="text"
                                                                                onChange={(e) => checkupdate(e)}
                                                                                value={inputValue.address.replace(/^\s+/g, '')}
                                                                                name="address"
                                                                                autoComplete="off"
                                                                                className="form-control"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder={t("superadmin.protocol.address")}
                                                                            />
                                                                        </div>
                                                                        <div className="d-flex justify-content-between user-admin-margin-box ">
                                                                            <div className="form-group relative w-100">
                                                                                <label>{t("superadmin.protocol.user")}</label>
                                                                                <div>{inputValue.user}</div>
                                                                            </div>
                                                                            <div className="form-group relative w-100">
                                                                                <label>{t("superadmin.protocol.admin")}</label>
                                                                                <div>{inputValue.admin}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group relative">
                                                                            <label>{t("superadmin.protocol.email")}</label>
                                                                            <input
                                                                                type="email"
                                                                                onChange={(e) => checkupdate(e)}
                                                                                value={inputValue.email}
                                                                                name="email"
                                                                                autoComplete="off"
                                                                                className="form-control mt-1"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder={t("superadmin.protocol.email")}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group relative ">
                                                                            <label>{t("superadmin.protocol.urlString")}</label>
                                                                            <input
                                                                                type="text"
                                                                                name="urlParam"
                                                                                className="form-control"
                                                                                value={inputValue.urlParam}
                                                                                onBlur={getCompanyUrl}
                                                                                onChange={(e) => checkupdate(e)}
                                                                                style={{ width: '100%' }}
                                                                                placeholder="URL"
                                                                            />
                                                                        </div>
                                                                        <div className="form-group relative ">
                                                                            <label>{t("superadmin.protocol.finalUrl")}</label>

                                                                            <img src={DOCUMENT_ICON} alt="icon" style={{ width: "25px", marginLeft: "5px", cursor: "pointer" }} onClick={() => { handleCopyClick(); setTextCopy(true); }} />
                                                                            <span style={{ marginLeft: "2px", color: "green" }}>{(textCopy) ? t("superadmin.protocol.urlCopy") : null}</span>

                                                                            <input
                                                                                type="text"
                                                                                name="finalUrl"
                                                                                ref={inputRef}
                                                                                className="form-control "
                                                                                value={inputValue.finalUrl}
                                                                                onChange={(e) => checkupdate(e)}
                                                                                style={{ width: '100%', fontSize: "17px" }}
                                                                                readOnly="true"
                                                                            />

                                                                        </div>

                                                                       
                                                                       
                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                            <div className="form-group relative">
                                                                                <label>{t("superadmin.add_protocal.consentId")} (EN)</label>
                                                                                
                                                                                    <img
                                                                                        src={Group878}
                                                                                        className="edit-icon visi-none"
                                                                                        alt="icon"
                                                                                    />
                                                                                    
                                                                                <input
                                                                                    type="text"
                                                                                    onChange={(e) => checkupdate(e)}
                                                                                    value={inputValue?.consentIdEn}
                                                                                    name="consentIdEn"
                                                                                    autoComplete="off"
                                                                                    className="form-control"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder={t("superadmin.add_protocal.consentId")}

                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                       
                                                                            <div className="form-group relative">
                                                                                <label>{t("superadmin.add_protocal.consentId")} (IT)</label>
                                                                               
                                                                                    <img
                                                                                        src={Group878}
                                                                                        className="edit-icon visi-none"
                                                                                        alt="icon"
                                                                                    />
                                                                                   
                                                                                <input
                                                                                    type="text"
                                                                                    onChange={(e) => checkupdate(e)}
                                                                                    value={inputValue?.consentIdIt}
                                                                                    name="consentIdIt"
                                                                                    autoComplete="off"
                                                                                    className="form-control"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder={t("superadmin.add_protocal.consentId")}

                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    <div className="col-sm-6">
                                                                        <div className="form-group relative">
                                                                            <label>{t("superadmin.add_protocal.configId")}</label>
                                                                            <input
                                                                                type="text"
                                                                                onChange={(e) => checkupdate(e)}
                                                                                value={inputValue.configId}
                                                                                name="configId"
                                                                                autoComplete="off"
                                                                                className="form-control"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder={t("superadmin.add_protocal.configId")}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                    <div className="">
                                                                            {alertMessage.show ? <div className={`d-flex mt-3 ${alertMessage.class}`} >
                                                                                {/* {alertMessage.spinner ? <div className="spinner-container-new">
                                                                                    <div className="loading-spinner-new">
                                                                                    </div>
                                                                                </div> : null} */}
                                                                                <div className="ml-1">{alertMessage.message}</div>
                                                                            </div> : null}
                                                                        </div>
                                                                        <div className="d-flex justify-content-end mt-5">
                                                                                <span className="">
                                                                                    <a className="buttons btn-save-cancel mr-3" style={{ paddingTop: "20px" }} href="/sa/protocol">{t("superadmin.protocol.popup.cancel")}</a></span>
                                                                                <button className={isDisabled ? 'buttons btn-save-cancel disable-btn-text' : 'buttons btn-save-cancel enable-btn'} disabled={isDisabled} onClick={(e) => checkValidation(e)} style={{ height: "64px" }}> {t("user.summary.save")}</button>
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    "tab-pane fade " +
                                                    (personalText == "admin" ? "active show" : "")
                                                }
                                                id="second"
                                                role="tabpanel"
                                                aria-labelledby="second-tab"
                                            >
                                                <div className="row">
                                                    <div className="col-md-12 pr-0 p0 ipd-righ">
                                                        <div className="rigt-dashboard topspace mx-3 ">
                                                            {(companyAdminData.length > 0 && companyAdminData) ? <>
                                                                <ToolkitProvider
                                                                    keyField="user_id"
                                                                    key={'datatable'}
                                                                    data={companyAdminData}
                                                                    columns={adminColumn}
                                                                    search
                                                                >{(props) => (
                                                                    <div id="test" className="table ricsb protocolli-data">
                                                                        <div>
                                                                            <div >
                                                                                <div className="searchbar d-flex justify-content-start my-2">
                                                                                    <img
                                                                                        src={Group698}
                                                                                        className="search_icon"
                                                                                        alt="icon"
                                                                                        style={{ top: "25px" }}
                                                                                    />
                                                                                    <SearchBar
                                                                                        placeholder={t("superadmin.protocol.search")}
                                                                                        {...props.searchProps}
                                                                                    />
                                                                                </div>
                                                                                <BootstrapTable

                                                                                    src={VectorDown} alt="icon"
                                                                                    data={companyAdminData}
                                                                                    {...props.baseProps}
                                                                                    filter={filterFactory()}
                                                                                    pagination={paginationFactory(paginationOption)}
                                                                                    wrapperClasses={'table-responsive'}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                </ToolkitProvider>
                                                            </> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    "tab-pane fade " +
                                                    (personalText == "user"
                                                        ? "active show"
                                                        : "")
                                                }
                                                id="third"
                                                role="tabpanel"
                                                aria-labelledby="third-tab"
                                            >
                                                <div className="row">
                                                    <div className="col-md-12 pr-0 p0 ipd-righ">
                                                        <div className="rigt-dashboard topspace mx-3 ">
                                                            {(companyUserData.length > 0 && companyUserData) ? <>
                                                                <ToolkitProvider
                                                                    keyField="user_id"
                                                                    key={'datatable'}
                                                                    data={companyUserData}
                                                                    columns={generalColumn}
                                                                    search
                                                                >{(props) => (
                                                                    <div id="test" className="table ricsb protocolli-data">
                                                                        <div>
                                                                            <div >
                                                                                <div className="searchbar d-flex justify-content-start my-2">
                                                                                    <img
                                                                                        src={Group698}
                                                                                        className="search_icon"
                                                                                        alt="icon"
                                                                                        style={{ top: "25px" }}

                                                                                    />
                                                                                    <SearchBar
                                                                                        placeholder={t("superadmin.protocol.search")}
                                                                                        {...props.searchProps}
                                                                                    />
                                                                                </div>
                                                                                <BootstrapTable
                                                                                    src={VectorDown} alt="icon"
                                                                                    data={companyUserData}
                                                                                    {...props.baseProps}
                                                                                    filter={filterFactory()}
                                                                                    pagination={paginationFactory(paginationOption)}
                                                                                    wrapperClasses={'table-responsive'}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                </ToolkitProvider>
                                                            </> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isLoading ? <LazyLoader /> : null}
                </div>
            </section>
            <Super_Sidebarmob />
        </>
    );
};

export default Add_Protocol;
