import { useEffect, useState, useRef, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import ReactPaginate from 'react-paginate';
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import { EditorState } from 'draft-js';
import moment from "moment";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Sidebar from "./sidebar";
import Sidebarmob from "./sidebarmob";
import AdminStatics from "./adminStatics";
import Modal from "../common/modal";
import PostRequest from "../apiConnection/postRequest";
import LazyLoader from "../common/lazyLoader";
import { getLocalStoarge, getDynamicCompany } from "../common/allfunction";
import VectorDown from "../../assets/Image/VectorDown.svg";
import Group698 from "../../assets/Image/Group698.svg";
import Group1857link from "../../assets/Image/Group1857link.svg"
import Group769 from "../../assets/Image/Group769.svg";
import ExpandIcon from '../../assets/Image/expand-icon.svg'
import OPEN_ICON from '../../assets/Image/OPEN2_ICONA.svg';
import NEXTICONA from "../../assets/Image/NEXTICONA.svg";
import PRIVIEWICONA from '../../assets/Image/PREVIEWICONA.svg';
import GetRequest from "../apiConnection/getRequest";
import VIDEO_IMAGE from '../../assets/Image/Rectangle319.svg';
import ShowVideo from "../common/ShowVideo";
import { ViewDocument } from "../common/ViewDocument";
import '../superAdmin/super_admin_assets/scss/protocol.scss';
import './admin_assets/scss/Adminstyle.scss';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import VECTORRIGHT from '../../assets/Image/VectorRight.svg';
import VECTORLEFT from '../../assets/Image/VectorLeft.svg';
import VideoPlayButton from '../../assets/Image/VideoPlayButton.svg'

const AdminTraining = (props) => {
    let urlPattern = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    const [fileSize, setFileSize] = useState(1e+7)
    const ref = useRef(null);
    const fileUpload = useRef(null);
    const [files, setFiles] = useState([]);
    const [pdfFile, setPdfFile] = useState([]);
    const [videoPopupPage, setVideoPopupPage] = useState(1);
    const [addVideoModal, setAddVideoModal] = useState(false);
    const [addPdfModal, setAddPdfModal] = useState(false);
    const [pdfPopupPage, setPdfPopupPage] = useState(1);
    const [contentId, setContentId] = useState(null);
    const [docPreview, setDocPreview] = useState(false);
    const [materialPopupPage, setMaterialPopupPage] = useState(1);
    const [uploadAttempts, setUploadAttempts] = useState(0);
    const [favIcon, setFavIcon] = useState('')
    const [urlRegex, setUrlRegex] = useState(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+~#?&/=]*)/);
    const [currentEditingUrl, setCurrentEditingUrl] = useState(null)
    const [currentEditing, setCurrentEditing] = useState(null)
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [uploadUrl, setUploadUrl] = useState([])
    const [title, setTitle] = useState(null)
    const [description, setDescription] = useState(null)
    const { SearchBar } = Search;
    const [company_id, setCompany_id] = useState(props.match.params.company_id)
    const [programData, setProgramData] = useState([])
    const [isDisabled, setIsDisabled] = useState(true)
    const [urlLink, setUrlLink] = useState(null)
    const path = window.location.pathname;
    const [t, i18n] = useTranslation("common");
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [popupLoader2, setPopupLoader2] = useState(false)
    const [companyDetails, setCompanyDetails] = useState({})
    const [filterShow, setFilterShow] = useState(false);
    const [filterValue, setFilterValue] = useState([]);
    const [filterValueData, setfilterValueData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [programColumn, setProgramColumn] = useState([{ text: "name", dataField: "program_name", sort: true }])
    const [personalText, setPersonalText] = useState("program");
    const [addProgramModal, setAddProgramModal] = useState(false)
    const [addProgramModal2, setAddProgramModal2] = useState(false)
    const [updateProgramModal2, setUpdateProgramModal2] = useState(false)
    const [updateProgramData, setUpdateProgramData] = useState({ company_id: props.match.params.company_id, program_name: "", description: "", course: {}, duration: 1, release_date: "", show_release_date: "", obligatory: "", status: 0, referent: "" })
    const [deleteProgramModal, setDeleteProgramModal] = useState(false)
    const [currentProgram, setCurrentProgram] = useState({})
    const [newProgramData, setNewProgramData] = useState({ company_id: props.match.params.company_id, program_name: "", description: "", course: {}, duration: 1, release_date: (new Date() / 1000), show_release_date: moment().format('YYYY-MM-DD'), obligatory: "", status: 0, referent: "" })
    const [alertMessage, setAlertMessage] = useState({ show: false, spinner: false, message: "", class: "" })
    const [documentData, setDocumentData] = useState([])
    const [activeDocument, setActiveDocument] = useState({})
    const [documentProgramsModal, setDocumentProgramsModal] = useState(false)
    const [documentPageCount, setDocumentPageCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [itemPerPage, setItemPerPage] = useState(10)
    const [refrentData, setRefrentData] = useState([])
    const [videoData, setVideoData] = useState([])
    const [videoPageCount, setVideoPageCount] = useState(0)
    const [videoCurrentPage, setVideoCurrentPage] = useState(0)
    const [videoItemPerPage, setVideoItemPerPage] = useState(10)
    const [addTextDocumentModal, setAddTextDocumentModal] = useState(false)
    const [addTextDocumentData, setAddTextDocumentData] = useState({ title: "", description: "", text: "", editorState: "" })
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [videoSearchQuery, setVideoSearchQuery] = useState("")
    const [pdfUrlChange, setPdfUrlChange] = useState(null)
    const [documentSearchQuery, setDocumentSearchQuery] = useState("")
    const [disableAddNewProgramButton, setDisableAddNewProgramButton] = useState(false)
    const [disableUpdateProgramButton, setDisableUpdateProgramButton] = useState(false)
    const [disableDeleteProgramButton, setDisableDeleteProgramButton] = useState(false)
    const [disableAddTextDocumentButton, setDisableAddTextDocumentButton] = useState(false)
    const [addLearningModal, setLearningModal] = useState(false)
    const [learningData, setLearningData] = useState([])
    const [learningDataLength, setLearningDataLength] = useState(0)
    const [arrObj, setArrObj] = useState([]);
    const [selectedData, setSelectedData] = useState({});
    const [selectIndex, setSelectIndex] = useState();
    const [selectedNewLearningData, setSelectedNewLearningData] = useState({});
    const [selectFinal, setSelectFinal] = useState({});
    const [checkboxStates, setCheckboxStates] = useState({});
    const [objectHandle, setObjectHandle] = useState({});
    const modalRef = useRef(null);
    const [durationCount, setDurationCount] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20])
    const [fileObject, setFileObject] = useState({ files: [], urls: [] });
    const [pdfFileObject, setPdfFileObject] = useState({ files: [], urls: [] });
    const [maxFile, setMaxFile] = useState(10)
    const [progress, setProgress] = useState()
    const [currentVideoDetails, setCurrentVideoDetails] = useState({ link: "", title: "", description: "" })
    const [showVideoContentModal, setShowVideoContentModal] = useState(false)
    const [allContentList, setAllContentList] = useState([])
    const [selectedContents, setSelectedContents] = useState();
    const [newProgramAllCourse, setNewProgramAllCourse] = useState([])
    const [activeContentType, setActiveContentType] = useState("document")
    const todayEpoch = moment().unix();

    const [refreshGraph, setRefreshGraph] = useState(false);
    const [staticsData, setStaticsData] = useState([])
    const [adminCompanyId, setAdminCompanyId] = useState(getLocalStoarge("company_id"))
    const [selectProgram, setSelectProgram] = useState();
    const [selectCourse, setSelectCourse] = useState();
    const [defaultStaticsData, setDefaultStaticsData] = useState({ "programId": "", "programCourseList": "", "noOfUsers": 0, "document&videoCounts": 0, "inProgressPercent": 0, "completePercent": 0, "recentViewCourses": 0 })
    const [documentLoadMoreIndex, setDocumentLoadMoreIndex] = useState('')
    const [themeColor, setThemeColor] = useState(getLocalStoarge("theme"))
    const getTimestampsOfFirstDayOfPrevious12Months = (epoch) => {
        // Convert the given Unix timestamp to milliseconds
        const milliseconds = epoch * 1000;

        // Convert to moment object
        const date = moment(milliseconds);

        // Calculate the first day of the current month
        const firstDayOfCurrentMonth = date.clone().startOf('month');

        // Subtract 12 months from the first day of the current month
        const firstDayOfPrevious12Months = firstDayOfCurrentMonth.clone().subtract(12, 'months');

        // Generate timestamps for the first day of each of the previous 12 months
        const timestamps = [];
        for (let i = 0; i < 12; i++) {
            timestamps.push(firstDayOfPrevious12Months.clone().add(i, 'months').unix());
        }

        return timestamps;
    };
    const startingDate = getTimestampsOfFirstDayOfPrevious12Months(todayEpoch);
    const getCurrentYearFirstDayEpoch = () => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const firstDayOfYear = new Date(currentYear, 0, 1); // Month is zero-based, so January is 0
        return Math.floor(firstDayOfYear.getTime() / 1000); // Convert milliseconds to seconds
      };
      
      // Example usage:
     const firstDayOfYearEpoch = getCurrentYearFirstDayEpoch();
    const [dateRange, setDateRange] = useState([firstDayOfYearEpoch * 1000, todayEpoch * 1000]);



    const changeProgram = (ep) => {
        setSelectProgram(ep)
        setSelectCourse('')
        getStaticsData(ep, '');
    }
    const changeCourse = (ec) => {
        setSelectCourse(ec)
        getStaticsData(selectProgram, ec);
    }
    const changeDate = (update) => {
        setDateRange([update[0], update[1]])
        if (update[0] != null && update[1] != null) {
            if(getMonthsBetweenDatesNo(update[0],update[1]).length > 12) 
            {
            setDateRange([firstDayOfYearEpoch * 1000, todayEpoch * 1000])
            return alert("Please select 12 months"); 
            }
            
            getStaticsData();
            setRefreshGraph(true)
        }
    }
    const getMonthsBetweenDatesNo = (startDate, endDate) => {
        const months = [];
        let currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            const monthNumber = currentDate.getMonth() + 1; // Adding 1 to convert 0-indexed month to 1-indexed
            const monthName = currentDate.toLocaleString('default', { month: 'long' });
            const year = currentDate.getFullYear();
            months.push(monthNumber);

            // Move to the next month
            currentDate.setMonth(currentDate.getMonth() + 1);
        }

        return months;
    };
    const getCompanyAllProgram = async (company) => {
        const payload = {
            "company_id": company,
        };
        setIsLoading(true);

        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                try {
                    const getAllDataResult = await GetRequest(
                        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_ADMIN_PROGRAM_DATA}?companyId=${company}`,
                        payload,
                        "",
                        ""
                    );
                    if (getAllDataResult && getAllDataResult.status === 200) {
                        setIsLoading(false);
                        if (getAllDataResult.data?.data?.length > 0) {
                            let arr = [];
                            getAllDataResult.data.data.forEach((item) => {
                                if (item.status > 0) {
                                    arr = [...arr, {
                                        program_name: item.program_name || "",
                                        duration: item.duration || "",
                                        course: item.course || 0,
                                        release_date: item.release_date ? `${moment(new Date(item.release_date * 1000)).format("DD.MM.YY").replace(/YYYY/g, "YY")}` : '',
                                        // obligatory: item.obligatory.toCapitalize() || '',
                                        obligatory: item.obligatory[0].toUpperCase() + item.obligatory.slice(1).toLowerCase() || '',
                                        // obligatory: item.obligatory || '',
                                        program_id: item.program_id || '',
                                        status: item.status || 0,
                                        referent: item.referent || '',
                                        description: item.description || ''
                                    }]
                                }
                            })
                            setProgramData(arr)

                        }
                    } else {
                        setIsLoading(false);
                        setLoading(false);
                    }
                } catch (error) {
                    setIsLoading(false);
                }
            }
            else {
                setIsLoading(false);
            }
        }
        catch (error) {
            setIsLoading(false);
        }

    };

    const getCompanyDocument = async (page) => {
        setIsLoading(true);
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const documentResult = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_ADMIN_COMPANY_DOCUMENT}?companyId=${companyDetails.document_id}&skip=${(page - 1) * itemPerPage}&limit=${itemPerPage}&search=${documentSearchQuery}`
                );
                if (documentResult && documentResult.status === 200) {
                    setIsLoading(false);
                    let data2 = documentResult.data?.data;
                    if (data2?.content.length > 0) {
                        let arr = []
                        data2.content.forEach((d1) => {
                            let pro = [];
                            d1.associatePrograms?.forEach((d2) => {
                                pro = [...pro, { id: d2.id, name: d2.name, description: d2.description }]
                            })
                            arr = [...arr, {
                                name: d1.title,
                                description: d1.description,
                                programs: pro,
                                contentId: d1.id
                            }]
                        })
                        setDocumentData(arr)
                        setDocumentPageCount(Math.ceil(data2?.count / itemPerPage))
                        setCurrentPage(page)
                    } else {
                        setDocumentData([])
                    }
                } else {
                    setIsLoading(false);
                    setLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }

    const getCompanyVideo = async (page) => {
        setIsLoading(true);
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const documentResult = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_ADMIN_COMPANY_VIDEO}?companyId=${companyDetails.document_id}&skip=${(page - 1) * itemPerPage}&limit=${itemPerPage}&search=${videoSearchQuery}`
                );
                if (documentResult && documentResult.status === 200) {
                    setIsLoading(false);
                    let data1 = documentResult.data?.data;
                    if (data1?.content?.length > 0) {
                        let arr = []
                        data1.content.forEach((d1) => {
                            let pro = [];
                            d1.associatePrograms?.forEach((d2) => {
                                pro = [...pro, { id: d2.id, name: d2.name, description: d2.description }]
                            })
                            arr = [...arr, {
                                contentId: d1.id,
                                name: d1.title,
                                link: d1.url,
                                description: d1.description,
                                thumbnail: d1.thumbnail,
                                programs: pro
                            }]
                        })
                        setVideoData(arr)
                        setVideoPageCount(Math.ceil(data1?.count / videoItemPerPage))
                        setVideoCurrentPage(page)
                    } else {
                        setVideoData([])
                    }
                } else {
                    setIsLoading(false);
                    setLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }
    const getContentDetails = async (id) => {
        setIsLoading(true);
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const videoResult = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_CONTENT_DETAILS}?contentId=${id}&skip=${(page - 1) * itemPerPage}&limit=${itemPerPage}&search=${videoSearchQuery}`

                );

                if (videoResult && videoResult.status === 200) {
                    setIsLoading(false);
                    let data1 = videoResult.data?.data;
                    let link = data1.url ? data1.url : data1.filePath?.includes("vimeo") ? data1.filePath : `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH_CONTENT}${data1.filePath}`;
                    setCurrentVideoDetails({ title: data1.title, description: data1.description, link: link, error: false })
                    setShowVideoContentModal(true)
                } else {
                    if (videoResult?.status === 203) {
                        setCurrentVideoDetails({ ...currentVideoDetails, error: true })
                    }
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }

    const getStaticsData = async (ep, ec) => {
        setIsLoading(true);
        try {
            // const refreshToken = await PostRequest(
            //     `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
            //     {},
            //     "",
            //     "refresh_token"
            // );
            // if (refreshToken) {
            let programIdQuery = (ep) ? `&programId=${ep}` : '';
            let courseQuery = (ec) ? `&course=${ec}` : `&course=`;
            let fromQuery = (dateRange[0]) ? `&from=${dateRange[0] / 1000}` : '';
            let toQuery = (dateRange[1]) ? `&to=${dateRange[1] / 1000}` : '';
            const staticsResult = await GetRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_TRAINING_CONTENT_STATICS}?companyId=${adminCompanyId}${programIdQuery}${courseQuery}${fromQuery}${toQuery}`
            );
            if (staticsResult && staticsResult.status === 200) {
                setIsLoading(false);
                setStaticsData(staticsResult.data?.data)

            } else {
                setIsLoading(false);
                setLoading(false);
                setStaticsData(defaultStaticsData)

            }
            // } else {
            //     setIsLoading(false);
            // }
        } catch (error) {
            setIsLoading(false);
        }
    }

    const handleTranslationLang = () => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
        const actionButtonsProgram = (cell, row, rowIndex, formateExtraData) => {
            return (
                <div className="d-flex justify-content-center">
                    <button className="table-action" onClick={() => window.location.href = `/admin/training/${adminCompanyId}/${row.program_id}`}><img src={ExpandIcon} alt="icon" /></button>
                </div>
            )
        }
        const nameFormatter = (cell, row) => {
            let color = row.status == 1 ? 'green' : row.status == 2 ? 'red' : 'yellow';
            return (
                <div><span className={`small-dot ${color} `}> </span> {` ${row.program_name}`}</div>
            )
        }
        setProgramColumn([
            {
                text: t("superadmin.training.name"),
                dataField: "program_name",
                formatter: nameFormatter,
                sort: true,
            },
            {
                text: `${t("superadmin.training.popup.referent")}/${t("superadmin.training.popup.responsible")}`,
                dataField: "referent",
                sort: true,
            },
            {
                text: t("superadmin.training.duration"),
                dataField: "duration",
                sort: true,
            },
            {
                text: t("superadmin.training.npaths"),
                dataField: "course",
                sort: true,
            },
            {
                text: t("superadmin.training.mandatory"),
                dataField: "obligatory",
                sort: true,
            },
            {
                text: t("superadmin.training.release"),
                dataField: "release_date",
                sort: true,
            },
            {
                text: t("superadmin.protocol.action"),
                dataField: "button",
                formatter: actionButtonsProgram,
                sort: true,
                headerAlign: "center"
            },
        ])
    };

    const callApis = async () => {
        setIsLoading(true)
        const company = await getDynamicCompany(localStorage.getItem('dynamic_id'));
        if (company) setCompanyDetails(company)
        setIsLoading(false)
        if (personalText == 'program') {
            setAdminCompanyId(company.document_id);
            await getCompanyAllProgram(company.document_id)
        }

    }

    useEffect(() => {
        handleTranslationLang();
    }, []);
    useEffect(() => {
        callApis()
    }, [])
    useEffect(() => {
        // if(refreshGraph==false) return ;
        if (personalText == 'statics') {
            // getStaticsData();
        }
        // setRefreshGraph(false);
    }, [personalText])

    useEffect(() => {
        if (activeContentType == "document" && personalText == "material") {
            const callApi = setTimeout(() => {
                getCompanyDocument(1)
            }, 2000)
            return () => clearTimeout(callApi)
        }
    }, [documentSearchQuery])
    useEffect(() => {
        if (activeContentType == "video") {
            const callApi = setTimeout(() => {
                getCompanyVideo(1);
            }, 2000)
            return () => clearTimeout(callApi)
        }
    }, [videoSearchQuery])

    const pageListRenderer = ({
        page,
        pages,
        active,
        disable,
        title,
        onPageChange
    }) => {
        return (
            <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12 d-flex justify-content-between pagination-list">
                <div className="row w-100">
                    <div className="col-lg-3 col-md-3"></div>
                    <div className="col-lg-4 col-md-4 d-flex justify-content-center">
                        {pages.map((p, index) => {
                            if (p.page === "<") return <div key={index} className="page" onClick={(e) => onPageChange(p.page)}><img src={PRIVIEWICONA} alt="icon" /></div>
                            if (p.page === ">") return <div key={index} className="page" onClick={(e) => onPageChange(p.page)}><img src={NEXTICONA} alt="icon" /></div>
                            if (p.active) return <div key={index} className="active" onClick={(e) => onPageChange(p.page)}>{p.page}</div>
                            return (
                                <div className="page" key={index} onClick={(e) => onPageChange(p.page)}>{p.page}</div>
                            )
                        })}
                    </div>
                    <div className="col-lg-5 col-md-5 d-flex justify-content-end content-center-mob">
                        <div className="mr-2 d-flex align-items-center buttons-superadmin">
                            <div className="mr-2 d-flex justify-content-center align-items-center logout-button" onClick={() => handleStatusFilter(1)}><span className="small-dot green mr-1"></span><span> {t("superadmin.training.active")}</span></div>
                            <div className="mr-2 d-flex justify-content-center align-items-center logout-button" onClick={() => handleStatusFilter(2)}><span className="small-dot red mr-1"></span><span> {t("superadmin.training.non_active")}</span></div>
                            <div className="mr-2 d-flex justify-content-center align-items-center logout-button" onClick={() => handleStatusFilter(3)}><span className="small-dot yellow mr-1"></span><span> {t("superadmin.training.scheduled")}</span></div>
                            <div className="d-flex justify-content-center align-items-center logout-button" onClick={() => { setFilteredData([]); setFilterShow(false) }}><span className="small-dot blue mr-1"></span><span> {t("superadmin.training.all")}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    let paginationOption = {
        paginationSize: 3,
        sizePerPage: 10,
        hideSizePerPage: true,
        withFirstAndLast: false,
        pageListRenderer
    }
    const closeModal = () => {
        setPdfUrlChange(null)
        setUrlLink(null)
        setCurrentEditing(null)
        setCurrentEditingUrl(null)
        setVideoPopupPage(1);
        setFileObject({ files: [], urls: [] })
        setPdfFileObject({ files: [], urls: [] })
        setDocumentProgramsModal(false)
        setActiveDocument({})
        setCurrentProgram({})
        setAlertMessage({ show: false, spinner: false, message: "", class: "" })
        setLearningModal(false)
        setPdfPopupPage(1)
        setArrObj([])
    }
    const saveLearningData = async () => {
        setLearningModal(false)
    }
    const handleLearningCategory = (index) => {
        setLearningModal(true)
        setSelectIndex(index);
    }

    const handleLearningProgram = (categoryKey, item, isChecked, materialbodyindex) => {
        setSelectedData(prevObjectHandle => {
            const updatedObjectHandle = { ...prevObjectHandle };
            if (!updatedObjectHandle[selectIndex]) {
                updatedObjectHandle[selectIndex] = {};
            }
            if (!updatedObjectHandle[selectIndex][categoryKey]) {
                updatedObjectHandle[selectIndex][categoryKey] = [];
            }
            const categoryArray = updatedObjectHandle[selectIndex][categoryKey];
            // Check if the item is already in the array
            const isItemInArray = categoryArray.includes(item);
            if (isChecked && !isItemInArray) {
                updatedObjectHandle[selectIndex][categoryKey].push(item);
            } else if (!isChecked && isItemInArray) {
                // Remove the item from the array
                updatedObjectHandle[selectIndex][categoryKey] = categoryArray.filter(
                    selectedItem => selectedItem !== item
                );
            }
            return updatedObjectHandle;
        });
        setCheckboxStates((prevState) => ({
            ...prevState,
            [`${categoryKey}_${item}_${materialbodyindex}`]: isChecked,
        }));
    };

    const documentProgramsModalContent = () => {
        return (
            <div className="model-pop-new">
                <div className="model-pop ">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <strong>{t("superadmin.training.popup.associated_programs")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div></div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="">
                            {
                                (activeDocument.programs && activeDocument.programs?.length) ?
                                    <div className="">
                                        {activeDocument.programs.map((data, i) => {
                                            return (
                                                <a href={'/admin/training/' + adminCompanyId + '/' + data.id}>
                                                    <div className="document-program-details" key={i}>
                                                        <div className="name">{data.name}</div>
                                                        <div className="description">{data.description}</div>
                                                    </div>
                                                </a>
                                            )
                                        })}
                                    </div> : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    const handleStatusFilter = (value) => {
        let arr = programData.filter((d) => d.status == value)
        setFilteredData(arr)
        setFilterShow(true)
    }

    const handlePageClick = (event) => {
        let page = event.selected + 1
        setCurrentPage(page)
        getCompanyDocument(page)
    };
    const handlePageClickVideo = (event) => {
        let page = event.selected + 1
        setCurrentPage(page)
        getCompanyVideo(page)
    }



    const previewDoc = (e, contentId) => {
        setContentId(contentId)
        setDocPreview(true)

    }
    const addLearningMaterialContent = () => {
        return (
            <>
                {
                    learningDataLength > 0 ? <div className="model-pop-new">
                        <div className="modal-body scroll-pop scroll-pop-new" ref={modalRef}>
                            <form className="register-form register-form-new new-program">
                                <div className="row">
                                    {
                                        Object.entries(learningData).map(([category, categoryData]) => (
                                            <div className="col-md-6" key={category}>
                                                <div className="load-new-heading">
                                                    <h3>{category}</h3>
                                                    {Object.entries(categoryData).map(([attribute, value]) => (
                                                        <div className="mandatory-check question-right-check" key={attribute}>
                                                            <label className={`mr-0 pl-0 check-radiotype ${checkboxStates[`${category}_${attribute}_${selectIndex}`] ? "checked-icon" : ""} `}>
                                                                <input
                                                                    type="checkbox"
                                                                    className="mr-2"
                                                                    name="correct2"
                                                                    onChange={(e) => handleLearningProgram(category, attribute, e.target.checked, selectIndex)}
                                                                />
                                                            </label>
                                                            <span className="pl20">{value}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </form>
                        </div>
                    </div> : ""}

            </>
        )
    }

    //post learnig data
    //get Video content details
    const getVideoContentDetails = async (id) => {
        setIsLoading(true);
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const videoResult = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_CONTENT_DETAILS}?contentId=${id}`
                );
                if (videoResult && videoResult.status === 200) {
                    setIsLoading(false);
                    let data1 = videoResult.data?.data;
                    let link = data1.url ? data1.url : data1.filePath?.includes("vimeo") ? data1.filePath : `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH_CONTENT}${data1.filePath}`;

                    if (data1.url) {
                        window.open(data1.url, '_blank').focus();
                    } else {
                        setCurrentVideoDetails({ title: data1.title, description: data1.description, link: link, error: false })
                        setShowVideoContentModal(true)
                    }
                } else {
                    if (videoResult?.status === 203) {
                        setCurrentVideoDetails({ ...currentVideoDetails, error: true })
                    }
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                handleModalClose();
            }
        };
        if (addLearningModal) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [addLearningModal]);

    useEffect(() => {
        setSelectFinal(selectedData);
        pdfFileObject.files.forEach((file, index) => {
            if (index == selectIndex) {
                file.learnData = selectFinal[index];
            }
        });
        setSelectedNewLearningData(pdfFileObject);
    }, [selectedData])

    const handleModalClose = () => {
        setLearningModal(false);
    };
    const DateRangeInput = forwardRef(({ value, onClick }, ref) => (
    
        <div className="date-range-input" style={{ textAlign: "start" }} onClick={onClick} ref={ref}>
            {value}
        </div>
    ));
    const datePickerHeader = ({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
    }) => {
        const months = [
            `${t("admin.training.Month.January")}`,
            `${t("admin.training.Month.February")}`,
            `${t("admin.training.Month.March")}`,
            `${t("admin.training.Month.April")}`,
            `${t("admin.training.Month.May")}`,
            `${t("admin.training.Month.June")}`,
            `${t("admin.training.Month.July")}`,
            `${t("admin.training.Month.August")}`,
            `${t("admin.training.Month.September")}`,
            `${t("admin.training.Month.October")}`,
            `${t("admin.training.Month.November")}`,
            `${t("admin.training.Month.December")}`,
        ];
        return (
            <div className="date-picker-header">
                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                    <img src={VECTORLEFT} alt="icon" />
                </button>
                <div>
                    {months[new Date(date).getMonth()]} { new Date(date).getFullYear()}
                </div>
                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                    <img src={VECTORRIGHT} alt="icon" />
                </button>
            </div>
        )
    }
    const datePickerDayName = (nameOfDay) => {
        let day = "";
        switch (nameOfDay) {
            case 'Monday': day = `${t("admin.training.day.Monday")}`
                break;
            case 'Tuesday': day = `${t("admin.training.day.Tuesday")}`
                break;
            case "Wednesday": day = `${t("admin.training.day.Wednesday")}`
                break;
            case "Thursday": day = `${t("admin.training.day.Thursday")}`
                break;
            case "Friday": day = `${t("admin.training.day.Friday")}`
                break;
            case "Saturday": day = `${t("admin.training.day.Saturday")}`
                break;
            case "Sunday": day = `${t("admin.training.day.Sunday")}`
                break;
            default: day = 'Unknown'
                break
        }
        return day;
    }
    return (
        <>

            <Modal title="" size="sm" contentClasses="" onClose={() => setDocumentProgramsModal(false)} show={documentProgramsModal}>
                {documentProgramsModalContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="learning-popup-size" onClose={() => setLearningModal(false)} show={addLearningModal}>
                {addLearningMaterialContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="" onClose={() => setShowVideoContentModal(false)} show={showVideoContentModal}>
                <ShowVideo details={currentVideoDetails} close={() => setShowVideoContentModal(false)} />
            </Modal>
            <Modal title="" size="sm" contentClasses=" " onClose={() => setDocPreview(false)} show={docPreview}>
                <ViewDocument contentId={contentId} close={() => setDocPreview(false)} />
            </Modal>
            <section className="full_bg dashboard-mobile">
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar path="training" />
                        <div className=" col-md-10 p0 pr-0 ipd-righ">
                            <div className="right-dashboard topspace leftr-dari">
                                <div className="right-header right-header-edit-training">
                                    <h2 className="heading-dash">
                                        {t("admin.training.trainingContent")}
                                    </h2>
                                </div>
                                <div className="graph-tabs graph-tabs-new graph-statistics-filter-tabs">
                                    <div>
                                        <div className=" d-lg-flex justify-content-between">
                                            <ul
                                                className="nav nav-tabs nav-stacked"
                                                id="myTab"
                                                role="tablist"
                                            >
                                                <>
                                                    <li className="nav-item" onClick={() => setPersonalText("program")}>
                                                        <a
                                                            className={
                                                                "nav-link buttons-superadmin " +
                                                                (personalText == "program"
                                                                    ? "active show"
                                                                    : "")
                                                            }
                                                            id="first-tab"
                                                            data-toggle="tab"
                                                            href="#first"
                                                            role="tab"
                                                            aria-controls="first"
                                                            aria-selected="true"
                                                        >

                                                            <button className="buttons mr-xl-3">{t("superadmin.training.button.program")}</button>
                                                        </a>
                                                    </li>
                                                    <li className="nav-item" onClick={() => { setPersonalText("material"); getCompanyDocument(1) }}>
                                                        <a
                                                            className={
                                                                "nav-link buttons-superadmin " +
                                                                (personalText == "material" ? "active show" : "")
                                                            }
                                                            id="second-tab"
                                                            data-toggle="tab"
                                                            href="#second"
                                                            role="tab"
                                                            aria-controls="second"
                                                            aria-selected="false"
                                                            onClick={() =>
                                                                window.dispatchEvent(new Event("resize"))
                                                            }
                                                        >
                                                            <button className="buttons mr-xl-3">{t("admin.training.material")}</button>
                                                        </a>
                                                    </li>


                                                    <li className="nav-item" onClick={() => { setPersonalText("statics"); }}>
                                                        <a
                                                            className={
                                                                "nav-link buttons-superadmin " +
                                                                (personalText == "statics"
                                                                    ? "active show"
                                                                    : "")
                                                            }
                                                            id="third-tab"
                                                            data-toggle="tab"
                                                            href="#third"
                                                            role="tab"
                                                            aria-controls="third"
                                                            aria-selected="false"
                                                        >
                                                            <button className="buttons">{t("admin.training.statics")}</button>
                                                        </a>
                                                    </li>
                                                </>
                                            </ul>

                                            <div className="ricsb super-search-top ">
                                                {personalText == 'program' ?
                                                    <div className="rieplogo rieplogo-new"></div> :
                                                    (personalText == 'document') ? <div>
                                                        <div className=" searchbar d-flex justify-content-end">
                                                            <div className="position-relative">
                                                                <img src={Group698} className="search_icon-new" alt="icon" />
                                                                <label htmlFor="search-bar-0" className="search-label">
                                                                    <span id="search-bar-0-label" className="sr-only"></span>
                                                                    <input id="search-bar-0" aria-labelledby="search-bar-0-label" className="search-bar-new form-control" placeholder={t("superadmin.protocol.search")} type="text" value={documentSearchQuery} onChange={(e) => setDocumentSearchQuery(e.target.value)} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div> :
                                                        personalText == 'statics' ?

                                                            <div className="static-filter mt-3 mt-lg-0 mob-row">
                                                                <div className="mob-col-12">

                                                                    <DatePicker
                                                                        selectsRange={true}
                                                                        startDate={dateRange[0]}
                                                                        endDate={dateRange[1]}
                                                                        placeholderText="Select date range"
                                                                        dateFormat="dd.MM.yy"
                                                                        minDate = {1693353600000}
                                                                        maxDate ={todayEpoch * 1000}
                                                                        onChange={(update) => {
                                                                            changeDate(update)

                                                                        }}
                                                                        customInput={< DateRangeInput />}
                                                                        renderCustomHeader={datePickerHeader}
                                                                        formatWeekDay={datePickerDayName}
                                                                    />
                                                                </div>
                                                                <div className="mob-col-12">
                                                                    <select className="program program-dropdown user-count" onChange={(e) => changeProgram(e.target.value)} value={staticsData.programId || 'Select'}>
                                                                        <option disabled={false} value="">{t("user.Questionnare.choose")}</option>
                                                                        {
                                                                            programData.map((p, v) => {
                                                                                return (
                                                                                    <option value={p?.program_id}>{p?.program_name}</option>
                                                                                )
                                                                            })
                                                                        }

                                                                    </select>
                                                                    <select className="course user-count" value={(selectCourse)} onChange={(e) => changeCourse(e.target.value)}>
                                                                        <option value="">{t("admin.training.allCourse")}</option>

                                                                        {
                                                                            (staticsData?.programCourseList != "") ?
                                                                                staticsData?.programCourseList?.map((k, v) => {
                                                                                    return (
                                                                                        <option value={k.key}>{k.value}</option>
                                                                                    )
                                                                                })
                                                                                : null
                                                                        }

                                                                    </select>
                                                                </div>
                                                            </div>
                                                            : null

                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="tab-content">
                                            <>
                                                <div
                                                    className={
                                                        "tab-pane fade " +
                                                        (personalText == "program"
                                                            ? "active show"
                                                            : "")
                                                    }
                                                    id="first"
                                                    role="tabpanel"
                                                    aria-labelledby="first-tab"
                                                >
                                                    <div className="container-new-admin">
                                                        <div className="row mt-5">

                                                            {(programData.length > 0 && programData) ? <>
                                                                <ToolkitProvider
                                                                    keyField="program_id"
                                                                    key={'datatable'}
                                                                    data={filterShow > 0 ? filteredData : programData}
                                                                    columns={programColumn}
                                                                    search
                                                                >{(props) => (

                                                                    <div id="test" className="table  ricsb ricsb-new protocolli-data push-table">
                                                                        <div>
                                                                            <div>
                                                                                <div className="searchbar  searchbar-sa d-flex justify-content-end m-2 searchmoved-top">
                                                                                    <div style={{ position: "relative" }}>
                                                                                        <img
                                                                                            src={Group698}
                                                                                            className="     "
                                                                                            alt="icon"
                                                                                            style={{ position: "absolute", top: "18px", left: "10px" }}
                                                                                        />
                                                                                        <SearchBar
                                                                                            placeholder={t("superadmin.protocol.search")}
                                                                                            {...props.searchProps}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <BootstrapTable
                                                                                    src={VectorDown} alt="icon"
                                                                                    data={filterShow ? filteredData : programData}
                                                                                    {...props.baseProps}
                                                                                    filter={filterFactory()}
                                                                                    pagination={paginationFactory(paginationOption)}
                                                                                    wrapperClasses={'table-responsive mt-4'}

                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                )}
                                                                </ToolkitProvider>
                                                            </> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        "tab-pane fade " +
                                                        (personalText == "material" ? "active show" : "")
                                                    }
                                                    id="second"
                                                    role="tabpanel"
                                                    aria-labelledby="second-tab"
                                                >

                                                    <div className="container-new-admin">
                                                        <div className="row content-section mt-2 " >
                                                            <div className="content-section-button">
                                                                <button onClick={() => { setActiveContentType("document"); getCompanyDocument(1) }} className={activeContentType === "document" ? "active" : "button-cursor"}>{t("superadmin.training.documents")}</button>
                                                                <button onClick={() => { setActiveContentType("video"); getCompanyVideo(1) }} className={activeContentType === "video" ? "active videoActive show" : "button-cursor"}>{t("superadmin.training.video")}</button>
                                                            </div>
                                                            {
                                                                activeContentType == 'document' ?

                                                                    <div className="col-md-12 pr-0 p0 ipd-righ">
                                                                        <div className="rigt-dashboard topspace ">
                                                                            {/* {documentData && documentData.length ? */}

                                                                            <div>
                                                                                <div className=" searchbar my-2">
                                                                                    <div className="position-relative">
                                                                                        <img src={Group698} className="search-icon-user-material" alt="icon" />
                                                                                        <label htmlFor="search-bar-0" className=" d-flex">
                                                                                            <span id="search-bar-0-label" className=""></span>
                                                                                            <input id="search-bar-0" aria-labelledby="search-bar-0-label" className="form-control" placeholder={t("superadmin.protocol.search")} type="text" value={documentSearchQuery} onChange={(e) => setDocumentSearchQuery(e.target.value)} />
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div>

                                                                                    {
                                                                                        documentData && documentData.length > 0 ?

                                                                                            documentData.map((data, i) => {
                                                                                                return (
                                                                                                    <div key={i}>
                                                                                                        <div className="d-flex document-section justify-content-between">
                                                                                                            <div className="text-left description-document w-100 description-document-new">
                                                                                                                <div className="d-flex w-100 ">
                                                                                                                    <div className="icon"><img src={(urlPattern.test(data.name) || (data.name.split('.').pop() == "html") || (data.name.split('.').pop() == "htm")) ? Group1857link : OPEN_ICON} alt="icon" onClick={(e) => previewDoc(e, data.contentId)} className="preview" /></div>
                                                                                                                    <div className="text w-100">
                                                                                                                        <div className="title-word-wrap">{data.name}</div>
                                                                                                                        {(data.description?.length > 250 && documentLoadMoreIndex != data.contentId) ?
                                                                                                                            <div className="mb-3 desc-normal w-100 pb-4" >
                                                                                                                                {/* {data.description?.substr(0, 250)}... */}
                                                                                                                                <p className="pb-2">{data.description?.substr(0, 250)}...</p>
                                                                                                                                <button onClick={() => setDocumentLoadMoreIndex(data.contentId)}><span>{t("superadmin.troubleshooting.showMore")}</span></button>
                                                                                                                            </div> :
                                                                                                                            documentLoadMoreIndex == data.contentId ? <div className="mb-3 desc-normal pb-4">
                                                                                                                                {data.description}
                                                                                                                                <button onClick={() => setDocumentLoadMoreIndex('')}><span>{t("superadmin.troubleshooting.showLess")}</span></button>
                                                                                                                            </div> :
                                                                                                                                <div className="mb-3 desc-normal" >{data.description}</div>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="program-document" onClick={() => { setActiveDocument(data); setDocumentProgramsModal(true) }}>
                                                                                                                <span >{t("superadmin.troubleshooting.programs")}</span>
                                                                                                                <div className="mt-3">{data.programs.length}</div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                            :
                                                                                            <>
                                                                                                {!isLoading ? <div >
                                                                                                    <div className="d-flex document-section justify-content-between">
                                                                                                        <div className="text-left description-document">
                                                                                                            <div className="d-flex w-100 ">
                                                                                                                {t("superadmin.troubleshooting.documentNotFound")}

                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div> : null}
                                                                                            </>

                                                                                    }

                                                                                    {
                                                                                        documentData && documentData.length > 0 ?
                                                                                            <div className="d-flex sa-program-pagination">
                                                                                                <ReactPaginate
                                                                                                    breakLabel="..."
                                                                                                    nextLabel={<img src={NEXTICONA} alt="icon" />}
                                                                                                    previousLabel={<img src={PRIVIEWICONA} alt="icon" />}
                                                                                                    onPageChange={handlePageClick}
                                                                                                    pageRangeDisplayed={3}
                                                                                                    pageCount={documentPageCount}
                                                                                                    renderOnZeroPageCount={null}
                                                                                                    containerClassName="selectuser-pagination"
                                                                                                    pageClassName="page-class"
                                                                                                    previousClassName={currentPage == 1 ? "d-none" : ""}
                                                                                                    nextClassName={currentPage == documentPageCount ? "d-none" : ""}
                                                                                                    forcePage={currentPage - 1}
                                                                                                />
                                                                                            </div>
                                                                                            : null}

                                                                                </div>

                                                                            </div>
                                                                            {/* : null} */}
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="col-md-12 pr-0 p0 ipd-righ">
                                                                        <div className="rigt-dashboard topspace ">
                                                                            {/* {videoData && videoData.length ? */}
                                                                            <div>
                                                                                <div className=" searchbar my-2">
                                                                                    <div className="position-relative">
                                                                                        <img src={Group698} className="search-icon-user-material" alt="icon" />
                                                                                        <label htmlFor="search-bar-0" className=" d-flex">
                                                                                            <span id="search-bar-0-label" className=""></span>
                                                                                            <input id="search-bar-0" aria-labelledby="search-bar-0-label" className="form-control" placeholder={t("superadmin.protocol.search")} type="text" value={videoSearchQuery} onChange={(e) => setVideoSearchQuery(e.target.value)} />
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div>

                                                                                    {videoData && videoData.length > 0 ?
                                                                                        videoData.map((data, i) => {
                                                                                            return (

                                                                                                <div key={i} className="d-flex document-section justify-content-between">
                                                                                                    <div className="text-left description-document w-100" >
                                                                                                        <div className="d-flex w-100">
                                                                                                            <div className="icon-image" onClick={() => getVideoContentDetails(data.contentId)}>
                                                                                                                {data.link ?
                                                                                                                    <div className="play-button"><img className="link" src={Group1857link} alt="" /></div> :
                                                                                                                    <div className="play-button"><img src={VideoPlayButton} alt="" /></div>
                                                                                                                }
                                                                                                                <img src={data.thumbnail || VIDEO_IMAGE} alt="icon" />
                                                                                                            </div>

                                                                                                            <div className="text w-100">
                                                                                                                <div className="p-1 title-word-wrap">{data.name}</div>
                                                                                                                {(data.description?.length > 250 && documentLoadMoreIndex != data.contentId) ?
                                                                                                                    <div className="pl-1 desc-normal w-100" >
                                                                                                                        {/* {data.description?.substr(0, 250)}... */}
                                                                                                                        <p className="pb-2">{data.description?.substr(0, 250)}...</p>
                                                                                                                        <button onClick={() => setDocumentLoadMoreIndex(data.contentId)}>{t("superadmin.troubleshooting.showMore")}</button>
                                                                                                                    </div> :
                                                                                                                    documentLoadMoreIndex == data.contentId ? <div className="pl-1 desc-normal">
                                                                                                                        {data.description}
                                                                                                                        <button onClick={() => setDocumentLoadMoreIndex('')}>{t("superadmin.troubleshooting.showLess")}</button>
                                                                                                                    </div> :
                                                                                                                        <div className="pl-1 desc-normal" >{data.description}</div>
                                                                                                                }
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="program-document" onClick={() => { setActiveDocument(data); setDocumentProgramsModal(true) }}>
                                                                                                        <span >{t("superadmin.troubleshooting.programs")}</span>
                                                                                                        <div className="mt-3">{data.programs.length}</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })

                                                                                        :
                                                                                        <>
                                                                                            {!isLoading ? <div >
                                                                                                <div className="d-flex document-section justify-content-between">
                                                                                                    <div className="text-left description-document">
                                                                                                        <div className="d-flex w-100 ">
                                                                                                            {t("superadmin.troubleshooting.videoNotFound")}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div> : null}
                                                                                        </>
                                                                                    }

                                                                                    <div>
                                                                                        {videoData && videoData.length > 0 ?
                                                                                            <div className="d-flex sa-program-pagination">
                                                                                                <ReactPaginate
                                                                                                    key="paginate2"
                                                                                                    breakLabel="..."
                                                                                                    nextLabel={<img src={NEXTICONA} alt="icon" />}
                                                                                                    previousLabel={<img src={PRIVIEWICONA} alt="icon" />}
                                                                                                    onPageChange={handlePageClickVideo}
                                                                                                    pageRangeDisplayed={3}
                                                                                                    pageCount={videoPageCount}
                                                                                                    renderOnZeroPageCount={null}
                                                                                                    containerClassName="selectuser-pagination"
                                                                                                    pageClassName="page-class"
                                                                                                    previousClassName={videoCurrentPage == 1 ? "d-none" : ""}
                                                                                                    nextClassName={videoCurrentPage == videoPageCount ? "d-none" : ""}
                                                                                                    forcePage={videoCurrentPage - 1}
                                                                                                />
                                                                                            </div>
                                                                                            : null}
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                                <div
                                                    className={
                                                        "tab-pane fade " +
                                                        (personalText == "statics"
                                                            ? "active show"
                                                            : "")
                                                    }
                                                    id="third"
                                                    role="tabpanel"
                                                    aria-labelledby="third-tab"
                                                >

                                                    {(staticsData?.programId != '') ?
                                                        <AdminStatics data={staticsData}  dateRange ={dateRange} />
                                                        : <div className="row mt-10 w-100">
                                                            <div className="col-md-4" >
                                                                <div className="durate-box ">
                                                                    <div className="durate-mediabox-not-found">
                                                                        {t("superadmin.troubleshooting.statistics_not_found")}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                            </>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {isLoading ? <LazyLoader /> : null}
                </div>
            </section>
            <Sidebarmob />
        </>
    );
};

export default AdminTraining;
