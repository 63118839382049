import React, { Component } from "react";
import Adminsidebar from "../admin/sidebar";
import heart from "../../assets/Image/heart.svg";
import Group11951 from "../../assets/Image/Group11951.svg";
import Group1174 from "../../assets/Image/Group1174.svg";
import Group1198101 from "../../assets/Image/Group1198101.svg";
import Group1199 from "../../assets/Image/Group1199.svg";
import Sidebarmob from "../admin/sidebarmob";
import Group1165 from "../../assets/Image/Group1165.svg";
import Group011991 from "../../assets/Image/Group011991.svg";
import Group1109 from "../../assets/Image/Group1109.svg";
import Group1105 from "../../assets/Image/Group1105.svg";
import Group1104 from "../../assets/Image/Group1104.svg";
import Group1108 from "../../assets/Image/Group1108.svg";
import Group1107 from "../../assets/Image/Group1107.svg";
import Group1200 from "../../assets/Image/Group1200.svg";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { FaEllipsisH } from "react-icons/fa";

export class report extends Component {
  render() {
    return (
      <>
        <section className="full_bg dashboard-mobile">
          <div className="container-fluid">
            <div className="row">
              <Adminsidebar />
              <div className=" col-md-10 pr-0 ipd-righ">
                <div className="right-dashboard topspace leftr-dari h100">
                  <div className="align-between">
                    <h2 className="heading-dash">Report</h2>
                     <button className="buttons top-pud visi-none">Esporta</button>
                      </div>
                       <div className="graph-tabs report-mob deskt-n">
                     <ul
                      className="nav nav-tabs nav-stacked"
                      id="myTab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="first-tab"
                          data-toggle="tab"
                          href="#Generale"
                          role="tab"
                          aria-controls="first"
                          aria-selected="true"
                        >
                          <span>Media utenti</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="first-tab"
                          data-toggle="tab"
                          href="#donne"
                          role="tab"
                          aria-controls="first"
                          aria-selected="true"
                        >
                          <span>Generazione utenti</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="first-tab"
                          data-toggle="tab"
                          href="#lavoro"
                          role="tab"
                          aria-controls="first"
                          aria-selected="true"
                        >
                          <span>Tipologia lavoro</span>
                        </a>
                      </li>

                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="first-tab"
                          data-toggle="tab"
                          href="#barre"
                          role="tab"
                          aria-controls="first"
                          aria-selected="true"
                        >
                          <span>Grafico a barre</span>
                        </a>
                      </li>
                    </ul>

                    <div className="card-body">
                      <div className="tab-content">
                        <div
                          className="tab-pane fade active show"
                          id="Generale"
                          role="tabpanel"
                          aria-labelledby="first-tab"
                        >
                          <div className="media-u">
                            <div className="right-s infor-cir-report">
                              <p>
                                86<sub></sub>
                              </p>
                              <span className="width_line"></span>
                              <i className="colorNew hd-n">
                                {" "}
                                <HiOutlineInformationCircle
                                  className="pointer"
                                  onClick={() => setModal1(true)}
                                />
                              </i>
                            </div>
                            <img src={Group011991} className="w-100"  alt="icon" />
                             <div className="repomon">
                               <div className="rep-num">
                                 <img src={Group1165} alt="icon" />{" "}
                                 <span>80%</span>
                                 <p>80-90</p>
                              </div>
                              <div className="rep-num">
                                <img src={Group1165} alt="icon" />{" "}
                                 <span>10%</span>
                                 <p>70-80</p>
                              </div>
                            </div>
                            <div className="repomon">
                              <div className="rep-num">
                                <img src={Group1165} alt="icon" />{" "}
                                <span>80%</span>
                                <p>80-90</p>
                              </div>
                              <div className="rep-num">
                                <img src={Group1165} alt="icon" />{" "}
                                 <span>10%</span>
                                 <p>70-80</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="donne"
                          role="tabpanel"
                          aria-labelledby="first-tab"
                        >
                          <div className="media-u">
                           <div className="right-s infor-cir-report">
                            <p><sub></sub></p>
                              <span className="width_line"></span>
                               <i className="colorNew hd-n">
                                {" "}
                                <HiOutlineInformationCircle
                                  className="pointer"
                                  onClick={() => setModal1(true)}
                                />
                              </i>
                            </div>
                            <img
                          src={Group11951}
                          className="img-fluid rigds w-100"
                          alt="icon"
                        />
                          </div>
                           <div className="media-u mt-4 mb-5">
                             <div className="right-s infor-cir-report">
                              <h4 className="Generazione">Generazione</h4>
                             </div>
                            <img
                          src={Group1198101}
                          className="img-fluid w-100"
                          alt="icon"/>
                          </div>
                         </div>
                        <div
                          className="tab-pane fade"
                          id="lavoro"
                          role="tabpanel"
                          aria-labelledby="first-tab"
                        >
                          <div className="media-u">
                            <div className="right-s infor-cir-report">
                              <img
                              src={Group1109}
                              className="img-fluid rigds"
                              alt="icon"
                              />
                              <p><sub></sub></p>
                               <span className="width_line"></span>
                                 <i className="colorNew hd-n">
                                 {" "}
                                  <HiOutlineInformationCircle
                                  className="pointer"
                                  onClick={() => setModal1(true)}
                                  />
                                </i>
                              </div>
                            <br/>
                          <img
                          src={Group1105}
                          className="img-fluid rigds w-100"
                          alt="icon"
                            />
                          </div>
                          <div className="media-u">
                            <div className="right-s infor-cir-report">
                              <img
                              src={Group1108}
                              className="img-fluid rigds"
                              alt="icon"
                              />
                              <p><sub></sub></p>
                               <span className="width_line"></span>
                                 <i className="colorNew hd-n">
                                 {" "}
                                  <HiOutlineInformationCircle
                                  className="pointer"
                                  onClick={() => setModal1(true)}
                                  />
                                </i>
                              </div>
                            <br/>
                          <img
                          src={Group1104}
                          className="img-fluid rigds w-100"
                          alt="icon"
                            />
                          </div>
                          <div className="media-u mb-5">
                            <div className="right-s infor-cir-report">
                              <img
                              src={Group1107}
                              className="img-fluid rigds"
                              alt="icon"
                              />
                              <p><sub></sub></p>
                               <span className="width_line"></span>
                                 <i className="colorNew hd-n">
                                 {" "}
                                  <HiOutlineInformationCircle
                                  className="pointer"
                                  onClick={() => setModal1(true)}
                                  />
                                </i>
                              </div>
                            <br/>
                          <img
                          src={Group1104}
                          className="img-fluid rigds w-100"
                          alt="icon"
                            />
                          </div>
                         </div>
                        <div
                          className="tab-pane fade"
                          id="barre"
                          role="tabpanel"
                          aria-labelledby="first-tab"
                        >
                          
                          <img
                          src={Group1200}
                          className="img-fluid rigds w-100"
                          alt="icon"
                            />
                        </div>
                      </div>
                    </div>
                       </div>
                  
                   <div className="row d-flex topreport">
                     <div className="col-md-3 d-flex w40-equal">
                      <div className="bg-white-d text-right">
                        <div className="in-f">
                          <div className="in-alini">
                            <div className="ico-bg">
                              <img src={heart} alt="icon" />
                            </div>
                            <div className="right-s s86 d-flex">
                              <h4>Media utenti</h4>
                            </div>
                          </div>
                          <div className="right-part">
                            <i className="colorNew">
                              {" "}
                              <FaEllipsisH
                                className="pointer"
                                onClick={() => setModal1(true)}
                              />
                            </i>
                          </div>
                        </div>
                        <div className="right-s s86">
                          <p>
                            86<sub></sub>
                          </p>
                          <span className="width_line"></span>
                        </div>
                        <img
                          src={Group1174}
                          className="img-fluid rigds w-70"
                          alt="icon"
                        />
                      </div>
                      </div>

                     <div className="col-md-3 ipad-h-show w80-equal">
                        <div className="bg-white-d text-right">
                         <div className="in-f">
                          <div className="in-alini">
                            <div className="ico-bg">
                              <img src={heart} alt="icon" />
                            </div>
                            <div className="right-s d-flex">
                              <h4></h4>
                              <p>
                                <sub></sub>
                              </p>
                              <span className="width_line"></span>
                            </div>
                          </div>
                          <div className="right-part">
                            <i className="colorNew">
                              {" "}
                              <FaEllipsisH
                                className="pointer"
                                onClick={() => setModal1(true)}
                              />
                            </i>
                          </div>
                        </div>
                        <img
                          src={Group11951}
                          className="img-fluid rigds w-100"
                          alt="icon"
                        />
                       </div>
                     </div>
                    
                      <div className="col-md-9 hide-iipad w80-equal">
                        <div className="bg-white-d">
                          <div className="in-f">
                           <div className="in-alini">
                            <div className="ico-bg">
                              <img src={heart} alt="icon" />
                            </div>
                            <div className="right-s d-flex">
                              <h4>Tipologia lavoro</h4>
                              <p>
                                <sub></sub>
                              </p>
                              <span className="width_line"></span>
                            </div>
                          </div>
                          <div className="right-part">
                            <i className="colorNew">
                              {" "}
                              <FaEllipsisH
                                className="pointer"
                                onClick={() => setModal1(true)}
                              />
                            </i>
                          </div>
                        </div>
                        <img
                          src={Group1198101}
                          className="img-fluid w-100"
                          alt="icon"
                        />
                       </div>
                      </div>
                       <div className="col-md-3 mt-4 hide-iipad fl-m">
                        <div className="bg-white-d text-right">
                        <div className="in-f">
                          <div className="in-alini">
                            <div className="ico-bg">
                              <img src={heart} alt="icon" />
                            </div>
                            <div className="right-s d-flex">
                              <h4>Generazione utenti</h4>
                              <p>
                                <sub></sub>
                              </p>
                              <span className="width_line"></span>
                            </div>
                          </div>
                          <div className="right-part">
                            <i className="colorNew">
                              {" "}
                              <FaEllipsisH
                                className="pointer"
                                onClick={() => setModal1(true)}
                              />
                            </i>
                          </div>
                        </div>
                        <img
                          src={Group11951}
                          className="img-fluid rigds w-100"
                          alt="icon"
                        />
                       </div>
                       </div>
                      <div className="col-md-9 ipad-h-show w100full">
                        <div className="bg-white-d">
                          <div className="in-f">
                           <div className="in-alini">
                            <div className="ico-bg">
                              <img src={heart} alt="icon" />
                            </div>
                            <div className="right-s d-flex">
                              <h4>Tipologia lavoro</h4>
                              <p>
                                <sub></sub>
                              </p>
                              <span className="width_line"></span>
                            </div>
                          </div>
                          <div className="right-part">
                            <i className="colorNew">
                              {" "}
                              <FaEllipsisH
                                className="pointer"
                                onClick={() => setModal1(true)}
                              />
                            </i>
                          </div>
                        </div>
                        <img
                          src={Group1198101}
                          className="img-fluid w-100"
                          alt="icon"
                        />
                      </div>
                       </div>
                     <div className="col-md-9 mt-4 d-flex w100full">
                      <div className="bg-white-d">
                        <div className="in-f">
                          <div className="in-alini">
                            <div className="ico-bg">
                              <img src={heart} alt="icon" />
                            </div>
                            <div className="right-s d-flex">
                              <h4>Grafico a barre</h4>
                              <p>
                                <sub></sub>
                              </p>
                              <span className="width_line"></span>
                            </div>
                          </div>
                          <div className="right-part">
                            <i className="colorNew">
                              {" "}
                              <FaEllipsisH
                                className="pointer"
                                onClick={() => setModal1(true)}
                              />
                             </i>
                           </div>
                         </div>
                         <img
                          src={Group1199}
                          className="img-fluid rigds w-100"
                          alt="icon"
                        />
                      </div>
                    </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </section>
        <Sidebarmob />
      </>
    );
  }
}

export default report;
