import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { getLocalStoarge } from '../../../common/allfunction';
import ReactApexChart from 'react-apexcharts';
import PostRequest from '../../../apiConnection/postRequest';
import GetRequest from '../../../apiConnection/getRequest';
import LazyLoader from '../../../common/lazyLoader';


const CognitiveNbackBarChart = (props) => {
  const [t, i18n] = useTranslation("common");
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [theme, setTheme] = useState(getLocalStoarge("theme"));
  const option = {
    series: [
      {
        data: [],
      },
      {
        data: [],
      }
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
      },
      // colors: ['#54C1FB', '#54C1FB',],
      responsive: [{
        breakpoint: 767, // define breakpoint for mobile
        options: {
          chart: {
            width: '100%', // make the chart 100% width for mobile
          }
        }
      }],

      legend: {
        show: false,
      },
      annotations: {
        yaxis: [
          {
            y: 0,
            y2: 3,
            borderColor: '#000',
            fillColor: '#FFDF9A',
            opacity: 0.1,
            label: {
              text: ''
            }
          },
          {
            y: 3,
            y2: 6,
            borderColor: '#000',
            fillColor: '#D3D6D4',
            opacity: 0.1,
            label: {
              text: ''
            }
          },
          {
            y: 3,
            y2: 24,
            borderColor: '#000',
            fillColor: '#95C9F6',
            opacity: 0.1,
            label: {
              text: ''
            }
          },

        ]
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50%',
          endingShape: 'rounded',
        },

      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        lines: {
          show: true,
        },
        labels: {
          style: {
            colors: (theme && (theme?.replace(/"/g, ''))=="dark") ? '#fff' : '#000',
          },
        },
        categories: ['G.1', 'G.2', 'G.3', 'G.4', 'G.5', 'G.6', 'G.7', 'G.8', 'G.9', 'G.10', 'G.11', 'G.12', 'G.13', 'G.14', 'G.15'],
      },
      yaxis: {
        lines: {
          show: true,
        },
        labels: {
          style: {
            colors: (theme && (theme?.replace(/"/g, ''))=="dark") ? '#fff' : '#000', 
          },
        },
        title: {
          text: ''
        },
      },
      colors: [function ({ value, seriesIndex, dataPointIndex, w }) {
        if (dataPointIndex < 4) {
          return "#54C1FB"
        } else if (dataPointIndex < 8) {
          return "#F6BF42";
        }
        else if (dataPointIndex < 12) {
          return "#6D71F9"
        } else {
          return "#9ECB61"
        }
      }],
      fill: {
        opacity: 1,
      },
      tooltip: {
        enabled: false,
        y: {
          formatter: function (val) {
            return val
          }
        }
      }
    }
  }

  useEffect(() => {
    let lang = getLocalStoarge("langauage");
    if (lang) {
      i18n.changeLanguage(lang);
    }
    setData(option);

  }, [theme]);

  useEffect(() => {
    nbackGraphData();
  }, [props.repeatTime])

  const nbackGraphData = async () => {
    setIsLoading(true)
    try {
      // const refreshToken = await PostRequest(
      //   `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
      //   {},
      //   "",
      //   "refresh_token"
      // );
      // if (refreshToken) {
        const getNbackGraphData = await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_USER_N_BACK_REPORT}?repeat_time=${props.repeatTime}`,
        );
        setIsLoading(false)
        if (getNbackGraphData && getNbackGraphData.status === 200) {
          let result = getNbackGraphData?.data?.data?.user_n_back_score
          let newArr=result.activity_day_wise_score.map((value)=>{
            return value !== 0 ? Math.round(value * 10) / 10 : value
          })
          setData(prevData => ({
            series: [
              {
                data: newArr,
              },
              {
                data: result.activity_day_wise_max_score,
              },
            ],
            options: prevData.options,
          }));
        }
      // } else {
      //   setIsLoading(false);
      // }
    } catch (error) {
      console.log("error", error)
      setIsLoading(false);
    }
  }


  return (
    <div>
      {isLoading ? <LazyLoader /> : null}
      <div className='second-top-heading mt-3'>
        <div className='col-12'>
          <h2 className='second-page-heading'>{t('user.activityReport.N-back')}</h2>
          <p className='pre-post-heading-description' dangerouslySetInnerHTML={{ __html: t('user.activityReport.N-back-description') }}></p>
        </div>
        <h6 className='n-back-max'>{t('user.activityReport.max')}</h6>
      </div>
      <div className='N-back-bar-graph'>
        <div className='row'>
          <div className="col-lg-8">
            {(data) ? <div id="chart" >
              <ReactApexChart options={data?.options} series={data?.series} type="bar" height={350} />
            </div> : null}
          </div>
          <div className='col-lg-4 n-back-graph-details'>
            <div className='n-back-bar-graph'>
              <div className='color-sky-blue mr-3'></div>
              <div className='bar-chart-coverage'>{`< 6`} {t('user.activityReport.N-back-color-code-1')}</div>
            </div>
            <div className='n-back-bar-graph mt-3'>
              <div className='color-sky-green mr-3'></div>
              <div className='bar-chart-coverage'>{`3-5`} {t('user.activityReport.N-back-color-code-2')}</div>
            </div>
            <div className='n-back-bar-graph mt-3'>
              <div className='color-sky-orange mr-3'></div>
              <div className='bar-chart-coverage'>{`< 3`} {t('user.activityReport.N-back-color-code-3')}</div>
            </div>

            <div className='mt-5'>
              <p className='text-left'>{t('user.activityReport.N-back-graph-description')}</p>
            </div>

          </div>

        </div>

        <div className='d-flex justify-content-between ml-4 n-back-name-circle-mob'>
          <div className='d-flex mb-2'><div className='go-no-go-visio-circle mr-3'></div> <div className='n-back-1-font'>N-BACK-{t('user.activityReport.N-back-1')}</div></div>
          <div className='d-flex mb-2'><div className='go-no-go-visuoacustico-circle mr-3'></div> <div className='n-back-1-font'>N-BACK-{t('user.activityReport.N-back-2')}</div></div>
          <div className='d-flex mb-2'><div className='go-no-go-visuoacustico2-circle mr-3'></div> <div className='n-back-1-font'>N-BACK-{t('user.activityReport.N-back-3')}</div></div>
          <div className='d-flex mb-2'><div className='go-no-go-visio-circle3 mr-3'></div> <div className='n-back-1-font'>N-BACK-{t('user.activityReport.N-back-4')}</div></div>
        </div>

      </div>
    </div>
  )
}

export default CognitiveNbackBarChart
