import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "./modal";
import closeIcon from '../../assets/Image/CLOSE_ICONA.png'
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function preAssessmentTutorialModal(props) {
    // console.log('tutorial props ', props);
    const [instruction, setInstruction] = useState(1);
    const [modalIns1, setModalIns1] = useState(props.popUpEnable);
    const [t, i18n] = useTranslation("common");
    useEffect(() => {
        // instPopUp();
    }, []);
    const closeModal = () => {
        setModalIns1(false);
    }
    const instPopUp = () => {
        return (
            <>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={props.header.icon} alt="icon" style={{ width: '50px' }} />
                            </div>
                            <strong> {props.header.modalHeading}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => { (props.handleModal) ? props.handleModal() : closeModal() }}
                        >
                            <img src={closeIcon} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body">

                        <div className={`row justify-content-center ${(instruction == 1) ? '' : 'd-none'}`}>
                            <div className="col-10" style={{ 'height': "325px", 'line-height': '325px' }}>
                                <img src={props.header.step1img} className="w-100 h-100 pt-5 pb-5" />

                            </div>

                            <div className="col-8 pt-3 text-center">
                                <p className="">{props.header.step1text}</p>
                            </div>
                        </div>
                        <div className={`row justify-content-center ${(instruction == 2) ? '' : 'd-none'}`}>
                            <div className="col-10" style={{ 'height': "325px", 'line-height': '325px' }}>

                                <img src={props.header.step2img} className="w-100 h-100 pt-5 pb-5" />

                            </div>

                            <div className="col-8 pt-3 text-center">
                                <p className="">{props.header.step2text}</p>
                            </div>
                        </div>
                        <div className={`row justify-content-center ${(instruction == 3) ? '' : 'd-none'}`}>
                            <div className="col-10" style={{ 'height': "325px", 'line-height': '325px' }}>

                                <img src={props.header.step3img} className="w-100 h-100 pt-5 pb-5" />

                            </div>
                            <div className="col-8 pt-3 text-center">
                                <p className="">{props.header.step3text}</p>
                            </div>
                        </div>

                        {(props.header.step4text != '') ? < div className={`row justify-content-center ${(instruction == 4) ? '' : 'd-none'}`}>
                            <div className="col-8">
                                <div className="pt-3 text-center">
                                    <p className="info-modal-body-text" dangerouslySetInnerHTML={{ __html: `${props.header.step4text}` }}></p>
                                </div>
                            </div>
                        </div> : null}

                    </div>
                    <div className={`modal-footer d-flex  ${(instruction == 1) ? null : 'justify-content-between'} `} style={{ 'border-top': 0 }}>
                        <div class="col-sm-3">
                            {(instruction == 1) ? null : <button class="ricontrolla " style={{ float: "left" }} onClick={() => setInstruction(instruction - 1)}>{t("user.dashboard.instruction_step_preview")}</button>}
                        </div>
                        <div className="col-sm-6 tabControl visi-none">
                            <div className="fit-bdots">
                                <ul
                                    className="nav nav-pills nav-fill"
                                    id="pills-tab"
                                    role="tablist">
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link  ${instruction === 1 ? "active" : ""}`}
                                            onClick={() => setInstruction(1)}
                                            id="pills-home-tab"
                                            data-toggle="pill"
                                            href="javascript:void(0)"
                                            role="tab"
                                            aria-controls="first"
                                            aria-selected="true"
                                        ></a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link  ${instruction === 2 ? "active" : ""}`}
                                            onClick={() => setInstruction(2)}
                                            id="pills-profile-tab "
                                            data-toggle="pill"
                                            href="javascript:void(0)"
                                            role="tab"
                                            aria-controls="second"
                                            aria-selected="false"
                                        ></a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link  ${instruction === 3 ? "active" : ""}`}
                                            onClick={() => setInstruction(3)}
                                            id="pills-contact-tab"
                                            data-toggle="pill"
                                            href="javascript:void(0)"
                                            role="tab"
                                            aria-controls="third"
                                            aria-selected="false"
                                        ></a>
                                    </li>

                                    <li className="nav-item">
                                        <a
                                            className={`nav-link  ${instruction === 4 ? "active" : ""}`}
                                            onClick={() => setInstruction(4)}
                                            id="pills-contact-tab"
                                            data-toggle="pill"
                                            href="javascript:void(0)"
                                            role="tab"
                                            aria-controls="third"
                                            aria-selected="false"
                                        ></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* <div class="col-sm-3 d-flex justify-content-end">
                            {
                                ((instruction == 4) && (props.header.step4text)) ? <>
                                    <Link to={props.header.startLink}>
                                        <button className="bw-pre-assesment-question-next"> {t("user.activity.executive_function.start")} </button>
                                    </Link>
                                </> : <button class="ricontrolla visi-none" onClick={() => setInstruction(instruction + 1)}>{t("user.dashboard.instruction_step_next")}</button>}
                        </div> */}
                        <div class="col-sm-3 d-flex justify-content-end">
                            {
                                ((instruction == 4) && (props.header.step4text)) ? <>
                                    <Link to={props.header.startLink}>
                                        <button className="ricontrolla  font-18 mobile-bw-pre-assesment"> {t("user.activity.executive_function.start")} </button>
                                    </Link>
                                </> : <button class="ricontrolla font-18" onClick={() => setInstruction(instruction + 1)}>{t("user.dashboard.instruction_step_next")}</button>}
                        </div>
                    </div>
                </div ></>
        );
    }

    return (
        <Modal title="" size="" onClose={() => setModalIns1(false)} show={modalIns1}>
            {instPopUp()}
        </Modal>
    );
}
export default preAssessmentTutorialModal;