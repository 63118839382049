import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "./modal";
import closeIcon from '../../assets/Image/CLOSE_ICONA.png'
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const infoActivityModel = (props) => {
    const [modalIns1, setModalIns1] = useState(props.popUpEnable);
    const [t, i18n] = useTranslation("common");
 
    const closeModal = () => {
        setModalIns1(false);
    }

    const instPopUp = () => {
        return (
            <>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={props.header.icon} alt="icon" style={{ width: '50px' }} />
                            </div>
                            <strong>{t("user.cognitive_training.heading_1")} {props.header.day} / {props.header.modalHeading}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => { (props.handleModal) ? props.handleModal() : closeModal() }}
                        >
                            <img src={closeIcon} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body">
                        <strong dangerouslySetInnerHTML={{__html:props.header.modelTitle}}></strong>
                        <p className="info-model-description" dangerouslySetInnerHTML={{__html:props.header.modelDescription}}></p>

                    </div>
                    <div className="modal-footer" style={{ 'borderTop': 0 }}>
                        <div className="col-sm-3 d-flex justify-content-end p-0">
                            <Link to={props.header.startLink}>
                                <button className="btn btn-primary info-model-start"> {t("user.activity.executive_function.start")} </button>
                            </Link>

                        </div>
                    </div>
                </div></>
        );
    }






    return (
        <Modal title="" size="" onClose={() => setModalIns1(false)} show={modalIns1}>
            {instPopUp()}
        </Modal>
    )
}

export default infoActivityModel
