import React, { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/Image/logo.svg";
import DOCUMENTO_ICONA from "../../assets/Image/DOCUMENTO_ICONA.svg";
import SidebarProfile from "../admin/sidebarProfile";
import Sidebarmob from "../admin/sidebarmob";
import leftarrow from "../../assets/Image/leftarrow.svg";
import { useTranslation } from "react-i18next";
import { getLocalStoarge } from "../common/allfunction";

function riferimenti() {
  const [t, i18n] = useTranslation("common");
  const [scrollTop, setScrollTop] = useState(false);


  useEffect(() => {
    handleTranslationLang();
    scrollMoveTop();

  }, []);

  const scrollMoveTop = () => {
    if (!scrollTop) {
      window.scrollTo(0, 0);
      setScrollTop(true);
    }
  }
  const handleTranslationLang = () => {
    let lang = getLocalStoarge("langauage");
    if (lang) {
      i18n.changeLanguage(lang);
    }
  };
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <>
      <section className="bg-privacy riferimenti dashboard-mobile">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2 topspace d-lfti none-viewport ipad-widhset">
              <SidebarProfile />
            </div>
            <div className="col-md-10 ipd-righ">
              <div className="top-priv-static">
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="otp-heading m-bspaci mb-3 imposta referment-h max-wscience">
                      <Link to="/profile-menu">
                        <img src={leftarrow} className="deskt-n" alt="icon" />
                      </Link>
                      {t("user.referments.heading")}{" "}
                    </h2>
                  </div>
                  <div className="col-md-6 w40s">
                    <div className="static-left mtops">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: t("user.referments.heading_1"),
                        }}
                      ></p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: t("user.referments.heading_2"),
                        }}
                      ></p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: t("user.referments.heading_3"),
                        }}
                      ></p>
                    </div>
                  </div>

                  <div className="col-md-6 w40s mb-5">
                    <div className="bg-white-static">
                      <div className="d-flex">
                        <div className="icon_doc">
                          <Link
                            onClick={() =>
                              openInNewTab(
                                "https://doi.org/10.3389/fpsyg.2018.00098"
                              )
                            }
                          >
                            <img src={DOCUMENTO_ICONA} alt="icon" />
                          </Link>
                        </div>
                        <div className="align">
                          <h5>
                            <Link
                              onClick={() =>
                                openInNewTab(
                                  "https://doi.org/10.3389/fpsyg.2018.00098"
                                )
                              }
                            >
                              {t("user.referments.heading_4")}
                            </Link>
                          </h5>
                          <span>{t("user.referments.heading_5")}</span>{" "}
                        </div>
                      </div>
                    </div>

                    <div className="bg-white-static">
                      <div className="d-flex">
                        <div className="icon_doc">
                          <Link
                            onClick={() =>
                              openInNewTab(
                                "https://doi.org/10.1038/s41598-018-28804-0"
                              )
                            }
                          >
                            <img src={DOCUMENTO_ICONA} alt="icon" />
                          </Link>
                        </div>
                        <div className="align">
                          <h5>
                            <Link
                              onClick={() =>
                                openInNewTab(
                                  "https://doi.org/10.1038/s41598-018-28804-0"
                                )
                              }
                            >
                              {t("user.referments.heading_6")}
                            </Link>
                          </h5>
                          <span>{t("user.referments.heading_7")}</span>{" "}
                        </div>
                      </div>
                    </div>

                    <div className="bg-white-static">
                      <div className="d-flex">
                        <div className="icon_doc">
                          <Link
                            onClick={() =>
                              openInNewTab(
                                "https://doi.org/10.1007/s11906-019-0990-3"
                              )
                            }
                          >
                            <img src={DOCUMENTO_ICONA} alt="icon" />
                          </Link>
                        </div>
                        <div className="align">
                          <h5>
                            <Link
                              onClick={() =>
                                openInNewTab(
                                  "https://doi.org/10.1007/s11906-019-0990-3"
                                )
                              }
                            >
                              {t("user.referments.heading_8")}
                            </Link>
                          </h5>
                          <span>{t("user.referments.heading_9")}</span>
                        </div>
                      </div>
                    </div>

                    <div className="bg-white-static">
                      <div className="d-flex">
                        <div className="icon_doc">
                          <Link
                            onClick={() =>
                              openInNewTab(
                                "https://doi.org/10.1161/CIRCIMAGING.119.008857"
                              )
                            }
                          >
                            <img src={DOCUMENTO_ICONA} alt="icon" />
                          </Link>
                        </div>
                        <div className="align">
                          <h5>
                            <Link
                              onClick={() =>
                                openInNewTab(
                                  "https://doi.org/10.1161/CIRCIMAGING.119.008857"
                                )
                              }
                            >
                              {t("user.referments.heading_10")}
                            </Link>
                          </h5>
                          <span>{t("user.referments.heading_11")}</span>
                        </div>
                      </div>
                    </div>

                    <div className="bg-white-static">
                      <div className="d-flex">
                        <div className="icon_doc">
                          <Link
                            onClick={() =>
                              openInNewTab(
                                "https://www.nuralogix.ai/wp-content/uploads/2021/06/smartphone-based-identification-of-critical-levels-of-glycated-hemoglobin-a1c-using-transdermal-optical-imaging.pdf"
                              )
                            }
                          >
                            <img src={DOCUMENTO_ICONA} alt="icon" />
                          </Link>
                        </div>
                        <div className="align">
                          <h5>
                            <Link
                              onClick={() =>
                                openInNewTab(
                                  "https://www.nuralogix.ai/wp-content/uploads/2021/06/smartphone-based-identification-of-critical-levels-of-glycated-hemoglobin-a1c-using-transdermal-optical-imaging.pdf"
                                )
                              }
                            >
                              {t("user.referments.heading_14")}
                            </Link>
                          </h5>
                          <span>{t("user.referments.heading_15")}</span>
                        </div>
                      </div>
                    </div>
                    {/* <div className="bg-white-static">
                      <div className="d-flex">
                        <div className="icon_doc">
                        <Link onClick={() => openInNewTab("https://doi.org/10.1097/MBP.0000000000000467")}>
                          <img src={DOCUMENTO_ICONA} alt="icon" />
                          </Link>
                        </div>
                        <div className="align">
                          <h5>
                            <Link  onClick={() => openInNewTab("https://doi.org/10.1097/MBP.0000000000000467")}>
                          guideline accuracy criteria: Anura smartphone app with TOI technology
                          </Link>
                          </h5>
                          <p>
                          Deye Yang, Guomin Xiao, Jing Wei, Hong Luo
                          </p>
                        </div>
                      </div>
                    </div> */}
                    {/* <Link to='/my-profile'>
                  <button className="btn btn-primary Ho visi-none">Chiudi</button>
                 </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Sidebarmob />
    </>
  );
}

export default riferimenti;
