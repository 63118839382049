import React, { useEffect,useState } from "react";
import Group1080 from "../../../assets/Image/Group1080.svg";
import SidebarMob from "../../../components/admin/sidebarmob";
import NEXTICONA from "../../../assets/Image/NEXTICONA.svg";
import { Link } from "react-router-dom";
import progetto from "../../../assets/Image/progetto.svg";
import referimenti from "../../../assets/Image/referimenti.svg";
import privacypolicy from "../../../assets/Image/privacy-policy.svg";
import faqicon from "../../../assets/Image/faq-icon.svg";
import Logoutbox from "../../../assets/Image/Logoutbox.svg";
import { isMobile } from "react-device-detect";
import terms from "../../../assets/Image/Terms.svg";
import { useTranslation } from "react-i18next";

import {
  setLocalStoarge,
  getLocalStoarge,
  removeLocalStoarge1,
  Logout,
} from "../../../components/common/allfunction";

function profilemenuAdmin() {
  const [t, i18n] = useTranslation("common");

  const [theme, setTheme] = useState("light");
  const [toggleStatus, setToggleStatus] = useState(true);
  const [userProfile, setuserProfile] = useState("");
  const [showpic, setSHowPic] = useState("");

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
    setToggleStatus(theme === "light" ? true : false);
    setLocalStoarge(
      "theme",
      JSON.stringify(theme === "light" ? "dark" : "light")
    );
  };

  const getdata = () => {};
  const removeToken = () => {
    const logout = Logout();
    if (logout) {
      window.location.href = `/admin/signin/${localStorage.getItem('dynamic_id')}`;
      removeLocalStoarge1();
    } else {
      console.log("something was wrong");
    }
    
  };

  useEffect(() => {
    document.body.className = theme;
    let profiledata = getLocalStoarge("admin_profile");
    profiledata = JSON.parse(profiledata);
    setSHowPic(
      profiledata?.profile_pic ? profiledata.profile_pic : "default.png"
    );
    setuserProfile(profiledata?.user_name ? profiledata.user_name : "NO NAME");
    getdata();
  }, [theme]);

  useEffect(() => {
    const theme1 = JSON.parse(localStorage.getItem("theme"));
    setTheme(theme1);
    if (theme1) {
      // setToggleStatus(theme1 === "light" ? false : true);
    } else {
      setTheme("light");
      setToggleStatus(false);
    }
  },
   []);
  return (
    <>
      {isMobile ? (
        <>
          <section className="full_bg dashboard-mobile">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 p0 heightfull">
                  <div className="right-dashboard topspace profile-right">
                    <h2 className="heading-dash">{t('admin.profile_menu.heading')}</h2>
                    <p className="visi-none">{t('admin.profile_menu.heading_1')}</p>
                    <div className="row">
                      {/* <div className="profile_menu">
                        {toggleStatus == true && (
                          <input
                            type="checkbox"
                            className="checkbox"
                            id="checkbox"
                            defaultChecked
                            onChange={toggleTheme}
                          />
                        )}
                        {toggleStatus == false && (
                          <input
                            type="checkbox"
                            className="checkbox"
                            id="checkbox"
                            onChange={toggleTheme}
                          />
                        )}
                        <label htmlFor="checkbox" className="label">
                          <div className="icco">
                            <img
                              src={ICONA_LIGHT1}
                              className="img-fluid"
                              alt="logo"
                            />
                          </div>
                          <div className="moon">
                            <img
                              src={DARK_ICONA2}
                              className="img-fluid"
                              alt="logo"
                            />
                          </div>
                          <div className="anim-dari">
                            <div className="ball"></div>
                            <div className="light"></div>
                            <div className="dark"></div>
                          </div>
                        </label>
                      </div> */}
                      <div className="col-md-6 fulxs">
                        <div className="leftprofiles">
                          <div className="l-rflex">
                            <div className="profil_img">
                              <span className="circl-profile m18">
                                <img
                                  src={
                                    process.env.REACT_APP_BACKEND_BASE_URL +
                                    process.env.REACT_APP_STATIC_PATH +
                                    showpic
                                  }
                                  className="img-fluid"
                                  alt="img"
                                />
                              </span>
                              {/* <span className="name_user">{userProfile}</span> */}
                            </div>
                          </div>
                          <div className="list-ul">
                            <ul>
                            <li>
                                <Link to="/bi-steps">
                                  <img src={progetto} alt="icon" />
                                  {t("user.profile_menu.steps")}
                                  <img
                                    src={NEXTICONA}
                                    className="arow-bullet"
                                    alt="icon"
                                  />
                                </Link>
                              </li>
                              <li>
                                <Link to="/bi-privacy">
                                  <img src={privacypolicy} alt="icon" />
                                  {t('user.profile_menu.privacy')}
                                  <img
                                    src={NEXTICONA}
                                    className="arow-bullet"
                                    alt="icon"
                                  />
                                </Link>
                              </li>
                              <li>
                                <Link to="/bi-terms">
                                  <img src={terms} alt="icon" />
                                  {t('user.profile_menu.t&c')}
                                  <img
                                    src={NEXTICONA}
                                    className="arow-bullet"
                                    alt="icon"
                                  />
                                </Link>
                              </li>
                                     
                              <li>
                                <Link to="/scientific-references">
                                  <img src={referimenti} alt="icon" />
                                  {t('user.profile_menu.referments')}
                                  <img
                                    src={NEXTICONA}
                                    className="arow-bullet"
                                    alt="icon"
                                  />
                                </Link>
                              </li>
                              <li>
                                <Link to="/frequently-asked-questions">
                                  <img src={faqicon} alt="icon" />
                                  {t('user.profile_menu.FAQ')}
                                  <img
                                    src={NEXTICONA}
                                    className="arow-bullet"
                                    alt="icon"
                                  />
                                </Link>
                              </li>
                              <li>
                                <Link to="/my-profile">
                                  <img src={Group1080} alt="icon" />
                                  {t('user.profile_menu.profile')}
                                  <img
                                    src={NEXTICONA}
                                    className="arow-bullet"
                                    alt="icon"
                                  />
                                </Link>
                              </li>                         
                              <li onClick={removeToken}>
                                {/* <a href=""> */}
                                  <img src={Logoutbox} alt="icon" />
                                  {t('user.profile_menu.logout')}
                                  <img
                                    src={NEXTICONA}
                                    className="arow-bullet"
                                    alt="icon"
                                  />
                                {/* </a> */}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <SidebarMob />
        </>
      ) : (
        <div>This page is intented to view only on mobile.</div>
      )}
    </>
  );
}
export default profilemenuAdmin;
