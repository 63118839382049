import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import Super_Sidebar from "./super_sidebar";
import Super_Sidebarmob from "./super_sidebarmob";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from "react-bootstrap-table2-paginator";
import PostRequest from "../../apiConnection/postRequest";
import LazyLoader from "../../common/lazyLoader";
import Group698 from "../../../assets/Image/Group698.svg";
import VectorDown from "../../../assets/Image/VectorDown.svg";
import ExpandIcon from '../../../assets/Image/expand-icon.svg'
import '../super_admin_assets/scss/protocol.scss';
import GetRequest from "../../apiConnection/getRequest";
import NEXTICONA from "../../../assets/Image/NEXTICONA.svg"
import PRIVIEWICONA from '../../../assets/Image/PREVIEWICONA.svg'
import filterFactory from "react-bootstrap-table2-filter";
import { useTranslation } from "react-i18next";
import { getLocalStoarge, setLocalStoarge } from "../../common/allfunction";
import moment from "moment";


const User_Logs = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [t, i18n] = useTranslation("common");
    const [companiesData, setCompaniesData] = useState([])
    const { SearchBar } = Search;    
    const [userId, setUserId] = useState(props.match.params.id)      
    const [generalColumn, setGeneralColumn] = useState([{name: "", email: "", status : [], scanResult:"" ,time: ""}]);
    const [userName, setUserName] = useState()
    const [userEmail, setUserEmail] = useState()

    
    const actionButtons = (cell, row, rowIndex, formateExtraData) => {
        return (
            <div className="d-flex justify-content-center">
                <button className="table-action" ><img src={ExpandIcon} alt="icon" /></button>             
            </div>
        )
    }

    const getUseLogs = async () => {
        setIsLoading(true);
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const getAllDataResult = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_USER_LOGS}?userId=${userId}`    
                );

                if (getAllDataResult && getAllDataResult.status === 200) {
                    if (getAllDataResult.data.data?.length > 0) {
                        let arr = []
                        getAllDataResult.data.data.forEach((data) => {
                            arr = [...arr, {
                                user_id: data._id,
                                name: data.name,
                                email: data.email,
                                status: data.event,
                                scanResult:data.scanResult,
                                time : moment(data.gmtTime * 1000).format("DD/MM/YYYY, h:mm:ss A")

                            }]
                            setCompaniesData(arr)
                            setUserName(arr[0].name);
                            setUserEmail(arr[0].email);
                        })
                    }
                    setIsLoading(false);

                } else if (getAllDataResult && getAllDataResult.status === 203) {
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            console.log("error", error)
            setIsLoading(false);
        }
    }
    const handleTranslationLang = () => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
        setGeneralColumn([
            // {
            //     text: t("superadmin.wla.name"),
            //     dataField: "name",
            //     style:{cursor:"pointer"}
            // },
            // {
            //     text: t("superadmin.wla.email"),
            //     dataField: "email",
            //     align: "left",
               
            // },
            {
                text: t("superadmin.userLog.time"),
                dataField: "time",
            },
            {
                text: t("superadmin.userLog.scanStatus"),
                dataField: "status",
                formatter: (cell, row) => {
                    let arr = row.status;
                    return arr[arr.length-1].eventName;
                },
            },    
            {
                text: t("superadmin.userLog.scanResult"),
                dataField: "scanResult",
                formatter: (cell, row) => {
                    let arr = row.scanResult;
                    return <span className={ (arr=="Successfully scanned")? "text-success":'text-danger'}>{(arr == null) ? "-" : arr}</span>;
                },
            },         
            {
                text: t("superadmin.userLog.view"),
                dataField: "button",
                formatter: actionButtons,
                sort: true,
                headerAlign: "center"
            },
        ])        
    };
    const expandRow = {           
        renderer: (companiesData) => (
          <div>
            <table className="table table-bordered" style={{backgroundColor: (JSON.parse(getLocalStoarge("theme")) == "dark") ? "#313250" : "aliceblue"}}>
                            <thead>
                                <th>{t("superadmin.userLog.eventName")}</th>
                                <th>{t("superadmin.userLog.eventTime")}</th>
                            </thead>
                             {
                                Object.entries(companiesData.status).reverse().map((k,v)=>{
                                    return (                       
                                            <tr style={{fontSize: "14px"}}>
                                                <td>{k[1].eventName}</td>
                                                <td>{moment(k[1].eventTime * 1000).format("DD/MM/YYYY, h:mm:ss A")}</td>
                                            </tr>                       
                                        )
                                })             
                            }
            </table>

          </div>
        ),
      };
    useEffect(() => {   
        setIsLoading(true)
        getUseLogs()
        handleTranslationLang()
    }, []);
 
   
    const pageListRenderer = ({
        page,
        pages,
        active,
        disable,
        title,
        onPageChange
    }) => {
        const handleClick = (e) => {
            e.preventDefault();
            onPageChange(page);
        };
        const activeStyle = {};
        if (active) {
            activeStyle.backgroundColor = '#6d71f9';
            activeStyle.margin = "10px";
            activeStyle.padding = "5px 15px";
            activeStyle.color = 'white';
        } else {
            // activeStyle.backgroundColor = 'gray';
            activeStyle.margin = "10px";
            activeStyle.padding = "5px 15px";
            activeStyle.color = 'black';
        }
        if (typeof page === 'string') {
            activeStyle.backgroundColor = '';
            activeStyle.color = '#6d71f9';
        }
        return (
            <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12 d-flex justify-content-center pagination-list align-items-center" >
                {pages.map((p, i) => {
                    if (p.page === "<") return <div style={{margin:'15px', cursor:'pointer'}} key={i} onClick={(e) => onPageChange(p.page)}><img src={PRIVIEWICONA} alt="icon" /></div>
                    if (p.page === ">") return <div style={{margin:'15px', cursor:'pointer'}} key={i} onClick={(e) => onPageChange(p.page)}><img src={NEXTICONA} alt="icon" /></div>
                    if (p.active) return <div style={{margin:'15px', cursor:'pointer'}} key={i} className="active" onClick={(e) => onPageChange(p.page)}>{p.page}</div>
                    return (
                        <div key={i} style={{margin:'15px', cursor:'pointer'}} onClick={(e) => onPageChange(p.page)}>{p.page}</div>
                    )
                })}
            
            </div>
        );
    };
    let paginationOption = {
        paginationSize: 3,
        sizePerPage: 10,
        hideSizePerPage: true,
        withFirstAndLast: false,
        pageListRenderer
    }



    return (

        <>           
            <section className="full_bg dashboard-mobile">
                <div className="container-fluid">
                    <div className="row">
                        <Super_Sidebar path="protocol" />
                        <div className="col-md-10 pr-0 p0 ipd-righ">
                            <div className="right-dashboard topspace">
                                <div className="d-flex justify-content-between protocollo-space row">
                                    <div className="col-12 col-sm-8">
                                    <h2 className="heading-dash" >{t("superadmin.userLog.userlog")}</h2>
                                    </div>
                                 
                                </div>
                                {(!isLoading) ? (companiesData.length > 0 && companiesData) ? <>
                                    <ToolkitProvider
                                        keyField="user_id"
                                        key={'datatable'}
                                        data={companiesData}
                                        columns={generalColumn}
                                        search
                                    >{(props) => (

                                        <div id="test" className="table  ricsb ricsb-new protocolli-data">
                                            <div>                                                
                                                <div>
                                                    {/* <div className="searchbar d-flex justify-content-start m-2 position-relative">
                                                        <img
                                                            src={Group698}
                                                            className="search_icon"
                                                            alt="icon"
                                                        />
                                                        <SearchBar
                                                            placeholder={t("superadmin.protocol.search")}
                                                            {...props.searchProps}
                                                        />
                                                    </div> */}
                                                    <span className="user-log-text"><strong>Name: </strong>{userName}</span><br/>
                                                    <span className="user-log-text mb-1"><strong>Email: </strong>{userEmail}</span>
                                                    <BootstrapTable
                                                        src={VectorDown} alt="icon"
                                                        data={companiesData}
                                                        {...props.baseProps}
                                                        filter={filterFactory()}
                                                        pagination={paginationFactory(paginationOption)}
                                                        wrapperClasses={'table-responsive'}
                                                        expandRow={expandRow}
                                                            
                                                    />
                                                    
                                                </div>
                                            </div>
                                        </div>

                                    )}
                                    </ToolkitProvider>
                                </> : 
                                
                                    <div className="card p-3 col-md-6">
                                    <div className="bg-light-purple card-header collapsed" data-toggle="collapse" aria-expanded="true" ><a className="card-title">{t('superadmin.userLog.nouserlog')}</a>
                                    </div>
                                   </div>
                                   : null
                                }
                            </div>
                        </div>
                    </div>
                    {(isLoading) ? <LazyLoader /> : null}
                </div>
            </section>
            <Super_Sidebarmob />
        </>
    );
}

export default User_Logs;


