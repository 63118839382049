import { useEffect, useState } from "react";
import { Walktour } from "walktour";
import shape from "../../assets/Image/shape.svg";
import heart from "../../assets/Image/heart.svg";
import iconLungs from "../../assets/Image/iconLungs.svg";
import mitter from "../../assets/Image/mitter.svg";
import outlineHeart from "../../assets/Image/outlineHeart.svg";
import wave from "../../assets/Image/wave.svg";
import carico from "../../assets/Image/carico.svg";
import capacita from "../../assets/Image/capacita.svg";
import stress from "../../assets/Image/stress.svg";
import Group911 from "../../assets/Image/Group911.svg";
import Group938 from "../../assets/Image/Group938.svg";
import massacorp from "../../assets/Image/massacorp.svg";
import Stressmentale from "../../assets/Image/STRESS_black.svg";
import cardiovascular from "../../assets/Image/cardiovascular.svg";
import Group846545011 from "../../assets/Image/Group846545011.svg";
import INFO_iconi from "../../assets/Image/INFO_iconi.svg";
import ATTACCOCARDIACOICONA from "../../assets/Image/ATTACCOCARDIACOICONA.svg";
import ICTUSICONA from "../../assets/Image/ICTUSICONA.svg";
import Group728 from "../../assets/Image/Group728.svg";
import GLICEMIAICONA from "../../assets/Image/GLICEMIAICONA.svg";
import Grouprishico from "../../assets/Image/Grouprishico.svg";
import PASSWORD_ICONA from '../../assets/Image/PASSWORD_ICONA.svg';
import Group_1175PP from '../../assets/Image/Group_1175PP.png';
import Group_1175UI from '../../assets/Image/Group_1175UI.png';
import Group_1175AND from '../../assets/Image/GroupUnlock101023.png';
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import Indicedimassacorporea1 from "../../assets/Image/Indice-di-massa-corporea1.svg";
import Group769 from "../../assets/Image/Group769.svg";
import FISIOLOGICIICONA from "../../assets/Image/FISIOLOGICIICONA.svg";
import Group784 from "../../assets/Image/Group784.svg";
import Group792 from "../../assets/Image/Group792.svg";
import Group785 from "../../assets/Image/Group785.svg";
import Rischiodiictus from "../../assets/Image/Rischio-di-ictus.svg";
import Group781 from "../../assets/Image/Group781.svg";
import DIABETE from "../../assets/Image/DIABETE_ICONA1.svg";
import Diabetebigpopup from "../../assets/Image/Diabetebigpopup.svg";
import Group849 from "../../assets/Image/Group849.svg";
import Variabilitadel from "../../assets/Image/Variabilitadel-icon.svg";
import FATICAMENTALE_ICONA from "../../assets/Image/FATICAMENTALE_ICONA.svg";
import DEPRESSIONE_ICONA from "../../assets/Image/DEPRESSIONE_ICONA .svg";
import ANSIA_ICONA from "../../assets/Image/ANSIA_ICONA .svg";
import Sidebar from "../common/sidebar";
import Sidebarmob from "../common/sidebarMobile";
import { FaEllipsisH } from "react-icons/fa";
import { HiOutlineInformationCircle } from "react-icons/hi";
import ReactApexChart from "react-apexcharts";
import Modal from "../common/modal";
import Group1028 from "../../assets/Image/Group1028.svg";
import Group847 from "../../assets/Image/Group847.svg";
import Group846 from "../../assets/Image/Group846.svg";
import { Link } from "react-router-dom";
import PostRequest from "../apiConnection/postRequest";
import LazyLoader from "../common/lazyLoader";
import Group1029 from "../../assets/Image/Group102912.svg";
import Group1145 from "../../assets/Image/cardiac.svg";
import Group1030 from "../../assets/Image/Group1030.svg";
import Group1146 from "../../assets/Image/Group1146.svg";
import Group1466101 from "../../assets/Image/Group1466101.svg";
import Group1465101 from "../../assets/Image/Group1465101.svg";
import Group1464101 from "../../assets/Image/Group1464101.svg";
import Group1463101 from "../../assets/Image/Group1463101.svg";
import Group1462101 from "../../assets/Image/Group1462101.svg";
import Group1179 from "../../assets/Image/Group117979.svg";
import Group9771 from "../../assets/Image/Group9771.svg";
import Group9412 from "../../assets/Image/Group9412.svg";
import Group911110 from "../../assets/Image/Group911110.svg";
import Group9416 from "../../assets/Image/Group9416.svg";
import Group9417 from "../../assets/Image/Group9417.svg";
import Group9414 from "../../assets/Image/Group9414.svg";
import Group9415 from "../../assets/Image/Group9415.svg";
import Group9418 from "../../assets/Image/Group9418.svg";
import Group_8417 from "../../assets/Image/Group_8417.svg";
import Group_8418 from "../../assets/Image/Group_8418.svg";
import Group_8412 from "../../assets/Image/Group_8412.svg";
import Group_8419 from "../../assets/Image/Group_8419.svg";
import Group_8420 from "../../assets/Image/Group_8420.svg";
import Group_8421 from "../../assets/Image/Group_8421.svg";
import Group_8414 from "../../assets/Image/Group_8414.svg";
import { setLocalStoarge, getLocalStoarge, getQuestionairs, removeLocalStoarge, trainingData } from "../common/allfunction";
import { useTranslation } from "react-i18next";
import BrainWellness from "./brainWellNess";
import InstructionModal from "../common/instructionModal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Istruzioni from '../../assets/Image/INFO_icon.svg';
import GetRequest from "../apiConnection/getRequest";

const CommonDashboard = (props) => {
	let num = Math.floor(Math.random() * 10);
	const [cognitiveReport, setCognitiveReport] = useState(false);
	const [mindFullnessReport, setMindFullnessReport] = useState(false);
	const [seriesStraightDatax, setSeriesStraightDatax] = useState([]);
	const [seriesStraightDatay, setSeriesStraightDatay] = useState([]);
	const [seriesbardata, setseriesbardata] = useState([]);
	const [barChartDatay, setBarChartDatay] = useState([]);
	const [seriesAreadatay, setSeriesAreadatay] = useState([]);
	const [activebg, setActivebg] = useState("active");
	const [activeContentType, setActiveContentType] = useState("dashboard");
	const [userStatus, setUserStatus] = useState();

	const [errorfetch, setErrorfetch] = useState(
		props.match.params?.errorfetch && props.match.params?.errorfetch !== ""
			? props.match.params?.errorfetch
			: ""
	);
	const [themeColor, setThemeColor] = useState(getLocalStoarge("theme"))
	const [graphColor, setGraphColor] = useState('#272848');
	const path = window.location.pathname;
	const [t, i18n] = useTranslation("common");
	const seriesStraight = [
		{
			name: "Variabilità battito cardiaco",
			data: seriesStraightDatay,
		},
	],
		optionsStraight = {
			chart: {
				height: 130,
				width: 160,
				zoom: {
					enabled: false,
				},
				toolbar: {
					show: false,
					tools: {
						download: false,
					},
				},
				background: "none",
				opacity: 1,
			},
			stroke: {
				curve: "straight",
				width: "3",
			},
			xaxis: {
				categories: seriesStraightDatax,
				show: false,
				labels: {
					show: false,
				},
			},

			yaxis: {
				show: false,
				labels: {
					show: false,
				},
			},
			colors: [graphColor],
			grid: {
				show: false,
				borderColor: "#90A4AE",
				strokeDashArray: 0,
				position: "back",
				xaxis: {
					lines: {
						show: false,
					},
				},
				yaxis: {
					lines: {
						show: false,
					},
				},
				row: {
					colors: undefined,
					opacity: 0.5,
				},
				column: {
					colors: undefined,
					opacity: 0.5,
				},
				padding: {
					top: 0,
					right: 0,
					bottom: 0,
					left: 0,
				},
			},
		};
	const seriesbar = [
		{
			name: "",
			data: seriesbardata,
		},
	],
		optionsbar = {
			chart: {
				type: "bar",
				height: 130,
				width: 400,
				zoom: {
					enabled: false,
				},
				toolbar: {
					show: false,
					tools: {
						download: false,
					},
				},
			},
			plotOptions: {
				bar: {
					borderRadius: 1,
					horizontal: true,
					barHeight: "5%",
				},
			},
			dataLabels: {
				enabled: false,
			},
			xaxis: {
				categories: [
					t("user.dashboard.overloaded"),
					t("user.dashboard.warden"),
					t("user.dashboard.productive"),
					t("user.dashboard.relaxed"),
					t("user.dashboard.exremely_relaxed")
				],
			},
			yaxis: {
				max: 5,
			},

			colors: ["#272848"],
			grid: {
				show: false,
				borderColor: "#90A4AE",
				strokeDashArray: 0,
				position: "back",
				xaxis: {
					lines: {
						show: false,
					},
				},
				yaxis: {
					lines: {
						show: false,
					},
				},
				row: {
					colors: undefined,
					opacity: 0.5,
				},
				column: {
					colors: undefined,
					opacity: 0.5,
				},
				padding: {
					top: 0,
					right: 0,
					bottom: 0,
					left: 0,
				},
			},
		};

	const seriesLine = [
		{
			name: "Carico cardiaco",
			data: barChartDatay,
		},
	],
		optionsLine = {
			chart: {
				height: 130,
				width: 160,
				type: "bar",
				zoom: {
					enabled: false,
				},
				toolbar: {
					show: false,
					tools: {
						download: false,
					},
				},
			},
			stroke: {
				width: "2",
			},
			xaxis: {
				categories: seriesStraightDatax,
				show: false,
				labels: {
					show: false,
				},
			},

			plotOptions: {
				bar: {
					columnWidth: "15%",
					distributed: true,
				},
			},
			dataLabels: {
				enabled: false,
			},
			legend: {
				show: false,
			},
			yaxis: {
				show: false,
				labels: {
					show: false,
				},
			},
			colors: [graphColor],
			grid: {
				show: false,
				xaxis: {
					lines: {
						show: true,
					},
				},
				yaxis: {
					lines: {
						show: true,
					},
				},
			},
		};
	const seriesArea = [
		{
			name: "capacita vascolare",
			data: seriesAreadatay,
		},
	],
		optionsArea = {
			chart: {
				height: 130,
				width: 160,
				type: "area",
				zoom: {
					enabled: false,
				},
				toolbar: {
					show: false,
					tools: {
						download: false,
					},
				},
				background: "none",
				opacity: 1,
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				curve: "smooth",
			},
			xaxis: {
				categories: seriesStraightDatax,
				show: false,
				labels: {
					show: false,
				},
			},
			yaxis: {
				show: false,
				labels: {
					show: false,
				},
			},
			colors: [graphColor],
			grid: {
				show: false,
				borderColor: "#90A4AE",
				strokeDashArray: 0,
				position: "back",
				xaxis: {
					lines: {
						show: true,
					},
				},
				yaxis: {
					lines: {
						show: false,
					},
				},
				row: {
					colors: undefined,
					opacity: 0.5,
				},
				column: {
					colors: undefined,
					opacity: 0.5,
				},
				padding: {
					top: 0,
					right: 0,
					bottom: 0,
					left: 0,
				},
			},
		};
	const [isLoading, setIsLoading] = useState(false);
	const [modal1, setModal1] = useState();
	const [modal2, setModal2] = useState();
	const [modal3, setModal3] = useState();
	const [modal4, setModal4] = useState();
	const [modal5, setModal5] = useState();
	const [modal6, setModal6] = useState();
	const [modal7, setModal7] = useState();
	const [modal8, setModal8] = useState();
	const [modal9, setModal9] = useState();
	const [modal10, setModal10] = useState();
	const [modal11, setModal11] = useState();
	const [modal12, setModal12] = useState();
	const [modal13, setModal13] = useState();
	const [modal14, setModal14] = useState();
	const [modal15, setModal15] = useState();
	const [modal16, setModal16] = useState();
	const [modal17, setModal17] = useState();
	const [modal18, setModal18] = useState();
	const [modal19, setModal19] = useState();
	const [modal20, setModal20] = useState();

	const [detail, setDetail] = useState("");
	const [loading, setLoading] = useState(true);
	const [tableData, settableData] = useState("");
	const [gender, setGender] = useState("");
	const [popupVisiable, setPopupVisiable] = useState(false);
	const [token, setToken] = useState(() => {
		// getting stored value
		const saved = localStorage.getItem("R1");
		const initialValue = JSON.parse(saved);
		return initialValue.access_token || "";
	});
	const [healthScore, setHealthScore] = useState("");

	const [personalizeDetail, setPersonalizeDetails] = useState({});
	const [personalText, setPersonalText] = useState("");
	const [trainingData1, setTrainigData] = useState([]);

	const [healthRecord, setHealthRecord] = useState([]);
	const [activityProgress, setActivityProgress] = useState([]);
	const [wellbeingRecord, setWellbeingRecord] = useState([]);
	const [brainWelnessRecord, setBrainWelnessRecord] = useState([]);
	const [mindfulProgress, setMindfulProgress] = useState(0);
	const [cognitiveProgress, setCognitiveProgress] = useState(0);
	const [mindfulFilter, setMindfulFilter] = useState(0);
	const [cognitiveFilter, setCognitiveFilter] = useState(0);
	const [goNoGo, setGONOGO] = useState(0);
	const [nBack, setNBACK] = useState(0);
	const [userActivity, setActivityData] = useState();
	const [activeTab, setActiveTab] = useState("dashboard"); // Initial active tab
	const [trainingModule, setTrainingModule] = useState(0);
	const [brainwellnessModule, setBrainwellnessModule] = useState(0);
	const handleTabClick = (tab) => {
		setActiveTab(tab);
		if (tab == 'brainwellness') {
			getHealthData();
			getWellbeingData();
			getMindfullData();
			geActivityProgressData();
		}
		// You can add additional logic here if needed
	};
	const [language, setLanguage] = useState(null);

	const [modal21, setModal21] = useState();
	const [modal22, setModal22] = useState();
	const [todayMeasurement, setTodayMeasurement] = useState();
	const pageLoad = () => {
		setIsLoading(true);
		getQuestionairs();
	}
	const { data, status, isLoadingCheck } = useSelector((state) => {
		return state.repetition
	})
	const cognitiveStep = getLocalStoarge("cognitiveStep");
	const [scanLimit, setScanLimit] = useState(getLocalStoarge("scanLimitOver"));


	const openDashboard = () => {
		window.location.href = "/app/dashboard";
	}
	const openBrainWellness = () => {
		window.location.href = `/app/common-brainwellness/${cognitiveStep}`;
	}
	const openTraining = () => {
		window.location.href = "/app/training";
	}
	const callBack = async (data) => {

		const refreshToken = await PostRequest(
			`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
			{},
			token,
			"refresh_token"
		);
		if (refreshToken) {
			if (data.prefix == 'cognitive') {
				const payload = {
					"company_id": getLocalStoarge('company_id'),
					"week": data.week,
					"month": data.month,
					"date": data.date
				};
				setIsLoading(true);
				try {
					const getBrainWell = await PostRequest(
						`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_BRAINWELLNESS_CG_MEDAL}`,
						payload,
						token,
						""
					);

					if (getBrainWell && getBrainWell.status === 200) {
						setIsLoading(false);
						setCognitiveFilter(getBrainWell.data.data.cognitiveProgressByFilter);
					} else {
						setIsLoading(false);
						setLoading(false);
					}

				} catch (error) {
					setIsLoading(false);
				}
			}
			else if (data.prefix == 'mindfulness') {
				const payload = {
					"company_id": getLocalStoarge('company_id'),
					"week": data.week,
					"month": data.month,
					"date": data.date
				};
				setIsLoading(true);
				try {
					const getBrainWell = await PostRequest(
						`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_BRAINWELLNESS_ML_MEDAL}`,
						payload,
						token,
						""
					);

					if (getBrainWell && getBrainWell.status === 200) {
						setIsLoading(false);
						setMindfulFilter(getBrainWell.data.data.mindFullnessProgressByFilter);
					} else {
						setIsLoading(false);
						setLoading(false);
					}

				} catch (error) {
					setIsLoading(false);
				}
			}

		} else {
			setIsLoading(false);
			setLoading(false);
		}
	}
	const getAllData = async () => {
		const payload = {};
		setIsLoading(true);
		try {
			const refreshToken = await PostRequest(
				`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
				{},
				token,
				"refresh_token"
			);
			if (refreshToken) {
				const getAllDataResult = await PostRequest(
					`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_USER_LAST_HEALTH_DATA}`,
					payload,
					token,
					""
				);

				if (getAllDataResult && getAllDataResult.status === 200) {
					if (localStorage.getItem('showInsPopup') == 'true') {
						setPopupVisiable(true);
						localStorage.setItem('showInsPopup', false);

					}
					getDashboardData();
					const alldata = getAllDataResult.data.data;

					const getGender =
						getAllDataResult.data.data.personalizeDetails.gender;
					setLocalStoarge("health_data", JSON.stringify(alldata.latestData))
					setLocalStoarge("measurment_data", alldata.todaysEntry);
					setTodayMeasurement(alldata.todaysEntry);
					setGender(getGender);
					const personalizeDetails =
						getAllDataResult.data.data.personalizeDetails;
					let personalizeArray = [
						"physiological",
						"mental",
						"physicists",
						"general_risks",
						"metabolic_risks",
						"measurement_of_psychological_values",
					];
					let check = true;
					personalizeArray.forEach((element) => {
						if (check) {
							let pageName = path.substring(path.lastIndexOf("/") + 1);
							if ((pageName == "anxietyAndDepression")) {
								setPersonalText("measurement_of_psychological_values");
								check = false;

							}
							else if (personalizeDetails[element] == 1) {
								setPersonalText(element);
								check = false;
							}
						}
					});
					setPersonalizeDetails(personalizeDetails);
					setDetail((alldata.todaysEntry = "0" ? alldata.latestData : "-"));
					setHealthScore((alldata?.latestData.HEALTH_SCORE) ? alldata?.latestData.HEALTH_SCORE : "80")
					getProgramStatus()

					var seriesbar = [];
					var msi = parseFloat(alldata.latestData.MSI).toFixed(0);
					for (var j = 1; j <= 5; j++) {
						seriesbar.push(j == msi ? msi : 0);
					}
					setseriesbardata(seriesbar.reverse());
					setLoading(false);

				} else {
					setIsLoading(false);
					setLoading(false);

				}
			} else {
				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false);
		}
	};


	const getProgramStatus = async () => {
		setIsLoading(true);
		try {
			// const refreshToken = await PostRequest(
			//   `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
			//   {},
			//   token,
			//   "refresh_token"
			// );
			// if (refreshToken) {
			// console.log("URL",process.env.REACT_APP_USER_PROGRAM_STATUS,`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_USER_PROGRAM_STATUS}?user_id=${getLocalStoarge("user_id")}`)
			const getResult = await GetRequest(
				`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_USER_PROGRAM_STATUS}?user_id=${getLocalStoarge("user_id")}`,
			);
			if (getResult && getResult.status === 200) {
				const alldata = getResult.data.data;
				setUserStatus(alldata);
				setLoading(false);
			} else {
				setIsLoading(false);
				setLoading(false);
			}
			// } else {
			//   setIsLoading(false);
			// }
		} catch (error) {
			setIsLoading(false);
		}
	};


	const getDashboardData = async () => {
		if (token && token !== null) {
			const payload = {};
			try {

				const getDashboard_Data = await PostRequest(
					`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DASHBOARD_API}`,
					payload,
					token,
					""
				);
				if (getDashboard_Data && getDashboard_Data.status === 200) {
					const alldata = getDashboard_Data.data.data;
					settableData(alldata);
					var chartarrx = [];
					var chartarry = [];
					var barchart = [];
					var cvdRiskchart = [];
					for (var i = 0; i < alldata.length; i++) {
						var x = formatDate(alldata[i].time_stamp * 1000);
						var y = alldata[i].HRV_SDNN;
						var cardiac = alldata[i].BP_RPP;
						var cvd = alldata[i].BP_TAU;
						if (y !== undefined) chartarry.push(y);
						if (x !== undefined) chartarrx.push(x);
						if (cardiac !== undefined) barchart.push(cardiac);
						if (cvd !== undefined) cvdRiskchart.push(cvd);
					}
					setSeriesStraightDatax(chartarrx.reverse());
					setSeriesStraightDatay(chartarry.reverse());
					setBarChartDatay(barchart.reverse());
					setSeriesAreadatay(cvdRiskchart.reverse());

					setIsLoading(false);
				} else {
					setIsLoading(false);

				}

			} catch (error) {
				setIsLoading(false);
			}
		}
	};

	const formatDate = (date) => {
		var d = new Date(date),
			month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;
		return [day, month, year].join(".");
	};
	const handleTranslationLang = () => {
		let lang = getLocalStoarge("langauage");
		setLanguage(lang);
		if (lang) {
			i18n.changeLanguage(lang);
		}
	};
	useEffect(() => {
		handleTranslationLang();
		getAllData();
		setBrainwellnessModule(getLocalStoarge('brainwellnessModule'));
		setTrainingModule(getLocalStoarge('trainingModule'));
		setTimeout(() => {


		}, 2000)
		// getProgramStatus()
		const data = trainingData();

		if (data) {
			setTrainigData(data);
		}
		setActivityData(data[0]);
		if (errorfetch === "error") {
			setTimeout(() => {
				setErrorfetch("");
				window.location = "../common-dashboard";
				localStorage.setItem('showInsPopup', true);
			}, 10000);
		}

	}, []);
	const getHealthData = async () => {
		const payload = {};
		setIsLoading(true);
		try {
			// console.log('token', token);
			const getAllDataResult = await GetRequest(
				`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_USER_HEALTH_DATA_WELLBEING_MEDAL}`,
				payload,
				token,
				""
			);

			if (getAllDataResult && getAllDataResult.status === 200) {
				// console.log('count data brainWellness')
				// console.log(Object.keys(getAllDataResult.data.data).length);
				setHealthRecord(getAllDataResult.data.data);
				setLoading(false);

			} else {
				setIsLoading(false);
				setLoading(false);

			}

		} catch (error) {
			setIsLoading(false);
		}
	};
	const getWellbeingData = async () => {
		const payload = {
			"company_id": getLocalStoarge('company_id')
		};
		setIsLoading(true);
		try {

			const getWellbeingRecord = await PostRequest(
				`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_WELLBEING_DATA_MEDAL}`,
				payload,
				token,
				""
			);

			if (getWellbeingRecord && getWellbeingRecord.status === 200) {
				setWellbeingRecord(getWellbeingRecord.data.data);

			} else {
				setIsLoading(false);
				setLoading(false);

			}

		} catch (error) {
			setIsLoading(false);
		}
	};
	const getMindfullData = async () => {
		const payload = {
			"company_id": getLocalStoarge('company_id'),
			"component_id": 1
		};
		setIsLoading(true);
		try {
			const getBrainWell = await PostRequest(
				`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_brainWellness_DATA_MEDAL}`,
				payload,
				token,
				""
			);

			if (getBrainWell && getBrainWell.status === 200) {
				setBrainWelnessRecord(getBrainWell.data.data);
				// setMindfulProgress(getBrainWell.data.data.mindfulProgress*100/30);
				setCognitiveReport(getBrainWell.data.data.cognitiveReport);
				setMindFullnessReport(getBrainWell.data.data.mindFullnessReport);
				// console.log("data",getBrainWell.data.data)

			} else {
				setIsLoading(false);
				setLoading(false);

			}

		} catch (error) {
			setIsLoading(false);
		}
	};
	const geActivityProgressData = async () => {
		const payload = {
			"company_id": getLocalStoarge('company_id')
		};
		setIsLoading(true);
		try {
			// console.log('token', token);
			const getAllDataResult = await PostRequest(
				`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_BRAINWELLNESS_PROGRESS_BAR}`,
				payload,
				token,
				""
			);

			if (getAllDataResult && getAllDataResult.status === 200) {
				setActivityProgress(getAllDataResult.data.data);
				setMindfulProgress(getAllDataResult.data.data.mindFullnessProgress);
				let cRec = Math.ceil(getAllDataResult.data.data.cognitiveProgress);
				setCognitiveProgress(cRec);
				setGONOGO(getAllDataResult.data.data.goNoGo)
				setNBACK(getAllDataResult.data.data.nBack)
				setLoading(false);

			} else {
				setIsLoading(false);
				setLoading(false);

			}

		} catch (error) {
			setIsLoading(false);
		}
	};
	useEffect(() => {

		setGraphColor(JSON.parse(themeColor) == "dark" ? "#fff" : "#272848");
		setInterval(() => {
			if (JSON.parse(themeColor) == JSON.parse(getLocalStoarge('theme'))) return
		}, 3000)
		if (activeContentType == "dashboard") {
			const callApi = setTimeout(() => {
			}, 2000)
			return () => clearTimeout(callApi)
		}
	}, [])

	useEffect(() => {
		if (activeContentType == "brainwellness") {
			const callApi = setTimeout(() => {
				// console.log("brainwedllness");
			}, 2000)
			return () => clearTimeout(callApi)
		}
	}, [])

	const modal1Content = () => {
		return (
			<div className="model-pop ">
				<div className="modal-header">
					<div className="modal-title pop_heading" id="exampleModalLabel">
						<div className="in-linw-w ico-bg pop-up-cmd">
							<div className="wellbeing-icon"></div>
						</div>
						<p className="cmd-pop-up pop-up-cmd-txt">{t("user.dashboard.wellbeing_heading")}</p>
					</div>
					<button
						type="button"
						className="close r0-side"
						onClick={() => setModal1(false)}
					>
						<img src={Group769} alt="icon" />
					</button>
				</div>
				<div className="modal-body scroll-pop">
					<div className="row">
						<div className="col-md-12 justify">
							<p className="cmd-pop-up-text"
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.cmd-text-one"),
								}}
							></p>
						</div>
					</div>
				</div>
			</div>
		);
	};
	const modal2Content = () => {
		return (
			<div>
				<div className="model-pop">
					<div className="modal-header">
						<div className="modal-title pop_heading" id="exampleModalLabel">
							<div className="in-linw-w ico-bg pop-up-cmd">
								<div className="brain-bg"></div>
							</div>
							<p className="cmd-pop-up pop-up-cmd-txt">{t("user.dashboard.brain_wellness")}</p>
						</div>
						<button
							type="button"
							className="close r0-side"
							onClick={() => setModal2(false)}
						>
							<img src={Group769} alt="icon" />
						</button>
					</div>
					<div className="modal-body scroll-pop">
						<div className="row">
							<div className="col-md-12 justify">
								<p className="cmd-pop-up-text"
									dangerouslySetInnerHTML={{
										__html: t("user.dashboard.cmd-text-two"),
									}}
								></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
	const modal3Content = () => {
		return (
			<div>
				<div className="model-pop">
					<div className="modal-header">
						<div className="modal-title pop_heading" id="exampleModalLabel">
							<div className="in-linw-w ico-bg pop-up-cmd">
								<div className="literacy-icon"></div>
							</div>
							<p className="cmd-pop-up pop-up-cmd-txt">{t("user.dashboard.wellbeing_literacy")}</p>
						</div>
						<button
							type="button"
							className="close r0-side"
							onClick={() => {
								setModal3(false);
							}}
						>
							<img src={Group769} alt="icon" />
						</button>
					</div>
					<div className="modal-body scroll-pop">
						<div className="row">
							<div className="col-md-12 justify">
								<p className="cmd-pop-up-text"
									dangerouslySetInnerHTML={{
										__html: t("user.dashboard.cmd-text-three"),
									}}
								></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
	const modal4Content = () => {
		return (
			<div className="model-pop">
				<div className="modal-header">
					<h5 className="modal-title pop_heading" id="exampleModalLabel">
						<div className="in-linw-w">
							<img src={Group_8412} className="img-fluid" alt="icon" />
						</div>
						<strong>{t("user.dashboard.heading_4")}</strong>
					</h5>
					<button
						type="button"
						className="close r0-side"
						onClick={() => setModal4(false)}
					>
						<img src={Group769} alt="icon" />
					</button>
				</div>
				<div className="modal-body scroll-pop">
					<div className="row">
						<div className="mar-b">
							<div className="col-md-7 r-linerigti">
								<p
									dangerouslySetInnerHTML={{
										__html: t("user.dashboard.text_4_1"),
									}}
								></p>
								<p
									dangerouslySetInnerHTML={{
										__html: t("user.dashboard.text_4_2"),
									}}
								></p>
							</div>
							<div className="col-md-5 d-flex align-items-center gr-per">
								<img src={Group1029} className="w-100" alt="icon" />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
	const modal5Content = () => {
		return (
			<div className="model-pop">
				<div className="modal-header">
					<h5 className="modal-title pop_heading" id="exampleModalLabel">
						<div className="in-linw-w">
							<img src={Group_8419} alt="icon" />{" "}
						</div>
						<strong>{t("user.dashboard.heading_5")}</strong>
					</h5>
					<button
						type="button"
						className="close r0-side"
						onClick={() => setModal5(false)}
					>
						<img src={Group769} alt="icon" />
					</button>
				</div>
				<div className="modal-body scroll-pop">
					<div className="mar-b">
						<div className="col-md-7 r-linerigti">
							<p
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.text_5_1"),
								}}
							></p>
							<p>{t("user.dashboard.text_5_2")}</p>
							<p
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.text_5_3"),
								}}
							></p>
						</div>
						<div className="col-md-5 d-flex align-items-center gr-per">
							<img src={Group1145} className="w-100" alt="icon" />
						</div>
					</div>
				</div>
			</div>
		);
	};
	const modal6Content = () => {
		return (
			<div className="model-pop">
				<div className="modal-header">
					<h5 className="modal-title pop_heading" id="exampleModalLabel">
						<div className="in-linw-w">
							<img src={Group_8420} alt="icon" />
						</div>
						<strong>{t("user.dashboard.heading_6")}</strong>
					</h5>
					<button
						type="button"
						className="close r0-side"
						onClick={() => setModal6(false)}
					>
						<img src={Group769} alt="icon" />
					</button>
				</div>
				<div className="modal-body scroll-pop">
					<div className="row">
						<div className="col-md-12">
							<p className="font16"></p>
							<p
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.text_6_1"),
								}}
							></p>
							<p
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.text_6_2"),
								}}
							></p>
						</div>
					</div>
				</div>
			</div>
		);
	};
	const modal7Content = () => {
		return (
			<div className="model-pop">
				<div className="modal-header">
					<h5 className="modal-title pop_heading" id="exampleModalLabel">
						<div className="in-linw-w">
							<img src={Group846545011} alt="icon" />
						</div>
						<strong>{t("user.dashboard.heading_7")}</strong>
					</h5>
					<button
						type="button"
						className="close r0-side"
						onClick={() => setModal7(false)}
					>
						<img src={Group769} alt="icon" />
					</button>
				</div>
				<div className="modal-body scroll-pop">
					<div className="row">
						<div className="mar-b">
							<div className="col-md-7 r-linerigti">
								<p
									dangerouslySetInnerHTML={{
										__html: t("user.dashboard.text_7_1"),
									}}
								></p>
								<span className="color-pe">{t("user.dashboard.text_7_2")}</span>
								<br />
								<p
									dangerouslySetInnerHTML={{
										__html: t("user.dashboard.text_7_3"),
									}}
								></p>
							</div>
							<div className="col-md-5 d-flex  align-items-center">
								<img src={Group1030} className="img-fluid" alt="icon" />
							</div>
						</div>
						<div className="col-md-12">
							<p
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.text_7_4"),
								}}
							></p>
							<p
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.text_7_5"),
								}}
							></p>
							<p
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.text_7_6"),
								}}
							></p>
							<p
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.text_7_7"),
								}}
							></p>
							<p
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.text_7_8"),
								}}
							></p>
						</div>
					</div>
				</div>
			</div>
		);
	};
	const modal8Content = () => {
		return (
			<div className="model-pop">
				<div className="modal-header">
					<h5 className="modal-title pop_heading" id="exampleModalLabel">
						<div className="in-linw-w">
							<img src={Variabilitadel} alt="icon" />
						</div>
						<strong>{t("user.dashboard.heading_8")}</strong>
					</h5>
					<button
						type="button"
						className="close r0-side"
						onClick={() => setModal8(false)}
					>
						<img src={Group769} alt="icon" />
					</button>
				</div>
				<div className="modal-body scroll-pop">
					<div className="row">
						<div className="col-md-7 r-linerigti">
							<p
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.text_8_1"),
								}}
							></p>
						</div>
						<div className="col-md-5 d-flex align-items-center gr-per">
							<img src={Group1179} className="w-100" alt="icon" />
						</div>
					</div>
				</div>
			</div>
		);
	};
	const modal9Content = () => {
		return (
			<div className="model-pop">
				<div className="modal-header">
					<h5 className="modal-title pop_heading" id="exampleModalLabel">
						<div className="in-linw-w">
							<img src={Group9771} alt="icon" />
						</div>
						<strong>{t("user.dashboard.heading_9")}</strong>
					</h5>
					<button
						type="button"
						className="close r0-side"
						onClick={() => setModal9(false)}
					>
						<img src={Group769} alt="icon" />
					</button>
				</div>
				<div className="modal-body scroll-pop">
					<div className="row mar-b">
						<div className="col-md-7 r-linerigti">
							<p
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.text_9_1"),
								}}
							></p>
						</div>
						<div className="col-md-5 d-flex align-items-center gr-per">
							<img src={Group1146} className="w-100" alt="icon" />
						</div>
					</div>
				</div>
			</div>
		);
	};
	const modal10Content = () => {
		return (
			<div className="model-pop">
				<div className="modal-header">
					<h5 className="modal-title pop_heading" id="exampleModalLabel">
						<div className="in-linw-w">
							<img src={Indicedimassacorporea1} alt="icon" />
						</div>
						<strong>{t("user.dashboard.heading_10")} </strong>
					</h5>
					<button
						type="button"
						className="close r0-side"
						onClick={() => setModal10(false)}
					>
						<img src={Group769} alt="icon" />
					</button>
				</div>
				<div className="modal-body scroll-pop">
					<div className="row">
						<div className="col-md-7 r-linerigti">
							<p
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.text_10_1"),
								}}
							></p>
						</div>
						<div className="col-md-5 d-flex align-items-center gr-per">
							<img src={Group1466101} className="w-100" alt="icon" />
						</div>
					</div>
				</div>
			</div>
		);
	};
	const modal11Content = () => {
		return (
			<div className="model-pop">
				<div className="modal-header">
					<h5 className="modal-title pop_heading " id="exampleModalLabel">
						<div className="in-linw-w">
							<img src={Group9414} alt="icon" />
						</div>
						<strong>{t("user.dashboard.heading_11")} </strong>
					</h5>
					<button
						type="button"
						className="close r0-side"
						onClick={() => setModal11(false)}
					>
						<img src={Group769} alt="icon" />
					</button>
				</div>
				<div className="modal-body scroll-pop">
					<div className="row mar-b">
						<div className="col-md-7 r-linerigti">
							<p
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.text_11_1"),
								}}
							></p>
							<p
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.text_11_2"),
								}}
							></p>
						</div>
						<div className="col-md-5 d-flex align-items-center gr-per">
							<img src={Group1462101} className="w-100" alt="icon" />
						</div>
					</div>
				</div>
			</div>
		);
	};
	const modal12Content = () => {
		return (
			<div className="model-pop">
				<div className="modal-header">
					<h5 className="modal-title pop_heading" id="exampleModalLabel">
						<div className="in-linw-w">
							<img src={Group9416} alt="icon" />
						</div>
						<strong> {t("user.dashboard.heading_12")} </strong>
					</h5>
					<button
						type="button"
						className="close r0-side"
						onClick={() => setModal12(false)}
					>
						<img src={Group769} alt="icon" />
					</button>
				</div>
				<div className="modal-body scroll-pop">
					<div className="row">
						<div className="mar-b">
							<div className="col-md-7 r-linerigti">
								<p
									dangerouslySetInnerHTML={{
										__html: t("user.dashboard.text_12_1"),
									}}
								></p>
							</div>
							<div className="col-md-5 d-flex align-items-center gr-per">
								<img src={Group1463101} className="w-100" alt="icon" />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
	const modal13Content = () => {
		return (
			<div className="model-pop">
				<div className="modal-header">
					<h5 className="modal-title pop_heading" id="exampleModalLabel">
						<div className="in-linw-w">
							<img src={Rischiodiictus} alt="icon" />
						</div>
						<strong>{t("user.dashboard.heading_13")}</strong>
					</h5>
					<button
						type="button"
						className="close r0-side"
						onClick={() => setModal13(false)}
					>
						<img src={Group769} alt="icon" />
					</button>
				</div>
				<div className="modal-body scroll-pop">
					<div className="row mar-b">
						<div className="col-md-7 r-linerigti">
							<p
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.text_13_1"),
								}}
							></p>
						</div>
						<div className="col-md-5 d-flex align-items-center gr-per">
							<img src={Group1464101} className="w-100" alt="icon" />
						</div>
					</div>
				</div>
			</div>
		);
	};
	const modal14Content = () => {
		return (
			<div className="model-pop">
				<div className="modal-header">
					<h5 className="modal-title pop_heading" id="exampleModalLabel">
						<div className="in-linw-w">
							<img src={Group9415} className="img-fluid" alt="icon" />
						</div>
						{t("user.dashboard.heading_14")}
					</h5>
					<button
						type="button"
						className="close r0-side"
						onClick={() => setModal14(false)}
					>
						<img src={Group769} alt="icon" />
					</button>
				</div>
				<div className="modal-body scroll-pop">
					<div className="row mar-b">
						<div className="col-md-7 r-linerigti">
							<p
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.text_14_1"),
								}}
							></p>
							<p
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.text_14_2"),
								}}
							></p>
						</div>
						<div className="col-md-5 d-flex align-items-center gr-per">
							<img src={Group1465101} className="w-100" alt="icon" />
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<p
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.text_14_3"),
								}}
							></p>
							<p
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.text_14_4"),
								}}
							></p>
							<p
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.text_14_5"),
								}}
							></p>
						</div>
					</div>
				</div>
			</div>
		);
	};
	const modal15Content = () => {
		return (
			<div className="model-pop">
				<div className="modal-header">
					<h5 className="modal-title pop_heading" id="exampleModalLabel">
						<div className="in-linw-w">
							<img src={Group9412} alt="icon" />
						</div>
						{t("user.dashboard.heading_15")}
					</h5>
					<button
						type="button"
						className="close r0-side"
						onClick={() => setModal15(false)}
					>
						<img src={Group769} alt="icon" />
					</button>
				</div>
				<div className="modal-body scroll-pop">
					<div className="row mar-b">
						<div className="mar-b">
							<div className="col-md-7 r-linerigti">
								<p
									// className="mb-0"
									dangerouslySetInnerHTML={{
										__html: t("user.dashboard.text_15_1"),
									}}
								></p>
							</div>
							<div className="col-md-5 d-flex align-items-center gr-per">
								<img src={Group1465101} className="w-100" alt="icon" />
							</div>
						</div>
						<div className="col-md-12">
							<p
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.text_15_2"),
								}}
							></p>
							<p
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.text_15_3"),
								}}
							></p>
							<p
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.text_15_4"),
								}}
							></p>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const modal16Content = () => {
		return (
			<div className="model-pop">
				<div className="modal-header">
					<h5 className="modal-title pop_heading" id="exampleModalLabel">
						<div className="in-linw-w">
							<img src={Group911110} className="img-fluid" alt="icon" />
						</div>
						{t("user.dashboard.heading_16")}
					</h5>
					<button
						type="button"
						className="close r0-side"
						onClick={() => setModal16(false)}
					>
						<img src={Group769} alt="icon" />
					</button>
				</div>
				<div className="modal-body scroll-pop">
					<div className="mar-b">
						<div className="col-md-7 r-linerigti">
							<p
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.text_16_1"),
								}}
							></p>
							<p
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.text_16_2"),
								}}
							></p>
						</div>
						<div className="col-md-5 d-flex align-items-center gr-per">
							<img src={Group1465101} className="w-100" alt="icon" />
						</div>
					</div>
					<div className="col-md-12">
						<p
							dangerouslySetInnerHTML={{
								__html: t("user.dashboard.text_16_3"),
							}}
						></p>
						<p>
							<ul
								className="norm-g"
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.text_16_4"),
								}}
							></ul>
						</p>
						<p
							dangerouslySetInnerHTML={{
								__html: t("user.dashboard.text_16_5"),
							}}
						></p>
					</div>
					<div className="col-md-12">
						<p
							dangerouslySetInnerHTML={{
								__html: t("user.dashboard.text_16_6"),
							}}
						></p>
						<p
							dangerouslySetInnerHTML={{
								__html: t("user.dashboard.text_16_7"),
							}}
						></p>
						<p
							dangerouslySetInnerHTML={{
								__html: t("user.dashboard.text_16_8"),
							}}
						></p>
					</div>
				</div>
			</div>
		);
	};

	const modal17Content = () => {
		return (
			<div className="model-pop">
				<div className="modal-header">
					<h5 className="modal-title pop_heading" id="exampleModalLabel">
						<div className="in-linw-w">
							<img src={Diabetebigpopup} alt="icon" />
						</div>
						<strong>{t("user.dashboard.heading_17")}</strong>
					</h5>
					<button
						type="button"
						className="close r0-side"
						onClick={() => setModal17(false)}
					>
						<div className="in-linw-w">
							<img src={Group769} alt="icon" />
						</div>
					</button>
				</div>
				<div className="modal-body scroll-pop">
					<div className="mar-b">
						<div className="col-md-7 r-linerigti">
							<p
								dangerouslySetInnerHTML={{
									__html: t("user.dashboard.text_17_1"),
								}}
							></p>
						</div>
						<div className="col-md-5 d-flex align-items-center gr-per">
							<img src={Group1465101} className="w-100" alt="icon" />
						</div>
					</div>
					<div className="col-md-12">
						<p
							dangerouslySetInnerHTML={{
								__html: t("user.dashboard.text_17_2"),
							}}
						></p>
						<p
							dangerouslySetInnerHTML={{
								__html: t("user.dashboard.text_17_3"),
							}}
						></p>
						<p
							dangerouslySetInnerHTML={{
								__html: t("user.dashboard.text_17_4"),
							}}
						></p>
					</div>
				</div>
			</div>
		);
	};
	const modal18Content = () => {
		return (
			<div className="model-pop">
				<div className="modal-header">
					<h5 className="modal-title pop_heading left25" id="exampleModalLabel">
						<div className="in-linw-w">
							<img src={Group9417} alt="icon" />
						</div>
						<strong>{t("user.dashboard.heading_18")}</strong>
					</h5>
					<button
						type="button"
						className="close r0-side"
						onClick={() => setModal18(false)}
					>
						<img src={Group769} alt="icon" />
					</button>
				</div>
				<p
					dangerouslySetInnerHTML={{ __html: t("user.dashboard.text_18_1") }}
				></p>
				<p
					dangerouslySetInnerHTML={{ __html: t("user.dashboard.text_18_2") }}
				></p>
				<p
					dangerouslySetInnerHTML={{ __html: t("user.dashboard.text_18_3") }}
				></p>
			</div>
		);
	};
	const modal19Content = () => {
		return (
			<div className="model-pop">
				<div className="modal-header">
					<h5 className="modal-title pop_heading" id="exampleModalLabel">
						<div className="in-linw-w">
							<img src={Group9418} alt="icon" />
						</div>
						<strong> {t("user.dashboard.heading_19")}</strong>
					</h5>
					<button
						type="button"
						className="close r0-side"
						onClick={() => setModal19(false)}
					>
						<img src={Group769} alt="icon" />
					</button>
				</div>
				<div className="modal-body scroll-pop">
					<p
						dangerouslySetInnerHTML={{ __html: t("user.dashboard.text_19_1") }}
					></p>
					<p
						dangerouslySetInnerHTML={{ __html: t("user.dashboard.text_19_2") }}
					></p>
					<p
						dangerouslySetInnerHTML={{ __html: t("user.dashboard.text_19_3") }}
					></p>
				</div>
			</div>
		);
	};
	const modal20Content = () => {
		return (
			<div className="model-pop">
				<div className="modal-header">
					<h5 className="modal-title pop_heading" id="exampleModalLabel">
						<div className="in-linw-w">
							<img src={Group9418} alt="icon" />
						</div>
						<strong> {t("user.dashboard.heading_20")}</strong>
					</h5>
					<button
						type="button"
						className="close r0-side"
						onClick={() => setModal20(false)}
					>
						<img src={Group769} alt="icon" />
					</button>
				</div>
				<div className="modal-body scroll-pop">
					<p
						dangerouslySetInnerHTML={{ __html: t("user.dashboard.text_20_1") }}
					></p>
					<p
						dangerouslySetInnerHTML={{ __html: t("user.dashboard.text_20_2") }}
					></p>
				</div>
			</div>
		);
	};

	const modal21Content = () => {
		return (
			(todayMeasurement)
				?
				<div className="model-pop">
					<div className="modal-header">
						<h5 className="modal-title pop_heading left25" id="exampleModalLabel">
							<div className="in-linw-w">
								<img src={Group9417} alt="icon" />
							</div>
							<strong>{t("user.dashboard.heading_anxiety_assessment")}</strong>
						</h5>
						<button
							type="button"
							className="close r0-side"
							onClick={() => setModal21(false)}
						>
							<img src={Group769} alt="icon" />
						</button>
					</div>
					<p
						dangerouslySetInnerHTML={{ __html: t("user.dashboard.text_anxiety_assessment") }}
					></p>
					<p className="btn-anxiety-align m-auto">
						<Link to={`/anxiety-and-depression/anxiety/${num}`} className={`buttons register-btn btn-anxiety ${(isDesktop || isTablet) ? " align-btn" : ''}`}>
							{t("user.dashboard.continue")}
						</Link>
						<button className={`buttons register-btn btn-anxiety ${(isDesktop || isTablet) ? " align-btn mr-2" : ''}`} onClick={() => setModal21(false)}>{t("user.dashboard.cancel")}</button>

					</p>
				</div>
				:
				<div className="model-pop">
					{/* <div className="modal-header">
            <div className="in-linw-w">
              <img src={Group9417} alt="icon" />
            </div>
            <div className="row">
             <div className="col-md-6 col-sm-12">
             <span className="scan-text">
              {t("user.dashboard.scan_for_measurement") }
              </span>
             </div>             
              <div className="col-md-6 col-sm-12">
              <p className="btn-anxiety-align m-auto">
              <button className="buttons register-btn btn-anxiety btn-scan-size" onClick={() => setModal21(false)}>{t("user.dashboard.cancel")}</button>     
              <button className="buttons register-btn btn-anxiety btn-scan-size" onClick={() => {pageLoad(); setModal21(false)}}>{t("user.dashboard.continue")}</button>
              </p>
               </div>  
             </div>    
        </div> */}

					<div className="modal-header">
						<div className="in-linw-w d-none d-sm-block">
							<img src={Group9417} alt="icon" />
						</div>
						<div className="row justify-content-end">
							<div className="col-3 d-sm-none">
								<img src={Group9417} alt="icon" />
							</div>
							<div className="col-md-6 col-sm-9 col-9">
								<span className="scan-text">{t("user.dashboard.scan_for_measurement")}</span>
							</div>
							<div className="col-md-6 col-sm-9 col-offset-9 col-9"><p className="btn-anxiety-align m-auto">
								<button className="buttons register-btn btn-anxiety btn-scan-size" onClick={() => setModal21(false)}>{t("user.dashboard.cancel")}</button>
								<button className="buttons register-btn btn-anxiety btn-scan-size" onClick={() => { pageLoad(); setModal21(false) }}>{t("user.dashboard.continue")}</button>
							</p>
							</div>
						</div>
					</div>
				</div>
		);
	};

	const modal22Content = () => {
		return (
			(todayMeasurement)
				?
				<>
					<div className="model-pop">
						<div className="modal-header">
							<h5 className="modal-title pop_heading left25" id="exampleModalLabel">
								<div className="in-linw-w">
									<img src={Group9418} alt="icon" />
								</div>
								<strong>{t("user.dashboard.heading_depression_assessment")}</strong>
							</h5>
							<button
								type="button"
								className="close r0-side"
								onClick={() => setModal22(false)}
							>
								<img src={Group769} alt="icon" />
							</button>
						</div>
						<p
							dangerouslySetInnerHTML={{ __html: t("user.dashboard.text_depression_assessment") }}
						></p>
						<p className="btn-anxiety-align m-auto">
							<Link to={`/anxiety-and-depression/depression/${num}`} className={`buttons register-btn btn-anxiety  ${(isDesktop || isTablet) ? "align-btn" : ''}`} >
								{t("user.dashboard.continue")}
							</Link>
							<button className={`buttons register-btn btn-anxiety ${(isDesktop || isTablet) ? " align-btn mr-2 " : ''}`} onClick={() => setModal22(false)}>{t("user.dashboard.cancel")}</button>

						</p>
					</div>
				</>
				:
				<div className="model-pop">
					{/* <div className="modal-header">
            <div className="in-linw-w">
              <img src={Group9418} alt="icon" />
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12">
              <span className="scan-text">
              {t("user.dashboard.scan_for_measurement") }
              </span>
              </div>
              <div className="col-md-6 col-sm-12">
              <p className="btn-anxiety-align m-auto">
              <button className="buttons register-btn btn-anxiety btn-scan-size" onClick={() => setModal22(false)}>{t("user.dashboard.cancel")}</button>     
              <button className="buttons register-btn btn-anxiety btn-scan-size" onClick={() => {pageLoad(); setModal22(false)}}>{t("user.dashboard.continue")}</button>
              </p>
              </div>
            </div>
          </div> */}

					<div className="modal-header">
						<div className="in-linw-w d-none d-sm-block">
							<img src={Group9418} alt="icon" />
						</div>
						<div className="row justify-content-end">
							<div className="col-3 d-sm-none">
								<img src={Group9418} alt="icon" />
							</div>
							<div className="col-md-6 col-sm-9 col-9">
								<span className="scan-text">{t("user.dashboard.scan_for_measurement")}</span>
							</div>
							<div className="col-md-6 col-sm-9 col-offset-9 col-9"><p className="btn-anxiety-align m-auto">
								<button className="buttons register-btn btn-anxiety btn-scan-size" onClick={() => setModal22(false)}>{t("user.dashboard.cancel")}</button>
								<button className="buttons register-btn btn-anxiety btn-scan-size" onClick={() => { pageLoad(); setModal22(false) }}>{t("user.dashboard.continue")}</button>
							</p>
							</div>
						</div>
					</div>

				</div>
		);
	};
	const mobPageGuide = [
		{
			selector: "#step-mob-1",
			title: t("user.sidebar.tourguide.title1"),
			description: t("user.sidebar.tourguide.description1")
		},
		{
			selector: "#step-mob-2",
			title: t("user.sidebar.tourguide.title2"),
			description: t("user.sidebar.tourguide.description2")
		},
		{
			selector: "#step-mob-3",
			title: t("user.sidebar.tourguide.title3"),
			description: t("user.sidebar.tourguide.description3")
		},
		{
			selector: "#step-mob-4",
			title: t("user.sidebar.tourguide.title4"),
			description: t("user.sidebar.tourguide.description4")
		},
		{
			selector: "#step-mob-5",
			title: t("user.sidebar.tourguide.title5"),
			description: t("user.sidebar.tourguide.description5")

		},
		{
			selector: "#step-mob-6",
			title: t("user.sidebar.tourguide.title6"),
			description: t("user.sidebar.tourguide.description6")
		},
		{
			selector: "#step-mob-7",
			title: t("user.sidebar.tourguide.title7"),
			description: t("user.sidebar.tourguide.description7")
		},
		{
			selector: "#step-mob-8",
			title: t("user.sidebar.tourguide.title8"),
			description: t("user.sidebar.tourguide.description8")

		},
		{
			selector: "#step-9",
			title: t("user.sidebar.tourguide.title9"),
			description: t("user.sidebar.tourguide.description9")

		},
		{
			selector: "#step-10",
			title: t("user.sidebar.tourguide.title10"),
			description: t("user.sidebar.tourguide.description10")

		},
		{
			selector: "#step-11",
			title: t("user.sidebar.tourguide.title11"),
			description: t("user.sidebar.tourguide.description11")
		},
		{
			selector: "#step-12",
			title: t("user.sidebar.tourguide.title12"),
			description: t("user.sidebar.tourguide.description12")

		},
		{
			selector: "#step-13",
			title: t("user.sidebar.tourguide.title13"),
			description: t("user.sidebar.tourguide.description13")

		},
		{
			selector: "#step-14",
			title: t("user.sidebar.tourguide.title14"),
			description: t("user.sidebar.tourguide.description14")

		},
		{
			selector: "#step-15",
			title: t("user.sidebar.tourguide.title15"),
			description: t("user.sidebar.tourguide.description15")
		}
	];
	const pageGuide = [
		{
			selector: "#step-1",
			title: t("user.sidebar.tourguide.title1"),
			description: t("user.sidebar.tourguide.description1")
		},
		{
			selector: "#step-2",
			title: t("user.sidebar.tourguide.title2"),
			description: t("user.sidebar.tourguide.description2")
		},
		{
			selector: "#step-3",
			title: t("user.sidebar.tourguide.title3"),
			description: t("user.sidebar.tourguide.description3")
		},
		{
			selector: "#step-4",
			title: t("user.sidebar.tourguide.title4"),
			description: t("user.sidebar.tourguide.description4")
		},
		{
			selector: "#step-5",
			title: t("user.sidebar.tourguide.title5"),
			description: t("user.sidebar.tourguide.description5")

		},
		{
			selector: "#step-6",
			title: t("user.sidebar.tourguide.title6"),
			description: t("user.sidebar.tourguide.description6")
		},
		{
			selector: "#step-7",
			title: t("user.sidebar.tourguide.title7"),
			description: t("user.sidebar.tourguide.description7")
		},
		{
			selector: "#step-8",
			title: t("user.sidebar.tourguide.title8"),
			description: t("user.sidebar.tourguide.description8")

		},
		{
			selector: "#step-9",
			title: t("user.sidebar.tourguide.title9"),
			description: t("user.sidebar.tourguide.description9")

		},
		{
			selector: "#step-10",
			title: t("user.sidebar.tourguide.title10"),
			description: t("user.sidebar.tourguide.description10")

		},
		{
			selector: "#step-11",
			title: t("user.sidebar.tourguide.title11"),
			description: t("user.sidebar.tourguide.description11")
		},
		{
			selector: "#step-12",
			title: t("user.sidebar.tourguide.title12"),
			description: t("user.sidebar.tourguide.description12")

		},
		{
			selector: "#step-13",
			title: t("user.sidebar.tourguide.title13"),
			description: t("user.sidebar.tourguide.description13")

		},
		{
			selector: "#step-14",
			title: t("user.sidebar.tourguide.title14"),
			description: t("user.sidebar.tourguide.description14")

		},
		{
			selector: "#step-15",
			title: t("user.sidebar.tourguide.title15"),
			description: t("user.sidebar.tourguide.description15")
		}
	];

	function getProgressBarColorClass(score) {
		// console.log('score - ', score);
		if (score === 0) {
			return 'progress-bar-color-0';
		} else if (score <= 25) {
			return 'progress-bar-color-25';
		} else if (score <= 50) {
			return 'progress-bar-color-50';
		} else if (score <= 75) {
			return 'progress-bar-color-75';
		} else {
			return 'progress-bar-color-100';
		}
	}

	const progressVal = (activityStatus, allActivity) => {
		let result = 0
		for (let i = 0; i < activityStatus.length; i++) {
			if (activityStatus[i] === 'complete') {
				result++
			}
		}
		let finalScore = Math.floor(result * 100 / allActivity)
		return finalScore
	}

	const activityPlayTime = (playTime) => {
		let result = 0;
		result = 100 * playTime / 300
		return result
	}
	const enablePageGuide = localStorage.getItem('pageGuide');
	const [enableRef, setEnableRef] = useState(1);
	const myCustomNext = (logic: WalktourLogic) => {
		if (logic.stepIndex == 3 && isMobile) {
			window.location.href = 'profilemenu';
			return false;
		}
		if (logic.stepIndex == 2) {
			$(".nav-stacked").scrollLeft(innerHeight);
		}
		if (document.referrer.split("/").pop() == 'profilemenu' && enableRef === 1) {
			logic.goToStep(8);
			setEnableRef(0);
		}
		else {
			logic.next();
		}

		if (isMobile && logic.stepIndex == 11) {
			localStorage.setItem('pageGuide', 1)
		}
		else if (!isMobile && logic.stepIndex == 13) {
			localStorage.setItem('pageGuide', 1)
		}
	}
	const closeTour = (logic: WalktourLogic) => {
		localStorage.setItem('pageGuide', 1)
		logic.close();
	}
	const mindfullnessStep = getLocalStoarge('mindfullnessStep');
	return (
		<>

			{(loading == false && enablePageGuide != 1) ? <Walktour steps={(isMobile) ? mobPageGuide : pageGuide} nextLabel={t("user.sidebar.nextlabel")} closeLabel={t("user.sidebar.closelabel")} customCloseFunc={closeTour} customNextFunc={myCustomNext} disableClose={0} allSteps={pageGuide} /> : null}

			<Modal title="" size="lg" onClose={() => setModal1(false)} show={modal1}>
				{modal1Content()}
			</Modal>
			<Modal title="" size="lg" onClose={() => setModal2(false)} show={modal2}>
				{modal2Content()}
			</Modal>
			<Modal title="" size="lg" onClose={() => setModal3(false)} show={modal3}>
				{modal3Content()}
			</Modal>
			<Modal title="" size="lg" onClose={() => setModal4(false)} show={modal4}>
				{modal4Content()}
			</Modal>
			<Modal title="" size="lg" onClose={() => setModal5(false)} show={modal5}>
				{modal5Content()}
			</Modal>
			<Modal title="" size="lg" onClose={() => setModal6(false)} show={modal6}>
				{modal6Content()}
			</Modal>
			<Modal title="" size="lg" onClose={() => setModal7(false)} show={modal7}>
				{modal7Content()}
			</Modal>
			<Modal title="" size="lg" onClose={() => setModal8(false)} show={modal8}>
				{modal8Content()}
			</Modal>
			<Modal title="" size="lg" onClose={() => setModal9(false)} show={modal9}>
				{modal9Content()}
			</Modal>
			<Modal
				title=""
				size="lg"
				onClose={() => setModal10(false)}
				show={modal10}
			>
				{modal10Content()}
			</Modal>
			<Modal
				title=""
				size="lg"
				onClose={() => setModal11(false)}
				show={modal11}
			>
				{modal11Content()}
			</Modal>
			<Modal
				title=""
				size="lg"
				onClose={() => setModal12(false)}
				show={modal12}
			>
				{modal12Content()}
			</Modal>
			<Modal
				title=""
				size="lg"
				onClose={() => setModal13(false)}
				show={modal13}
			>
				{modal13Content()}
			</Modal>
			<Modal
				title=""
				size="lg"
				onClose={() => setModal14(false)}
				show={modal14}
			>
				{modal14Content()}
			</Modal>
			<Modal
				title=""
				size="lg"
				onClose={() => setModal15(false)}
				show={modal15}
			>
				{modal15Content()}
			</Modal>
			<Modal
				title=""
				size="lg"
				onClose={() => setModal16(false)}
				show={modal16}
			>
				{modal16Content()}
			</Modal>
			<Modal
				title=""
				size="lg"
				onClose={() => setModal17(false)}
				show={modal17}
			>
				{modal17Content()}
			</Modal>
			<Modal
				title=""
				size="lg"
				onClose={() => setModal18(false)}
				show={modal18}
			>
				{modal18Content()}
			</Modal>
			<Modal
				title=""
				size="lg"
				onClose={() => setModal19(false)}
				show={modal19}
			>
				{modal19Content()}
			</Modal>
			<Modal
				title=""
				size="lg"
				onClose={() => setModal20(false)}
				show={modal20}
			>
				{modal20Content()}
			</Modal>
			<Modal
				title=""
				size="lg"
				onClose={() => setModal21(false)}
				show={modal21}
			>
				{modal21Content()}
			</Modal>
			<Modal
				title=""
				size="lg"
				onClose={() => setModal22(false)}
				show={modal22}
			>
				{modal22Content()}
			</Modal>
			{(popupVisiable) ?
				<InstructionModal popUpEnable={`true`} />

				: null
			}
			<section className="full_bg dashboard-mobile dashboard-section">
				{isLoading ? <LazyLoader /> : null}
				<div className="container-fluid">
					<div className="row">
						<Sidebar />
						<div className=" col-md-10 p0 pr-0 ipd-righ">
							<div className="right-dashboard topspace leftr-dari">
								{/** Dashboard Tab & Header Start */}
								<div className="row">
									<div className="col-9 col-sm-8 col-md-8 col-lg-9 col-xl-9 pr-sm-0 pr-md-0">

										<h2 className="heading-dash">
											{(activeTab == "dashboard") ? t("user.dashboard.heading_trand") : t("user.dashboard.heading_main")}
										</h2>
										{errorfetch === "error" ? (
											<>
												<div className={`alert alert-danger`}>
													{t(`user.dashboard.camera_error`)}
												</div>
											</>
										) : (
											<></>
										)}
										<div className="dashboard-tabs">
											<ul className="nav nav-tabs" id="myTabs" role="tablist">
												<li className="nav-item"><a className={`nav-link ${activeTab === "dashboard" ? "active show" : ""}`} href="javascript:void(0)" onClick={() => handleTabClick("dashboard")}> {t("user.dashboard.moduli")} </a></li>
												<li className="nav-item"><a className={`nav-link ${activeTab === "brainwellness" ? "active show" : ""}`} href="javascript:void(0)" onClick={() => handleTabClick("brainwellness")} > {t("user.dashboard.mytrend")} </a> </li>
											</ul>
										</div>
									</div>
									<div className="col-3 col-sm-4 col-md-4 col-lg-3 col-xl-3" style={{ display: "flex", justifyContent: "right" }}>
										<div className="align-j pt-2">
											{personalizeDetail ? (
												personalizeDetail?.vitali ?? 0 === 1 ? (
													<>
													</>
												) : (
													<h4></h4>
												)
											) : (
												<h4></h4>
											)}
											<div className="righ-side top-right-score" id="step-8">
												<div className="logo-shape">
													<img src={shape} alt="icon" />
													<span>
														{detail.HEALTH_SCORE <= 20 ? (
															<p className="">
																{t("user.dashboard.buono")}
															</p>
														) : detail.HEALTH_SCORE >= 21 &&
															detail.HEALTH_SCORE <= 40 ? (
															<p className="">
																{t("user.dashboard.buono1")}
															</p>
														) : detail.HEALTH_SCORE >= 41 &&
															detail.HEALTH_SCORE <= 60 ? (
															<p className="">
																{t("user.dashboard.buono2")}
															</p>
														) : detail.HEALTH_SCORE >= 61 &&
															detail.HEALTH_SCORE <= 80 ? (
															<p className="">
																{t("user.dashboard.buono3")}
															</p>
														) : detail.HEALTH_SCORE >= 81 &&
															detail.HEALTH_SCORE <= 100 ? (
															<p className="">
																{t("user.dashboard.buono4")}
															</p>
														) : (
															<span className=""></span>
														)}
													</span>
												</div>
												<div className="w80">
													{detail.HEALTH_SCORE
														? parseFloat(detail.HEALTH_SCORE)
														: "0"}
												</div>
												{/* <p className="deskt-n buon-d"> {t("user.dashboard.buono")} </p> */}
											</div>
										</div>
									</div>
								</div>

								{/** Dashboard Tab & Header End */}

								<div className="tab-content">
									<div className={`tab-pane ${activeTab === "dashboard" ? "active show" : ""}`} id="dashboard">

										<div id="step-9">
											{/* <div className="align-j"><h4>{t("user.dashboard.heading_vitali")}</h4></div> */}
											<div className="dash-box row mt-3 mb-5">
												<div className="left-side col-md-6">
													<div className="left-div">
														<div className="in-f">
															<div className="in-alini">
																<div className="ico-bg common-dashbard-icon">
																	<div className="wellbeing-icon"></div>
																</div>
																<div className="right-s">
																	<h6 className="card-module">{t("user.dashboard.modulo")}</h6>
																	<h5 className="heading-com-dash">
																		{t("user.dashboard.wellbeing_heading")}
																	</h5>
																</div>
															</div>
															<div className="right-part">
																<i className="colorNew visi-none">
																	{" "}
																	<img src={Istruzioni} className="new-sidebar-icon" alt="icon" onClick={() => setModal1(true)} />
																</i>
																<i className="colorNew hd-n">
																	{" "}
																	<HiOutlineInformationCircle
																		className="pointer"
																		onClick={() => setModal1(true)}
																	/>
																</i>
																<button className="btn-apr mt-4" onClick={openDashboard}>{t("user.dashboard.open")}</button>
															</div>
														</div>
													</div>
													{(brainwellnessModule == 1) ?
														<div className="left-div">
															<div className="in-f">
																<div className="in-alini">
																	<div className="ico-bg common-dashbard-icon">
																		<div className="brain-bg"></div>
																	</div>
																	<div className="right-s">
																		<h6 className="card-module">{t("user.dashboard.modulo")}</h6>
																		<h5 className="heading-com-dash">
																			{t("user.dashboard.brain_wellness")}
																		</h5>
																	</div>
																</div>
																<div className="right-part">
																	<i className="colorNew visi-none">
																		{" "}
																		<img src={Istruzioni} className="new-sidebar-icon" alt="icon" onClick={() => setModal2(true)} />
																	</i>
																	<i className="colorNew hd-n">
																		{" "}
																		<HiOutlineInformationCircle
																			className="pointer"
																			onClick={() => setModal2(true)}
																		/>
																	</i>
																	<button className="btn-apr mt-4" onClick={openBrainWellness}>{t("user.dashboard.open")}</button>
																</div>
															</div>
														</div> : null
													}
													{(trainingModule == 1) ?
														<div className="left-div">
															<div className="in-f">
																<div className="in-alini">
																	<div className="ico-bg common-dashbard-icon">
																		<div className="literacy-icon"></div>
																	</div>
																	<div className="right-s">
																		<h6 className="card-module">{t("user.dashboard.modulo")}</h6>
																		<h5 className="heading-com-dash">
																			{t("user.dashboard.wellbeing_literacy")}
																		</h5>
																	</div>
																</div>
																<div className="right-part">
																	<i className="colorNew visi-none">
																		{" "}

																		<img src={Istruzioni} className="new-sidebar-icon" alt="icon" onClick={() => setModal3(true)} />
																	</i>
																	<i className="colorNew hd-n">
																		{" "}
																		<HiOutlineInformationCircle
																			className="pointer"
																			onClick={() => setModal3(true)}
																		/>
																	</i>
																	<button className="btn-apr mt-4" onClick={openTraining}>{t("user.dashboard.open")} </button>
																</div>
															</div>
														</div> : null}
												</div>
												<div className="col-md-6 ">
													<div className="in-f second-card">
														<div className="in-alini">
															<div className="">
																<h4 className="second-card-text-h1" dangerouslySetInnerHTML={{ __html: t("user.dashboard.task_complete") }}></h4>
																<h6 className="second-card-text-h2" dangerouslySetInnerHTML={{ __html: t("user.dashboard.daily_activity") }}>
																</h6>
															</div>
														</div>
														<div className="right-part">
															{/* <button className="btn-guidami mt-5">{t("user.dashboard.open")}</button> */}
														</div>
													</div>
													<div className="right-side cmd-tab mt-2 pb-3">
														<div className="card-cmd-width">
															<div className=" ">
																<div className="dashboard-tabs">
																	<ul className="nav nav-tabs" id="myTabs" role="tablist">
																		<li className="nav-item"><a className={`nav-link ${activeTab === "dashboard" ? "active show" : ""}`} href="#" onClick={() => handleTabClick("dashboard")}> {t("user.cognitive_training.today")}</a></li>
																		{/* <li className="nav-item"><a className={`nav-link ${activeTab === "Settimana" ? "active show" : ""}`} href="javascript:void(0)" onClick={() => handleTabClick("dashboard")}> {t("superadmin.protocol.weeks")}</a></li> */}
																		{/* <li className="nav-item"><a className={`nav-link ${activeTab === "Mese" ? "active show" : ""}`} href="javascript:void(0)" onClick={() => handleTabClick("dashboard")}> {t("user.dashboard.month")}</a></li> */}
																	</ul>
																</div>
															</div>
															<div className="cmd-statics">
																<div className="row w-100">
																	<div className="col-12 p-0">
																		<div className="d-flex">
																			<div className="wellbeing-icon-second"></div>
																			<h5 className="heading-cmd-left ml-2">
																				{t("user.dashboard.wellbeing_heading")}
																			</h5>
																		</div>
																	</div>
																	<div className="w-100 ml-3">
																		{
																			(scanLimit == 1) ?
																				<>
																					<div className="col-2">
																					</div>
																					<div className="col-10">
																						<span className="measure-done-txt">{t("user.login.invalid_scan").toUpperCase()}</span>
																					</div>
																				</>
																				:
																				(!todayMeasurement) ?
																					<>
																						<div className="d-flex justify-content-between">
																							<div>
																								<h6 className="subheading-cmd">{t("user.dashboard.toi").toUpperCase()}</h6>
																								<h6 className="card-module" dangerouslySetInnerHTML={{ __html: t("user.dashboard.daily_measurement") }}></h6>
																							</div>
																							<div className="align-items-end justify-content-end ">
																								<button className="btn-apr btn-inizia-ipad" onClick={() => { pageLoad() }}>{t("user.dashboard.start")}</button>
																							</div>
																						</div>
																					</>
																					:
																					<>
																						<div className="col-2">
																						</div>
																						<div className="col-10">
																							<span className="measure-done-txt">{t("user.dashboard.measure_done").toUpperCase()}</span>
																						</div>
																					</>
																		}
																	</div>
																</div>
															</div>
															{(brainwellnessModule == 1) ?
																<div className="cmd-statics mt-2">
																	<div className="row">
																		<div className="col-12 row">
																			<div className="col-12 row">
																				<div className="literacy-icon-second"></div>
																				<h5 className="heading-cmd-left ml-2">
																					{t("user.dashboard.brain_wellness")}
																				</h5>
																			</div>
																		</div>
																		<div className="col-12">
																			<h6 className="subheading-cmd">{t("user.dashboard.training_cognitive").toUpperCase()}</h6>
																		</div>
																		{(!cognitiveReport) ?
																			<>
																				<div className="col-12">
																					<h6 className="card-module">{t("user.dashboard.gonogo").toUpperCase()}</h6>
																					<div className="congnative-progress progress progress-bg-color-cmd ml-2">
																						<div className={`progress-bar ${getProgressBarColorClass(goNoGo)}`} role="progressbar" style={{
																							width: `${goNoGo}%`,
																						}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
																						</div>

																					</div>
																					<p className='progress-number-day'> {`${goNoGo ? goNoGo : 0}%`} {t("user.cognitive_training.progress")} </p>

																				</div>

																				<div className="col-12">
																					<h6 className="card-module">{t("user.dashboard.n-back")}</h6>
																					<div className="congnative-progress progress progress-bg-color-cmd ml-2">
																						<div className={`progress-bar ${getProgressBarColorClass(nBack)}`} role="progressbar" style={{
																							width: `${nBack}%`,
																						}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
																						</div>

																					</div>
																					<p className="progress-number-day"> {`${nBack ? nBack : 0}%`} {t("user.cognitive_training.progress")} </p>
																					{/* {(userActivity.activities_play_time[index] > 0) ? <p className='progress-number-day'>{`${(userActivity?.activities_play_time.length > 0) ? (activityPlayTime(userActivity?.activities_play_time[0])) ? Math.floor(activityPlayTime(userActivity?.activities_play_time[0])) : 0 : 0}%`} {t("user.dashboard.score").toUpperCase()}</p> : <p className='progress-number-day'>0% {t("user.dashboard.score").toUpperCase()}</p>} */}

																				</div>
																			</>
																			:
																			<div className="col-12">
																				<h6 className="card-module">{t("admin.sidebar.report")}</h6>
																				<span className="card-module free-activity-label-cmd report-text-card" dangerouslySetInnerHTML={{
																					__html: t("user.dashboard.report-activity-text"),
																				}}></span>
																				<div className="start-btn-cmd mb-1">
																					<Link to={`/report-cognitive/1/1`}>
																						<button type='button' className='btn-apr'>{t("user.activityReport.view")}</button>
																					</Link>
																				</div>
																			</div>
																		}

																		<div className="col-12">
																			<span className="card-module free-activity-label-cmd">{t("user.dashboard.free-activity")}</span>
																			<span className="common-activity" >{t("user.dashboard.common-activity")}</span>
																		</div>
																		<div className="col-12 start-btn-cmd mb-1">
																			<button className="btn-apr" onClick={() => window.location.href = `congnitive-training/` + cognitiveStep}>{t("user.dashboard.gotoactivity")}</button>
																		</div>

																		<div className="col-12">
																			<h6 className="subheading-cmd">{t("user.dashboard.mindfullness_txt").toUpperCase()}</h6>
																		</div>
																		{(!mindFullnessReport) ?
																			<div className="col-12">
																				<h6 className="card-module">{t("user.dashboard.mindfullness_txt")}</h6>
																				<div className="congnative-progress progress progress-bg-color-cmd ml-2">
																					<div className={`progress-bar ${getProgressBarColorClass(mindfulProgress)}`} role="progressbar" style={{
																						width: `${mindfulProgress}%`,
																					}} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
																				</div>
																				<p className="progress-number-day"> {mindfulProgress ? mindfulProgress : 0}% {t("user.cognitive_training.progress").toUpperCase()}</p>
																			</div>
																			:
																			<div className="col-12">
																				<h6 className="card-module">{t("admin.sidebar.report")}</h6>
																				<span className="card-module free-activity-label-cmd report-text-card" dangerouslySetInnerHTML={{
																					__html: t("user.dashboard.report-activity-text"),
																				}}></span>
																				<div className="start-btn-cmd mb-1">
																					<Link to={`/report-mindfulness/2/3`}>
																						<button type='button' className='btn-apr'>{t("user.activityReport.view")}</button>
																					</Link>
																				</div>
																			</div>
																		}
																		<div className="col-12">
																			<span className="card-module free-activity-label-cmd">{t("user.dashboard.free-activity")}</span>
																			<span className="common-activity">{t("user.dashboard.relax")}</span>
																		</div>
																		<div className="col-12 start-btn-cmd mb-1">
																			<button className="btn-apr" onClick={() => window.location.href = `mindfulness/` + mindfullnessStep}>{t("user.dashboard.gotoactivity")}</button>
																		</div>
																	</div>
																</div>
																: null}
															{(trainingModule == 1) ?
																<div className="cmd-statics mt-2 pb-2">
																	<div className="row">
																		<div className="col-12 row">
																			<div className="col-12 row" key={userStatus}>
																				<div className="brain-bg-second"></div>
																				<h5 className="heading-cmd-left ml-2">
																					{t("user.dashboard.wellbeing_literacy")}
																				</h5>
																			</div>
																		</div>
																		{(userStatus) ? <>
																			{
																				Object(userStatus).map((data, i) => {
																					return (
																						<>
																							<div className="col-12">
																								<h6 className="subheading-cmd">{(language == "it") ? data?.programNameITA.toUpperCase() : data?.program_name.toUpperCase()}</h6>
																								<h6 className="card-module">{(language == "it") ? data?.courseNameITA[0] : data?.courseName[0]}</h6>
																							</div>
																							<div className="col-12">
																								<div className="congnative-progress progress progress-bg-color-cmd ml-2">
																									<div className="progress-bar progress-bar-color-course" role="progressbar" style={{ width: data?.progress[0] + '%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
																									</div>
																								</div>
																								<p className="progress-number-day">{data?.progress[0]}% {t("user.dashboard.completed")}</p>
																							</div>
																							<div className="col-12 start-btn-cmd">
																								<button className="btn-apr" onClick={() => window.location.href = `program-details/${data.programId}`}>{t("user.dashboard.gotoactivity")}</button>
																							</div>

																						</>
																					)
																				})
																			}
																		</>
																			:
																			<div className="col-12">
																				<h6 class="card-module">{t("user.common_dashboard.right_section.noProgram")}</h6>
																			</div>
																		}

																	</div>
																</div>
																: null}
														</div>
													</div>
												</div>
											</div>
										</div>

									</div>
									<div className={`tab-pane ${activeTab === "brainwellness" ? "active show" : ""}`} id="brainwellness">
										{Object.keys(healthRecord).length > 0 ?
											<BrainWellness healthScore={healthScore} healthRecord={healthRecord} wellbeingRecord={wellbeingRecord} brainWelnessRecord={brainWelnessRecord} trainingModule={trainingModule} cognitiveFilter={cognitiveFilter} mindfulFilter={mindfulFilter} brainwellnessModule={brainwellnessModule} callBack={callBack} />
											: null}
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* {isLoading ? <LazyLoader /> : null} */}
				</div>
			</section >
			<Sidebarmob />
		</>
	);
};

export default CommonDashboard;
