import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/Image/logo.svg";
import Einstein from "../../assets/Image/einstein_logo.png";

import SidebarProfile from "../common/sidebarProfile";
import Sidebarmob from "../common/sidebarMobile";
import leftarrow from "../../assets/Image/leftarrow.svg";
import { useTranslation } from "react-i18next";
import { getLocalStoarge } from "../common/allfunction";

function brainwellnessScience() {
    const [t, i18n] = useTranslation('common');

    useEffect(() => {
        handleTranslationLang();
    }, []);


    const handleTranslationLang = () => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang)
        }
    }

    return (
        <>
            <section className="bg-privacy faqs dashboard-mobile">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-2 topspace none-viewport ipad-widhset">
                            <SidebarProfile />
                        </div>
                        <div className="col-md-9 ipd-righ">


                            <div className="pt-5">
                                <h1>Terms and Conditions of Brain Wellness©</h1>
                                <p>Users of the Services offered by Brain Wellness© acknowledge and accept these terms and conditions.</p>
                            </div>

                            <div className="pt-5 pb-3">
                                <h2 className="">Owner of Brain Wellness© and related Services</h2>
                                <p>Neocogita S.r.l.<br />
                                    Via Rialto, 73<br />
                                    38068 Rovereto (TRENTO) - Italy<br />
                                    VAT Number: 02260080227<br />
                                    Paid up share capital: € 50.000,00<br />
                                    Chamber of Commerce of Trento<br />
                                    info@neocogita.com</p>
                            </div>

                            <div className="pt-3">
                                <h2 className="">Introduction</h2>
                                <h3>This document</h3>
                                <p>This document is a legal agreement between you, the User, and the corporation which runs Brain Wellness© for your use of it and, in any case, for the use of the services provided. “Legal agreement” means that the terms of this agreement are binding on the relationship between you and us once you have accepted the terms.
                                    For simplicity, “User,” “you,” “your” and like terms, either in singular or plural form, refer to you, the User. “Neocogita S.r.l.” “we,” “our,” “us” and like terms refer to the corporation which owns and manages Brain Wellness© as outlined in the present document. “Brain Wellness©” refers to the current website and/or application. “Agreement” refers to this document, as amended from time to time. The Agreement is entered into in Italian. Other defined terms can be found in the Agreement or are set forth in the section named “Definitions” at the bottom.</p>
                                <h3>Acceptance of the Agreement</h3>
                                <p>In order to use Brain Wellness©, you must read carefully and agree to this Agreement. Your use of Brain Wellness© implies your acceptance of the Agreement in full. If you do not agree with the present document you must refrain from using Brain Wellness©.</p>
                            </div>

                            <div className="pt-4">
                                <h2 className="">Information about Brain Wellness©</h2>
                                <p>Brain Wellness© is an online platform which is meant to be used by the employees and/or workers (“Participants”) of the enterprises and/or organisations (“Clients”) which join the consultancy and training programme supplied by the Owner.
                                    The platform is open to Participants, Clients and the teachers (Psychologists and Coaches). Each of the said User must respect the limits set forth for the access credentials associated to its type of account.</p>
                            </div>

                            <div className="pt-4">
                                <h2 className="">Registration, Content on Brain Wellness© and Prohibited Use of Brain Wellness©</h2>
                                <h3 className="pt-2">Registration</h3>
                                <p>In order to use the Service or any part of it, Users must register in a truthful and complete manner by providing all the required data in the relevant registration form. The Users must also accept the Privacy Policy and these Terms and Conditions in full. Users are responsible for keeping their unique and personal login credentials confidential which shall be assigned to each User by the Owner. The User is responsible to keep the confidentiality of such credentials and must promptly communicate to the Owner any unauthorised access that the User may be aware of, as well as the loss/theft/dissemination of the said credentials.
                                    The access credentials are assigned by the Owner and they provide for different levels of permissions depending on the type of account they are associated to.
                                    Brain Wellness© is meant to be used by registered Users only, and precisely: Participants, Clients, Coaches e Psychologists.</p>
                                <h3 className="pt-2">Deleting User accounts and account termination</h3>
                                <p>Registered Users can delete their accounts and stop using the Service at any time, through the interface of Brain Wellness© or directly contacting the Owner.
                                    Upon cancellation of the account, the User will only be able to browse through Brain Wellness© but it will not have access to the additional features for which registration is required (e.g. purchase of products).
                                    The Owner reserves the right to suspend or terminate the User's account at any time and without notice if it believes that:</p>

                                <ul>
                                    <li>User has violated this Agreement;</li>
                                    <li>User's access or use of the Service may result in injury to the Owner, other Users or third parties;</li>
                                    <li>the use of Brain Wellness© by the User may result in violation of law or regulations;</li>
                                    <li>in case of an investigation by legal action or governmental involvement;</li>
                                    <li>the account is deemed to be, at the Owner’s sole discretion, for whatever reason, inappropriate or offensive or in violation of this Agreement.</li>
                                </ul>
                                <h3>Contents available on Brain Wellness</h3>
                                <p>The contents available on Brain Wellness© are protected by the laws in force on intellectual property rights and by related international treaties. Unless otherwise stated, their use is permitted exclusively within the limits set forth in the present clause.
                                    The Owner grants the User, for the entire duration of the Agreement, a personal, non-assignable and non-exclusive license for the use of such contents. Such license is solely intended for personal use of any such content and never for its commercial use and is limited to the User's device.
                                    Therefore, User may not copy and/or download and/or share (beyond the limits set forth below), modify, publish, transmit, sell, sublicense, edit, transfer/assign to third parties or create derivative works from the contents, even of third parties, available on Brain Wellness© nor allow any third party to do so through the User or its device, even without User's knowledge.
                                    Where explicitly stated on Brain Wellness©, the User may be authorized, only for personal use, to download and/or copy and/or share some contents available through Brain Wellness©, for its sole personal use and provided that the copyright attributions and all the other attributions requested by the Owner are correctly reported.</p>
                                <h2>Content provided by the User</h2>
                                <p>Users are responsible for their own content and that of third parties that they share through Brain Wellness©, that they upload and post on or through Brain Wellness©, or that they transfer by any other means.Users indemnify the Owner for any liability or claim arising against the Owner in connection with illegal distribution of third-party content or unlawful use of the Service.
                                    The Owner does not moderate the content provided by User or by third parties but it may choose to suspend or terminate display of any such content if:</p>


                                <ul>
                                    <li>complaints are received from other Users;</li>
                                    <li>it receives a copyright infringement claim;</li>
                                    <li>it believes that it must do so in anticipation of, or as part of, legal actions;</li>
                                    <li>action is requested by authorities; or</li>
                                    <li>it believes that such content remaining accessible on or through Brain Wellness© may pose a risk to Users, third parties, the Service's availability and/or the Owner itself</li>
                                </ul>
                                <h3>Rights over content provided by Users</h3>
                                <p>By submitting, posting or displaying content on or through Brain Wellness©, the User grants a license to the Owner without territorial limits, non-exclusive, royalty-free and with the right to sublicense, to use, copy, reproduce, process, adapt, modify, publish, transmit, display, and distribute such content in any media or via distribution methods currently available or developed later.
                                    The only rights granted to the Owner in relation to content provided by Users are those necessary to operate and maintain Brain Wellness©.</p>
                                <h3 className="pt-2">Forbidden use</h3>
                                <p>The Service shall be used only in accordance with these Terms.
                                    Users may not:</p>

                                <ul>
                                    <li>reverse engineer, decompile, disassemble, modify or create derivative works based on Brain Wellness© or any portion of it;</li>
                                    <li>circumvent any technology used by Brain Wellness© or its licensors to protect content accessible via it;</li>
                                    <li>copy, store, edit, change, prepare any derivative work of or alter in any way any of the content provided through Brain Wellness©;</li>
                                    <li>use any robot, spider, site search/retrieval application, or other automated device, process or means to access, retrieve, scrape, or index any portion of Brain Wellness© or its content;</li>
                                    <li>rent, lease or sublicense Brain Wellness©;</li>
                                    <li>defame, abuse, harass, use threatening practices, threaten or violate the legal rights of others in any other way (such as rights of privacy and publicity)</li>
                                    <li>disseminate or publish content that is unlawful, obscene, illegitimate, defamatory or inappropriate;</li>
                                    <li>use Brain Wellness© in any other improper way that violates these Terms.</li>
                                </ul>
                            </div>
                            <div className="pt-5">
                                <h2 className="">Indemnification and Limitation of Liability</h2>
                                <h3>Indemnity</h3>
                                <p>The User agrees to indemnify and hold the Owner and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners and employees, as the case may be, harmless from and against any claim or demand, including without limitation, reasonable lawyer's fees and costs, made by any third party due to or arising out of the User’s content, use of or connection to the Service, violation of these Terms, or violation of any third-party rights.</p>
                                <h3>Limitations of liability</h3>
                                <p>Brain Wellness© and all functions accessible through Brain Wellness© are made available to the Users under the terms and conditions of the Agreement, without any warranty, express or implied, that is not required by law. In particular, there is no guarantee of suitability of the services offered for the User's specific goals. Brain Wellness© and functions accessible through Brain Wellness© are used by the Users at their own risk and under their own responsibility.
                                    In particular, the Owner, within the limits of applicable law, is liable for contractual and non-contractual damages to Users or third parties only by way of intent or gross negligence, when these are immediate and direct consequences of the activity of Brain Wellness©. Therefore, the Owner shall not be liable for:</p>

                                <ul>
                                    <li>any losses that are a not direct consequence of the breach of the Agreement by the Owner;</li>
                                    <li>any loss of business opportunities and any other loss, even indirect, that may be incurred by the User (such as, but not limited to, trading losses, loss of revenue, income, profits or anticipated savings, loss of contracts or business relationships, loss of reputation or goodwill, etc.);</li>
                                    <li>damages or losses resulting from interruptions or malfunctions of Brain Wellness© due to acts of force majeure, or at least to unforeseen and unforeseeable events and, in any case, independent of the will and extraneous to the Owner's control, such as, by way of example but not limited to, failures or disruptions of telephone or electrical lines, the Internet and / or other means of transmission, unavailability of websites, strikes, natural disasters, viruses and cyber attacks, interruptions in the delivery of products, third party services or applications and</li>
                                    <li>incorrect or unsuitable use of Brain Wellness© by Users or third parties.</li>
                                </ul>
                                <p>The Service provided by the Owner of Brain Wellness© consists solely of supplying questionnaires and tests aimed at stimulating the cognitive potential of the individual, and it is meant to be used within the larger framework of a training programme carried out by the experts who lead the training course attended by the Participant.
                                    Such tests/questionnaires have been studied and elaborated by specialists and are the result of their authoritative research and experience. Brain Wellness© subjects any content it publishes on the platform to a strict selection process and it encourages any suggestions and/or comments that Users may want to submit about the content published.
                                    However, the Participant must understand that the tests/questionnaires available on Brain Wellness© shall not, under any circumstances, be regarded as a tool for personal diagnosis and/or as a substitute for medical or psychological advice.
                                    Said tests/questionnaires have the sole purpose of complementing the course undertaken by the Participant and in no case shall any diagnostic value be attributed to them that could even theoretically encourage the Participant to any “do it by yourself” practice, or to put an end to or modify any medical treatment currently underway.
                                    <br />
                                    <strong>
                                        By using the Service, the Client warrants that it has fully and adequately informed the Participants about the Service and that it has acted in the strictest compliance with the law, especially with the labour law provisions that apply. Furthermore, the Client warrants that it has collected all the mandatory consents as requested by the law, including but not limited to, those requested by the data protection law that applies. As a consequence, the Client indemnifies and holds the Owner harmless from any possible claim, raised by the Participants or by any third party, that may arise as as result of its failure to comply with the law.
                                    </strong>

                                </p>
                            </div>
                            <div className="pt-5">
                                <h2 className="">Miscellaneous</h2>
                                <h3>Service interruption</h3>
                                <p>To guarantee the Users the best possible use of the Service, the Owner reserves the right to interrupt the service for maintenance or system updates, informing the Users through constant updates published on Brain Wellness©.</p>
                                <h3>Privacy policy</h3>
                                <p>For information about the use of their personal data, Users must refer to the privacy policy of Brain Wellness©.</p>
                                <h3>Intellectual property</h3>
                                <p>All the Application's trademarks, nominal or figurative, and all other marks, trade names, service marks, word marks, illustrations, images, or logos that appear concerning Brain Wellness© are, and remain, the exclusive property of the Owner or available to its licensors and are protected by the laws in force on trademarks and by related international treaties.
                                    All trademarks, nominal or figurative, and all other marks, trade names, service marks, word marks, illustrations, images, or logos that appear concerning third parties and the content published by these third parties on Brain Wellness© are, and remain, the exclusive property of -the Owner -these third parties or available to its licensors and are protected by the laws in force on trademarks and by related international treaties. The Owner does not own the property of these rights and may use them only within the limits and in accordance with the contracts in place with these third parties and for the set out purposes.</p>

                                <h3>Changes to these Terms</h3>
                                <p>The Owner reserves the right to modify these Terms at any time, informing Users by publishing a notice within Brain Wellness©.
                                    Users who continue to use Brain Wellness© after the publication of the changes accept the new Terms in their entirety.</p>

                                <h3>Contacts</h3>
                                <p>All communications relating to Brain Wellness© must be sent using the contacts stated in this document.</p>
                                <h3>Severability</h3>
                                <p>If any provision of this Agreement is deemed invalid or unenforceable, such provision shall be void while the remaining provisions will not be affected by this and shall remain in full force and effect.</p>
                                <h3>Authoritative version of these legal documents</h3>
                                <p>These Terms have been written and reviewed in the italian language. Any translations into any language other than italian are to be considered mere translations. In case of any discrepancy or inconsistency, the italian language text shall prevail in any event.</p>
                                <h3>Governing law and jurisdiction</h3>
                                <p>These Terms and any dispute concerning the implementation, interpretation and validity of this agreement are subject to the law, the jurisdiction of the state and to the exclusive jurisdiction of the courts where the Owner has their registered offices. An exception to this rule applies in cases, where the law provides a sole place of jurisdiction for consumers.</p>
                                <h3>Online dispute resolution for consumers</h3>
                                <p>The consumer resident in Europe must be aware that the European Commission has established an online platform for alternative dispute resolutions that provides for an out-of-court method to solve any dispute related to and stemming from online sale and service contracts. As a consequence, if you are a European consumer, you can use such platform for resolving any dispute stemming from the online contract entered into with the Owner. The platform is available at the following link.
                                    The Owner is available for any questions via the email address posted under the Owner's information in this document.</p>
                            </div>

                            <div className="accordion pl-0 none-plusicon" id="accordionExample">
                                <div className="card">
                                    <div className="card-header" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false">
                                        <span className="title" style={{fontSize:"20px", fontWeight: "normal"}}>Definitions and legal references</span>
                                        <span className="accicon">
                                            <svg className="rotate-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                <path d="M8 12L14 6V18L8 12Z"></path></svg>
                                        </span>
                                    </div>
                                    <div id="collapseOne" className="collapse" data-parent="#accordionExample">
                                        <div className="card-body">
                                            <h4>Service</h4>
                                            <p>The service provided by Brain Wellness© as described in these Terms and in Brain Wellness©.</p>

                                            <h4>Terms and Conditions (or Terms)</h4>
                                            <p>These Terms and Conditions, which constitute a legally binding agreement between the User and the Owner.</p>


                                            <h4>User</h4>
                                            <p>Any user of the Service, whether a human being or legal entity. Unless stated otherwise, the word User refers to all the different types of accounts available on Brain Wellness©: Client, Coach, Participant and Psychologist.</p>

                                            <h4>Client</h4>
                                            <p>Each User who is an enterprise and/or organisation and avails itself of the consultancy and training programme provided by the Owner for its employees and/or workers.</p>

                                            <h4>Participant</h4>
                                            <p>Each User who is a employee or worker of the Client and participates in the training programme provided by the Owner.</p>

                                            <h4>Coach</h4>
                                            <p>Each User who is a coach and provides frontal lessons to the Participant during the training programme.</p>

                                            <h4>Psychologist</h4>
                                            <p>Each User who is a licensed psychologist and provides frontal lessons to the Participant during the training programme.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="pt-5 pb-3">
                                <p>Latest update: September 01, 2017</p>
                                <p>iubenda hosts this content and only collects the Personal Data strictly necessary for it to be provided</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="act-ve">
                <Sidebarmob />
            </div>
        </>
    );
}

export default brainwellnessScience;
