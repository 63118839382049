import assiduo from "../../assets/Image/medal-detail/assiduo.png";
import relax from "../../assets/Image/medal-detail/relax.png";
import fisicoperfetto from "../../assets/Image/medal-detail/fisico-perfetto.png";
import wellbeingcrescita from "../../assets/Image/medal-detail/wellbeing-crescita.png";

import good1 from "../../assets/Image/medal-detail/good1.png";
import good2 from "../../assets/Image/medal-detail/good2.png";
import good3 from "../../assets/Image/medal-detail/good3.png";
import good4 from "../../assets/Image/medal-detail/good4.png";

import expert1 from "../../assets/Image/medal-detail/expert1.png";
import expert2 from "../../assets/Image/medal-detail/expert2.png";
import expert3 from "../../assets/Image/medal-detail/expert3.png";
import expert4 from "../../assets/Image/medal-detail/expert4.png";

import mindful1 from "../../assets/Image/medal-detail/mindful1.png";
import mindful2 from "../../assets/Image/medal-detail/mindful2.png";
import mindful3 from "../../assets/Image/medal-detail/mindful3.png";

import cognitive1 from "../../assets/Image/medal-detail/cognitive1.png";
import cognitive2 from "../../assets/Image/medal-detail/cognitive2.png";
import cognitive3 from "../../assets/Image/medal-detail/cognitive3.png"; 

import wellbeing1 from "../../assets/Image/medal-detail/wellbeing1.png";
import wellbeing2 from "../../assets/Image/medal-detail/wellbeing2.png";
import wellbeing3 from "../../assets/Image/medal-detail/wellbeing3.png";

const medalList = {
    "it": [
        {
            "icon" : assiduo,
            "name": "assiduo misuratore", // MISURAZIONE TOITM 
            "description": "Hai ricevuto la medaglia «Assiduo Misuratore» per aver effettuato almeno 20 misurazioni nel mese in corso!",
            "description1": "Scopri come ottenere le altre medaglie relative alla Misurazione",
            "popup":"well"
        },
        {
            "icon" : relax,
            "name": "rilassato",
            "description": "Hai ricevuto la medaglia «Rilassato» per aver mantenuto l’indice di stress inferiore a 3 per due settimane.!",
            "description1": "Scopri come ottenere le altre medaglie relative alla Misurazione",
            "popup":"well"
        },
        {
            "icon" : fisicoperfetto,
            "name": "fisico perfetto",
            "description": "Hai ricevuto la medaglia «Fisico perfetto» per aver mantenuto il tuo indice di massa corporea (BMI) tra 18.5 e  24.9 durante tutto il mese!",
            "description1": "Scopri come ottenere le altre medaglie relative alla Misurazione",
            "popup":"well"
        },
        {
            "icon" : wellbeingcrescita,
            "name": "wellbeing in crescita",
            "description": "Hai ricevuto la medaglia «Wellbeing in crescita» perché il tuo indice di benessere generale medio è aumentato di almeno un punto rispetto al mese precedente!",
            "description1": "Scopri come ottenere le altre medaglie relative alla Misurazione",
            "popup":"well"
        },
        {
            "icon" : good1,
            "name": "Buona salute mese",
            "description": "Hai ricevuto la medaglia «Buona Salute» per aver sempre mantenuto, durante il mese, un indice di benessere generale compreso tra 60 e 79!",
            "description1": "Scopri come ottenere le altre medaglie relative alla Misurazione",
            "popup":"well"
        },
        {
            "icon" : good2,
            "name": "Buona salute trimestre",
            "description": "Hai ricevuto la medaglia «Buona Salute» per aver sempre mantenuto, durante il trimestre, un indice di benessere generale compreso tra 60 e 79! ",
            "description1": "Scopri come ottenere le altre medaglie relative alla Misurazione",
            "popup":"well"
        },
        {
            "icon" : good3,
            "name": "Buona salute semestre",
            "description": "Hai ricevuto la medaglia «Buona Salute» per aver sempre mantenuto, durante il semestre, un indice di benessere generale compreso tra 60 e 79!",
            "description1": "Scopri come ottenere le altre medaglie relative alla Misurazione",
            "popup":"well"
        },
        {
            "icon" : good4,
            "name": "Buona salute anno",
            "description": "Hai ricevuto la medaglia «Buona Salute» per aver sempre mantenuto, durante l’anno, un indice di benessere generale compreso tra 60 e 79!",
            "description1": "Scopri come ottenere le altre medaglie relative alla Misurazione",
            "popup":"well"
        },
        {
            "icon" : expert1,
            "name": "ottima salute mese",
            "description": "Hai ricevuto la medaglia «Ottima Salute» per aver sempre mantenuto, durante il mese, un indice di benessere generale compreso tra 80 e 100!",
            "description1": "Scopri come ottenere le altre medaglie relative alla Misurazione",
            "popup":"well"
        },
        {
            "icon" : expert2,
            "name": "ottima salute trimestre",
            "description": "Hai ricevuto la medaglia «Ottima Salute» per aver sempre mantenuto, durante il trimestre, un indice di benessere generale compreso tra 80 e 100!",
            "description1": "Scopri come ottenere le altre medaglie relative alla Misurazione",
            "popup":"well"
        },
        {
            "icon" : expert3,
            "name": "ottima salute semestre",
            "description": "Hai ricevuto la medaglia «Ottima Salute» per aver sempre mantenuto, durante il semestre, un indice di benessere generale compreso tra 80 e 100!",
            "description1": "Scopri come ottenere le altre medaglie relative alla Misurazione",
            "popup":"well"
        },
        {
            "icon" : expert4,
            "name": "ottima salute anno",
            "description": "Hai ricevuto la medaglia «Ottima Salute» per aver sempre mantenuto, durante l’anno, un indice di benessere generale compreso tra 80 e 100!",
            "description1": "Scopri come ottenere le altre medaglie relative alla Misurazione",
            "popup":"well"
        },

        {
            "icon" : mindful1,
            "name": "assiduo meditatore", // MINDFULNESS 
            "description": "Hai ricevuto la medaglia «Assiduo Meditatore» per aver completato 5 sessioni di Mindfulness nella settimana in corso!",
            "description1": "Scopri come ottenere le altre medaglie relative alla Mindfulness",
            "popup":"brainwellness"
        },
        {
            "icon" : mindful2,
            "name": "buon meditatore",
            "description": "Hai ricevuto la medaglia «Buon Meditatore» per aver completato la metà delle sessioni previste dal protocollo di Mindfulness!",
            "description1": "Scopri come ottenere le altre medaglie relative alla Mindfulness",
            "popup":"brainwellness"
        },
        {
            "icon" : mindful3,
            "name": "esperto meditatore",
            "description": "Hai ricevuto la medaglia «Esperto Meditatore» per aver completato tutte le sessioni previste dal protocollo di Mindfulness!",
            "description1": "Scopri come ottenere le altre medaglie relative alla Mindfulness",
            "popup":"brainwellness"
        },

        {
            "icon" : cognitive1,
            "name": "brain fitness top", // TRAINING COGNITIVO 
            "description": "Hai ricevuto la medaglia «Brain Fitness Top» per aver completato 5 sessioni di Training Cognitivo nella settimana in corso!",
            "description1": "Scopri come ottenere le altre medaglie relative al Training Cognitivo",
            "popup":"cognitive"
        },
        {
            "icon" : cognitive2,
            "name": "brain fitness leader",
            "description": "Hai ricevuto la medaglia «Brain Fitness Leader» per aver completato la metà delle sessioni previste dal protocollo di Training Cognitivo!",
            "description1": "Scopri come ottenere le altre medaglie relative al Training Cognitivo",
            "popup":"cognitive"
        },
        {
            "icon" : cognitive3,
            "name": "brain fitness expert",
            "description": "Hai ricevuto la medaglia «Brain Fitness Expert» per aver completato tutte le sessioni previste dal protocollo di Training Cognitivo!",
            "description1": "Scopri come ottenere le altre medaglie relative al Training Cognitivo",
            "popup":"cognitive"
        },

        {
            "icon" : wellbeing1,
            "name": "alunno modello", // Wellbeing Literacy 
            "description": "Hai ricevuto la medaglia «Alunno Modello» per aver completato il corso previsto nel mese corrente!",
            "description1": "Scopri come ottenere le altre medaglie relative alla Wellbeing Literacy",
            "popup":"wellbeing"
        },
        {
            "icon" : wellbeing2,
            "name": "wellbeing master",
            "description": "Hai ricevuto la medaglia «Wellbeing Master» per aver completato la metà dei corsi dal programma!",
            "description1": "Scopri come ottenere le altre medaglie relative alla Wellbeing Literacy",
            "popup":"wellbeing"
        },
        {
            "icon" : wellbeing3,
            "name": "wellbeing expert",
            "description": "Hai ricevuto la medaglia «Wellbeing Expert» per aver completato tutti i corsi previsti dal programma!",
            "description1": "Scopri come ottenere le altre medaglie relative alla Wellbeing Literacy",
            "popup":"wellbeing"
        },
    ],
    "en": [
        {
            "icon" : assiduo,
            "name": "ASSIDUOUS MISURER", // MISURAZIONE TOITM 
            "description": "You received the 'Assiduous Misurer ' medal for taking at least 20 measurements in the current month!",
            "description1": "Find out how to get other Measurement medals",
            "popup":"well"
        },
        {
            "icon" : relax,
            "name": "RELAXED",
            "description": "You received the 'Relaxed' medal for keeping your stress index below 3 for two weeks!",
            "description1": "Find out how to get other Measurement medals",
            "popup":"well"
        },
        {
            "icon" : fisicoperfetto,
            "name": "PERFECT PHYSIQUE",
            "description": "You received the 'Perfect Physique' medal for keeping your body mass index (BMI) between 18.5 and 24.9 throughout the month!",
            "description1": "Find out how to get other Measurement medals",
            "popup":"well"
        },
        {
            "icon" : wellbeingcrescita,
            "name": "GROWING WELLBEING",
            "description": "You received the 'Growing Wellbeing' medal because your average General Wellbeing Index increased by at least one point from the previous month!",
            "description1": "Find out how to get other Measurement medals",
            "popup":"well"
        },
        {
            "icon" : good1,
            "name": "GOOD HEALTH month",
            "description": "You received the 'Good Health' medal for consistently maintaining the General Wellbeing Index between 60 and 79 during the month!",
            "description1": "Find out how to get other Measurement medals",
            "popup":"well"
        },
        {
            "icon" : good2,
            "name": "GOOD HEALTH quarter",
            "description": "You received the 'Good Health' medal for consistently maintaining the General Wellbeing Index between 60 and 79 during the quarter! ",
            "description1": "Find out how to get other Measurement medals",
            "popup":"well"
        },
        {
            "icon" : good3,
            "name": "GOOD HEALTH semester",
            "description": "You received the 'Good Health' medal for consistently maintaining the General Wellbeing Index between 60 and 79 during the semester!",
            "description1": "Find out how to get other Measurement medals",
            "popup":"well"
        },
        {
            "icon" : good4,
            "name": "GOOD HEALTH year",
            "description": "You received the 'Good Health' medal for consistently maintaining the General Wellbeing Index between 60 and 79 during the year!",
            "description1": "Find out how to get other Measurement medals",
            "popup":"well"
        },
        {
            "icon" : expert1,
            "name": "EXCELLENT HEALTH month",
            "description": "You received the 'Excellent Health' medal for consistently maintaining a General Wellbeing Index between 80 and 100 during the month!",
            "description1": "Find out how to get other Measurement medals",
            "popup":"well"
        },
        {
            "icon" : expert2,
            "name": "EXCELLENT HEALTH quarter",
            "description": "You received the 'Excellent Health' medal for consistently maintaining a General Wellbeing Index between 80 and 100 during the quarter!",
            "description1": "Find out how to get other Measurement medals",
            "popup":"well"
        },
        {
            "icon" : expert3,
            "name": "EXCELLENT HEALTH semester",
            "description": "You received the 'Excellent Health' medal for consistently maintaining a General Wellbeing Index between 80 and 100 during the semester!",
            "description1": "Find out how to get other Measurement medals",
            "popup":"well"
        },
        {
            "icon" : expert4,
            "name": "EXCELLENT HEALTH year",
            "description": "You received the 'Excellent Health' medal for consistently maintaining a General Wellbeing Index between 80 and 100 during the year!",
            "description1": "Find out how to get other Measurement medals",
            "popup":"well"
        },

        {
            "icon" : mindful1,
            "name": "FREQUENT MEDITATOR", // MINDFULNESS 
            "description": "You have received the 'Frequent Meditator' medal for completing 5 Mindfulness sessions in the current week!",
            "description1": "Find out how to get other Mindfulness medals",
            "popup":"brainwellness"
        },
        {
            "icon" : mindful2,
            "name": "GOOD MEDITATOR",
            "description": "You have received the 'Good Meditator' medal for completing half of the sessions in the Mindfulness protocol!",
            "description1": "Find out how to get other Mindfulness medals",
            "popup":"brainwellness"
        },
        {
            "icon" : mindful3,
            "name": "EXPERT MEDITATOR",
            "description": "You have received the 'Expert Meditator' medal for completing all the sessions provided in the Mindfulness protocol!",
            "description1": "Find out how to get other Mindfulness medals",
            "popup":"brainwellness"
        },

        {
            "icon" : cognitive1,
            "name": "brain fitness top", // TRAINING COGNITIVO 
            "description": "You received the 'Brain Fitness Top' medal for completing 5 Cognitive Training sessions in the current week!",
            "description1": "Find out how to get other Cognitive Training medals",
            "popup":"cognitive"
        },
        {
            "icon" : cognitive2,
            "name": "brain fitness leader",
            "description": "You have received the 'Brain Fitness Leader' medal for completing half of the sessions in the Cognitive Training protocol! ",
            "description1": "Find out how to get other Cognitive Training medals",
            "popup":"cognitive"
        },
        {
            "icon" : cognitive3,
            "name": "brain fitness expert",
            "description": "You have received the 'Brain Fitness Expert' medal for completing all the sessions provided in the Cognitive Training protocol! ",
            "description1": "Find out how to get other Cognitive Training medals",
            "popup":"cognitive"
        },

        {
            "icon" : wellbeing1,
            "name": "MODEL PUPIL", // Wellbeing Literacy 
            "description": "You received the 'Model Pupil' medal for completing the scheduled course in the current month!",
            "description1": "Find out how to get other Wellbeing Literacy medals",
            "popup":"wellbeing"
        },
        {
            "icon" : wellbeing2,
            "name": "wellbeing master",
            "description": "You received the 'Wellbeing Master' medal for completing half of the courses from the program!",
            "description1": "Find out how to get other Wellbeing Literacy medals",
            "popup":"wellbeing"
        },
        {
            "icon" : wellbeing3,
            "name": "wellbeing expert",
            "description": "You received the 'Wellbeing Expert' medal for completing all the courses in the program!",
            "description1": "Find out how to get other Wellbeing Literacy medals",
            "popup":"wellbeing"
        },
    ]
}
export default medalList;