import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../../assets/Image/logo.svg";
import { useTranslation } from "react-i18next";
import { getLocalStoarge } from '../../../common/allfunction';
import Group1765 from "../../../../assets/Image/Group1765.svg";
import Group1766 from "../../../../assets/Image/Group1766.svg";
import click from "../../../../assets/Image/click.svg";
import wait from "../../../../assets/Image/wait.svg";
import PostRequest from '../../../apiConnection/postRequest';
import GetRequest from "../../../apiConnection/getRequest";
import BRAIN_NEXT_ICONA from "../../../../assets/Image/BRAIN_NEXT_ICONA.svg";
import Circle from "../../../../assets/Image/Circle.svg";
import Rectangle from "../../../../assets/Image/Rectangle325.svg";
import Hexagon from "../../../../assets/Image/Hexagon.svg";
import Rhombus from "../../../../assets/Image/Rhombus.svg";
import Triangle from "../../../../assets/Image/Triangle.svg"


import { NBackIndex } from "./NBackIndex";

export const NBackInfo = (props) => {
    const [t, i18n] = useTranslation("common");
    // console.log("props", props);
    const [totalTime, setTotalTime] = useState();
    const [activityId, setActivityId] = useState();
    const [timeRemaining, setTimeRemaining] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [round, setRound] = useState(0);
    const [avgAccuracy, setAvgAccuracy] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const [activityPlayTime, setActivityPlayTime] = useState();
    const [activityStatus, setActivityStatus] = useState();
    const [step, setStep] = useState(0);
    const [n, setN] = useState(2);
    const component_id = props?.match?.params?.component_id;
    const repeat_time = props?.match?.params?.repeat_time;
    const day_sequence = props?.match?.params?.day_sequence;
    const activity_id = props?.match?.params?.activity_id;
    const [showNBackIndex, setShowNBackIndex] = useState(false);
    const shapeArr = [Hexagon, Rectangle, Circle, Rhombus, Triangle];
    const limitedItems = shapeArr.slice(0, n);
    const [free, setFree] = useState(props?.match?.params?.free);
    // condition to set N
    // you also have to pass n in index page
    useEffect(() => {
        console.log("getActivityUserData use effect called")
        if (!free) {
            getActivityUserData();
        }
    }, [])

    const getActivityUserData = async () => {
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );

            console.log("refresh_token", refreshToken);
            if (refreshToken) {
                const getUserActivityData = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_USER_ACTIVITY_STATUS}?repeat_time=${repeat_time}&component_id=${component_id}&day_sequence=${day_sequence}&activity_id=${activity_id}`,
                    "",
                    "",
                    ""
                );

                console.log("getPreAssessmentData_3", getUserActivityData?.data?.data?.activity_data);
                if (getUserActivityData && getUserActivityData.status === 200) {
                    console.log("getPreAssessmentData_4_1", getUserActivityData);
                    setIsLoading(false);
                    if (getUserActivityData?.data?.data?.activity_data) {
                        console.log("get-api-data", getUserActivityData?.data?.data?.activity_data);
                        let dataObj = getUserActivityData?.data?.data?.activity_data;
                        setActivityId(dataObj.activity_id);
                        setActivityPlayTime(dataObj.activity_play_time);
                        setTotalTime(dataObj.activity_total_data[0].activity_duration);
                        setTimeRemaining(((dataObj.activity_total_data[0].activity_duration) * 60) - (dataObj.activity_play_time));
                        setRound(dataObj.activity_round_no);
                        setAvgAccuracy(dataObj.activity_score);
                        setActivityStatus(dataObj.status);
                        // setTotalAvgResponseTime(dataObj.total_avg_response_time);
                        // setTotalAvgResponseTime(0);

                    }
                } else if (getAllDataResult && getAllDataResult.status === 203) {
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log("error", error)
        }
    }

    const resetRound = (accuracyLastRound, n) => {
        // console.log('Parent function  is called');

        if (accuracyLastRound >= .6) {
            if (n == 4) {
                setN(n)
            } else {
                setN(n + 1);
            }
        } else if (accuracyLastRound <= .6 && accuracyLastRound >= .4) {
            setN(n)
        } else {
            if (n == 2) {
                setN(n)
            } else {
                setN(n - 1);
            }
        }

        setStep(0);
        // setClickCount(0)
        setRound(round + 1);
        // setAccuracyCurrentRound(0);
        getActivityUserData();
        setShowNBackIndex(false);
        console.log("round_1", step);
    }

    const togglePause = () => {
        setIsPaused(!isPaused);
    };

    const startTimer = () => {
        setIsPaused(false);
    }

    // console.log("params...", props.match.params);

    return (
        <>
            {!showNBackIndex
                ?
                <section className="reaction-speed-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="stepsin stpelog">
                                    <Link to="dashboard">
                                        <img src={logo} className="logo-steps" alt="logo" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="btnNext-top">
                                    {free ?
                                        <Link to={`/n-back-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}/${free}`}>
                                            <button
                                                className="btn btn-primary  btnNext"
                                            >
                                                {t("user.activity.reaction_speed.back")}
                                            </button>
                                        </Link>
                                        :
                                        <Link to={`/n-back-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}`}>
                                            <button
                                                className="btn btn-primary  btnNext"
                                            >
                                                {t("user.activity.reaction_speed.back")}
                                            </button>
                                        </Link>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="reaction-speed-p">
                                    <p className="reaction-speed-p-1">{t("user.activity.n_back.intro_heading")}</p>
                                    <p className="reaction-speed-p-2">{t("user.activity.n_back.info_message_1")}<br></br>{n} {t("user.activity.n_back.info_message_2")}</p>
                                </div>
                            </div>
                            <div className="col-md-12">

                                <div className="reaction-speed-main n-back-info">
                                    {/* <h1> {n}</h1> */}
                                    {
                                        limitedItems.map((e, i) =>
                                            <>
                                                {/* <img className="n-back-info-shape" src={`/static/media/shape_${i}.96e80518.svg`} alt="" /> */}

                                                <img className="n-back-info-shape" src={limitedItems[i]} alt="" />
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                            <div id="btnNext">
                                <div className="n-back-info-next">
                                    <div>
                                        <button className="remove-colorbtn">
                                            {t("user.activity.reaction_speed.see_tutorial")}
                                        </button>
                                        <img src={BRAIN_NEXT_ICONA} className="" alt="" />
                                    </div>
                                    {/* <Link to={`/reaction-speed/${component_id}/${repeat_time}/${day_sequence}/${activity_id}`}> */}
                                    <button className="btn btn-primary btnNext" onClick={() => setShowNBackIndex(true)} >
                                        {t("user.activity.reaction_speed.start")}
                                    </button>
                                    {/* </Link> */}
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                :
                <NBackIndex props={{ "round": round, "totalTime": totalTime, "resetRound": resetRound, "startTimer": startTimer, "timeRemaining": timeRemaining, "avgAccuracy": avgAccuracy, "params": props.match.params, "step": step, "n": n, "free": free }} />
            }
        </>
    );
}

