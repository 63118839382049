import Sidebar from "../common/sidebar";
import Sidebarmob from "../common/sidebarMobile";
import { useEffect, useState ,useRef} from "react";
import { useTranslation } from "react-i18next";
import { getLocalStoarge } from "../common/allfunction";
const UserManual = () => {
    useEffect(() => {
        handleTranslationLang();
        UserManual();
    }, [])
    const [t, i18n] = useTranslation("common");
    const [language, setLanguage] = useState();
    const [iframeUrl, setIframeUrl] = useState(process.env.REACT_APP_ITA_USER_MANUAL);
    const handleTranslationLang = () => {
        let lang = getLocalStoarge("langauage");
        setLanguage(lang);
        if (lang) {
            i18n.changeLanguage(lang);
        }
    };
    const UserManual = () => {
        let lang = getLocalStoarge("langauage");

        if (lang == 'en') {
            setIframeUrl(process.env.REACT_APP_EN_USER_MANUAL);
        }
    };
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    
    const iframCss = {
        'width': '100%',
        'border': ' 1px solid #ccc',
        'border-radius': '5px',
        'padding': 'none',
    }
    const htmlFilePath = `${process.env.PUBLIC_URL}/ita/index.html`;
    return (
        <>
            <section className="full_bg dashboard-mobile dashboard-section">
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar />
                        <div className=" col-md-10 p0 pr-0 ipd-righ">
                            <div className="right-dashboard topspace leftr-dari">
                                <h2 className="heading-dash">
                                    {t("user.sidebar.manual")}
                                </h2>
                                <div className="mt-4">
                                    <iframe src={iframeUrl} style={iframCss}  height={windowHeight-100}></iframe>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section >

            <Sidebarmob />
        </>

    );
}
export default UserManual;