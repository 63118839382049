import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Super_Sidebar from "./super_sidebar";
import { getLocalStoarge } from "../../common/allfunction";
import PREV_PAGE_ICON from '../../../assets/Image/PREVPAGE_ICON.svg';
import Group878 from "../../../assets/Image/Group878.svg";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PostRequest from "../../apiConnection/postRequest";
import Super_Sidebarmob from "./super_sidebarmob";
import LazyLoader from "../../common/lazyLoader";
import GetRequest from "../../apiConnection/getRequest";
import { Link } from "react-router-dom";

export const Edit_brainwellness = (props) => {
    const [t, i18n] = useTranslation("common");
    const [isLoading, setIsLoading] = useState(false);
    const [companyName, setCompanyName] = useState(props.match.params.company_name);
    const [companyId, setCompanyId] = useState(props.match.params.company_id);
    const [companyDynamicId, setCompanyDynamicId] = useState(getLocalStoarge('dynamic_id'))
    const [numbers, setNumbers] = useState([0, 1, 2, 3, 4, 5,]);
    const [selectedNum, setSelectedNum] = useState()
    const [startDate, setStartDate] = useState(new Date());
    const [trainingData, setTrainingData] = useState({
        company_id: companyId,
    })
    const [repeatMindfulness, setRepeatMindfulness] = useState(null)
    const [repeatCognitive,setRepeatCognitive]=useState(null)

    useEffect(() => {
        getAssignTrainingData();
        let dateArr = []
        let tomorrow = moment().add(1, 'days').unix()
        dateArr.push(tomorrow)
        setTrainingData({ ...trainingData, repeat_start_dates_mindfulness: dateArr, repeat_start_dates_cognitive: dateArr })
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }

    }, [])

    const handleChange = (e, type, days) => {
        const selectedNum = e.target.value
        const arr = futureSelectedDate(selectedNum, days)
        if (type === 'mindfulness') {
            setRepeatMindfulness(selectedNum)
            setTrainingData({ ...trainingData, repeat_allowed_mindfulness: +selectedNum, repeat_start_dates_mindfulness: arr })
        }
        else {
            setRepeatCognitive(selectedNum)
            setTrainingData({ ...trainingData, repeat_allowed_cognitive: +selectedNum, repeat_start_dates_cognitive: arr })
        }

    }

    const futureSelectedDate = (selectedNum, days, i) => {
        setSelectedNum(selectedNum)
        let tomorrow = moment().add(1, 'days');
        const arr = [];
        for (let i = 0; i < selectedNum; i++) {
            const new_date = moment(tomorrow, "DD-MM-YYYY").add('days', i * days);
            const convertedDate = new_date.unix();
            arr.push(convertedDate);
        }
        return arr
    }


    const onDateChange = (date, index, type, days) => {
        const changeDate = moment(date).unix()
        if (type === 'mindfulness') {
            let arr = trainingData.repeat_start_dates_mindfulness.slice(0)
            let p = 0;
            for (let i = index; i < selectedNum; i++) {
                const newDate = moment(date, "DD-MM-YYYY").add('days', p * days);
                let convertedDate = newDate.unix();
                arr[i] = convertedDate
                p++;
            }
            setTrainingData({ ...trainingData, repeat_start_dates_mindfulness: arr });

        }
        else {
            let arr = trainingData.repeat_start_dates_cognitive.slice(0)
            let p = 0;
            for (let i = index; i < selectedNum; i++) {
                const newDate = moment(date, "DD-MM-YYYY").add('days', p * days);
                let convertedDate = newDate.unix();
                arr[i] = convertedDate
                p++;
            }
            setTrainingData({ ...trainingData, repeat_start_dates_cognitive: arr });
        }

    }

    const getAssignTrainingData = async () => {
        setIsLoading(true);
        try {
            // const refreshToken = await PostRequest(
            //     `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
            //     {},
            //     "",
            //     "refresh_token"
            // );
            // if (refreshToken) {
                const getTrainingData = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_ASSIGN_TRAINING_DATA}?company_id=${companyId}&company_dynamic_id=${companyDynamicId}`
                );
                setIsLoading(false)
                if (getTrainingData && getTrainingData.status === 200) {
                    setTrainingData({ ...trainingData, ...getTrainingData?.data?.data?.module_training_date })
                    setRepeatMindfulness(getTrainingData?.data?.data?.module_training_date?.repeat_allowed_mindfulness)
                    setRepeatCognitive(getTrainingData?.data?.data?.module_training_date?.repeat_allowed_cognitive)
                }
                else{
                    setIsLoading(false)
                }
            // } else {
            //     setIsLoading(false);
            // }
        } catch (error) {
            console.log("error", error)
            setIsLoading(false);
        }
    }


    const assignTraining = async () => {
        setIsLoading(true)
        try {
            // const refreshToken = await PostRequest(
            //     `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
            //     {},
            //     "",
            //     "refresh_token"
            // );
            // if (refreshToken) {
                const addTrainingData = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADD_COMPANY_TRAINING_DATE}`,
                    trainingData,
                    "",
                    ""
                );
                if (addTrainingData && addTrainingData.status === 200) {
                    setIsLoading(false);
                }
                else {
                    setIsLoading(false)
                }
            // } else {
            //     setIsLoading(false);
            // }
        } catch (error) {
            console.log("error", error)
            setIsLoading(false);
        }
    }

    return (
        <>
            <section className="full_bg dashboard-mobile">
                <div className="container-fluid">
                    <div className="row">
                        <Super_Sidebar path="brain-wellness" />
                        <div className="col-md-10 pr-0 p0 ipd-righ">
                            <div className="right-dashboard">
                                <div className="d-flex justify-content-between protocollol-space">
                                    <div className="protocollol-space">
                                        <h2 className="heading-dash">
                                            <button className="back-button" onClick={() => window.location.href = `/sa/brain-wellness`}><img src={PREV_PAGE_ICON} alt="icon" /></button>
                                            {companyName}</h2>
                                    </div>
                                </div>
                                <div className="edit-training-date">
                                    <div className="row mt-5 text-left">
                                        <div className="col-sm-6">
                                            <h3 className="training-heading">{t('user.cognitive_training.mind_fullness')}</h3>
                                            <p className="heading-line"></p>

                                            <div className="mb-3 theme-color">
                                                <strong><label htmlFor="select" className="m-0">{t('superadmin.brainwellness.number_repeat')}</label></strong>
                                                <select className="form-select select-number p-1" id="select" onChange={(e) => handleChange(e, 'mindfulness', 27)} aria-label="Default select example">
                                                    {numbers && numbers.length > 0 && numbers.map((num, index) => (
                                                        <option className="p-1" key={index} value={num}
                                                            selected={trainingData?.repeat_allowed_mindfulness === num}>
                                                            {num}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            { repeatMindfulness > 0 && trainingData && trainingData?.repeat_start_dates_mindfulness?.map((item, index) => {
                                                return (
                                                    <div className="mb-3 theme-color" key={index}>
                                                        <strong><label htmlFor="date1" className="m-0">{t('superadmin.brainwellness.date')}{index + 1}</label></strong>
                                                        <label className="date-input">
                                                            <DatePicker dateFormat="dd.MM.yyyy"
                                                                selected={item ? item * 1000 : startDate}
                                                                onChange={(date) => onDateChange(date, index, 'mindfulness', 27)}
                                                                minDate={item * 1000}
                                                            /><span className="brainwelness-edit-date"><img className="img-span" src={Group878} alt="" /></span>
                                                        </label>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                        <div className="col-sm-6">

                                            <h3 className="training-heading">{t('user.cognitive_training.cognitive_training')}</h3>
                                            <p className="heading-line"></p>
                                            <div className="mb-3 theme-color">
                                                <strong><label htmlFor="select" className="m-0">{t('superadmin.brainwellness.number_repeat')}</label></strong>
                                                <select className="form-select select-number p-1" id="select" onChange={(e) => handleChange(e, 'cognitive', 21)} aria-label="Default select example">
                                                    {numbers && numbers.length > 0 && numbers.map((num, index) => (
                                                        <option className="p-1" key={index} value={num}
                                                            selected={trainingData?.repeat_allowed_cognitive === num}>
                                                            {num}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            { repeatCognitive > 0 && trainingData && trainingData?.repeat_start_dates_cognitive?.map((item, index) => {
                                                return (
                                                    <div className="mb-3 theme-color" key={index}>
                                                        <strong><label htmlFor="date" className="m-0">{t('superadmin.brainwellness.date')}{index + 1}</label></strong>
                                                        <label className="date-input">
                                                            <DatePicker dateFormat="dd.MM.yyyy"
                                                                selected={item ? item * 1000 : startDate}
                                                                onChange={(date) => onDateChange(date, index, 'cognitive', 21)}
                                                                minDate={item * 1000}
                                                            /><span className="brainwelness-edit-date"><img className="img-span" src={Group878} alt="" /></span>
                                                        </label>
                                                    </div>
                                                )
                                            })}

                                        </div>

                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <Link
                                            to="/brain-wellness"
                                            type="button"
                                            className="save-btn mr-2"
                                        >
                                            {t("superadmin.protocol.popup.cancel")}
                                        </Link>
                                        <button
                                            type="button"
                                            className="save-btn"
                                            onClick={() => assignTraining()}
                                        >
                                            {t("superadmin.training.popup.save")}
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {(isLoading) ? <LazyLoader /> : null}
                </div>
            </section>
            <Super_Sidebarmob />
        </>
    )
}
