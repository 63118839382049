import React, { useEffect } from "react";
import logo from "../../assets/Image/logo.svg";
import FEMMINA_ICONA from "../../assets/Image/FEMMINA_ICONA.svg";
import MASCHIO_ICONA from "../../assets/Image/MASCHIO_ICONA.svg";
import NONBINARIO_ICONA from "../../assets/Image/NONBINARIO_ICONA.svg";
import CALENDARIO_ICONA from "../../assets/Image/CALENDARIO_ICONA.svg";
import UFFICIO_ICONA from "../../assets/Image/UFFICIO_ICONA.svg";
import TURNISTA_ICONA from "../../assets/Image/TURNISTA_ICONA.svg";
import INFOICON from "../../assets/Image/info-icon.png";
import UTENTE_questionario from "../../assets/Image/UTENTE_questionario.png";
import UTENTE_questionario2 from "../../assets/Image/UTENTE_questionario-2.png";
import UTENTE_questionario3 from "../../assets/Image/UTENTE_questionario-3.png";
import Attendi from "../../assets/Image/Attendi-img.svg";
import TRASFERTE_ICONA from "../../assets/Image/TRASFERTE_ICONA.svg";
import Siediti from "../../assets/Image/siediti.svg";
import Prepara from "../../assets/Image/prepara.svg";
import Rimani from "../../assets/Image/rimani.svg";
import Autocomplete from "react-google-autocomplete";
import { useState } from "react";
import { Link } from "react-router-dom";
import { publicKey } from "./publikcKey.ts";
// import axios from '../apiConnection/axios_backend';
import { api_axios, base_axios } from "../apiConnection/axios";
import PostRequest from "../apiConnection/postRequest";
// import { profileAxios } from 'path/to/baseurl';
import { v1 as uuidv4 } from "uuid";
import crypto from "crypto";
import LazyLoader from "../common/lazyLoader";
import { getLocalStoarge } from "../common/allfunction";
import { useTranslation } from "react-i18next";
import Modal from "../common/modal";
import Popup_Cross from '../../assets/Image/Popup_Cross.svg'
import Dob_icon from '../../assets/Image/dob_select_icon.svg'
import Gender_icon from '../../assets/Image/gender_select_icon.svg'


import Group769 from "../../assets/Image/Group769.svg";
import {
getQuestionairs,
} from "../common/allfunction";

const Questionnaire = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userid, setUserId] = useState("");
  const [ages, setAges] = useState(0);
  const [weights, setWeights] = useState("");
  const [heights, setHeights] = useState("");
  const [genders, setGenders] = useState("");
  const [genderValue,setGenderValue] =useState("");
  const [dobValue,setDobValue] =useState("");
  const [placeOfWork, setPlaceOfWork] = useState("");
  const [corporate_department, setDepartment] = useState("");
  const [ans, setAns] = useState("");
  const [ans1, setAns1] = useState("");
  const [ans2, setAns2] = useState("");
  const [ans3, setAns3] = useState("");
  const [ans4, setAns4] = useState("");
  const [dob, setDob] = useState("");
  const [genderUpdated, setGenderUpdated] = useState(false);
  const [dobUpdated, setDobUpdated] = useState(false);
  const [t, i18n] = useTranslation("common");
  const [btntext, setBtnText] = useState(`${t("user.Questionnare.next")}`);
  const [counttab, setCountTab] = useState(1);
  const [errorfetch, setErrorfetch] = useState(
    props.match.params?.errorfetch && props.match.params?.errorfetch !== ""
      ? props.match.params?.errorfetch
      : ""
  );
  const [questionairs, setQuestionairs] = useState(
    getLocalStoarge("questionnare")
  );
  var today = new Date();
  var DD = today.getDate();
  var MM = today.getMonth() + 1; //January is 0!
  var YYYY = today.getFullYear();

  if (DD < 10) {
    DD = "0" + DD;
  }

  if (MM < 10) {
    MM = "0" + MM;
  }

  today = YYYY + "-" + MM + "-" + DD;
  var startAge = YYYY - 13 + "-" + MM + "-" + DD;
  var endAge = YYYY - 120 + "-" + MM + "-" + DD;


  const [todayDate, setTodayDate] = useState(today);
  const [alerts, setAlerts] = useState({
    alert: "",
    alerttype: "",
    alertclass: "danger",
    showalert: false,
  });
  const { alert, alerttype, alertclass, showalert } = alerts;
  const [token, setToken] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("R1");
    const initialValue = JSON.parse(saved);
    return initialValue.access_token || "";
  });

  const nextpage = () => {
    var next = counttab <= 2 ? counttab + 1 : counttab;
    var textchange =
      counttab < 2
        ? `${t("user.Questionnare.next")}`
        : questionairs == 0
          ? `${t("user.Questionnare.save")}`
          : `${t("user.Questionnare.close")}`;

    if (
      (btntext === `${t("user.Questionnare.save")}` ||
        btntext === `${t("user.Questionnare.close")}`) &&
      counttab === 3
    ) {
      handleSubmitData();
      //window.location.href = "./dashboard";
    }
    setBtnText(textchange);
    setCountTab(next);
  };

  const changeDot = (newval) => {
    var newtext =
      newval < 3
        ? `${t("user.Questionnare.next")}`
        : questionairs == 0
          ? `${t("user.Questionnare.next")}`
          : `${t("user.Questionnare.close")}`;
    setBtnText(newtext);
    setCountTab(newval);
  };
  const [confirmEnable,setConfirmEnable] = useState(false);
  const [headingText,setHeadingText] = useState("");
  const [textMessage,setTextMessage] = useState("");
  const [gvUpdate,setGvUpdate] = useState(0);
  const [dobvUpdate,setDobvUpdate] = useState(0);
  const [dobIcone,setDobIcon] = useState(0);
  const [genIcon,setGenIcon] = useState(0);

  useEffect(() => {
      if(genderValue!=genders && genderValue!=""){
          setHeadingText( t("user.Questionnare.gconfirm_heading"));
          setTextMessage( t("user.Questionnare.gconfirm_message"));
          setGvUpdate(1);
          setGenIcon(1);
          setConfirmEnable(true);
      }
  },[genders]);
  useEffect(() => {
      if(dobValue!=dob && dobValue!=""){
        setTimeout(()=>{    
          setHeadingText( t("user.Questionnare.dobconfirm_heading"));
          setTextMessage( t("user.Questionnare.dobconfirm_message"));
          setDobvUpdate(1);
          setDobIcon(1);
          setConfirmEnable(true);
        },2500)
      }
  },[dob]);
  useEffect(() => {
    getQuestionairsMain();
    handleTranslationLang();
    if (errorfetch === "error") {
      setTimeout(() => {
        setErrorfetch("");
      }, 10000);
    }
  }, []);
  
  const handleTranslationLang = () => {
    let lang = getLocalStoarge("langauage");
    if (lang) {
      i18n.changeLanguage(lang);  
    }
  };

  const calciAge = (e) => {
    e.preventDefault();
    setDob(e.target.value);
    const dateValue = new Date(e.target.value);
    let year = dateValue.getFullYear();
    const currentdateValue = new Date();
    let currentyear = currentdateValue.getFullYear();
    const ageValue = parseInt(currentyear - year);
    setAges(ageValue);
  };

  const checkValidation = async (data) => {
    const {
      age,
      gender,
      height,
      weight,
      smoker,
      // hypertension,
      medication,
      diabetic,
      work,
      placeOfWork,
      corporate_department,
      userid,
      office,
    } = data;
    let result = true;
    if (
      age === undefined ||
      age === null ||
      isNaN(age) === true ||
      parseInt(age) < 13 || parseInt(age) > 120
    ) {
      result = t("user.Questionnare.validation_age");
    }
    if (
      gender == undefined ||
      gender === null ||
      (gender !== "male" && gender !== "female" && gender !== "other")
    ) {
      result = t("user.Questionnare.validation_gender");
    }
    if (
      height == undefined ||
      height == null ||
      height == "" ||
      isNaN(height) === true ||
      parseInt(height) < 120 || parseInt(height) > 220
    ) {
      result = t("user.Questionnare.validation_height");
    }
    if (
      weight == undefined ||
      weight == null ||
      weight == "" ||
      isNaN(weight) === true ||
      parseInt(weight) < 30 || parseInt(weight) > 300
    ) {
      result = t("user.Questionnare.validation_weight");
    }

    if (
      smoker == undefined ||
      smoker === null ||
      (smoker !== "yes" && smoker !== "no")
    ) {
      result = t("user.Questionnare.validation_smoker");
    }
    // if (
    //   hypertension == undefined ||
    //   hypertension === null ||
    //   (hypertension !== "yes" && hypertension !== "no")
    // ) {
    //   result = t("user.Questionnare.validation_hypertension");
    // }
    if (
      medication == undefined ||
      medication === null ||
      (medication !== "yes" && medication !== "no")
    ) {
      result = t("user.Questionnare.validation_medication");
    }
    if (
      diabetic == undefined ||
      diabetic === null ||
      (diabetic !== "type1" && diabetic !== "type2" && diabetic !== "0")
    ) {
      result = t("user.Questionnare.validation_medication");
    }
    if (
      work == undefined ||
      work === null ||
      (work !== "Ufficio" && work !== "Turnista" && work !== "Trasfertista")
    ) {
      result = t("user.Questionnare.validation_work") + work;
    }
    if (
      placeOfWork == undefined || placeOfWork == null || placeOfWork == ""
    ) {
      result = t("user.Questionnare.validate_placeOfWork");
    }
    if (
      corporate_department == undefined ||
      corporate_department == null || corporate_department == ""
    ) {
      result = t("user.Questionnare.validate_corporateDepartment");
    }

    return result;
  };

  const getQuestionairsMain = async () => {
    // /user/get-questionnaire
    if (token && token !== null) {
      const payload = {};
      try {
        setIsLoading(true);
        const refreshToken = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
          {},
          token,
          "refresh_token"
        );
        if (refreshToken) {
          const getQuestionnaireResult = await PostRequest(
            `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_QUESTIONNAIRE}`,
            payload,
            token
          );

          if (getQuestionnaireResult && getQuestionnaireResult.status === 200) {
            if (getQuestionnaireResult.data.code === 200) {
              setIsLoading(false);
              const questionairs = getQuestionnaireResult.data.data;
              setGenderUpdated(questionairs.gender_updated==2 ? true : false);
              setDobUpdated(questionairs.dob_updated==2 ? true : false);
              setDob(questionairs.dob);
              setDobValue(questionairs.dob);
              setGenderValue(questionairs.gender == 1
                ? "male"
                : questionairs.gender == 2
                  ? "female"
                  : "other");
              setGenders(
                questionairs.gender == 1
                  ? "male"
                  : questionairs.gender == 2
                    ? "female"
                    : "other"
              );
              setHeights(questionairs.height || "");
              setWeights(questionairs.weight || "");
              setPlaceOfWork(questionairs.placeOfWork || "");
              setDepartment(questionairs.corporate_department || "");
              setAns(questionairs.smoker == 1 ? "yes" : "no");
              // setAns1(questionairs.hypertension == 1 ? "yes" : "no");
              setAns2(questionairs.bp_medicine == 1 ? "yes" : "no");
              setAns3(
                questionairs.diabetic == 0
                  ? "0"
                  : questionairs.diabetic == 1
                    ? "type1"
                    : "type2"
              );
              setAns4(questionairs.work);
              setUserId(questionairs.user_id);
              const dateValue = new Date(questionairs.dob);
              let year = dateValue.getFullYear();
              const currentdateValue = new Date();
              let currentyear = currentdateValue.getFullYear();
              const ageValue = parseInt(currentyear - year);
              setAges(ageValue);
            } else {
              setIsLoading(false);
            }
          } else if (
            getQuestionnaireResult &&
            getQuestionnaireResult.status === 203
          ) {
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const handleSubmitData = async () => {
    setAlerts({
      alert: t("user.Questionnare.step_close_msg"),
      alerttype: "Info",
      alertclass: "info",
      showalert: true,
    });
    const jsondata = {
      age: ages,
      gender: genders,
      height: heights,
      weight: weights,
      smoker: ans,
      // hypertension: ans1,
      medication: ans2,
      diabetic: ans3,
      work: ans4,
      userid: userid,
      placeOfWork: placeOfWork,
      corporate_department: corporate_department,
      // Profession : ans4
    };
    const validate = await checkValidation(jsondata);
    if (validate !== true) {
      setAlerts({
        alert: validate,
        alerttype: "Error",
        alertclass: "danger",
        showalert: true,
      });
      setTimeout(() => {
        setAlerts({
          alert: "",
          alerttype: "",
          alertclass: "",
          showalert: false,
        });
      },
        process.env.REACT_APP_ALERT_TIMEOUT * 1000);
      return false;
    } else {
      const {
        age,
        diabetic,
        height,
        // hypertension,
        medication,
        gender,
        smoker,
        weight,
        placeOfWork,
        corporate_department,
      } = jsondata;
      // // Get the token ID
      const { data: configResponseData } = await base_axios.get(
        `${process.env.REACT_APP_CONFIGID}`
      );

      // // Get a token from the back end
      const { data: tokenResponseData } = await base_axios.post(
        `${process.env.REACT_APP_TOKEN}`
      );
      const { Token, RefreshToken } = tokenResponseData;
      const dfxPayload ={
        dfxToken: tokenResponseData.data.Token
      }
      await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_STORE_DFX_TOKEN_AND}`,
        dfxPayload,
        ''
      );
      const payload = {
        identifier: userid, // Unique identifier for the user, it will be used to tag the measurement
        age: parseInt(age),
        height: parseInt(height),
        weight: parseInt(weight),
        gender,
        smoking: smoker === "yes" ? 1 : 0,
        // hyper: hypertension === "yes" ? 1 : 0,
        bloodpressuremedication: medication === "yes" ? 1 : 0,
        diabetes: diabetic,

      };
      // console.log('payload', payload);
      const buffer = Buffer.from(JSON.stringify(payload));
      const encryptedProfile = crypto.publicEncrypt(publicKey, buffer);
      const sessionId = await uuidv4(); // Unique identifier for the session, passed back as part of the result object to identify the measurement

      const content = {
        gender:
          gender == "male"
            ? 1
            : gender == "female"
              ? 2
              : gender == "other"
                ? 3
                : 0,
        dob,
        height: parseInt(height),
        weight: parseInt(weight),
        smoker: smoker === "yes" ? 1 : 0,
        // hypertension: hypertension === "yes" ? 1 : 0,
        bp_medicine: medication === "yes" ? 1 : 0,
        diabetic: diabetic === "0" ? 0 : diabetic === "type1" ? 1 : 2,
        work: ans4,
        placeOfWork,
        corporate_department,
      };
      // console.log('content', content);

      try {
        setIsLoading(true);
        const refreshToken = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
          {},
          token,
          "refresh_token"
        );
        if (refreshToken) {
          const updateResult = await PostRequest(
            `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_QUESTIONNAIRE}`,
            content,
            token
          );
          if (updateResult && updateResult.status === 200) {
            if (updateResult.data.code === 200) {
              setIsLoading(false);                       

              if (questionairs == 1) {
                setAlerts({
                  alert: t("user.Questionnare.step_close_msg_2"),
                  alerttype: "Success",
                  alertclass: "success",
                  showalert: true,
                });
                setTimeout(() => {
                  window.location.href = `/app/profile`;
                }, 1000);
              } else {
                setAlerts({
                  alert: t("user.Questionnare.step_close_msg_3"),
                  alerttype: "Success",
                  alertclass: "success",
                  showalert: true,
                });
                const scanData = getQuestionairs();
                if (!scanData) {
                  setIsLoading(false);
                  removeLocalStoarge();
                  setAlerts({
                    alert: t("user.login.server"),
                    alerttype: "Error",
                    alertclass: "danger",
                    showalert: true,
                  });
                }
                // setTimeout(() => {
                //   window.location.href = `https://awe.na-east.nuralogix.ai/c/${configResponseData.data.config_id
                //     }/${encodeURIComponent(
                //       encryptedProfile.toString("base64")
                //     )}/${encodeURIComponent(
                //       tokenResponseData.data.Token
                //     )}/${encodeURIComponent(
                //       tokenResponseData.data.RefreshToken
                //     )}/${sessionId}`;
                // }, 1000);
              }
            } else {
              setIsLoading(false);
            }
          } else {
            setIsLoading(false);
            setAlerts({
              alert: t("user.login.server"),
              alerttype: "Error",
              alertclass: "danger",
              showalert: true,
            });
            setTimeout(() => {
              setAlerts({
                alert: "",
                alerttype: "",
                alertclass: "",
                showalert: false,
              });
            },
              process.env.REACT_APP_ALERT_TIMEOUT * 1000);
          }
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        setAlerts({
          alert: t("user.login.server"),
          alerttype: "Error",
          alertclass: "danger",
          showalert: true,
        });
        setTimeout(() => {
          setAlerts({
            alert: "",
            alerttype: "",
            alertclass: "",
            showalert: false,
          });
        },
          process.env.REACT_APP_ALERT_TIMEOUT * 1000);
      }
    }
  };

    
    //   Age change confirmation box
    const confirmationBox = () => {
        return (
            <div className="model-pop-new">
                <div className="model-pop ">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={(dobIcone == 1) ? Dob_icon : Gender_icon} alt="icon" />
                            </div>
                            <strong>{headingText}</strong>
                            
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            // disabled={isDisabled}
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div></div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="container">
                            <h6>{textMessage}</h6>
                        </div>
                        <div className="d-flex justify-content-end buttons-superadmin">
                            <button type="button" className="buttons modal-button mr-3" onClick={() => handleConfirm()}>{t("superadmin.protocol.popup.confirm")}</button>
                            <button type="button" className="buttons modal-button"  onClick={() => handleCancel()}>{t("superadmin.protocol.popup.cancel")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const handleCancel = () =>{
      setGenIcon(0);
      setDobIcon(0);
        if(gvUpdate==1){           
            setGenders(genderValue);
            setGvUpdate(0);
        }
        if(dobvUpdate==1){
            setDob(dobValue);
            setDobvUpdate(0);
        }
        setConfirmEnable(false);
    }
    const handleConfirm = () =>{
      setGenIcon(0);
      setDobIcon(0);
        if(gvUpdate==1){
            setGvUpdate(0)
        }
        if(dobvUpdate==1){
            setDobvUpdate(0)
        }
        setConfirmEnable(false);
    }
    const closeModal = () =>{
        setGenders(genderValue);
        setDob(dobValue);
        setConfirmEnable(false);
    }
  return (
    <>
        <Modal title="" size="lg" contentClasses="elimina-popup" onClose={() => setConfirmEnable(false)} show={confirmEnable}>
                {confirmationBox()}
        </Modal>
      <section className="steps-process cover-q question-ipad bgsteps4">
        <div className="container-fluid container-fluid-dark">
          <div className="row">
            <div className="stepsin stpelog">
              <Link to="dashboard">
                <img src={logo} className="logo-steps" alt="logo" />
              </Link>
            </div>
            <div className="col-md-12 p-l-0 mobabsul">
              <div className="tabControl">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className={`tab-pane fade qbg1 commonbg-question ${counttab === 1 ? "show active" : ""
                      }`}
                    id="pills-1"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="row question-w on-responsive mx-0">
                      <div className="col-md-3"></div>
                      <div className="col-md-6 tab-fully">
                        <div className="auth question_steps">
                          <div className="icon-aut relative">
                          <div className={`alert alert-custom-primary d-flex`}>
                            <img src={INFOICON} alt="info icon"  />
                            <div dangerouslySetInnerHTML={{
                                __html: t("user.Questionnare.warning"),
                            }}></div>
                            </div>
                            <h2 className="otp-heading top-Align mobw100">
                              {t("user.Questionnare.text_insertdata")}
                            </h2>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: t("user.Questionnare.text_info"),
                              }}
                            ></p>
                            {errorfetch === "error" ? (
                              <div className={`alert alert-danger`}>
                                {`Invalid signal strength, please retry with good light conditions.`}
                              </div>
                            ) : (
                              <></>
                            )}
                            {showalert ? (
                              <div
                                className={`newalertred alert alert-${alertclass}`}
                              >
                                {alert}
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="row topfields">
                              <div className="col-md-12 pr-0">
                                <div className="div-st">
                                  <h4>{t("user.Questionnare.gender")}</h4>
                                  <div className="d-flex">
                                    <div className="col-md-4 mobile-sbox">
                                        <label htmlFor="male" className={ genders == "male" ? "gend newclass" : "gend" } >
                                        <img src={MASCHIO_ICONA} alt="icon" />
                                        <span className="">
                                          {t("user.Questionnare.male")}
                                        </span>
                                      </label>
                                      <input
                                        type="radio"
                                        id="male"
                                        name="Gender"
                                        value="male"
                                        className="questionnaire-radio"
                                        onChange={() => setGenders("male")}
                                        disabled={genderUpdated} 
                                      />
                                    </div>
                                    <div className="col-md-4 mobile-sbox">
                                      <label
                                        htmlFor="female"
                                        className={
                                          genders == "female"
                                            ? "gend newclass"
                                            : "gend"
                                        }
                                      >
                                        <img src={FEMMINA_ICONA} alt="icon" />
                                        <span className="">
                                          {t("user.Questionnare.female")}{" "}
                                        </span>
                                      </label>
                                      <input
                                        type="radio"
                                        id="female"
                                        name="Gender"
                                        value="female"
                                        className="questionnaire-radio"
                                        onChange={() => setGenders("female")}
                                        disabled={genderUpdated} 
                                      />
                                    </div>
                                    <div className="col-md-4 mobile-sbox">
                                      <label
                                        htmlFor="other"
                                        className={
                                          genders == "other"
                                            ? "gend newclass"
                                            : "gend"
                                        }
                                      >
                                        <img
                                          src={NONBINARIO_ICONA}
                                          alt="icon"
                                        />
                                        <span className="">
                                          {t("user.Questionnare.others")}
                                        </span>
                                      </label>
                                      <input
                                        type="radio"
                                        id="other"
                                        name="Gender"
                                        value="other"
                                        className="questionnaire-radio"
                                        onChange={() => setGenders("other")}
                                        disabled={genderUpdated} 
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12">
                                <div className="div-st">
                                  <h4>{t("user.Questionnare.age")}</h4>
                                  <div className="relative w-100">
                                    {/* <img
                                      src={CALENDARIO_ICONA}
                                      alt="icon"
                                      className="icon_right"
                                    /> */}
                                    <input
                                      type="date"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                      placeholder={todayDate}
                                      value={dob}
                                      onChange={(e) => calciAge(e)}
                                      // max={todayDate}
                                      min={endAge}
                                      max={startAge}
                                      disabled={dobUpdated} 
                                    />
                                   </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 m-widhdevice pr4">
                                <div className="div-st plac-pos">
                                  <h4>{t("user.Questionnare.height")}</h4>
                                  <div className="relative">
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                      placeholder="Cm"
                                      value={heights}
                                      onChange={(e) =>
                                        setHeights(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 m-widhdevice">
                                <div className="div-st plac-pos">
                                  <h4>{t("user.Questionnare.weight")}</h4>
                                  <div className="form-group relative">
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                      placeholder="Kg"
                                      value={weights}
                                      onChange={(e) =>
                                        setWeights(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 visiblity-hide"></div>
                    </div>
                    <div id="btnNext">
                      <div className="fit-bdots">
                        <ul
                          className="nav nav-pills nav-fill"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 1 ? "active" : ""
                                }`}
                              onClick={() => changeDot(1)}
                              id="pills-home-tab"
                              data-toggle="pill"
                              href="#pills-1"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 2 ? "active" : ""
                                }`}
                              onClick={() => changeDot(2)}
                              id="pills-profile-tab"
                              data-toggle="pill"
                              href="#pills-2"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 3 ? "active" : ""
                                }`}
                              onClick={() => changeDot(3)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-3"
                              role="tab"
                            ></a>
                          </li>
                          {/* <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 4 ? "active" : ""
                                }`}
                              onClick={() => changeDot(4)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-4"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 5 ? "active" : ""
                                }`}
                              onClick={() => changeDot(5)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-5"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 6 ? "active" : ""
                                }`}
                              onClick={() => changeDot(6)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-6"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 7 ? "active" : ""
                                }`}
                              onClick={() => changeDot(7)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-7"
                              role="tab"
                            ></a>
                          </li> */}
                        </ul>
                        <button
                          className="btn btn-primary btnNext"
                          onClick={nextpage}
                          onSubmit={nextpage}
                        >
                          {btntext}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade qbg2 commonbg-question  ${counttab === 2 ? "show active" : ""
                      }`}
                    id="pills-2"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="row question-w on-responsive mx-0">
                      <div className="col-md-3"></div>
                      <div className="col-md-6 tab-fully">
                        <div className="auth question_steps">
                          <div className="icon-aut relative">
                            <h2 className="otp-heading top-Align mobw100">
                              {t("user.Questionnare.text_insertdata")}
                            </h2>

                            <p
                              dangerouslySetInnerHTML={{
                                __html: t("user.Questionnare.text_info"),
                              }}
                            ></p>
                            {showalert ? (
                              <div
                                className={`newalertred alert alert-${alertclass}`}
                              >
                                {alert}
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="row topfields">
                              <div className="col-md-5">
                                <h4>{t("user.Questionnare.smoker")}</h4>
                                <div className="ali-bs">
                                  <div>
                                    <label
                                      htmlFor="Si"
                                      className={
                                        ans == "yes" ? "gend newclass" : "gend"
                                      }
                                    >
                                      {t("user.Questionnare.yes")}
                                    </label>
                                    <input
                                      type="radio"
                                      id="Si"
                                      name="smoker"
                                      value="yes"
                                      className="questionnaire-radio"
                                      onClick={() => setAns("yes")}
                                    />
                                  </div>
                                  <div>
                                    <label
                                      htmlFor="No"
                                      className={
                                        ans == "no" ? "gend newclass" : "gend"
                                      }
                                    >
                                      {t("user.Questionnare.no")}
                                    </label>
                                    <input
                                      type="radio"
                                      id="No"
                                      name="smoker"
                                      value="no"
                                      className="questionnaire-radio"
                                      onClick={() => setAns("no")}
                                    />
                                  </div>
                                </div>
                              </div>
                              {/* <div className="col-md-7 d-none">
                                <h4 className="text-formation">{t("user.Questionnare.hypertension")}</h4>
                                <div className="ali-bs">
                                  <div>
                                    <label
                                      htmlFor="Si1"
                                      className={
                                        ans1 == "yes" ? "gend newclass" : "gend"
                                      }
                                    >
                                      {t("user.Questionnare.yes")}
                                    </label>
                                    <input
                                      type="radio"
                                      id="Si1"
                                      name="suffering from hypertension"
                                      value="yes"
                                      className="questionnaire-radio"
                                      onClick={() => setAns1("yes")}
                                    />
                                  </div>
                                  <div>
                                    <label
                                      htmlFor="No1"
                                      className={
                                        ans1 == "no" ? "gend newclass" : "gend"
                                      }
                                    >
                                      No
                                    </label>
                                    <input
                                      type="radio"
                                      id="No1"
                                      name="suffering from hypertension"
                                      value="no"
                                      className="questionnaire-radio"
                                      onClick={() => setAns1("no")}
                                    />
                                  </div>
                                </div>
                              </div> */}

                              <div className="col-md-12 mt-4">
                                <h4>{t("user.Questionnare.Bp")}</h4>
                                <div className="ali-bs">
                                  <div>
                                    <label
                                      htmlFor="Si2"
                                      className={
                                        ans2 == "yes" ? "gend newclass" : "gend"
                                      }
                                    >
                                      {t("user.Questionnare.yes")}
                                    </label>
                                    <input
                                      type="radio"
                                      id="Si2"
                                      name="bloodpressuremedication"
                                      value="yes"
                                      className="questionnaire-radio"
                                      onClick={() => setAns2("yes")}
                                    />
                                  </div>
                                  <div>
                                    <label
                                      htmlFor="No2"
                                      className={
                                        ans2 == "no" ? "gend newclass" : "gend"
                                      }
                                    >
                                      {t("user.Questionnare.no")}
                                    </label>
                                    <input
                                      type="radio"
                                      id="No2"
                                      name="bloodpressuremedication"
                                      value="no"
                                      className="questionnaire-radio"
                                      onClick={() => setAns2("no")}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12 mt-4">
                                <h4>{t("user.Questionnare.diabeties")}</h4>
                                <div className="ali-bs">
                                  <div>
                                    <label
                                      htmlFor="No3"
                                      className={
                                        ans3 == "0" ? "gend newclass" : "gend"
                                      }
                                    >
                                      {t("user.Questionnare.no")}
                                    </label>
                                    <input
                                      type="radio"
                                      id="No3"
                                      name="Diabatic"
                                      value="0"
                                      className="questionnaire-radio"
                                      onClick={() => setAns3("0")}
                                    />
                                  </div>
                                  <div>
                                    <label
                                      htmlFor="Tipo1"
                                      className={
                                        ans3 == "type1"
                                          ? "gend newclass"
                                          : "gend"
                                      }
                                    >
                                      {t("user.Questionnare.type1")}
                                    </label>
                                    <input
                                      type="radio"
                                      id="Tipo1"
                                      name="Diabatic"
                                      value="type1"
                                      className="questionnaire-radio"
                                      onClick={() => setAns3("type1")}
                                    />
                                  </div>
                                  <div>
                                    <label
                                      htmlFor="Tipo2"
                                      className={
                                        ans3 == "type2"
                                          ? "gend newclass"
                                          : "gend"
                                      }
                                    >
                                      {t("user.Questionnare.type2")}
                                    </label>
                                    <input
                                      type="radio"
                                      id="Tipo2"
                                      name="Diabatic"
                                      value="type2"
                                      className="questionnaire-radio"
                                      onClick={() => setAns3("type2")}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 visiblity-hide"></div>
                    </div>
                    <div id="btnNext">
                      <div className="fit-bdots">
                        <ul
                          className="nav nav-pills nav-fill"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 1 ? "active" : ""
                                }`}
                              onClick={() => changeDot(1)}
                              id="pills-home-tab"
                              data-toggle="pill"
                              href="#pills-1"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 2 ? "active" : ""
                                }`}
                              onClick={() => changeDot(2)}
                              id="pills-profile-tab"
                              data-toggle="pill"
                              href="#pills-2"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 3 ? "active" : ""
                                }`}
                              onClick={() => changeDot(3)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-3"
                              role="tab"
                            ></a>
                          </li>
                          {/* <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 4 ? "active" : ""
                                }`}
                              onClick={() => changeDot(4)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-4"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 5 ? "active" : ""
                                }`}
                              onClick={() => changeDot(5)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-5"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 6 ? "active" : ""
                                }`}
                              onClick={() => changeDot(6)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-6"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 7 ? "active" : ""
                                }`}
                              onClick={() => changeDot(7)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-7"
                              role="tab"
                            ></a>
                          </li> */}
                        </ul>
                        <button
                          className="btn btn-primary btnNext mnn"
                          onClick={() => changeDot(1)}
                          id="reverse"
                        >
                          {t("user.Questionnare.backwards")}
                        </button>
                        <button
                          className="btn btn-primary btnNext"
                          onClick={nextpage}
                          onSubmit={nextpage}
                        >
                          {btntext}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`tab-pane fade qbg2 commonbg-question
                     ${counttab === 3 ? "show active" : ""}`}
                    id="pills-3"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                  >
                    <div className="row question-w on-responsive mx-0">
                      <div className="col-md-3"></div>
                      <div className="col-md-6 tab-fully">
                        <div className="auth question_steps">
                          <div className="icon-aut relative">
                            <h2 className="otp-heading top-Align mobw100">
                              {t("user.Questionnare.beforestart")}
                            </h2>
                            {/* <p className="Prima-di">/ */}
                            <p
                              dangerouslySetInnerHTML={{
                                __html: t("user.Questionnare.textpg3"),
                              }}
                            ></p>
                            {showalert ? (
                              <div
                                className={`newalertred alert alert-${alertclass}`}
                              >
                                {alert}
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="row topfields leftqq3">
                              <div className="col-md-4 qmob-w pleft7">
                                <label
                                  htmlFor="Ufficio"
                                  className={
                                    ans4 == "Ufficio"
                                      ? "thir-step newclass"
                                      : "thir-step"
                                  }
                                >
                                  <img src={UFFICIO_ICONA} alt="icon" />
                                  <h4>{t("user.Questionnare.office")}</h4>
                                </label>
                                <input
                                  type="radio"
                                  id="Ufficio"
                                  name="Profession"
                                  value="Ufficio"
                                  style={{ display: "none" }}
                                  onChange={() => setAns4("Ufficio")}
                                />
                              </div>

                              <div className="col-md-4 qmob-w">
                                <label
                                  htmlFor="Turnista"
                                  className={
                                    ans4 == "Turnista"
                                      ? "thir-step newclass"
                                      : "thir-step"
                                  }
                                >
                                  <img src={TURNISTA_ICONA} alt="icon" />
                                  <h4>{t("user.Questionnare.shiftworker")}</h4>
                                </label>
                                <input
                                  type="radio"
                                  id="Turnista"
                                  name="Profession"
                                  value="Turnista"
                                  style={{ display: "none" }}
                                  onChange={() => setAns4("Turnista")}
                                />
                              </div>

                              <div className="col-md-4 qmob-w q-r5">
                                <label
                                  htmlFor="Trasfertista"
                                  className={
                                    ans4 == "Trasfertista"
                                      ? "thir-step newclass"
                                      : "thir-step"
                                  }
                                >
                                  <img src={TRASFERTE_ICONA} alt="icon" />
                                  <h4>{t("user.Questionnare.transfer")}</h4>
                                </label>
                                <input
                                  type="radio"
                                  id="Trasfertista"
                                  name="Profession"
                                  value="Trasfertista"
                                  style={{ display: "none" }}
                                  onChange={() => setAns4("Trasfertista")}
                                />
                              </div>

                            </div>

                            <div className="row ">
                              <div className="col-md-6 m-widhdevice pr4">
                                <div className="div-st plac-pos">
                                  <h4>{t("user.Questionnare.place_of_work")}</h4>
                                  <div className="relative">
                                    {/* <input
                                      type="text"
                                      className="form-control"
                                      placeholder={t("user.Questionnare.write")}
                                      // id="exampleInputEmail1"
                                      // aria-describedby="emailHelp"
                                      // placeholder="Cm"
                                      value={placeOfWork}
                                      name="placeOfWork"
                                      onChange={(e) =>
                                        setPlaceOfWork(e.target.value)
                                      }
                                    /> */}
                                    <Autocomplete
                                      apiKey={process.env.REACT_APP_GOOGLE_AUTOCOMPLETE_API_KEY}
                                      defaultValue={placeOfWork}
                                      onPlaceSelected={(place) => {
                                        setPlaceOfWork(place.formatted_address)
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 m-widhdevice">
                                <div className="div-st plac-pos">
                                  <h4>{t("user.Questionnare.department")}</h4>
                                  <div className="form-group relative ">
                                    <div className="arrow_down1">
                                      <select className="form-control" onChange={(e) => setDepartment(e.target.value)} name="department" value={corporate_department || 'Select'}>
                                        <option value="" >
                                          {t("user.Questionnare.choose")}
                                        </option>
                                        <option value={t("user.Questionnare.sales")} >{t("user.Questionnare.sales")}</option>
                                        <option value={t("user.Questionnare.marketing")}>{t("user.Questionnare.marketing")}</option>
                                        <option value={t("user.Questionnare.administration_and_finance")}>{t("user.Questionnare.administration_and_finance")}</option>
                                        <option value={t("user.Questionnare.secretary")}>{t("user.Questionnare.secretary")}</option>
                                        <option value={t("user.Questionnare.research_and_development")}>{t("user.Questionnare.research_and_development")}</option>
                                        <option value={t("user.Questionnare.purchasing")}>{t("user.Questionnare.purchasing")}</option>
                                        <option value={t("user.Questionnare.production_and_quality")}>{t("user.Questionnare.production_and_quality")}</option>
                                        <option value={t("user.Questionnare.logistics")}>{t("user.Questionnare.logistics")}</option>
                                        <option value={t("user.Questionnare.legal_affairs")}>{t("user.Questionnare.legal_affairs")}</option>
                                        <option value={t("user.Questionnare.personal_management")}>{t("user.Questionnare.personal_management")}</option>
                                        <option value={t("user.Questionnare.information_system")}>{t("user.Questionnare.information_system")}</option>
                                        <option value={t("user.Questionnare.designing")}>{t("user.Questionnare.designing")}</option>
                                        <option value={t("user.Questionnare.support")}>{t("user.Questionnare.support")}</option>
                                        <option value={t("user.Questionnare.external_relation")}>{t("user.Questionnare.external_relation")}</option>
                                        <option value={t("user.Questionnare.security_and_safety")}>{t("user.Questionnare.security_and_safety")}</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 visiblity-hide"></div>
                    </div>

                    <div id="btnNext">
                      <div className="fit-bdots">
                        <ul
                          className="nav nav-pills nav-fill"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 1 ? "active" : ""
                                }`}
                              onClick={() => changeDot(1)}
                              id="pills-home-tab"
                              data-toggle="pill"
                              href="#pills-1"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 2 ? "active" : ""
                                }`}
                              onClick={() => changeDot(2)}
                              id="pills-profile-tab"
                              data-toggle="pill"
                              href="#pills-2"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 3 ? "active" : ""
                                }`}
                              onClick={() => changeDot(3)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-3"
                              role="tab"
                            ></a>
                          </li>
                          {/* <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 4 ? "active" : ""
                                }`}
                              onClick={() => changeDot(4)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-4"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 5 ? "active" : ""
                                }`}
                              onClick={() => changeDot(5)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-5"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 6 ? "active" : ""
                                }`}
                              onClick={() => changeDot(6)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-6"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 7 ? "active" : ""
                                }`}
                              onClick={() => changeDot(7)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-7"
                              role="tab"
                            ></a>
                          </li> */}
                        </ul>
                        <button
                          className="btn btn-primary btnNext mnn"
                          onClick={() => changeDot(2)}
                          id="reverse"
                        >
                          {t("user.Questionnare.backwards")}
                        </button>
                        <button
                          className="btn btn-primary btnNext"
                          onClick={nextpage}
                          onSubmit={nextpage}
                        >
                          {btntext}
                        </button>
                      </div>
                    </div>
                  </div>


                  {/* <div
                    className={`tab-pane fade attendi-bg commonbg-question
                     ${counttab === 4 ? "show active" : ""}`}
                    id="pills-4"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                  >
                    <div className="row question-w on-responsive mx-0">
                      <div className="btnNext-top">
                        <button
                          className="btn btn-primary btnNext ipdnn"
                          onClick={() => handleSubmitData()}>
                          {t('user.steps.pg_1.text.nxtbtn')}
                        </button>
                      </div>
                      <div className="col-md-3"></div>
                      <div className="col-md-6 tab-fully">

                        <div className="auth question_steps">
                          <div className="icon-aut relative">

                            <img className="center-attendi" src={Attendi} alt="icon" />
                            <h2 className="otp-heading mobw100 questionnaire-new-heading">
                              {t("user.Questionnare.necessary_time_heading")}
                            </h2>
                            <p className="text-center"
                              dangerouslySetInnerHTML={{
                                __html: t("user.Questionnare.necessary_time_content"),
                              }}
                            ></p>
                            {showalert ? (
                              <div
                                className={`newalertred alert alert-${alertclass}`}
                              >
                                {alert}
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="row topfields leftqq3">


                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 visiblity-hide"></div>
                    </div>
                    <div id="btnNext">
                      <div className="fit-bdots">
                        <ul
                          className="nav nav-pills nav-fill"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 1 ? "active" : ""
                                }`}
                              onClick={() => changeDot(1)}
                              id="pills-home-tab"
                              data-toggle="pill"
                              href="#pills-1"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 2 ? "active" : ""
                                }`}
                              onClick={() => changeDot(2)}
                              id="pills-profile-tab"
                              data-toggle="pill"
                              href="#pills-2"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 3 ? "active" : ""
                                }`}
                              onClick={() => changeDot(3)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-3"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 4 ? "active" : ""
                                }`}
                              onClick={() => changeDot(4)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-4"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 5 ? "active" : ""
                                }`}
                              onClick={() => changeDot(5)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-5"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 6 ? "active" : ""
                                }`}
                              onClick={() => changeDot(6)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-6"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 7 ? "active" : ""
                                }`}
                              onClick={() => changeDot(7)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-7"
                              role="tab"
                            ></a>
                          </li>

                        </ul>
                        <button
                          className="btn btn-primary btnNext mnn"
                          onClick={() => changeDot(3)}
                          id="reverse"
                        >
                          {t("user.Questionnare.backwards")}
                        </button>
                        <button
                          className="btn btn-primary btnNext"
                          onClick={nextpage}
                          onSubmit={nextpage}
                        >
                          {btntext}
                        </button>
                      </div>
                    </div>
                  </div> */}

                  {/* <div
                    className={`tab-pane fade commonbg-question
                     ${counttab === 5 ? "show active" : ""}`}
                    id="pills-5"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                  >
                    <div className="row siediti question-w on-responsive mx-0">
                      <div className="btnNext-top">
                        <button
                          className="btn btn-primary btnNext ipdnn"
                          onClick={() => handleSubmitData()}>
                          {t('user.steps.pg_1.text.nxtbtn')}
                        </button>
                      </div>
                      <div className="col-md-2"></div>
                      <div className="col-md-8 tab-fully">
                        <div className="auth question_steps">
                          <div className="icon-aut relative">
                            <img className="center-attendi" src={Siediti} alt="icon" />
                            <h2 className="otp-heading mobw100 questionnaire-new-heading">
                              {t("user.Questionnare.before_measuring_heading")}
                            </h2>
                            <p className="text-center max-siediti"
                              dangerouslySetInnerHTML={{
                                __html: t("user.Questionnare.before_measuring_content"),
                              }}
                            ></p>
                            {showalert ? (
                              <div
                                className={`newalertred alert alert-${alertclass}`}
                              >
                                {alert}
                              </div>
                            ) : (
                              <></>
                            )}

                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 visiblity-hide"></div>
                    </div>
                    <div id="btnNext">
                      <div className="fit-bdots">
                        <ul
                          className="nav nav-pills nav-fill"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 1 ? "active" : ""
                                }`}
                              onClick={() => changeDot(1)}
                              id="pills-home-tab"
                              data-toggle="pill"
                              href="#pills-1"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 2 ? "active" : ""
                                }`}
                              onClick={() => changeDot(2)}
                              id="pills-profile-tab"
                              data-toggle="pill"
                              href="#pills-2"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 3 ? "active" : ""
                                }`}
                              onClick={() => changeDot(3)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-3"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 4 ? "active" : ""
                                }`}
                              onClick={() => changeDot(4)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-4"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 5 ? "active" : ""
                                }`}
                              onClick={() => changeDot(5)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-5"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 6 ? "active" : ""
                                }`}
                              onClick={() => changeDot(6)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-6"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 7 ? "active" : ""
                                }`}
                              onClick={() => changeDot(7)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-7"
                              role="tab"
                            ></a>
                          </li>

                        </ul>
                        <button
                          className="btn btn-primary btnNext mnn"
                          onClick={() => changeDot(4)}
                          id="reverse"
                        >
                          {t("user.Questionnare.backwards")}
                        </button>
                        <button
                          className="btn btn-primary btnNext"
                          onClick={nextpage}
                          onSubmit={nextpage}
                        >
                          {btntext}
                        </button>
                      </div>
                    </div>
                  </div> */}

                  {/* <div
                    className={`tab-pane fade commonbg-question
                     ${counttab === 6 ? "show active" : ""}`}
                    id="pills-6"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                  >
                    <div className="row prepara-bg question-w on-responsive mx-0">
                      <div className="btnNext-top">
                        <button
                          className="btn btn-primary btnNext ipdnn"
                          onClick={() => handleSubmitData()}>
                          {t('user.steps.pg_1.text.nxtbtn')}
                        </button>
                      </div>
                      <div className="col-md-2"></div>
                      <div className="col-md-8 tab-fully">
                        <div className="auth question_steps">
                          <div className="icon-aut relative">
                            <img className="center-attendi" src={Prepara} alt="icon" />
                            <h2 className="otp-heading mobw100 questionnaire-new-heading">
                              {t("user.Questionnare.prepare_face_heading")}
                            </h2>
                            <p className="text-center mb-2"
                              dangerouslySetInnerHTML={{
                                __html: t("user.Questionnare.prepare_face_content_sub_heading"),
                              }}
                            ></p>
                            <p className="text-left"
                              dangerouslySetInnerHTML={{
                                __html: t("user.Questionnare.prepare_face_content"),
                              }}
                            ></p>
                            {showalert ? (
                              <div
                                className={`newalertred alert alert-${alertclass}`}
                              >
                                {alert}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 visiblity-hide"></div>
                    </div>
                    <div id="btnNext">
                      <div className="fit-bdots">
                        <ul
                          className="nav nav-pills nav-fill"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 1 ? "active" : ""
                                }`}
                              onClick={() => changeDot(1)}
                              id="pills-home-tab"
                              data-toggle="pill"
                              href="#pills-1"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 2 ? "active" : ""
                                }`}
                              onClick={() => changeDot(2)}
                              id="pills-profile-tab"
                              data-toggle="pill"
                              href="#pills-2"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 3 ? "active" : ""
                                }`}
                              onClick={() => changeDot(3)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-3"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 4 ? "active" : ""
                                }`}
                              onClick={() => changeDot(4)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-4"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 5 ? "active" : ""
                                }`}
                              onClick={() => changeDot(5)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-5"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 6 ? "active" : ""
                                }`}
                              onClick={() => changeDot(6)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-6"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 7 ? "active" : ""
                                }`}
                              onClick={() => changeDot(7)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-7"
                              role="tab"
                            ></a>
                          </li>

                        </ul>
        
                        <button
                          className="btn btn-primary btnNext mnn"
                          onClick={() => changeDot(5)}
                          id="reverse"
                        >
                          {t("user.Questionnare.backwards")}
                        </button>
                        <button
                          className="btn btn-primary btnNext"
                          onClick={nextpage}
                          onSubmit={nextpage}
                        >
                          {btntext}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`tab-pane fade commonbg-question
                     ${counttab === 7 ? "show active" : ""}`}
                    id="pills-7"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                  >
                    <div className="row rimani-bg question-w on-responsive mx-0">
                      <div className="btnNext-top">
                        <button
                          className="btn btn-primary btnNext ipdnn"
                          onClick={() => handleSubmitData()}>
                          {t('user.steps.pg_1.text.nxtbtn')}
                        </button>
                      </div>
                      <div className="col-md-2"></div>
                      <div className="col-md-8 tab-fully">
                        <div className="auth question_steps">
                          <div className="icon-aut relative">
                            <img className="center-attendi" src={Rimani} alt="icon" />
                            <h2 className="otp-heading mobw100 questionnaire-new-heading">
                              {t("user.Questionnare.stay_still_heading")}
                            </h2>
                            <p className="text-center"
                              dangerouslySetInnerHTML={{
                                __html: t("user.Questionnare.stay_still_content"),
                              }}
                            ></p>
                            {showalert ? (
                              <div
                                className={`newalertred alert alert-${alertclass}`}
                              >
                                {alert}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 visiblity-hide"></div>
                    </div>
                    <div id="btnNext">
                      <div className="fit-bdots">
                        <ul
                          className="nav nav-pills nav-fill"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 1 ? "active" : ""
                                }`}
                              onClick={() => changeDot(1)}
                              id="pills-home-tab"
                              data-toggle="pill"
                              href="#pills-1"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 2 ? "active" : ""
                                }`}
                              onClick={() => changeDot(2)}
                              id="pills-profile-tab"
                              data-toggle="pill"
                              href="#pills-2"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 3 ? "active" : ""
                                }`}
                              onClick={() => changeDot(3)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-3"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 4 ? "active" : ""
                                }`}
                              onClick={() => changeDot(4)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-4"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 5 ? "active" : ""
                                }`}
                              onClick={() => changeDot(5)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-5"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 6 ? "active" : ""
                                }`}
                              onClick={() => changeDot(6)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-6"
                              role="tab"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${counttab === 7 ? "active" : ""
                                }`}
                              onClick={() => changeDot(7)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-7"
                              role="tab"
                            ></a>
                          </li>

                        </ul>
                        <button
                          className="btn btn-primary btnNext mnn"
                          onClick={() => changeDot(6)}
                          id="reverse"
                        >
                          {t("user.Questionnare.backwards")}
                        </button>
                        <button
                          className="btn btn-primary btnNext"
                          onClick={nextpage}
                          onSubmit={nextpage}
                        >
                          {btntext}
                        </button>
                      </div>
                    </div>
                  </div> */}


                </div>

              </div>
            </div>
          </div>
          {isLoading ? <LazyLoader /> : null}
        </div>
      </section>
    </>
  );
};

export default Questionnaire;
