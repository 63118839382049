import React, { useEffect } from "react";
import Lottie from 'react-lottie';
import animationData from './animation.json';

const Backdrop = ({ activeColor, trackIndex, isPlaying }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
};

  useEffect(() => {
    document.documentElement.style.setProperty("--active-color", activeColor);
  }, [trackIndex, activeColor]);

  return  <Lottie options={defaultOptions} isClickToPause={isPlaying} />
};

export default Backdrop;
