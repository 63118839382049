import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/Image/logo.svg";
import SidebarProfile from "../common/sidebarProfile";
import Sidebarmob from "../common/sidebarMobile";
import leftarrow from "../../assets/Image/leftarrow.svg";
import { useTranslation } from "react-i18next";
import { getLocalStoarge } from "../common/allfunction";

function brainwellnessSciencePrivacyPolicy() {
    const [t, i18n] = useTranslation('common');
    const [mentalData, setMental] = useState(false);
    const [mindfulnessData, setMindfulness] = useState(false);
    const [cognitiveData, setCognitiveData] = useState(false);

    useEffect(() => {
        handleTranslationLang();
    }, []);


    const handleTranslationLang = () => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang)
        }
    }

    return (
        <>
            <section className="bg-privacy faqs dashboard-mobile">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-2 topspace none-viewport ipad-widhset">
                            <SidebarProfile />
                        </div>
                        <div className="col-md-10 ipd-righ">
                            <div className="pt-5 pb-3">
                                <h1>Privacy Policy of <strong>Brain Wellness©</strong></h1>
                                <p>This privacy policy refers to the platform called <a href="https://www.brainwellness.it/" target="_blank" rel="noopener">www.brainwellness.it</a> (hereinafter "Brain Wellness©") owned by the company Neocogita S.r.l.</p>
                            </div>

                            <div className="pt-5 pb-3">
                                <h2>Owner and Data Controller</h2>
                                <p>Neocogita S.r.l. Via Rialto, 73 38068 Rovereto (TRENTO) - Italy</p>
                                <p><b>Owner contact email:</b> info@neocogita.com</p>

                                <h3>Service supplied by Brain Wellness©</h3>
                                <p>Brain Wellness© is an online platform which is meant to be used mostly by the Participants chosen by the Clients which join the consultancy and training programme supplied by the Owner.
                                    Through the platform Brain Wellness© the Participant can answer questionnaires and/or practice the exercises available in order to strengthen the knowledge gained during the training programme undertaken.<br />
                                    The platform is meant to be used by Participants, Clients and the teachers (Psychologists and Coaches). Each of the said User must respect the limits set forth for the access credentials linked to their type of account.</p>
                            </div>


                            <div className="pt-5 pb-3">
                                <h2>Types of Data collected</h2>
                                <p>
                                    Among the types of Personal Data that Brain Wellness© collects, by itself or through third parties, there are:
                                    first name, last name, gender, date of birth, profession, workplace, language, Cookies, Usage Data and email address.
                                </p>

                                <p>Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data collection.<br />The Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when using Brain Wellness©.<br />All Data requested by Brain Wellness© is mandatory and failure to provide this Data may make it impossible for Brain Wellness© to provide its services. In cases where Brain Wellness© specifically states that some Data is not mandatory, Users are free not to communicate this Data without any consequences on the availability or the functioning of the service.<br />Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner.<br />Any use of Cookies — or of other tracking tools — by Brain Wellness© or by the owners of third-party services used by Brain Wellness© serves the purpose of providing the service required by the User, in addition to any other purposes described in the present document and in the Cookie Policy, if available.</p>
                                <p>Users are responsible for any third-party Personal Data obtained, published or shared through Brain Wellness©.</p>
                                <h3>Sensitive Data</h3>
                                <p>Brain Wellness© could collect some Sensitive Data as well as some data related to the statistics of usage of the platform Brain Wellness©, which are necessary for supplying the Service.<br />
                                    Among the Sensitive Data collected there may be various data related to the health conditions of the Participant, including but not limited to, data related to the level of stress, anxiety and resilience <i>etc</i>. which are automatically assigned according to the results obtained upon completion of the questionnaires and exercises available.<br />
                                    Such Sensitive Data are collected directly from the Participant and, specifically, via the questionnaires and/or the practicing of the exercises that are available on the platform and the supply of which constitutes the scope and main object of the Service.<br />
                                    The only individuals authorised to get access to any of such Sensitive Data are the medical personnel and, specifically, the Psychologists that run the educational programme attended by the Users. Such individuals are data controller of the Sensitive Data. Neocogita S.r.l. is not the controller of the Sensitive Data of the User and it is prevented from even accessing them.<br />
                                    Neocogita is however authorised to host such Sensitive Data on its servers and can process them in its quality of appointed data processor and strictly within the limits set forth in the appointment agreement entered into with these controllers.</p>
                            </div>


                            <div className="pt-5 pb-3">
                                <h2>Mode and place of processing the Data</h2>

                                <h3>Methods of processing</h3>

                                <p>The Data Controller processes the Data of Users in a proper manner and shall take appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data.<br />The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Data Controller, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of the site (administration, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Data Controller at any time.</p>
                                <p>All the Sensitive Data collected via Neocogita, including the the Data related to the statistics of use of the platform, are processed in compliance with the highest security standards which minimise the risks of dissemination, modification, destruction, loss, unauthorised or not permitted access. In particular, such Data are separately stored and processed exclusively by people authorized to do so.<br />
                                    For further information, please contact the Owner.</p>

                                <h3>Place</h3>

                                <p>The Data is processed at the Data Controller's operating offices and in any other places where the parties involved with the processing are located. For further information, please contact the Data Controller.</p>

                                <h3>Retention time</h3>

                                <p>The Data is kept for the time necessary to provide the service requested by the User, or stated by the purposes outlined in this document, and the User can always request that the Data Controller suspend the processing or cancel the Data.<br />
                                    As for the Sensitive Data, Participants acknowledge and agree that, after 10 weeks starting from the termination of the supplying of the Service, their access credentials shall be revoked and destroyed and such Sensitive Data shall be anonymised and, prior Participants' consent, transferred to the Owner for scientific research and statistical purposes as well as for improving the Service. The Owner guarantees that the Data resulting from such processing shall be used solely for the above mentioned purposes and always in aggregate and anonymous fashion. In particular, as for the anonymisation process, once the Sensitive Data are anonymised, it shall be no longer possible, even for the original data controller itself, to identify the Data Subject. After such anonymisation has taken place, none of the Data Subjects shall possibly be identified or identifiable by using any of the technical means commonly available, as such process is irreversible.</p>
                            </div>

                            <div className="pt-5 pb-3">
                                <h2>The use of the collected Data</h2>
                                <p>
                                    The Data concerning the User is collected to allow the Owner to provide its Service, comply with its legal obligations, respond to enforcement requests, protect its rights and interests (or those of its Users or third parties), detect any malicious or fraudulent activity, as well as the following:
                                    Registration and authentication, Analytics, Managing contacts and sending messages and Hosting and backend infrastructure.
                                </p>
                                <p>The Personal Data used for each purpose is outlined in the specific sections of this document.</p>

                            </div>

                            <div className="pt-5 pb-3">
                                <h2>Detailed information on the processing of Personal Data</h2>
                                <p>Personal Data is collected for the following purposes and using the following services:</p>

                                <div className="accordion pl-0 pt-3 none-plusicon" id="accordion1">
                                    <div className="card">
                                        <div className="card-header" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false">
                                            <span className="title" style={{ fontSize: "20px", fontWeight: "normal" }}>Analytics</span>
                                            <span className="accicon">
                                                <svg className="rotate-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                    <path d="M8 12L14 6V18L8 12Z"></path></svg>
                                            </span>
                                        </div>
                                        <div id="collapseOne" className="collapse" data-parent="#accordion1">
                                            <div className="card-body">
                                                <p>The services contained in this section enable the Owner to monitor and analyze web traffic and can be used to keep track of User behavior.</p>
                                                <h4>
                                                    Hotjar Form Analysis &amp; Conversion Funnels (Hotjar Ltd.)
                                                </h4>

                                                <p>Hotjar is an analytics service provided by Hotjar Ltd.<br />
                                                    Hotjar honors generic Do Not Track headers. This means your browser can tell its script not to collect any of your data. This is a setting that is available in all major browsers. Find Hotjar’s <a href="https://www.hotjar.com/opt-out" target="_blank" rel="noopener">opt-out information here</a>.</p>

                                                <p>
                                                    Personal Data processed:
                                                    Cookies and Usage Data.
                                                </p>
                                                <p>Place of processing:
                                                    Malta
                                                    –
                                                    <a href="https://www.hotjar.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> – <a href="https://www.hotjar.com/opt-out " target="_blank">Opt Out</a>.
                                                </p>
                                                <h4>
                                                    Google Analytics (Google Inc.)
                                                </h4>

                                                <p>Google Analytics is a web analysis service provided by Google Inc. (“Google”). Google utilizes the Data collected to track and examine the use of Brain Wellness©, to prepare reports on its activities and share them with other Google services.<br />
                                                    Google may use the Data collected to contextualize and personalize the ads of its own advertising network.</p>
                                                <p>
                                                    Personal Data processed:
                                                    Cookies and Usage Data.
                                                </p>
                                                <p>Place of processing:
                                                    US
                                                    –
                                                    <a href="https://www.google.com/intl/en/policies/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a> – <a href="https://tools.google.com/dlpage/gaoptout?hl=en" onclick="javascript:return tryFunc('tryGaOptOut',{href:'https://tools.google.com/dlpage/gaoptout?hl=en'})" target="_blank">Opt Out</a>.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion pl-0 pt-3 none-plusicon" id="accordion2">
                                    <div className="card">
                                        <div className="card-header" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false">
                                            <span className="title" style={{ fontSize: "20px", fontWeight: "normal" }}>Hosting and backend infrastructure</span>
                                            <span className="accicon">
                                                <svg className="rotate-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                    <path d="M8 12L14 6V18L8 12Z"></path></svg>
                                            </span>
                                        </div>
                                        <div id="collapseTwo" className="collapse" data-parent="#accordion2">
                                            <div className="card-body">
                                                <p>This type of service has the purpose of hosting data and files that enable Brain Wellness© to run and be distributed as well as to provide a ready-made infrastructure to run specific features or parts of Brain Wellness©.
                                                    Some of these services work through geographically distributed servers, making it difficult to determine the actual location where the Personal Data are stored.</p>

                                                <h4>
                                                    Chino.io (Chino s.r.l.s.)
                                                </h4>

                                                <p>Chino.io is a hosting and backend service provided by the company Chino s.r.l.s.</p>
                                                <p>
                                                    Personal Data processed:
                                                    various types of Data as specified in the privacy policy of the service.
                                                </p>

                                                <p>Place of processing:
                                                    Italy
                                                    –
                                                    <a href="https://www.chino.io/legal/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                                                </p>

                                                <h4>
                                                    Openshift (Red Hat, Inc.)
                                                </h4>

                                                <p>Openshift is a hosting and backend service provided by Red Hat, Inc.</p>

                                                <p>
                                                    Personal Data processed:
                                                    various types of Data as specified in the privacy policy of the service.
                                                </p>

                                                <p>Place of processing:
                                                    USA
                                                    –
                                                    <a href="https://www.redhat.com/en/about/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="accordion pl-0 pt-3 none-plusicon" id="accordion3">
                                    <div className="card">
                                        <div className="card-header" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false">
                                            <span className="title" style={{ fontSize: "20px", fontWeight: "normal" }}>Managing contacts and sending messages</span>
                                            <span className="accicon">
                                                <svg className="rotate-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                    <path d="M8 12L14 6V18L8 12Z"></path></svg>
                                            </span>
                                        </div>
                                        <div id="collapseThree" className="collapse" data-parent="#accordion3">
                                            <div className="card-body">
                                                <p>This type of service makes it possible to manage a database of email contacts, phone contacts or any other contact information to communicate with the User.<br />
                                                    These services may also collect data concerning the date and time when the message was viewed by the User, as well as when the User interacted with it, such as by clicking on links included in the message.</p>

                                                <h4>
                                                    Mailchimp (The Rocket Science Group, LLC.)
                                                </h4>

                                                <p>Mailchimp is an email address management and message sending service provided by Intuit Inc.</p>
                                                <p>
                                                    Personal Data processed:
                                                    email address.
                                                </p>
                                                <p>Place of processing:
                                                    US
                                                    –
                                                    <a href="https://mailchimp.com/legal/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="accordion pl-0 pt-3 none-plusicon" id="accordion4">
                                    <div className="card">
                                        <div className="card-header" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false">
                                            <span className="title" style={{ fontSize: "20px", fontWeight: "normal" }}>Registration and authentication</span>
                                            <span className="accicon">
                                                <svg className="rotate-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                    <path d="M8 12L14 6V18L8 12Z"></path></svg>
                                            </span>
                                        </div>
                                        <div id="collapseFour" className="collapse" data-parent="#accordion4">
                                            <div className="card-body">
                                                <p>By registering or authenticating, Users allow Brain Wellness© to identify them and give them access to dedicated services.<br />
                                                    Depending on what is described below, third parties may provide registration and authentication services. In this case, Brain Wellness© will be able to access some Data, stored by these third party services, for registration or identification purposes.</p>
                                                <h4>
                                                    Direct registration (Brain Wellness©)
                                                </h4>

                                                <p>The User registers by filling out the registration form and providing the Personal Data directly to Brain Wellness©.</p>
                                                <p>
                                                    Personal Data processed:
                                                    date of birth, first name, gender, language, last name, profession and workplace.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="pt-5 pb-3">
                                <h2>Further information about the processing of Personal Data</h2>
                                <div className="accordion pl-0 none-plusicon" id="accordionExample1">
                                    <div className="card">
                                        <div className="card-header" data-toggle="collapse" data-target="#collapseOne1" aria-expanded="false">
                                            <span className="title" style={{ fontSize: "20px", fontWeight: "normal" }}> SparkPost</span>
                                            <span className="accicon">
                                                <svg className="rotate-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                    <path d="M8 12L14 6V18L8 12Z"></path></svg>
                                            </span>
                                        </div>
                                        <div id="collapseOne1" className="collapse" data-parent="#accordionExample1">
                                            <div className="card-body">
                                                <p>SparkPost is an email address management and message sending service provided by  Message Systems, Inc.<br />[Place] US<br />[Privacy Policy] <a href="https://www.sparkpost.com/policies/privacy/" target="_blank" rel="noopener">https://www.sparkpost.com/policies/privacy/</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion pl-0 pt-3 none-plusicon" id="accordionExample2">
                                    <div className="card">
                                        <div className="card-header" data-toggle="collapse" data-target="#collapseTwo2" aria-expanded="false">
                                            <span className="title" style={{ fontSize: "20px", fontWeight: "normal" }}>Hosting and backend infrastructure</span>
                                            <span className="accicon">
                                                <svg className="rotate-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                    <path d="M8 12L14 6V18L8 12Z"></path></svg>
                                            </span>
                                        </div>
                                        <div id="collapseTwo2" className="collapse" data-parent="#accordionExample2">
                                            <div className="card-body">
                                                <p>This type of service has the purpose of hosting data and files that enable Brain Wellness© to run and be distributed as well as to provide a ready-made infrastructure to run specific features or parts of Brain Wellness©.
                                                    Some of these services work through geographically distributed servers, making it difficult to determine the actual location where the Personal Data are stored.</p>

                                                <h4>
                                                    Chino.io (Chino s.r.l.s.)
                                                </h4>

                                                <p>Chino.io is a hosting and backend service provided by the company Chino s.r.l.s.</p>
                                                <p>
                                                    Personal Data processed:
                                                    various types of Data as specified in the privacy policy of the service.
                                                </p>

                                                <p>Place of processing:
                                                    Italy
                                                    –
                                                    <a href="https://www.chino.io/legal/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                                                </p>

                                                <h4>
                                                    Openshift (Red Hat, Inc.)
                                                </h4>

                                                <p>Openshift is a hosting and backend service provided by Red Hat, Inc.</p>

                                                <p>
                                                    Personal Data processed:
                                                    various types of Data as specified in the privacy policy of the service.
                                                </p>

                                                <p>Place of processing:
                                                    USA
                                                    –
                                                    <a href="https://www.redhat.com/en/about/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* /////////////////////////// */}

                            <div className="pt-5 pb-3">
                                <h2>Cookie Policy</h2>
                                <p>Brain Wellness© uses Trackers. To learn more, Users may consult the <a href="//www.iubenda.com/privacy-policy/7952831/cookie-policy" title="Cookie Policy" className="show_comp_link iframe-preserve an-preserve">Cookie Policy</a>.</p>
                            </div>

                            <div className="pt-5 pb-3">
                                <h2>Additional information about Data collection and processing</h2>
                                <h3>Data sharing with other Users of Brain Wellness©</h3>
                                <p>Brain Wellness© is a closed platform whose use is limited to registered Users.<br />
                                    The Participants' Sensitive Data can be accessed exclusively by the Psychologists who are tasked with monitoring the Participants' performance and checking the completion of the exercises and procedures which may vary according to the programme attended by the same Participants.<br />
                                    Sensitive Data shall not in any way be accessible to the Owner or to any other Users registered on Brain Wellness©.<br />
                                    In particular, the Coach, while part of the teaching team who delivers the courses attended by the Participants, can only use Brain Wellness© to check on the state of completion of the programme by each Participant assigned to him/her. Under no circumstances shall the Coaches  have access to Sensitive Data or Participant Personal Data with the exception of their name, surname and the percentage of completion of the programme by each Participant assigned to him/her (e.g. 25% of programme completed).</p>
                                <p>In like manner, the Clients are exclusively able access to the name, surname and the percentage of completion of the programme by each of their Participants.<br />
                                    <strong>By using the Service, the Client warrants that it has fully and adequately informed the Participants about the Service and that it has collected all the mandatory consents as requested by law, including but not limited to, those requested by the data protection law that applies. As a consequence, the Client indemnifies and holds the Owner harmless from any possible claim, raised by the Participants or by any third party, that may arise as as result of its failure to comply with the law.</strong></p>

                                <h3>Unique identification code</h3>
                                <p>Upon completion of the registration process, each User is assigned a unique identifier code. Such code is used by Brain Wellness© in order to identify Users and to carry out statistics concerning the use of Brain Wellness© without using personal identifiable information of the User.</p>


                                <h3>Legal action</h3>
                                <p>The User's Personal Data may be used for legal purposes by the Data Controller, in Court or in the stages leading to possible legal action arising from improper use of Brain Wellness© or the related services.<br />
                                    The User declares to be aware that the Data Controller may be required to reveal personal data upon request of public authorities.</p>

                                <h3>Additional information about User's Personal Data</h3>
                                <p>
                                    In addition to the information contained in this privacy policy, Brain Wellness© may provide the User with additional and contextual information concerning particular services or the collection and processing of Personal Data upon request.
                                </p>

                                <h3>System logs and maintenance</h3>
                                <p>
                                    For operation and maintenance purposes, Brain Wellness© and any third-party services may collect files that record interaction with Brain Wellness© (System logs) or use other Personal Data (such as the IP Address) for this purpose.
                                </p>

                                <h3>Information not contained in this policy</h3>
                                <p>
                                    More details concerning the collection or processing of Personal Data may be requested from the Data Controller at any time. Please see the contact information at the beginning of this document.
                                </p>

                                <h3>The rights of Users based on the General Data Protection Regulation (GDPR)</h3>
                                <p>
                                    Users have the right, at any time, to know whether their Personal Data has been stored and can consult the Data Controller to learn about their contents and origin, to verify their accuracy or to ask for them to be supplemented, cancelled, updated or corrected, or for their transformation into anonymous format or to block any data held in violation of the law, as well as to oppose their treatment for any and all legitimate reasons. Requests should be sent to the Data Controller at the contact information set out above.
                                </p>
                                <p>
                                    Brain Wellness© does not support “Do Not Track” requests.<br />To determine whether any of the third-party services it uses honor the “Do Not Track” requests, please read their privacy policies.
                                </p>
                                <h3>Changes to this privacy policy</h3>
                                <p>
                                    The Data Controller reserves the right to make changes to this privacy policy at any time by giving notice to its Users on this page. It is strongly recommended to check this page often, referring to the date of the last modification listed at the bottom. If a User objects to any of the changes to the Policy, the User must cease using Brain Wellness© and can request that the Data Controller remove the Personal Data. Unless stated otherwise, the then-current privacy policy applies to all Personal Data the Data Controller has about Users.
                                </p>
                            </div>
                            <div className="accordion pl-0 none-plusicon" id="accordionExampleEnd">
                                <div className="card">
                                    <div className="card-header" data-toggle="collapse" data-target="#collapseEnd" aria-expanded="false">
                                        <span className="title" style={{ fontSize: "20px", fontWeight: "normal" }}>Definitions and legal references</span>
                                        <span className="accicon">
                                            <svg className="rotate-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                <path d="M8 12L14 6V18L8 12Z"></path></svg>
                                        </span>
                                    </div>
                                    <div id="collapseEnd" className="collapse" data-parent="#accordionExampleEnd">
                                        <div className="card-body">
                                            <h4>Personal Data (or Data)</h4>
                                            <p>Any information regarding a natural person, a legal person, an institution or an association, which is, or can be, identified, even indirectly, by reference to any other information, including a personal identification number.</p>
                                            <h4>Usage Data</h4>
                                            <p>Information collected automatically through Brain Wellness© (or third-party services employed in Brain Wellness©), which can include: the IP addresses or domain names of the computers utilized by the Users who use Brain Wellness©, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Application) and the details about the path followed within the Application with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment.</p>
                                            <h4>Data Subject</h4>
                                            <p>The legal or natural person to whom the Personal Data refers.</p>
                                            <h4>Data Processor (or Processor)</h4>
                                            <p>The natural person, legal person, public administration or any other body, association or organization authorized by the Data Controller to process the Personal Data in compliance with this privacy policy.</p>
                                            <h4>Data Controller (or Owner)</h4>
                                            <p>The natural person, legal person, public administration or any other body, association or organization with the right, also jointly with another Data Controller, to make decisions regarding the purposes, and the methods of processing of Personal Data and the means used, including the security measures concerning the operation and use of Brain Wellness©. The Data Controller, unless otherwise specified, is the Owner of Brain Wellness©.</p>
                                            <h4>Brain Wellness©</h4>
                                            <p>The means by which the Personal Data of the User is collected and processed.</p>
                                            <h4>Sensitive Data</h4>
                                            <p>The Participants' personal data concerning their health such as, including but not limited to, level of stress, resilience, anxiety which are automatically calculated on the basis of the results obtained from the tests and exercises available on Brain Wellness©.</p>
                                            <h4>User</h4>
                                            <p>Any user of the Service, whether a human being or legal entity. Unless stated otherwise, the word User refers to all the different types of accounts available on Brain Wellness©: Client, Coach, Participant and Psychologist.</p>
                                            <h4>Client</h4>
                                            <p>Each User who is an enterprise and/or organisation and avails itself of the consultancy and training programme provided by the Owner for its employees and/or workers.</p>
                                            <h4>Participant</h4>
                                            <p>Each User who is a employee or worker of the Client and participates in the training programme provided by the Owner.</p>
                                            <h4>Coach</h4>
                                            <p>Each User who is a coach and provides frontal lessons to the Participant during the training programme.</p>
                                            <h4>Psychologist</h4>
                                            <p>Each User who is a licensed psychologist and provides frontal lessons to the Participant during the training programme.</p>
                                            <h4>Cookies</h4>
                                            <p>Small sets of data stored in the User's device.</p>
                                            <hr />
                                            <h4>Legal information</h4>
                                            <p>Notice to European Users: this privacy statement has been prepared in fulfillment of the obligations under Art. 10 of EC Directive n. 95/46/EC, and under the provisions of Directive 2002/58/EC, as revised by Directive 2009/136/EC, on the subject of Cookies.</p>
                                            <p>This privacy policy relates solely to Brain Wellness©.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pt-5 pb-3">
                                <p>
                                    Latest update: May 25, 2018
                                </p>
                                <p>
                                    <a target="_top" href="https://www.iubenda.com/en/privacy-and-cookie-policy-generator" title="iubenda - Privacy Policy generator">iubenda</a> hosts this content and only collects <a target="_top" href="//www.iubenda.com/privacy-policy/65675001">the Personal Data strictly necessary</a> for it to be provided.
                                </p>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <div className="act-ve">
                <Sidebarmob />
            </div>
        </>
    );
}

export default brainwellnessSciencePrivacyPolicy;
