import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LazyLoader from "../../../common/lazyLoader";
import logo from "../../../../assets/Image/logo.svg";
import { getLocalStoarge } from '../../../common/allfunction';
import PlanningCase from "./PlanningCase.json";
import Img111 from "../../../../assets/Image/img111.png";
import Img222 from "../../../../assets/Image/img222.png";
import Img333 from "../../../../assets/Image/img333.png";
import Img444 from "../../../../assets/Image/img444.png";
import Img555 from "../../../../assets/Image/img555.png";
import Img666 from "../../../../assets/Image/img666.png";
import Img777 from "../../../../assets/Image/img777.png";
import Img888 from "../../../../assets/Image/img888.png";
import Img999 from "../../../../assets/Image/img999.png";
import Img1011 from "../../../../assets/Image/img1011.png";
import Img1111 from "../../../../assets/Image/img1111.png";
import Img1211 from "../../../../assets/Image/img1211.png";
import { PlanningResult } from "./PlanningResult";

export const Planning = (props) => {
    const [t, i18n] = useTranslation("common");
    const [isLoading, setIsLoading] = useState(false);
    const [round, setRound] = useState(1);
    const [maxRound, setMaxRound] = useState(12);
    const [positionGivenToUser, setPositionGivenToUser] = useState({});
    const [positionWantFromUser, setpositionWantFromUser] = useState({});
    const [moves, setMoves] = useState();
    const [img, setImg] = useState();
    const [items, setItems] = useState([]);
    const [items_1, setItems_1] = useState([]);
    const [items_2, setItems_2] = useState([]);
    const [itemsLength, setItemLength] = useState();
    const [items_1Length, setItem_1Length] = useState();
    const [items_2Length, setItem_2Length] = useState();
    const [steps, setSteps] = useState(0);
    const [gameOver, setGameOver] = useState(false);
    const [roundStatus, setRoundStatus] = useState(false);
    const [transformYellow, setTransformYellow] = useState('none');
    const [transformBrown, setTransformBrown] = useState('none');
    const [transformBlue, setTransformBlue] = useState('none');
    const [score, setScore] = useState(0);
    const [scoreCurrentRound, setScoreCurrentRound] = useState(3);
    const component_id = props?.match?.params?.component_id;
    const repeat_time = props?.match?.params?.repeat_time;
    const day_sequence = props?.match?.params?.day_sequence;
    const activity_id = props?.match?.params?.activity_id;
    const [theme, setTheme] = useState(JSON.parse(localStorage.getItem("theme")));
    const [dragItemId, setDragItemId] = useState();
    const [free, setFree] = useState(props?.match?.params?.free);

    useEffect(() => {
        document.body.className = (theme) ? theme : 'light';
    }, [theme])

    useEffect(() => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, [])


    const totalSteps = 3;
    // const [moves, setMoves] = useState(moves)

    console.log("positionGivenToUser=>", positionGivenToUser);
    console.log("positionWantFromUser=>", positionWantFromUser);

    useEffect(() => {
        if (round && round > 0) {
            setRoundStatus(true)
            setIsLoading(true);

            const timeoutId = setTimeout(() => {
                setRoundStatus(false);
                setIsLoading(false);
            }, 1000);

            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [round])



    const change = () => {

        // update score
        if (round > 1) {
            setScore(score + scoreCurrentRound);
        }

        setScoreCurrentRound(3);
        // score updated
        var x = `case-${round}`;

        if (round && round <= maxRound) {

            var positionGivenToUser = PlanningCase[x].positionGivenToUser;
            setPositionGivenToUser(positionGivenToUser);

            var positionWantFromUser = PlanningCase[x].positionWantFromUser;
            setpositionWantFromUser(positionWantFromUser);

            var moves = PlanningCase[x].moves;
            setMoves(moves);

            var img = PlanningCase[x].positionWantFromUser_image;
            if (img === "img111") {
                setImg(Img111);
            }
            if (img === "img222") {
                setImg(Img222);
            }
            if (img === "img333") {
                setImg(Img333);
            }
            if (img === "img444") {
                setImg(Img444);
            }
            if (img === "img555") {
                setImg(Img555);
            }
            if (img === "img666") {
                setImg(Img666);
            }
            if (img === "img777") {
                setImg(Img777);
            }
            if (img === "img888") {
                setImg(Img888);
            }
            if (img === "img999") {
                setImg(Img999);
            }
            if (img === "img1011") {
                setImg(Img1011);
            }
            if (img === "img1111") {
                setImg(Img1111);
            }
            if (img === "img1211") {
                setImg(Img1211);
            }
        }
    }

    // useEffect(() => {
    //   console.log("round123", round);
    //   alert("round changed");
    //   change();
    // }, [])

    useEffect(() => {
        console.log("round123", round);
        // alert("round changed");
        change();
        setSteps(0);
        setItems([]);
        setItems_1([]);
        setItems_2([]);
    }, [round])



    if (positionGivenToUser?.droppable) {
        var a = function setData() {
            // console.log("positionGivenToUser.droppable",positionGivenToUser.droppable);
            var arr = Object.values(positionGivenToUser.droppable);
            // console.log("arr",arr);
            return arr;
        }
    }

    if (positionGivenToUser?.droppable_1) {
        var b = function setData_1() {
            // console.log("positionGivenToUser.droppable_1",positionGivenToUser.droppable_1);
            var arr = Object.values(positionGivenToUser.droppable_1);
            // console.log("arr",arr);
            return arr;
        }
    }

    if (positionGivenToUser?.droppable_2) {
        var c = function setData_2() {
            // console.log("positionGivenToUser.droppable_2",positionGivenToUser.droppable_2);    
            var arr = Object.values(positionGivenToUser.droppable_2);
            // console.log("arr",arr);
            return arr;
        }
    }

    //However below function is not in use but it is important for future, please don't delete it
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    /**
     * Moves an item from one list to another list.
     */
    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };

    const grid = 8;

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,
        background: isDragging ? "lightgreen" : "grey",
        ...draggableStyle,
    });

    const getListStyle = (isDraggingOver) => ({
        background: isDraggingOver ? "lightblue" : "lightgrey",
        padding: grid,
        width: 250,
    });





    useEffect(() => {
        if (positionGivenToUser?.droppable) {
            let arr = a();
            setItems(arr);
        }
    }, [positionGivenToUser])

    useEffect(() => {
        if (positionGivenToUser?.droppable_1) {
            let arr_1 = b();
            setItems_1(arr_1);
        }
    }, [positionGivenToUser])

    useEffect(() => {
        if (positionGivenToUser?.droppable_2) {
            let arr_2 = c();
            setItems_2(arr_2);
        }
    }, [positionGivenToUser])


    useEffect(() => {
        checkResult()
    }, [items, items_1, items_2])

    useEffect(() => {
        setRound(1);
    }, [])

    function checkResult() {

        // alert("Check result called");
        // console.log("positionWantFromUser_1",positionWantFromUser);
        console.log("items", items);
        console.log("items_1", items_1);
        console.log("items_2", items_2);
        // console.log("positionWantFromUser", positionWantFromUser)


        // console.log("positionWantFromUser.droppable",positionWantFromUser.droppable);
        // console.log("positionWantFromUser.droppable_1",positionWantFromUser.droppable_1);
        // console.log("positionWantFromUser.droppable_2",positionWantFromUser.droppable_2);


        //sometime positionWantFromUser.droppable are not present and gives error thats 
        // why we check condition before calling function

        var res_1 = true;
        var res_2 = true;
        var res_3 = true;
        if (positionWantFromUser?.droppable) {
            res_1 = arraysAreEqual(items, positionWantFromUser.droppable);
        }

        if (positionWantFromUser?.droppable_1) {
            res_2 = arraysAreEqual(items_1, positionWantFromUser.droppable_1);
        }

        if (positionWantFromUser?.droppable_2) {
            res_3 = arraysAreEqual(items_2, positionWantFromUser.droppable_2);
        }

        console.log("res_11", res_1);
        console.log("res_22", res_2);
        console.log("res_33", res_3);
        //  console.log("items",items);
        //  console.log("items_1",items_1);
        //  console.log("items_2",items_2);

        if ((res_1 === true) && (res_2 === true) && (res_3 === true)) {
            setRound(round + 1);
        }

    }


    function arraysAreEqual(arr1, arr2) {
        // console.log("arr1.length",arr1.length);
        // console.log("arr2.length",arr2.length);
        // alert("arraysAreEqual called");


        if (arr1.length !== arr2.length) {
            console.log("length are not same", arr1, "=", arr2);
            return false;
        }
        for (let i = 0; i < arr1.length; i++) {
            console.log("item, position want", arr1[i], arr2[i]);
            if (arr1[i] !== arr2[i]) {
                console.log("values are not same", arr1, arr2);
                return false;
            }
        }
        console.log("every thing is perfect", arr1, arr2);
        return true;
    }

    function getList(droppableId) {
        if (droppableId === "droppable") {
            return items;
        }
        if (droppableId === "droppable_1") {
            return items_1;
        }
        if (droppableId === "droppable_2") {
            return items_2;
        }
    }
    // a little function to help us with reordering the result
    const onDragEnd = (result) => {
        // console.log("result123", result);
        const { source, destination, draggableId, draggableOldStatus } = result;

        console.table([dragItemId, draggableId]);

        let div = document.getElementById(draggableId);

        // console.log("div.classList.add('translated-p')", div.classList.add('translated-p'));
        div.classList.add('translated-p');



        if (!destination) {
            return;
        }

        if ((source.index !== 0) || (destination.index !== 0)) {
            // alert("This case is not possible, Source and Destination both should have 0 index");
            return;
        }

        if (source.droppableId === destination.droppableId) {
            // alert("This case is not possible, You can not drag in same array")
            return;
            // const reorderedItems = reorder(
            //     getList(source.droppableId),
            //     source.index,
            //     destination.index
            //   );
            //   if(source.droppableId==="droppable"){
            //     setItems(reorderedItems);
            //    }

            //    if(source.droppableId==="droppable_1"){
            //     setItems_1(reorderedItems);
            //    }

            //    if(source.droppableId==="droppable_2"){
            //     setItems_2(reorderedItems);
            //    }
        } else {

            if (source.droppableId === 'droppable' && destination.droppableId === 'droppable_1') {

                //check length of destination before movement
                var destinationLength = getList(destination.droppableId).length;
                //  console.log("destinationLength", destinationLength);
                if (destinationLength >= 2) {
                    // alert("This case is not possible, This array contain max 2 items");
                    return;
                }
                const result = move(
                    getList(source.droppableId),
                    getList(destination.droppableId),
                    source,
                    destination
                );
                console.log("result_1=>", result);
                console.log("result.droppable_1", result.droppable_1.length)

                setItems(result.droppable);
                setItems_1(result.droppable_1);
                setSteps(steps + 1);

                //here we call a function which will compare result
                // checkResult();
            }

            if (source.droppableId === 'droppable' && destination.droppableId === 'droppable_2') {

                //check length of destination before movement
                var destinationLength = getList(destination.droppableId).length;
                //  console.log("destinationLength", destinationLength);
                if (destinationLength >= 1) {
                    // alert("This case is not possible, This array contain max 1 items");
                    return;
                }

                const result = move(
                    getList(source.droppableId),
                    getList(destination.droppableId),
                    source,
                    destination
                );
                console.log("result_1", result);
                setItems(result.droppable);
                setItems_2(result.droppable_2);
                setSteps(steps + 1);

                //here we call a function which will compare result
                // checkResult();
            }

            if (source.droppableId === 'droppable_1' && destination.droppableId === 'droppable_2') {

                //check length of destination before movement
                var destinationLength = getList(destination.droppableId).length;
                //  console.log("destinationLength", destinationLength);
                if (destinationLength >= 1) {
                    // alert("This case is not possible, This array contain max 1 items");
                    return;
                }

                const result = move(
                    getList(source.droppableId),
                    getList(destination.droppableId),
                    source,
                    destination
                );
                console.log("result_1", result);
                setItems_1(result.droppable_1);
                setItems_2(result.droppable_2);
                setSteps(steps + 1);

                //here we call a function which will compare result
                // checkResult();
            }

            if (source.droppableId === 'droppable_2' && destination.droppableId === 'droppable_1') {

                //check length of destination before movement
                var destinationLength = getList(destination.droppableId).length;
                // console.log("destinationLength", destinationLength);
                if (destinationLength >= 2) {
                    // alert("This case is not possible, This array contain max 2 items");
                    return;
                }

                const result = move(
                    getList(source.droppableId),
                    getList(destination.droppableId),
                    source,
                    destination
                );
                console.log("result_1", result);

                setItems_2(result.droppable_2);
                setItems_1(result.droppable_1);
                setSteps(steps + 1);

                //here we call a function which will compare result
                // checkResult();
            }

            if (source.droppableId === 'droppable_2' && destination.droppableId === 'droppable') {

                //check length of destination before movement
                var destinationLength = getList(destination.droppableId).length;
                //  console.log("destinationLength", destinationLength);
                if (destinationLength >= 3) {
                    // alert("This case is not possible, This array contain max 3 items");
                    return;
                }

                const result = move(
                    getList(source.droppableId),
                    getList(destination.droppableId),
                    source,
                    destination
                );
                console.log("result_1", result);
                setItems_2(result.droppable_2);
                setItems(result.droppable);
                setSteps(steps + 1);

                //here we call a function which will compare result
                // checkResult();
            }

            if (source.droppableId === 'droppable_1' && destination.droppableId === 'droppable') {

                //check length of destination before movement
                var destinationLength = getList(destination.droppableId).length;
                //  console.log("destinationLength", destinationLength);
                if (destinationLength >= 3) {
                    // alert("This case is not possible, This array contain max 3 items");
                    return;
                }

                const result = move(
                    getList(source.droppableId),
                    getList(destination.droppableId),
                    source,
                    destination
                );
                console.log("result_1", result);
                setItems_1(result.droppable_1);
                setItems(result.droppable);
                setSteps(steps + 1);

                //here we call a function which will compare result
                // checkResult();
            }
        }
    }

    const reset = () => {
        setSteps(0);
        // change();


        let arr = ['brown', 'yellow'];
        let arr_1 = ['blue'];
        let arr_2 = [];
        setItems(arr);
        setItems_1(arr_1);
        setItems_2(arr_2);

        if (scoreCurrentRound !== 0) {
            setScoreCurrentRound(scoreCurrentRound - 1);
        }
    }

    const handleDrag = (i) => {
        // alert();
        console.log("Dragging...", i)
        if (i == 'yellow') {
            setTransformYellow('00');
        }
        if (i == 'blue') {
            setTransformBlue(' translate(0px, 97px)');
        }
        if (i == 'brown') {
            setTransformBrown(' translate(0px, 97px)');
        }
    }

    useEffect(() => {
        //this condition working when steps are greater than moves in this case also score will reduce
        if (steps > moves && scoreCurrentRound > 0) {
            setScoreCurrentRound(scoreCurrentRound - 1);
        }
        console.log("score", score);
    }, [steps])


    // ----------------------------------------------------------------------------

    // Function to handle click event
    function handleClick(item) {
        let div = document.getElementById(item);
        console.log("div, hadleclick called class added", div);
        div.classList.remove('translated-p');


    }

    function addClass(item) {
        let div = document.getElementById(item);
        console.log("div, mouse in  class added", div);;
        div.classList.add('translated-p');

    }

    function removeClass(item) {
        let div = document.getElementById(item);
        // setDragItemId(item);
        console.log("div, mouse out class removed", div);
        div.classList.remove('translated-p');
    }




    return (
        <>
            {round <= maxRound ?
                <>
                    {/* {(isLoading) ? <LazyLoader /> : null} */}
                    <section className="planning-section">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="stepsin stpelog">
                                        <Link to="dashboard">
                                            <img src={logo} className="logo-steps" alt="logo" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="btnNext-top">
                                        {free ?
                                            <Link to={`/planning-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}/${free}`}>
                                                <button
                                                    className="btn btn-primary  btnNext"
                                                >
                                                    {t("user.activity.reaction_speed.back")}
                                                </button>
                                            </Link>
                                            :
                                            <Link to={`/planning-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}`}>
                                                <button
                                                    className="btn btn-primary  btnNext"
                                                >
                                                    {t("user.activity.reaction_speed.back")}
                                                </button>
                                            </Link>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="reaction-speed-p">
                                        <p className="reaction-speed-p-1">{t("user.activity.planning_activty.intro_heading")}</p>
                                        <p className="reaction-speed-p-2 planning-index-info" dangerouslySetInnerHTML={{ __html: t("user.activity.planning_activty.index_message") }}></p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="reaction-speed-step">
                                        <p>{round}/12</p>
                                    </div>
                                </div>
                            </div>

                            {/* <p>"score" {score} || "scoreCurrentRound"{scoreCurrentRound}</p> */}


                            {/* <h2>Activity-Planning  </h2>
                            <h3>Round No {round}</h3>
                            <h3>{steps + "/" + moves}</h3>
                            // <img src={img} />
                            <button onClick={() => reset()}>Reset</button> */}
                            <div className="row flex-column-reverse flex-sm-row">
                                <div className="col-md-8">
                                    <div className="planning-main">
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <ul>
                                                {/* <Droppable droppableId="droppable" style={{ paddingTop: items.length === 3 ? 120 : items.length === 2 ? 320 : items.length === 2 ? 420 : null }}> */}
                                                {/* <Droppable droppableId="droppable" style={{ paddingTop: items.length === 3 ? '120px' : items.length === 2 ? '220px' : '320px' }}> */}
                                                <Droppable droppableId="droppable">

                                                    {(provided, snapshot) => (
                                                        <div
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                        // style={{ paddingTop: items.length === 3 ? '120px' : items.length === 2 ? '220px' : '320px' }}
                                                        // style={getListStyle(snapshot.isDraggingOver)}
                                                        >
                                                            {items.map((item, index) => (
                                                                <Draggable key={item} draggableId={item} index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            className="card translated-p"
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            // onDrag={() => handleDrag(item)}
                                                                            // onClick={() => handleDrag(item)}
                                                                            id={item}
                                                                            // onClick={() => handleClick(item)}
                                                                            onPointerDown={() => removeClass(item)}
                                                                            onPointerUp={() => addClass(item)}
                                                                        // onMouseLeave={() => addClass(item)}

                                                                        // onMouseEnter={() => handleDrag(item)}
                                                                        // style={{ transform: item === 'brown' ? transformBrown : item === 'brown' ? transformBrown : item === 'yellow' ? transformYellow : null }}
                                                                        >
                                                                            {/* {item} */}
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </ul>
                                            <ul>
                                                <Droppable droppableId="droppable_1">
                                                    {(provided, snapshot) => (
                                                        <div
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                        // style={getListStyle(snapshot.isDraggingOver)}
                                                        // style={{ paddingTop: items_1.length === 2 ? '120px' : '220px' }}
                                                        >
                                                            {items_1.map((item, index) => (
                                                                <Draggable key={item} draggableId={item} index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            className="card translated-p"
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            id={item}
                                                                            // onClick={() => handleClick(item)}
                                                                            // onMouseEnter={() => removeClass(item)}
                                                                            onPointerDown={() => removeClass(item)}
                                                                            onPointerUp={() => addClass(item)}
                                                                        >
                                                                            {/* {item} */}
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </ul>
                                            <ul>
                                                <Droppable droppableId="droppable_2">
                                                    {(provided, snapshot) => (
                                                        <div
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                        // style={getListStyle(snapshot.isDraggingOver)}
                                                        >
                                                            {items_2.map((item, index) => (
                                                                <Draggable key={item} draggableId={item} index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            className="card translated-p"
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            id={item}
                                                                            // onClick={() => handleClick(item)}
                                                                            // onMouseEnter={() => removeClass(item)}
                                                                            onPointerDown={() => removeClass(item)}
                                                                            onPointerUp={() => addClass(item)}
                                                                        >
                                                                            {/* {item} */}
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </ul>

                                        </DragDropContext>
                                        {/* <br></br>
                                        <hr className="planning-hr"></hr> */}
                                    </div >

                                </div>
                                <div className="col-md-4">
                                    <div className="planning-moves">
                                        <p style={{ color: steps > moves ? 'red' : 'black' }}>{steps + "/" + moves} {t("user.activity.planning_activty.moves")}</p>
                                        <img src={img} style={{ height: '150px', width: '200px' }} />
                                        <div></div>
                                        {
                                            roundStatus ?
                                                <>
                                                    {/* <button className="btn btn-success reset-btn" style={{ border: "green" }}><mark> &#x2713; </mark></button> */}
                                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
                                                        <polyline fill="none" stroke="#42a047" stroke-miterlimit="10" stroke-width="4" points="6,27.5 17,38.5 42,13.5"></polyline>
                                                    </svg>
                                                    <p>{steps + "/" + moves}</p>
                                                </>
                                                :
                                                <button className={`btn btn-primary reset-btn`} onClick={() => reset()}>Reset</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
                :
                <PlanningResult props={{ "params": props.match.params, "score": score, 'free': free }} />
            }
        </>
    )
}
