import userIcon from "../../../assets/Image/userIcon.svg";
import emailIcon from "../../../assets/Image/emailIcon.svg";
import password from "../../../assets/Image/password.svg";
import hidePassword from "../../../assets/Image/hidePassword.svg";
import showPassword from "../../../assets/Image/showPassword.svg";
import logo from "../../../assets/Image/logo.svg";
import PREVIEWICONA from "../../../assets/Image/PREVIEWICONA.svg";
import { Link } from "react-router-dom";
import Group1027 from "../../../assets/Image/Group1027.svg";
import { useState, useEffect } from "react";
import PostRequest from "../../apiConnection/postRequest";
import LazyLoader from "../../common/lazyLoader"
import {  getDynamicCompany, setLocalStoarge, getLocalStoarge } from "../../common/allfunction";
import CHEVRON_ICONA_DOWN from '../../../assets/Image/CHEVRON_ICONA_DOWN.svg';
import CHEVRON_ICONA_UP from '../../../assets/Image/CHEVRON_ICONA_UP.svg';
import { useTranslation } from "react-i18next";

const Register = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [companyId, setCompanyId] = useState()
  const [inputValues, setInputValue] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [alerts, setAlerts] = useState({
    alert: "",
    alerttype: "",
    alertclass: "danger",
    showalert: false,
  });
  const { alert, alerttype, alertclass, showalert } = alerts;
  const [validation, setValidation] = useState({
    name: "",
    email: "",
    password: "",
    check1: "",
    check2: "",
    check3: "",
  });
  const react_app_check = process.env.REACT_APP_RUN_TIME;
  const uatUrl = process.env.REACT_APP_URL_PRIVACY_CONSENT_UAT;
  const productionUrl = process.env.REACT_APP_URL_PRIVACY_CONSENT_PRODUCTION;

  const [finalUrl, setFinalUrl] = useState((react_app_check === 'production') ? productionUrl : uatUrl);
  const [termConditionUrl, setTermConditionnUrl] = useState("/");
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState("/");
  const [consentText, setConsentText] = useState("");
  const [consentText1, setConsentText1] = useState("");
  const [checkedTerm, setCheckedTerm] = useState(false);
  const [checkedOne, setCheckedOne] = useState(false);
  const [checkedTwo, setCheckedTwo] = useState(false);
  const [checkedOneValue, setCheckedOneValue] = useState("");
  const [checkedTwoValue, setCheckedTwoValue] = useState("");
  const [checkedPolicy, setCheckedPolicy] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("it");
  const [t, i18n] = useTranslation("common");
 const [company_name, setCompanyName] = useState('');
 const [consentIdEN, setConsentIdEN] = useState('');
 const [consentIdIT, setConsentIdIT] = useState('');

  useEffect(() => {
    handleTranslationLang();
    getConsentsDetails();
    setAlerts("", "", "", false);
    setValidation({
      name: "",
      email: "",
      password: "",
      check1: "",
      check2: "",
      check3: ""
    });
  }, [selectedLanguage,company_name])

  useEffect(() => {
    async function fetchCompanyAPI() {
      const company = await getDynamicCompany(props.match.params.company_id); 
        setConsentIdEN(company.content.consent_id_en);
        setConsentIdIT(company.content.consent_id_it);
        setCompanyName(company.content.company_name);
        setSelectedLanguage((company.content.language === 0) ? 'it' : 'en');
        setLocalStoarge("language",(company.content.language === 0) ? 'it' : 'en');      
        if (company !== false) {
          setCompanyId(company.document_id);
        } else {
          window.location.href = "/app/invalid"
        }
     }
    // handleTranslationLang();
    fetchCompanyAPI();
  }, [])

  //handle submit updates
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValue({ ...inputValues, [name]: value });
    setAlerts("", "", "", false);
    setValidation({
      name: "",
      email: "",
      password: "",
    });
  };

  // password eye icon
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  const handleChangeCheckBox = (e) => {
    const { name, value } = e.target;
    if (name == "check1") {
      setCheckedTerm(!checkedTerm);
    } else if (name == "check2") {
      setCheckedOne(!checkedOne);
    }
    else if (name == "check3") {
      setCheckedPolicy(!checkedPolicy);
    }
    setValidation({
      name: "",
      email: "",
      password: "",
      check1: "",
      check2: "",
      check3: ""
    });

  }

  const checkValidation = () => {
    let errors = validation;
    //name validation
    let Nameregex = new RegExp(/^[a-zA-Z ]*$/);
    const name = inputValues.name;
    if (!name) {
      errors.name = t('user.login.name_req');
    } else if (!name.match(Nameregex) || parseInt(name.length) < 3 || isNaN(name) !== true) {
      errors.name = t('user.login.name_vali');
    } else {
      errors.name = "";
    }
    // email validation
    let Emailregex = new RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    const email = inputValues.email;
    if (!email) {
      errors.email = t('user.login.email_req');
    } else if (!email.match(Emailregex)) {
      errors.email = t('admin.profile.valid_admin_email');
    } else {
      errors.email = "";
    }
    //password validation
    const Passregex = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/
    );
    const password = inputValues.password;
    if (!password) {
      errors.password = t('user.login.password_req');
    } else if (password.length < 8) {
      errors.password =t('user.register.pwd-valid');
    } else if (password.length > 20) {
      errors.password =t('user.register.pwd-valid')
    } else if (!password.match(Passregex)) {
      errors.password = t('user.register.pwd-valid');
    } else {
      errors.password = "";
    }
    //checkbox validation
    if (!checkedTerm) {
      errors.check1 = t('user.register.check1');
    } else {
      errors.check1 = "";
    }
    if (!checkedOne) {
      errors.check2 =  t('user.register.check1');
    }
    else {
      errors.check2 = "";
    }

    if (!checkedPolicy) {
      errors.check3 =  t('user.register.check1');
    } else {
      errors.check3 = "";
    }
    setValidation(errors);
    console.log(errors)
    let return_val = true;
    Object.entries(errors).forEach(([key, value]) => {
      if (value !== "") return_val = false;
    });
    return return_val;
  };

  const do_Registration = async (e) => {
    e.preventDefault();
    setAlerts({
      alert: t('user.forgotpass.Forgot'),
      alerttype: "Info",
      alertclass: "info",
      showalert: true,
    });
    const { name, email, password } = inputValues;
    if (
      email == "" ||
      password == "" ||
      name == "" ||
      email == undefined ||
      password == undefined ||
      name == undefined
    ) {
      setAlerts({
        alert: t('user.login.all_field'),
        alerttype: "Error",
        alertclass: "danger",
        showalert: true,
      });
      return false;
    }

    const role_id = process.env.REACT_APP_ROLE_ID;

    // const user_schema_id = process.env.REACT_APP_USER_SCHEMA_ID;
    const company = companyId;

    setIsLoading(true);
    const validate = checkValidation();
    if (validate) {
      let acceptedValue = [checkedOneValue];
      if (checkedOne && checkedTwo) {
        acceptedValue = [checkedOneValue, checkedTwoValue];
      }
      try {
        const payload = {
          name,
          email,
          role_id,
          password,
          company,
          // user_schema_id,
          "user_consent": {
            "consent_id": (selectedLanguage == 'it') ? consentIdIT :  consentIdEN,
            "tc": checkedTerm,
            "tc_restrictive": checkedTerm,
            "pp": checkedTerm,
            "accepted": acceptedValue
          }
        };
        const registerResult = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REGISTER}`,
          payload
        );
        if (registerResult && registerResult.status === 200) {
          if (registerResult.data.code === 200) {
            setIsLoading(false);
            setAlerts({
              alert: t('user.login.register_email'),
              alerttype: "Success",
              alertclass: "success",
              showalert: true,
            });
            setTimeout(() => {
              window.location.href = `/app/login/${props.match.params.company_id}`;
            }, 5000);
          }
        } else if (registerResult && registerResult.status === 203) {
          setIsLoading(false);
          setAlerts({
            alert: (registerResult.data.data.res == "license") ? t("user.login.invalid_license") :t('user.register.term-text6'),
            alerttype: "Error",
            alertclass: "danger",
            showalert: true,
          });
        } else {
          setIsLoading(false);
          setAlerts({
            alert: t('user.register.term-text6'),
            alerttype: "Error",
            alertclass: "danger",
            showalert: true,
          });
        }
      } catch (error) {
        setIsLoading(false);
        setAlerts({
          alert: t('user.register.term-text6'),
          alerttype: "Error",
          alertclass: "danger",
          showalert: true,
        });
      }
    } else {
      setIsLoading(false);
      // setAlerts("", "", "", false);
      setAlerts({
        alert: "",
        alerttype: "",
        alertclass: "",
        showalert: false,
      });
    }
  };

  const handleTranslationLang = () => {
    let lang = getLocalStoarge("language");
    if (lang) {
      i18n.changeLanguage(lang);
    }
  };

  const getConsentsDetails = async (e) => {
    setIsLoading(true);
    console.log("consent", selectedLanguage, consentIdEN, consentIdIT)
    try {
      const payload = {
        consent_id: (selectedLanguage == 'it') ? consentIdIT : consentIdEN,
        // consent_id: (selectedLanguage == 'it') ? ((company_name == "Gellify") ? process.env.REACT_APP_CONSENT_ID_IT_GELLIFY : process.env.REACT_APP_CONSENT_ID_IT) : (company_name == "Gellify") ? process.env.REACT_APP_CONSENT_ID_EN_GELLIFY : process.env.REACT_APP_CONSENT_ID_EN,

      }
      const getConsents = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_CONSENT}`,
        payload
      );

      if (getConsents && getConsents.status === 200) {
        if (getConsents.data.code === 200) {
          setIsLoading(false);
          setPrivacyPolicyUrl(getConsents.data.data.privacy_policy.url);
          setTermConditionnUrl(getConsents.data.data.terms_and_condition.url);
          setConsentText(getConsents.data.data.purposes[0].description);
          setConsentText1(getConsents.data.data.purposes[1].description);
          setCheckedOneValue(getConsents.data.data.purposes[1].internal_id);
          setCheckedTwoValue(getConsents.data.data.purposes[0].internal_id);
        } else {
          setIsLoading(false);
          setAlerts({
            alert: "",
            alerttype: "",
            alertclass: "",
            showalert: '',
          });
        }

      } else {
        setIsLoading(false);
        setAlerts({
          alert: '',
          alerttype: "",
          alertclass: "",
          showalert: '',
        });
      }
    } catch (error) {
      setIsLoading(false);
      setAlerts({
        alert: t('user.login.server'),
        alerttype: "Error",
        alertclass: "danger",
        showalert: true,
      });
    }
  };


  const toggleButton = (e) => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleLanguageChange = (language) => {
    setLocalStoarge("language",language)
    setSelectedLanguage(language);
  };


  return (
    
      <div className="bg-imgmobile">
        <section className="m-top25m newccount commonipadbg">
          <div className="d-flex justify-content-end p-4 mr-3 align-item-center mt-0">
          <div className="dropdown">
                    <button
                      type="button"
                      className="dropdown-toggle bg-none"
                      onClick={(e) => toggleButton(e)}
                    >
                      {t("user.login.language")}
                      {(!isDropdownOpen) ? <img src={CHEVRON_ICONA_DOWN} className="ml-2" alt="img" /> : <img src={CHEVRON_ICONA_UP} className="ml-2" alt="img" />}
                    </button>
                    <div className="drop-down-menu-items">
                      <div className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
                        <p className="ml-2 p-0 mb-0 switch-para">
                          <input type="radio" name="language" className="cursor-pointer" id="italianRadio"
                            checked={selectedLanguage === 'it'}
                            onChange={() => handleLanguageChange('it')} />
                          <label className="ml-1 cursor-pointer" htmlFor="italianRadio">Italiano</label>
                        </p>
                        <p className="ml-2 p-0 mb-0 switch-para">
                          <input type="radio" name="language" className="cursor-pointer" id="englishRadio"
                            checked={selectedLanguage === 'en'}
                            onChange={() => handleLanguageChange('en')} />
                          <label className="ml-1 cursor-pointer" htmlFor="englishRadio">English</label>
                        </p>
                      </div>
                    </div>
                  </div>
          </div>
          <div className="container">
            <div className="logo-w">
              <Link to={`/login/${localStorage.getItem('dynamic_id')}`}>
                <img src={logo} className="logo" alt="logo" />
              </Link>
            </div>
            <div className="row top_30">
              <div className="forgot">
                <div className="icon-aut">
                  <img src={Group1027} className="key" alt="icon" />
                  {/* <img src={Group1015} className="key" alt="icon"/> */}
                  <h2 className="otp-heading">{t('user.register.new')}</h2>
                  <p>{t('user.register.instructions1')}</p>
                  <form
                    method="POST"
                    className="registrati-form"
                    onSubmit={(e) => do_Registration(e)}
                  >
                    {showalert ? (
                      <div className={`alert alert-${alertclass}`}>{alert}</div>
                    ) : (
                      <></>
                    )}
                    <div className="form-group relative">
                      <img src={userIcon} alt="icon" className="icon_left" />
                      <input
                        type="text"
                        placeholder={t('user.registration.placeholder')}
                        className="form-control"
                        onChange={handleChange}
                        name="name"
                        defaultValue={inputValues.name}
                      />
                      {console.log()}
                      {validation.name && (
                        <p className="danger password-validation">{validation.name}</p>
                      )}
                    </div>
                    <div className="form-group relative">
                      <img src={emailIcon} alt="icon" className="icon_left" />
                      <input
                        type="text"
                        placeholder="Email"
                        className="form-control"
                        onChange={handleChange}
                        name="email"
                        defaultValue={inputValues.email}
                      />
                      {validation.email && (
                        <p className="danger password-validation">{validation.email}</p>
                      )}
                    </div>
                    <div className="form-group relative">
                      <img src={password} alt="icon" className="icon_left" />
                      <input
                        // type="password"
                        type={isRevealPwd ? "text" : "password"}
                        placeholder="Password"
                        className="form-control"
                        onChange={handleChange}
                        name="password"
                        defaultValue={inputValues.password}
                      />
                      <img src={isRevealPwd ? hidePassword : showPassword}
                        onClick={() => setIsRevealPwd(prevState => !prevState)} alt="icon"
                        className="eye_icon_right" />
                      {validation.password && (
                        <p className="danger password-validation">{validation.password}</p>
                      )}
                    </div>

                    <small id="emailHelp" className="form-text11 mb-10">
                        {t('user.register.validation')}
                    </small>

                    <div className="align-checkboxs">
                      <div className="custom-control custom-checkbox register-checkbox">
                        <input type="checkbox" value={checkedTerm} onChange={handleChangeCheckBox} name="check1" className="custom-control-input" id="customCheck1" />
                        <label className="custom-control-label" htmlFor="customCheck1">
                          {t('user.register.term-text1')}
                          <a className="registercolor" href={`${finalUrl}/${(selectedLanguage == 'it') ? consentIdIT :  consentIdEN }/terms-and-conditions`} target={"_blank"} style={{ cursor: 'pointer' }}>

                            &nbsp;{t('user.register.term-text2')} </a>
                        </label>

                      </div>
                      {validation.check1 && (
                        <p className="danger ml-4">{validation.check1}</p>
                      )}
                      <div className="custom-control custom-checkbox register-checkbox">
                        <input type="checkbox" value={checkedPolicy} onChange={handleChangeCheckBox} name="check3" className="custom-control-input" id="customCheck3" />
                        <label className="custom-control-label" htmlFor="customCheck3">
                          {t('user.register.term-text3')}  &nbsp;
                          <a className="registercolor" href={`${finalUrl}/${(selectedLanguage == 'it') ? consentIdIT :  consentIdEN }/privacy-policy`} target={"_blank"} style={{ cursor: 'pointer' }}>{t('user.register.term-text4')}</a>
                        </label>

                      </div>
                      {validation.check3 && (
                        <p className="danger ml-4">{validation.check3}</p>
                      )}
                      <div className="custom-control custom-checkbox register-checkbox">
                        <input type="checkbox" value={checkedOne} onChange={handleChangeCheckBox} name="check2" className="custom-control-input" id="customCheck2" />
                        <label className="custom-control-label" htmlFor="customCheck2">{consentText1} *</label>

                      </div>
                      {validation.check2 && (
                        <p className="danger ml-4">{validation.check2}</p>
                      )}
                      <small className="form-text1">{t('user.register.term-text5')}</small>
                    </div>
                    <br />
                    <button type="submit" className="buttons">
                      {t('user.register.proceed')}
                    </button>
                    <Link to={`/login/${localStorage.getItem('dynamic_id')}`}>
                      <div className="torna">
                        <img src={PREVIEWICONA} alt="icon" /> {t('user.register.back')}
                      </div>
                    </Link>
                  </form>
                </div>
              </div>
            </div>
            {(isLoading) ? <LazyLoader /> : null}
          </div>
        </section>
        <p className="Powered">{t('user.register.powered')}</p>
      </div>
  );
};

export default Register;
