import React from 'react'
const { useState } = React;


function LazyLoader() {
    
  return (
    <div className="spinner-container">
      <div className="loading-spinner">
      </div>
    </div>
    
  );
}

export default LazyLoader